import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import InputNumberRange from '../../../components/Inputs/InputNumberRange';
import { InputText } from '../../../components/Inputs/InputText';
import MenuPopUp from '../../../components/MenuPopPup';
import { Dialog } from '../../../components/Modal';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import validateNumberRange from '../../../utils/numbers/validateNumberRange';
import openReports from '../../../utils/openReports';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import OsMenuHeader from '../osMenuHeader';
import { IContractDTO } from '../osPrintContract/dtos/IContractDTO';
import { OsReport } from '../osReport';
import { IOsList } from './dtos/IOsList';
import CalendarRange from '../../../components/Inputs/CalendarRange';
import { EnumPriority } from '../../../enum/EnumPriority';
import Prioridades from '../types/Prioridades';

const OsFormList: React.FC = () => {
  const hoje = new Date();
  /** hooks */
  const router = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  /** useStates */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [osReportVisible, setOsReportVisible] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>('');
  const [osNumber, setOsNumber] = useState<number>(0);
  const [kindAtendimento, setKindAtendimento] = useState<string>('');
  const [periodOpen, setPeriodOpen] = useState<Date[]>([
    startOfMonth(hoje),
    endOfMonth(hoje),
  ]);
  const [periodPrevision, setPeriodPrevision] = useState<Date[]>([
    startOfMonth(hoje),
    endOfMonth(addMonths(hoje, 1)),
  ]);
  const [priority, setPriority] = useState<string[]>([]);
  const [statusOs, setStatusOs] = useState<string[]>([]);
  const [totals, setTotals] = useState<number[]>([0, 100000]);

  const [statusOpts, setStatusOpts] = useState<IOptionsDTO[]>([]);
  const [status, setStatus] = useState<string>('');

  const [itensOs, setItensOs] = useState<IOsList[]>([]);

  const [contract_id, setContractId] = useState<string>('');
  const [contract, setContract] = useState<IContractDTO>({
    id: '',
    codigo: 0,
    title: 'NÃO ENCONTRADO!',
    text: 'NÃO ENCONTRADO!',
  });

  const [openPrintModal, setOpenPrintModal] = useState(false);

  /** function */
  const handleSubmit = async (d: any) => {
    if (!validateNumberRange(totals[0], totals[1])) {
      toast(
        'warn',
        'Alerta',
        `O total inicial não pode ser maior que o total  final!`,
      );
    } else {
      try {
        setLoadingStatus(true);
        const filter = {
          keyword: keyword || undefined,
          os_number: osNumber || undefined,
          kind_atendimento: kindAtendimento || undefined,
          period_open: periodOpen,
          period_prevision: periodPrevision,
          priority: priority,
          status_os: statusOs,
          total: totals,
          status: status || undefined,
        };
        const res = await api.post('/os/v1/search', { filter });

        setItensOs([...res.data.listOSs]);
      } catch (e: any) {
        if (e.code === 'ERR_BAD_REQUEST') {
          toast('warn', 'Alerta', e.response.data.error);
        } else {
          toast('error', 'Erro', e.message);
        }
      } finally {
        setLoadingStatus(false);
      }
    }
  };

  const loadOsList = async () => {
    setLoadingStatus(true);
    await api
      .post('/os/v1/search', {
        filter: {
          period_open: periodOpen,
          period_prevision: periodPrevision,
        },
      })
      .then(({ data }) => {
        setItensOs([...data.listOSs]);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_BAD_REQUEST') {
          toast('warn', 'Alerta', e.response.data.error);
        } else {
          toast('error', 'Erro', e.message);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  const loadOsStatus = async () => {
    await api
      .get('/os/status/list?page=1&perPage=50&status=A')
      .then(({ data }) => {
        const opts = data.listAllOsStatus.map((d: any) => {
          return {
            label: `${d.position}-${d.title}`,
            value: d.id,
          };
        });

        setStatusOpts(opts);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_BAD_REQUEST') {
          toast('warn', 'Alerta', e.response.data.error);
        } else {
          toast('error', 'Erro', e.message);
        }
      })
      .finally(() => setLoadingStatus(false));
  };

  const handleCancelOs = async (id: string) => {
    try {
      setLoadingStatus(true);
      const res = await api.delete(`/os/${id}`);
      if (res.status >= 200 && res.status <= 299) {
        toast('success', 'Sucesso', 'O.S cancelada com sucesso!');
        loadOsList();
      }
    } catch (e: any) {
      toast('warn', 'Error!', e?.response?.data?.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleSendWhatsapp = async (id: string) => {
    try {
      setLoadingStatus(true);
      const res = await api.get(`/os/link/${id}`);
      if (res.status >= 200 && res.status <= 299) {
        window.open(res.data.link);
      }
    } catch (e: any) {
      toast('warn', 'Error!', e?.response?.data?.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleSendOsMail = async (id: string) => {
    try {
      setLoadingStatus(true);
      const res = await api.post(`/os/mail/${id}`);
      if (res.status >= 200 && res.status <= 299) {
        toast(
          'success',
          'Email enviado',
          'O Email pode demorar um pouco para ser recebido pelo cliente. Caso não receba verifique a caixa de spam.',
        );
      }
    } catch (e: any) {
      toast('warn', 'Error!', e?.response?.data?.error);
    } finally {
      setLoadingStatus(false);
    }
  };

  const searchContractById = async (id: string) => {
    const { data } = await api.get(`/os/contract/${id}`);
    setContract({
      id: data.listByIdOsContract.id,
      codigo: data.listByIdOsContract.codigo,
      title: data.listByIdOsContract.title,
      text: data.listByIdOsContract.text,
    });
    return {
      title: data.listByIdOsContract.title,
      text: data.listByIdOsContract.text,
    };
  };

  useEffect(() => {
    loadOsList();
  }, []);

  useEffect(() => {
    loadOsStatus();
  }, []);

  /** render */
  const renderColumnStatus = (row: any) => {
    return (
      <>
        {row.status === 'A' && (
          <span className={`product-badge status-instock mx-1`}>A</span>
        )}

        {row.status === 'C' && (
          <span className={`product-badge status-outofstock mx-1`}>C</span>
        )}

        {row.os_registered && (
          <span className={`product-badge status-instock mx-1`}>R</span>
        )}

        {row.os_registered === false && (
          <span className={`product-badge status-outofstock mx-1`}>R</span>
        )}
      </>
    );
  };

  const renderColumnPriority = (row: any) => {
    return (
      <>
        {row.priority === EnumPriority.MEDIA && (
          <i className="fa-solid fa-flag" style={{ color: 'orange' }}></i>
        )}

        {row.priority === EnumPriority.BAIXA && (
          <i className="fa-solid fa-flag" style={{ color: 'green' }}></i>
        )}

        {row.priority === EnumPriority.ALTA && (
          <i className="fa-solid fa-flag" style={{ color: 'red' }}></i>
        )}
      </>
    );
  };

  const renderColumnOsStatus = (e: IOsList) => {
    if (e.color) {
      return (
        <Badge value={e.os_status} style={{ background: `#${e.color}` }} />
      );
    } else {
      return e.os_status;
    }
  };

  const renderColumnClose = (row: any) => {
    return (
      <>
        {row.close === true && <i className="fa-solid fa-lock"></i>}

        {row.close === false && (
          <i className="fa-solid fa-lock-open" style={{ color: 'green' }}></i>
        )}
      </>
    );
  };

  const mountMenu = (row: IOsList) => {
    const items: any[] = [];
    if (row.close === false) {
      items.push({
        label: 'Editar',
        icon: 'fa-solid fa-edit',
        command: () => {
          router.push(`/os/new/${row.id}`);
        },
      });
    }

    if (row.os_registered) {
      // OS já registrada no caixa
      items.push({
        label: 'Cancelar registro',
        icon: 'fa-solid fa-xmark',
        command: async () => {
          setLoadingStatus(true);
          await api
            .delete(`/register-cash-movimentations/v2/os/${row.id}`)
            .then(async () => {
              toast(
                'success',
                'Sucesso',
                'Registro da OS cancelado com sucesso!',
              );
              await loadOsList();
            })
            .catch((e: any) => {
              toast(
                'warn',
                'Alerta',
                'Falha ao cancelar o registro da OS: ' + e.response.data.error,
              );
            })
            .finally(() => setLoadingStatus(false));
        },
      });
    } else {
      // OS não registrada no caixa
      if (row.close) {
        items.push({
          label: 'Registrar OS',
          icon: 'fa-solid fa-circle-check',
          command: async () => {
            setLoadingStatus(true);
            await api
              .post(`/register-cash-movimentations/v2/os/${row.id}`)
              .then(async () => {
                toast(
                  'success',
                  'Sucesso',
                  'OS registrada no caixa com sucesso!',
                );
                await loadOsList();
              })
              .catch((e: any) => {
                toast(
                  'warn',
                  'Alerta',
                  'Falha ao registrar a OS: ' + e.response.data.error,
                );
              })
              .finally(() => setLoadingStatus(false));
          },
        });
      }
    }

    if (row.status !== 'C') {
      items.push(
        {
          label: 'Gerar Nfe',
          icon: 'fa-solid fa-file-lines',
          command: async () => {
            setLoadingStatus(true);
            await api
              .get(`/nfe/v2/emit/os/${row.id}`)
              .then(d => {
                if (d.status === 201) {
                  toast('success', 'Sucesso', 'Documento gerado com sucesso!');
                  router.push('/nfe/export/list');
                }
              })
              .catch(e => {
                toast('warn', 'Falha', messageRequestError(e), 10000);
              })
              .finally(() => setLoadingStatus(false));
          },
        },
        {
          label: 'Gerar Nfce',
          icon: 'fa-solid fa-file-lines',
          command: async () => {
            setLoadingStatus(true);
            await api
              .get(`/nfce/v2/emit/os/${row.id}`)
              .then(d => {
                if (d.status === 201) {
                  toast('success', 'Sucesso', 'Documento gerado com sucesso!');
                  router.push('/nfce/export/list');
                }
              })
              .catch(e => {
                toast('warn', 'Falha', messageRequestError(e), 10000);
              })
              .finally(() => setLoadingStatus(false));
          },
        },
        {
          label: 'Gerar Nfse',
          icon: 'fa-solid fa-file-invoice-dollar',
          command: async () => {
            setLoadingStatus(true);
            await api
              .post(`/nfse/${row.id}`)
              .then(d => {
                if (d.status === 201) {
                  toast(
                    'success',
                    'Sucesso',
                    'Nfse enviada para prefeitura, aguarando aprovação!',
                  );
                  router.push('/nfse/export/list');
                }
              })
              .catch(e => {
                toast('error', 'Erro', messageRequestError(e));
              })
              .finally(() => {
                setLoadingStatus(false);
              });
          },
        },
      );
    }

    items.push(
      {
        label: 'Imprime O.S',
        icon: 'fa-solid fa-print',
        command: async () => {
          await api
            .post(`/os/report/${row.id}`)
            .then(({ data }) => {
              openReports(
                `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
                'ordem-servico',
              );
            })
            .catch((e: any) => {
              toast('warn', 'Alerta', 'Falha ao construir o relatório!');
            })
            .finally(() => setLoadingStatus(false));
        },
      },
      {
        label: 'Imprime cupom',
        icon: 'fa-solid fa-print',
        command: async () => {
          await api
            .post(`/os/report/coupon/${row.id}`)
            .then(({ data }) => {
              openReports(
                `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.fileName}`,
                'ordem-servico-cupom',
              );
            })
            .catch((e: any) => {
              toast('warn', 'Alerta', 'Falha ao construir o relatório!');
            })
            .finally(() => setLoadingStatus(false));
        },
      },
      {
        label: 'Imprime Contrato',
        icon: 'fa-solid fa-print',
        command: () => {
          setOpenPrintModal(true);
          setContractId(row.contract_id);
          loadContractinModal(row.contract_id);
        },
      },
      {
        label: 'Cancelar',
        icon: 'fa-solid fa-xmark',
        command: async () => handleCancelOs(row.id),
      },
      {
        label: 'Envia email',
        icon: 'fa-solid fa-envelope',
        command: async () => handleSendOsMail(row.id),
      },
      {
        label: 'Envia whatsapp',
        icon: 'fa-brands fa-whatsapp',
        command: async () => handleSendWhatsapp(row.id),
      },
    );

    return items;
  };

  const renderCollumnMenu = (row: IOsList) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={() => { }} />
      </>
    );
  };

  const loadContractinModal = async (id_contract: string) => {
    const { title, text } = await searchContractById(id_contract);
    const contentHtml = `
      <html>
        <head>
        </head>
          <body>
            <h2>${title}</h2>
            ${text}
          </body>
      </html>
    `;
    var docs = iframeRef.current?.contentWindow?.document;
    docs?.open();
    docs?.write(contentHtml);
    docs?.close();
  };

  const print = () => {
    iframeRef.current?.contentWindow?.print();
  };

  const headerModalPrint = () => {
    return (
      <Button
        type="button"
        label="Imprimir"
        icon="fa-solid fa-print"
        onClick={print}
        style={{ display: 'block', marginBottom: '20px', marginLeft: '6px' }}
      ></Button>
    );
  };

  return (
    <>
      <OsMenuHeader />
      <Button
        label="Relatório"
        className="mb-2 mr-2"
        icon="fa-solid fa-print"
        onClick={() => setOsReportVisible(true)}
      />
      <div className="card my-2">
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="field col-12 md:col-4">
            <label htmlFor="keyword">Nome</label>
            <InputText
              name="keyword"
              placeholder="Ex.: João da Silva"
              value={keyword}
              onChange={e => setKeyword(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="kind_atendimento">Tipo atendimento</label>
            <InputDropDown
              name="kind_atendimento"
              options={[
                { label: 'Interno', value: 'INT' },
                { label: 'Externo', value: 'EXT' },
              ]}
              placeholder="Ex.: Interno"
              value={kindAtendimento}
              onChange={e => setKindAtendimento(e.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="period_open">Abertura entre</label>
            <CalendarRange
              name="period_open"
              value={periodOpen}
              onChange={e => {
                setPeriodOpen(e.value as Date[]);
                if (e.value) {
                  const dates: Date[] = e.value as Date[];

                  if (dates[0] !== null && dates[1] !== null)
                    setPeriodPrevision([dates[0], addMonths(dates[1], 3)]);
                }
              }}
              placeholder="00/00/00 ~ 00/00/00"
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="period_prevision">Previsão entre</label>
            <CalendarRange
              name="period_prevision"
              value={periodPrevision}
              onChange={e => setPeriodPrevision(e.value as Date[])}
              placeholder="00/00/00 ~ 00/00/00"
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              name="btn_search"
              label="Buscar"
              icon="fa-solid fa-search"
              type="submit"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="os_number">Número O.S</label>
            <InputNumber
              name="os_number"
              placeholder="Ex.: 705"
              value={osNumber}
              onChange={e => setOsNumber(e.value as number)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="priority">Prioridade</label>
            <MultiSelect
              name="priority"
              options={Prioridades}
              placeholder="Ex.: Alta"
              value={priority}
              onChange={e => setPriority(e.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="status_os">Situação</label>
            <MultiSelect
              name="status_os"
              options={statusOpts}
              placeholder="Ex.: Manutenção"
              value={statusOs}
              onChange={e => setStatusOs(e.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="status">Status doc.</label>
            <InputDropDown
              name="status"
              options={[
                { label: 'Ativo', value: 'A' },
                { label: 'Cancelado', value: 'C' },
              ]}
              placeholder="Ex.: Ativo"
              value={status}
              onChange={e => setStatus(e.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="totals">Valor entre</label>
            <InputNumberRange
              name={'totals'}
              defaultMinValue={0}
              defaultMaxValue={100000}
              range={totals}
              onMinValueChange={e => {
                setTotals([e as number, totals[1]]);
              }}
              onMaxValueChange={e => {
                setTotals([totals[0], e as number]);
              }}
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              name="btn_clear"
              label="Limpar"
              icon="pi pi-trash"
              className="p-button-danger"
              type="reset"
            />
          </div>
        </Form>
      </div>
      <div className="card">
        <DataTable
          value={itensOs}
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          emptyMessage="O.S não encontradas..."
          selectionMode="single"
          loading={loadingStatus}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} O.S"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column field="os_number" header="O.S" align={'center'}></Column>
          <Column field="customer" header="Cliente" align={'left'}></Column>
          <Column
            field="date_open"
            header="Abertura"
            align={'center'}
            body={e => renderColumnDate(e.date_open)}
          ></Column>
          <Column
            field="date_prevision"
            header="Previsão"
            align={'center'}
            body={e => renderColumnDate(e.date_prevision)}
          ></Column>
          <Column
            field="date_close"
            header="Fechamento"
            align={'center'}
            body={e => renderColumnDate(e.date_close)}
          ></Column>

          <Column
            field="os_status"
            header="Situação"
            align={'center'}
            body={e => renderColumnOsStatus(e)}
          ></Column>
          <Column
            field="priority"
            header="Prioridade"
            align={'center'}
            body={e => renderColumnPriority(e)}
          ></Column>
          <Column
            field="total"
            header="Total"
            align={'center'}
            body={e => renderColumnDecimal(e.total)}
          ></Column>
          <Column
            field="total_paied"
            header="Pago"
            align={'center'}
            body={e => renderColumnDecimal(e.total_paied)}
          ></Column>
          <Column
            field="close"
            header="Encerrada"
            align={'center'}
            body={e => renderColumnClose(e)}
          ></Column>
          <Column
            field="status"
            header="Status"
            align={'center'}
            body={e => renderColumnStatus(e)}
          ></Column>
          <Column header="*" body={e => renderCollumnMenu(e)}></Column>
        </DataTable>
      </div>

      <Dialog
        visible={true}
        onHide={() => setOpenPrintModal(false)}
        style={{
          width: '50vw',
          height: '80vh',
          display: openPrintModal ? 'flex' : 'none',
        }}
        header={headerModalPrint}
        contentStyle={{ display: openPrintModal ? 'flex' : 'none' }}
        modal={openPrintModal}
      >
        <iframe
          id="frame-print"
          ref={iframeRef}
          className="w-12 border-none"
        ></iframe>
      </Dialog>

      <OsReport
        key={v4()}
        isOpen={osReportVisible}
        onRequestClose={() => setOsReportVisible(false)}
      />
    </>
  );
};

export default OsFormList;

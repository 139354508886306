import { useField } from '@unform/core';
import { addLocale, locale } from 'primereact/api';
import {
  Calendar,
  CalendarChangeParams,
  CalendarProps,
} from 'primereact/calendar';
import React, { useEffect, useRef, useState } from 'react';
import languageCalendar from '../../../config/calendar';
import { addYears, endOfDay, startOfDay } from 'date-fns';

interface PropsCalendar extends CalendarProps {
  name: string;
  onChange: (e: Partial<CalendarChangeParams>) => void;
}

const CalendarRange = (props: PropsCalendar): React.ReactElement => {
  const inputRef = useRef(null);

  addLocale('pt', languageCalendar);

  locale('pt');

  const hoje = new Date();

  const [period, setPeriod] = useState<Date[]>([]);

  const handleHide = () => {
    if (period[1] === null) {
      props.onChange({
        value: [startOfDay(period[0]), endOfDay(period[0])],
      });
    }
  };

  const handleChange = (e: CalendarChangeParams) => {
    setPeriod(e.value as Date[]);
    props.onChange(e);
  };

  const { fieldName, registerField, error } = useField(props.name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Calendar
      inputRef={inputRef}
      {...props}
      monthNavigator
      yearNavigator
      yearRange={`1970:${addYears(hoje, 20).getFullYear()}`}
      className={error ? `p-invalid ${props.className}` : `${props.className}`}
      inputClassName="text-center"
      selectionMode="range"
      dateFormat="dd/mm/yy"
      placeholder="00/00/00 ~ 00/00/00"
      readOnlyInput
      showIcon
      onChange={handleChange}
      onHide={() => handleHide()}
    />
  );
};

export default CalendarRange;

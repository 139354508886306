import Dashboard from '../components/Dashboard';
import AccessUserGroupCreate from '../pages/accessUserGroup/Create';
import AccessUserGroupEdit from '../pages/accessUserGroup/Edit';
import AccessUserGroupList from '../pages/accessUserGroup/List';
import AdditionalCreate from '../pages/additional/Create';
import AdditionalEdit from '../pages/additional/Edit';
import AdditionalList from '../pages/additional/List';
import AdditionalItemCreate from '../pages/additionalItems/Create';
import AdditionalItemEdit from '../pages/additionalItems/Edit';
import AdditionalItemList from '../pages/additionalItems/List';
import ApplicationCreate from '../pages/application/Create';
import ApplicationEdit from '../pages/application/Edit';
import ApplicationList from '../pages/application/List';
import BankCreate from '../pages/bank/Create';
import BankEdit from '../pages/bank/Edit';
import BankList from '../pages/bank/List';
import BankAccountCreate from '../pages/bankAccount/Create';
import BankAccountEdit from '../pages/bankAccount/Edit';
import BankAccountList from '../pages/bankAccount/List';
import BillToPay from '../pages/bills-to-pay/create';
import BillsToPayDrop from '../pages/bills-to-pay/drop';
import BillsToPayList from '../pages/bills-to-pay/list';
import BillsToReceivableCreate from '../pages/bills-to-receive/create';
import BillsreceivableDropByPeriod from '../pages/bills-to-receive/drop/all';
import BillsReceivableEdit from '../pages/bills-to-receive/form-edit';
import BillsToReceibleList from '../pages/bills-to-receive/list';
import BillsReceivableRenegotiation from '../pages/bills-to-receive/renegotiation';
import BrandsCreate from '../pages/brands/Create';
import BrandsEdit from '../pages/brands/Edit';
import BrandsList from '../pages/brands/List';
import BusinessCreate from '../pages/business/Create';
import BusinessEdit from '../pages/business/Edit';
import BusinessList from '../pages/business/listage';
import Calendar from '../pages/calendar';
import CachMovimentationCreate from '../pages/cashMovimentation/Input';
import CashMovimentationOutPut from '../pages/cashMovimentation/OutPut';
import CestCreate from '../pages/cest/Create';
import CestEdit from '../pages/cest/Edit';
import CestList from '../pages/cest/List';
import CfopsList from '../pages/cfops/List';
import Chat from '../pages/chat';
import CityList from '../pages/city/List';
import ComissionedCreate from '../pages/comissioned/Create';
import ComissionedEdit from '../pages/comissioned/Edit';
import ComissionedList from '../pages/comissioned/List';
import ConsignedDevolutionCreate from '../pages/consigned/devolution/create';
import ConsignedDevolutionUpdate from '../pages/consigned/devolution/edit';
import ConsignedDevolutionList from '../pages/consigned/devolution/list';
import ConsignedOrderCreate from '../pages/consigned/order/create';
import ConsignedOrderUpdate from '../pages/consigned/order/edit';
import ConsignedOrderList from '../pages/consigned/order/list';
import ReportDevolutionSearch from '../pages/consigned/reports/devolution-search';
import ReportOrderSearch from '../pages/consigned/reports/order-search';
import ReportRouteSearch from '../pages/consigned/reports/route-search';
import ReportRouteStock from '../pages/consigned/reports/route-stock';
import ConsignedRouteList from '../pages/consigned/route/list';
import ConsignedRouteOpen from '../pages/consigned/route/open';
import ControlPanel from '../pages/control-panel';
import ConvertProduct from '../pages/convertProduct';
import CostCenterCreate from '../pages/cost/Create';
import CostCenterList from '../pages/cost/List';
import CrediaryCreate from '../pages/crediary/Create';
import CrediaryEdit from '../pages/crediary/Edit';
import CrediaryList from '../pages/crediary/List';
import CreditAnalysisCreate from '../pages/creditAnalysis/Create';
import CreditAnalysisEdit from '../pages/creditAnalysis/Edit';
import CreditAnalysisList from '../pages/creditAnalysis/List';
import CsosnList from '../pages/csosn/List';
import CstList from '../pages/cst/List';
import DropBillsReceivable from '../pages/dropBillsReceivable';
import GroupCreate from '../pages/group/Create';
import GroupEdit from '../pages/group/Edit';
import GroupList from '../pages/group/List';
import InputProductsCreateHandle from '../pages/input-products/create-handle';
import InputProductsCreateXml from '../pages/input-products/create-xml';
import InputProductsList from '../pages/input-products/list';
import InventoryClose from '../pages/inventory/close';
import InventoryDigitation from '../pages/inventory/digitation';
import InventoryOpen from '../pages/inventory/open';
import MainActivityCreate from '../pages/mainActivity/Create';
import MainActivityEdit from '../pages/mainActivity/Edit';
import MainActivityList from '../pages/mainActivity/List';
import ConsultantCreate from '../pages/marketing/consultant/Create';
import ConsultantEdit from '../pages/marketing/consultant/Edit';
import ConsultantList from '../pages/marketing/consultant/List';
import CrmCharts from '../pages/marketing/crm/charts';
import CrmPanel from '../pages/marketing/crm/panel';
import CrmPanels from '../pages/marketing/crm/panels';
import LeadsCreate from '../pages/marketing/leads/Create';
import LeadsEdit from '../pages/marketing/leads/Edit';
import LeadsList from '../pages/marketing/leads/List';
import RfbConsultaAvancada from '../pages/marketing/rfb/search';
import MetaBusinessCreate from '../pages/metaBusiness/Create';
import MetaBusinessEdit from '../pages/metaBusiness/Edit';
import MetaBusinessList from '../pages/metaBusiness/List';
import MetaComissionedCreate from '../pages/metaComissioned/Create';
import MetaComissionedEdit from '../pages/metaComissioned/Edit';
import MetaComissionedList from '../pages/metaComissioned/List';
import AddOperationNature from '../pages/nature-operation/Create';
import EditOperationNature from '../pages/nature-operation/Edit';
import ListOperationNature from '../pages/nature-operation/List';
import NcmCreate from '../pages/ncm/Create';
import NcmEdit from '../pages/ncm/Edit';
import NcmList from '../pages/ncm/List';
import NfceList from '../pages/nfce/List';
import NfeList from '../pages/nfe/List';
import NfseList from '../pages/nfse/List';
import OrderForm from '../pages/order/Create/index';
import OrderList from '../pages/order/List';
import OrderReplaceList from '../pages/order/OrderReplaceList';
import OrderSwap from '../pages/order/Swap';
import OrderSwapList from '../pages/order/SwapList';
import OrderDevolutionCreate from '../pages/order/devolution/create';
import OrderDevolutionList from '../pages/order/devolution/list';
import OriginTaxesList from '../pages/originTaxes/List';
import osNew from '../pages/os/os';
import osContract from '../pages/os/osContract';
import osList from '../pages/os/osFormList';
import osKindObject from '../pages/os/osKindObject';
import osObject from '../pages/os/osObject';
import osPrintContract from '../pages/os/osPrintContract';
import osService from '../pages/os/osService';
import osServiceUnity from '../pages/os/osServiceUnity';
import osStatus from '../pages/os/osStatus';
import Parameters from '../pages/parameters';
import PaymentConditionCreate from '../pages/payment-condition/form-new';
import PaymentConditionEdit from '../pages/payment-condition/form-upd';
import PayemntConditionList from '../pages/payment-condition/list';
import Payments from '../pages/payments';
import PersonsCreate from '../pages/person/create';
import createCustomerShort from '../pages/person/CreateCustomerShort';
import PersonUpdate from '../pages/person/update';
import BusinessPixCreate from '../pages/pixBusiness/Create';
import BusinessPixEdit from '../pages/pixBusiness/Edit';
import BusinessPixList from '../pages/pixBusiness/List';
import Plans from '../pages/plans';
import PrintedNfeCreate from '../pages/printedNfe/Create';
import PrintedNfeEdit from '../pages/printedNfe/Edit';
import PrintedNfeList from '../pages/printedNfe/List';
import ProductPricesAdjust from '../pages/product-price/adjust-prices';
import ProductPriceEdit from '../pages/product-price/form-edit';
import ProductPriceCreate from '../pages/product-price/form-new';
import ProductPriceList from '../pages/product-price/list';
import AddProduct from '../pages/product/Create';
import EditProduct from '../pages/product/Edit';
import LisProduct from '../pages/product/List';
import Profile from '../pages/profile/index';
import CacheClose from '../pages/register-cash/Close';
import CacheOpen from '../pages/register-cash/Open';
import ReportsBillPayableToProvider from '../pages/reports/billsPayable/byProvider';
import ReportsBillReceible from '../pages/reports/billsReceible/byCustomer';
import ReportCash from '../pages/reports/cash';
import ReportsCheckByProvider from '../pages/reports/check/byProvider';
import ReportsCheckOn from '../pages/reports/check/checkOn';
import ReportCommissionsOs from '../pages/reports/commisions/os';
import ReportCommissionsSalles from '../pages/reports/commisions/salles';
import ReportContactCustomer from '../pages/reports/contactCustomer';
import ReportCrmCards from '../pages/reports/crm/cards';
import ReportCustomer from '../pages/reports/customer';
import ReportCustomerActive from '../pages/reports/customerActive';
import ReportCustomerBirthDate from '../pages/reports/customerBirthDay';
import ReportsInventoryCount from '../pages/reports/inventory/count';
import ReportsInventoryDiff from '../pages/reports/inventory/diff';
import ConsultantReport from '../pages/reports/marketing/consultant';
import LeadsReport from '../pages/reports/marketing/leads';
import ReportOrder from '../pages/reports/order';
import ReportOrderNotRegistred from '../pages/reports/orderNotRegistred';
import ReportProductsSalled from '../pages/reports/product';
import ReportProposal from '../pages/reports/proposal';
import ReportProvider from '../pages/reports/provider';
import ReportInputsByPaymentType from '../pages/reports/inputsByPaymentType';
import ReportsNfce from '../pages/reports/reportsNfce';
import ReportsNfe from '../pages/reports/reportsNfe';
import ReportCurrentCount from '../pages/reports/stock/currentCount';
import ReportsStockInput from '../pages/reports/stock/inputControl';
import ReportsInventoryStock from '../pages/reports/stock/inventory';
import ReportsMostSell from '../pages/reports/stock/mostSell';
import ReportsNoSell from '../pages/reports/stock/noSell';
import ReportsNoStock from '../pages/reports/stock/noStock';
import ReportsProductMov from '../pages/reports/stock/productMovim';
import ReportsReceiptProduct from '../pages/reports/stock/receipt';
import ReportTablePrice from '../pages/reports/table-price';
import TagClothes from '../pages/reports/tag/clothes';
import ReportTagRing from '../pages/reports/tag/ring';
import ReportTicket from '../pages/reports/ticket';
import TransportReport from '../pages/reports/transporter';
import ReportsUser from '../pages/reports/user';
import ReportWallet from '../pages/reports/wallet';
import SmartSearch from '../pages/smartSearch';
import StateList from '../pages/states/List';
import StoreCreate from '../pages/store/Create';
import StoreEdit from '../pages/store/Edit';
import StoreList from '../pages/store/List';
import SubGroupCreate from '../pages/subGroup/Create';
import SubGroupEdit from '../pages/subGroup/Edit';
import SubGroupList from '../pages/subGroup/List';
import TablePriceCreate from '../pages/tablePrice/Create';
import TablePriceEdit from '../pages/tablePrice/Edit';
import TablePriceList from '../pages/tablePrice/List';
import TagCreate from '../pages/tags/Create';
import TagEdit from '../pages/tags/Edit';
import TagList from '../pages/tags/List';
import AccountCreate from '../pages/ticket/account/Create';
import AccountEdit from '../pages/ticket/account/Edit';
import AccountList from '../pages/ticket/account/List';
import AssignorList from '../pages/ticket/assignor/List';
import ConvenienceCreate from '../pages/ticket/convenience/Create';
import ConvenienceEdit from '../pages/ticket/convenience/Edit';
import ConvenienceList from '../pages/ticket/convenience/List';
import RemittanceList from '../pages/ticket/remittance/List';
import SetupCreate from '../pages/ticket/setup/Create';
import SetupUpdate from '../pages/ticket/setup/Edit';
import SetupList from '../pages/ticket/setup/List';
import TitleCreate from '../pages/ticket/title/Create';
import TitleList from '../pages/ticket/title/List';
import AccessUserCreate from '../pages/user-system-access/form-create';
import AccessUserEdit from '../pages/user-system-access/form-edit';
import AccessUserList from '../pages/user-system-access/list';
import UserCreate from '../pages/user/Create';
import UserEdit from '../pages/user/Edit';
import UserList from '../pages/user/List';
import UserRecorderCreate from '../pages/userRecorder/Create';
import UserRecorderEdit from '../pages/userRecorder/Edit';
import UserRecorderList from '../pages/userRecorder/List';
import VariationCreate from '../pages/variation/Create';
import VariationEdit from '../pages/variation/Edit';
import VariationList from '../pages/variation/List';
import VariationItemCreate from '../pages/variationItems/Create';
import VariationItemEdit from '../pages/variationItems/Edit';
import VariationItemList from '../pages/variationItems/List';
import WalletCreate from '../pages/wallet/Create';
import WalletList from '../pages/wallet/List';
import WalletResume from '../pages/wallet/Resume';
import DropBillsReceivableByBankReturnFile from '../pages/bills-to-receive/drop-by-return-file';
import PersonsList from '../pages/person/listage';

const routers = [
  {
    path: '/',
    component: Dashboard,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard' }] },
  },
  {
    path: '/calendar',
    component: Calendar,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard' }] },
  },
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard' }] },
  },
  {
    path: '/additionals-items/create',
    component: AdditionalItemCreate,
    pageId: 'bdcc3af2-833e-4aa2-8e6d-836d35cd1ebb',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Itens Adicionais' }],
    },
  },
  {
    path: '/additionals-items/edit/:id',
    component: AdditionalItemEdit,
    pageId: 'fe81512f-f25d-4e37-bdb4-65ec5396fec7',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Item Adicional' }],
    },
  },
  {
    path: '/control-panel',
    component: ControlPanel,
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Painel de controle' }],
    },
  },
  {
    path: '/additionals-items/list',
    component: AdditionalItemList,
    pageId: '0d883aec-ad46-492a-9759-0d0c2024f641',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Itens Adicionais' }],
    },
  },
  {
    path: '/orders/list',
    component: OrderList,
    pageId: '132b2657-8061-49c9-937e-8ff8cc811d79',
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Pedidos de Venda' }] },
  },
  {
    path: '/orders/create/:id',
    exact: false,
    component: OrderForm,
    pageId: '50127f4b-c651-4e9a-aeb2-691215baab9c',
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Criar Pedido' }] },
  },
  {
    path: '/orders/create',
    exact: false,
    component: OrderForm,
    pageId: '50127f4b-c651-4e9a-aeb2-691215baab9c',
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Criar Pedido' }] },
  },
  {
    path: '/order-swap',
    component: OrderSwap,
    pageId: 'cbf30908-a74c-4410-97dc-5591264ed9a5',
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Troca de Produtos' }] },
  },
  {
    path: '/order-replace',
    component: OrderDevolutionCreate,
    pageId: '0f274780-ebd2-4754-b701-4d71b6d864c3',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Devolução de produtos' }],
    },
  },
  {
    path: '/order-replace/list',
    component: OrderDevolutionList,
    pageId: '2706e470-fa7f-11ed-b3de-ef420c5c13ec',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Listagem de devoluções' }],
    },
  },
  {
    path: '/additionals/list',
    component: AdditionalList,
    pageId: '8aa342e8-26aa-42e4-a0b5-d59fa7b2d024',
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Adicionais' }] },
  },
  {
    path: '/additionals/create',
    component: AdditionalCreate,
    pageId: 'd8ab5f2b-ab25-4d6c-a53d-825061e63bee',
    exact: true,
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Criar Adicional' }] },
  },
  {
    path: '/additionals/edit/:id',
    component: AdditionalEdit,
    pageid: '8c475303-37a5-477a-ad48-e9fe67eb4e2e',
    meta: { breadcrumb: [{ parent: 'Dashboard', label: 'Editar Adicional' }] },
  },
  {
    path: '/applications/create',
    component: ApplicationCreate,
    pageId: '156bc892-150a-44e5-aab3-8f2d0c07cb54',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Aplicação' }],
    },
  },
  {
    path: '/applications/edit/:id',
    component: ApplicationEdit,
    pageId: '6e268d8c-610f-4f30-9be3-cd71b0420be9',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Aplicação' }],
    },
  },
  {
    path: '/applications/list',
    component: ApplicationList,
    pageId: '156bc892-150a-44e5-aab3-8f2d0c07cb54',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Aplicações' }],
    },
  },
  {
    path: '/bills/pay/create',
    component: BillToPay,
    pageId: '3ad80be3-f19d-4c98-8885-b5a86aeb7178',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar conta a pagar' }],
    },
  },
  {
    path: '/bills/pay/drop/:id',
    component: BillsToPayDrop,
    pageId: '13f4249f-f981-40f7-acc7-4328a59f78e3',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Baixar contas a pagar' }],
    },
  },
  {
    path: '/bills/pay/drop',
    component: BillsToPayDrop,
    exact: true,
    pageId: '13f4249f-f981-40f7-acc7-4328a59f78e3',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Baixa de contas a pagar' }],
    },
  },
  {
    path: '/bills/receive/drop/:id',
    component: DropBillsReceivable,
    pageId: 'd68f573d-48be-49f4-90e0-0521f2819b86',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Baixa de contas a receber' }],
    },
  },
  {
    path: '/bills/receive/drop',
    component: DropBillsReceivable,
    pageId: 'd68f573d-48be-49f4-90e0-0521f2819b86',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Baixa de contas a receber' }],
    },
  },
  {
    path: '/bills/receive/drop/by/return-file',
    component: DropBillsReceivableByBankReturnFile,
    pageId: '673d6d75-a1ee-4bc7-ad1f-689a2f50853f',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Baixa de contas a receber com retorno bancário',
        },
      ],
    },
  },
  {
    path: '/user-access/set/create',
    component: AccessUserCreate,
    pageId: '8a91c82f-4fe0-4474-878f-fbad6f8fa446',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar permissões de acesso' },
      ],
    },
  },
  {
    path: '/user-access/set/edit/:id',
    component: AccessUserEdit,
    pageId: '8b9f1051-3af7-44d2-aabf-810cc4500674',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar permissões de acesso',
        },
      ],
    },
  },
  {
    path: '/user-access/set/list',
    component: AccessUserList,
    pageId: '5a712c17-5aeb-4702-9b81-c2529f1706e7',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Acesso dos Usuários',
        },
      ],
    },
  },
  {
    path: '/products/create',
    component: AddProduct,
    pageId: '24a5322f-6878-41f2-ac7d-cb3de0fba1c1',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Produto' }],
    },
  },
  {
    path: '/products/edit/:id',
    component: EditProduct,
    pageId: 'b03fc790-1745-43b2-97df-333552d74f12',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Produto' }],
    },
  },
  {
    path: '/products/list',
    component: LisProduct,
    pageId: 'ec588787-fee8-4068-87a2-87def5edb14e',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Produtos' }],
    },
  },
  {
    path: '/operation-nature/create',
    component: AddOperationNature,
    pageId: 'cd58db01-6b0f-4163-b82c-89081839c964',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar Natureza de Operação' },
      ],
    },
  },
  {
    path: '/operation-nature/edit/:id',
    component: EditOperationNature,
    pageId: '1927e5f3-2a5a-4a21-9391-f2b5e813843d',
    exact: false,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Editar Natureza de Operação' },
      ],
    },
  },
  {
    path: '/operation-nature/list',
    component: ListOperationNature,
    pageId: 'dd206d96-fed5-4e77-9370-4e044cabd8ff',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Naturezas de operação' }],
    },
  },
  {
    path: '/input-products/create',
    component: InputProductsCreateHandle,
    pageId: 'b9d13de8-bba0-4f60-ba97-6b2e4811aadd',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar entrada de produto manualmente' },
      ],
    },
  },
  {
    path: '/input-products/create/xml',
    component: InputProductsCreateXml,
    pageId: 'd076a91e-d6fd-11ed-8b74-473f44d7c8b9',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar entrada de produto via xml' },
      ],
    },
  },
  {
    path: '/input-products/list',
    component: InputProductsList,
    pageId: 'eed8c642-15b8-4578-a2d7-3c565cb177a5',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Lista de Entrada de Produto' },
      ],
    },
  },
  {
    path: '/inventory/abertura',
    component: InventoryOpen,
    pageId: '39ae8fc5-57e5-4b46-a28d-f2ad71170645',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Abertura de inventário' }],
    },
  },
  {
    path: '/inventory/digitacao',
    component: InventoryDigitation,
    pageId: '6576d4fc-f33b-4029-905b-641da0aa1200',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Digitação do inventário' }],
    },
  },
  {
    path: '/inventory/encerramento',
    component: InventoryClose,
    pageId: '2aa0da2c-c57f-41a2-8685-8ff42329367b',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Encerramento do inventário' },
      ],
    },
  },
  {
    path: '/persons/create',
    component: PersonsCreate,
    pageId: 'dca6f2d3-090d-4a6e-a938-0aab175833dd',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Cliente / Fornecedor / Transportadora',
        },
      ],
    },
  },
  {
    path: '/customer/short/create',
    component: createCustomerShort,
    pageId: 'b9d48ffb-10b0-4801-8858-21139b6252d6',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Cliente rápido',
        },
      ],
    },
  },
  {
    path: '/persons/edit/:id',
    component: PersonUpdate,
    pageId: 'd87dd4ef-4e7f-44ed-afc7-a01a05e45c88',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar Cliente / Fornecedor / Transportadora',
        },
      ],
    },
  },
  {
    path: '/persons/list',
    component: PersonsList,
    pageId: '64419c35-fc12-405a-8e01-bb3b4d69ad3c',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: '👧🏻 Clientes / 🏭 Fornecedores / 🚛 Transportadoras',
        },
      ],
    },
  },
  {
    path: '/banks/create',
    component: BankCreate,
    pageId: '8ac854b5-5ce7-4552-9732-5d53125a98cd',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Banco' }],
    },
  },
  {
    path: '/banks/edit/:id',
    component: BankEdit,
    pageId: '2f86d132-b341-4a05-ac10-62aeaf917524',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Banco' }],
    },
  },
  {
    path: '/banks/list',
    component: BankList,
    pageId: '7da3092f-f52d-41cd-a5af-a1290bf8b299',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Bancos' }],
    },
  },
  {
    path: '/bills/pay/drop-partial',
    component: BillsToPayDrop,
    pageId: '6f3f3f43-a0cf-497d-b219-d0dca1c97377',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Pagamento parcial de documento' },
      ],
    },
  },
  {
    path: '/bills/pay/list',
    component: BillsToPayList,
    pageId: '8a212128-ec81-4de9-8394-07731969aa79',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Contas a pagar' }],
    },
  },
  {
    path: '/bills/receive/create',
    component: BillsToReceivableCreate,
    pageId: '222db633-8236-47f9-ba6b-1169f920162b',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Conta a Receber' }],
    },
  },
  {
    path: '/bills/receive/edit/:id',
    component: BillsReceivableEdit,
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Conta a Receber' }],
    },
  },
  {
    path: '/bills/receive/list',
    component: BillsToReceibleList,
    pageId: '92f50a35-926a-41d7-9052-f034458cb3fd',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Contas a Receber' }],
    },
  },
  {
    path: '/brands/create',
    component: BrandsCreate,
    pageId: '3a2f1972-103e-48a5-8b45-8a84dc7fc249',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Marca' }],
    },
  },
  {
    path: '/brands/list',
    component: BrandsList,
    pageId: '563cf0c2-5412-4881-9376-50a1ad3814b8',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Marcas' }],
    },
  },
  {
    path: '/brands/edit/:id',
    component: BrandsEdit,
    pageId: '563cf0c2-5412-4881-9376-50a1ad3814b8',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Marca' }],
    },
  },
  {
    path: '/business-bank-accounts/create',
    component: BankAccountCreate,
    exact: true,
    pageId: '2ea257e6-8993-4170-a5e5-b2de30370443',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Conta Bancária' }],
    },
  },
  {
    path: '/business-bank-accounts/list',
    component: BankAccountList,
    exact: true,
    pageId: '01702bde-f02c-491e-85c4-197285474dff',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Contas Bancárias' }],
    },
  },
  {
    path: '/business-bank-accounts/edit/:id',
    component: BankAccountEdit,
    pageId: 'ce53af54-9985-41eb-8870-04e7a2c62e6e',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Conta Bancária' }],
    },
  },
  {
    path: '/business-pix/create',
    component: BusinessPixCreate,
    pageId: '33b83b1a-a64e-4371-ad68-64e730bd4d9d',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Chave Pix' }],
    },
  },
  {
    path: '/business-pix/list',
    component: BusinessPixList,
    pageId: '1bdbf88f-090f-4437-adf9-87fbb19dbdd1',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Chaves Pix' }],
    },
  },
  {
    path: '/business-pix/edit/:id',
    component: BusinessPixEdit,
    pageId: 'fb74ec42-93b9-433e-8e7f-4dfb0266a7af',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Chave Pix' }],
    },
  },
  {
    path: '/business/create',
    component: BusinessCreate,
    pageId: 'a2880cc6-6310-403e-8566-4445e0024b37',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Registrar Empresa' }],
    },
  },
  {
    path: '/business/edit/:id',
    component: BusinessEdit,
    pageId: '310ae77f-30a2-4e8a-8ef0-60d902c8b68e',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Empresa' }],
    },
  },
  {
    path: '/business/list',
    component: BusinessList,
    pageId: '00859fcc-d380-4e00-a896-cc098f57d318',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Empresas' }],
    },
  },
  {
    path: '/cash-register/close',
    component: CacheClose,
    pageId: '596a36a5-fadc-4d30-98b0-270335789cd0',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Fechamento de Caixa Registradora' },
      ],
    },
  },
  {
    path: '/cash-register/open',
    component: CacheOpen,
    pageId: '6ff2f4b4-36d6-4651-a126-5eb03de7e589',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Abertura de Caixa Registradora' },
      ],
    },
  },
  {
    path: '/cfops/list',
    component: CfopsList,
    pageId: '5d53e82e-c233-4329-9977-766040f83b4c',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'CFOP' }],
    },
  },
  {
    path: '/cities/list',
    component: CityList,
    pageId: '15ab0b59-68b6-4a70-b82d-39223ea9d038',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Cidades' }],
    },
  },
  {
    path: '/commissioners/create',
    component: ComissionedCreate,
    pageId: '4be37728-b9be-4ea5-88b0-6b25308043dc',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar comissionado' }],
    },
  },
  {
    path: '/commissioners/edit/:id',
    component: ComissionedEdit,
    pageId: '68d6fcd1-8474-45e5-9c3d-9291478a5afb',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar comissionado' }],
    },
  },
  {
    path: '/commissioners/list',
    component: ComissionedList,
    pageId: '2d0f85b6-1bb7-4028-9f4e-c422bb4072d2',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Comissionados' }],
    },
  },
  {
    path: '/cost-center/create',
    component: CostCenterCreate,
    pageId: '5c1f1960-4b98-4b2e-bd0a-5eee8c141f31',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Centro de Custos' }],
    },
  },
  {
    path: '/cost-center/list',
    component: CostCenterList,
    pageId: '0f95af0f-7423-4b48-ad8c-51c8e930b0fc',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Centros de Custos' }],
    },
  },
  {
    path: '/crediaries/create',
    component: CrediaryCreate,
    pageId: '0660afd9-3b18-4bfa-b98f-a365849e728d',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Crediário' }],
    },
  },
  {
    path: '/crediaries/edit/:id',
    component: CrediaryEdit,
    pageId: '1716a8bc-a941-455f-8a11-63230cfffb1d',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Crediário' }],
    },
  },
  {
    path: '/crediaries/list',
    component: CrediaryList,
    pageId: 'a82040ea-9212-4640-ae47-409e5c254a58',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Crediário' }],
    },
  },
  {
    path: '/credit-analise/create',
    component: CreditAnalysisCreate,
    pageId: '04346956-7cb3-49c8-b18f-baf55d0d3ad6',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Análise de Crédito' }],
    },
  },
  {
    path: '/credit-analise/edit/:id',
    component: CreditAnalysisEdit,
    pageId: '29234fa1-7f15-4f73-b9cf-402babefcdac',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Análise de Crédito' }],
    },
  },
  {
    path: '/credit-analise/list',
    component: CreditAnalysisList,
    pageId: '0092133b-da51-4224-9ff1-64d840a29293',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Análises de Crédito' }],
    },
  },
  {
    path: '/groups/create',
    component: GroupCreate,
    pageId: '6402c35a-4139-40ae-85a6-d959fc41e97b',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar categoria de produtos' },
      ],
    },
  },
  {
    path: '/groups/edit/:id',
    component: GroupEdit,
    pageId: '5281726f-b034-4854-95af-8ac9874a781d',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Editar categoria de produtos' },
      ],
    },
  },
  {
    path: '/groups/list',
    component: GroupList,
    pageId: '821e2711-1adf-4111-92cb-ccfdd2019f0b',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Grupo de produtos' }],
    },
  },
  {
    path: '/impresso-nfe/create',
    component: PrintedNfeCreate,
    pageId: '19932b4e-3c7c-439e-8fb1-10e7ce003d79',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Impresso de NFE' }],
    },
  },
  {
    path: '/impresso-nfe/edit/:id',
    component: PrintedNfeEdit,
    pageId: '44916d49-9e0c-41f0-910b-31995677e862',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Impresso de NFE' }],
    },
  },
  {
    path: '/impresso-nfe/list',
    component: PrintedNfeList,
    pageId: '5276d5d8-4647-4af1-a5f8-f1e17f5bf855',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Impressos de NFE' }],
    },
  },
  {
    path: '/main-activity/create',
    component: MainActivityCreate,
    pageId: 'b9c3bb8b-862b-41db-856a-137950fc7c93',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Atividade Principal' }],
    },
  },
  {
    path: '/ncm/create',
    component: NcmCreate,
    exact: false,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar NCM' }],
    },
  },
  {
    path: '/ncm/edit/:id',
    component: NcmEdit,
    exact: false,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar NCM' }],
    },
  },
  {
    path: '/main-activity/edit/:id',
    component: MainActivityEdit,
    pageId: 'ee6d8244-e19c-42f5-9031-8249c5132152',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Editar Atividade Principal' },
      ],
    },
  },
  {
    path: '/main-activity/list',
    component: MainActivityList,
    pageId: '2765b20d-8f98-4551-961b-35dddf7a1c95',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Atividades principais' }],
    },
  },
  {
    path: '/meta-business/create',
    component: MetaBusinessCreate,
    pageId: 'e4dfbf6d-d36f-45cc-919a-cf299d02df4d',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Meta de Empresa' }],
    },
  },
  {
    path: '/meta-business/edit/:id',
    component: MetaBusinessEdit,
    pageId: 'f33440e6-67c2-4149-97b9-768dc7da1ebe',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar Meta da Empresa' }],
    },
  },
  {
    path: '/meta-business/list',
    component: MetaBusinessList,
    pageId: 'c2f74cf7-d5d1-4795-9889-b4f512d97a4f',
    exact: true,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Metas de Empresa' }],
    },
  },
  {
    path: '/meta-commissioneds/create',
    component: MetaComissionedCreate,
    pageId: '54e02187-533b-4648-9719-551882d175b3',
    exact: true,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar Meta de Comissionado' },
      ],
    },
  },
  {
    path: '/meta-commissioneds/edit/:id',
    component: MetaComissionedEdit,
    pageId: '97952f1f-798d-4522-b4f3-8c430c6eeb58',
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar Meta de Comissionado' },
      ],
    },
  },
  {
    path: '/meta-commissioneds/list',
    pageId: '3c3e2fd4-1155-4bae-86f1-ca8e310d0aa7',
    component: MetaComissionedList,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Metas de comissionados' }],
    },
  },
  {
    path: '/ncm/list',
    component: NcmList,
    pageId: '46bc294a-7e60-4204-9958-771e125f0912',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: "NCM's" }],
    },
  },
  {
    path: '/nfce/export/list',
    component: NfceList,
    pageId: '3ffc9637-94de-4a52-a691-05c219449416',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Nfce (cupom Fiscal)' }],
    },
  },
  {
    path: '/nfe/export/list',
    component: NfeList,
    pageId: '7aec2dd3-0f62-4fbe-97d6-5a8f03976adf',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Nfe' }],
    },
  },
  {
    path: '/nfse/export/list',
    component: NfseList,
    pageId: '0f2173de-29b4-11ee-a873-d7ed58ba202b',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Exportar Nfse (Nota fiscal de serviço)',
        },
      ],
    },
  },
  {
    path: '/payment-condition/create',
    pageId: '7133f2d1-cce5-4b36-92af-9b81873e759b',
    component: PaymentConditionCreate,
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar Condição de Pagamento' },
      ],
    },
  },
  {
    path: '/payment-condition/edit/:id',
    component: PaymentConditionEdit,
    pageId: 'a9f9e332-513c-4f98-9e13-e1546b2b2843',
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Editar Condição de Pagamento' },
      ],
    },
  },
  {
    path: '/payment-condition/list',
    component: PayemntConditionList,
    pageId: 'cbac2806-d7d6-4c9c-8c31-ead71959d46a',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Condição de pagamento' }],
    },
  },
  {
    path: '/products-prices/create',
    component: ProductPriceCreate,
    pageId: '1b14519c-c7c7-4848-b6ac-0f010b1151a7',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar preço do produto' }],
    },
  },
  {
    path: '/products-prices/edit/:id',
    component: ProductPriceEdit,
    pageId: '69a12436-397d-4de2-9333-3855570d7f86',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Editar preço do produto' }],
    },
  },
  {
    path: '/products-prices/adjust',
    component: ProductPricesAdjust,
    pageId: 'a3f9280c-eebe-11ed-a1f1-9791207b4b88',
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Ajustar preços dos produtos' },
      ],
    },
  },
  {
    path: '/products-prices/list',
    component: ProductPriceList,
    pageId: '63bc4c4e-dd90-4d37-bfc6-0869da36529e',
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Listar preços dos produtos' },
      ],
    },
  },
  {
    path: '/profile',
    component: Profile,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Perfil do usuário' }],
    },
  },
  {
    path: '/register-cash-movimentation/cash-in',
    pageId: 'a09346cc-a834-4238-931a-be0499dae299',
    component: CachMovimentationCreate,
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Entrada no Caixa' }],
    },
  },
  {
    path: '/register-cash-movimentation/cash-out',
    component: CashMovimentationOutPut,
    pageId: '4f07acc3-9d96-4c0a-9038-a5ecee3d0eed',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Saída do Caixa' }],
    },
  },
  {
    path: '/register-user/create',
    component: UserRecorderCreate,
    pageId: '4df838f3-0e8f-4d80-b87c-51e7b7975826',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Criar Usuário Registrador' }],
    },
  },
  {
    path: '/register-user/edit/:id',
    component: UserRecorderEdit,
    pageId: 'b6077cdf-d65f-4a6e-9211-5955dfdbff58',
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Editar Usuário Registrador' },
      ],
    },
  },
  {
    path: '/register-user/list',
    component: UserRecorderList,
    pageId: '77b2ee32-a97c-4672-984d-d3be659ddbb4',
    meta: {
      breadcrumb: [{ parent: 'Dashboard', label: 'Usuários registradores' }],
    },
  },
  {
    path: '/reports/bills-payable/by-provider',
    component: ReportsBillPayableToProvider,
    pageId: 'f2a88bd9-5b76-45ee-a1f9-c5b48c7ee9f4',
    meta: {
      breadcrumb: [
        { parent: 'Dashboard', label: 'Criar relatório de contas a pagar' },
      ],
    },
  },
  {
    path: '/reports/bills-payable/cheque-by-provider',
    pageId: '0718d400-098d-4c45-ad65-dc85d3112566',
    component: ReportsCheckByProvider,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Relatório de cheques por fornecedor',
        },
      ],
    },
  },
  {
    path: '/reports/bills-payable/cheque-on',
    component: ReportsCheckOn,
    pageId: '79ff3b36-7a21-410d-84a2-1e83b2e303b5',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Relatório cheques em mãos',
        },
      ],
    },
  },
  {
    path: '/reports/billsToReceive/by-customer',
    component: ReportsBillReceible,
    pageId: '9f8b322d-b2f3-4066-ad66-1e980f357f76',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório de contas a receber',
        },
      ],
    },
  },
  {
    path: '/reports/invetory/diff',
    component: ReportsInventoryDiff,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório diferença contagem inventário',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/input-historic',
    component: ReportsStockInput,
    pageId: 'bb602aed-1868-4959-856e-b42582e8d055',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório histórico entrada de produtos',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/inventory-stock',
    component: ReportsInventoryStock,
    pageId: '6acb7fd5-0e1d-438b-ad1d-fe4f574a3401',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório contagem de inventário',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/most-sell',
    component: ReportsMostSell,
    pageId: '079da4cb-fa7b-4257-a592-4f0ffbe15271',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório produtos mais vendidos',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/no-sell',
    component: ReportsNoSell,
    pageId: '1e57053d-7b07-473e-80ab-b9dac3378d74',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Relatório de produtos sem vendas',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/no-stock',
    pageId: '21de657e-864c-4f26-b658-6237ccffc896',
    component: ReportsNoStock,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório produtos sem estoque',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/product-movimentation',
    component: ReportsProductMov,
    pageId: '49021491-8fb1-409b-9de2-51925f22be71',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório movimentação de produto',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/receib-input-product',
    component: ReportsReceiptProduct,
    pageId: 'd0d07692-c4c3-43c4-9d90-459c133455a6',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Relatório comprovante de entrada',
        },
      ],
    },
  },
  {
    path: '/report/nfce/filter',
    component: ReportsNfce,
    pageId: '507f87ec-5d38-11ed-8ca8-ffef55bc2b11',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Cupons fiscais emitidos',
        },
      ],
    },
  },
  {
    path: '/report/nfe/filter',
    component: ReportsNfe,
    pageId: 'a11e58c0-5d1c-11ed-8092-eff4a5914a4e',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Notas fiscais emitidas',
        },
      ],
    },
  },
  {
    path: '/store/create',
    component: StoreCreate,
    pageId: '85287cc3-3a03-4831-bccb-0ecc973e44f4',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Loja Origem de Vendas',
        },
      ],
    },
  },
  {
    path: '/store/edit/:id',
    component: StoreEdit,
    pageId: '8b6327c1-a527-4871-b985-9122de9ad911',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar Loja Origem de Vendas',
        },
      ],
    },
  },
  {
    path: '/store/list',
    component: StoreList,
    pageId: 'f2ac6c7e-5239-4fc8-b007-a8792ef713bb',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Lojas - Marketplaces',
        },
      ],
    },
  },
  {
    path: '/sub-group/create',
    pageId: '23340047-6ae1-47ba-9f57-325a2260fe88',
    component: SubGroupCreate,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Sub Grupo de produtos',
        },
      ],
    },
  },
  {
    path: '/sub-group/edit/:id',
    component: SubGroupEdit,
    pageId: '4df838f3-0e8f-4d80-b87c-51e7b7975826',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar Sub Grupo de produtos',
        },
      ],
    },
  },
  {
    path: '/sub-group/list',
    pageId: '43cc478c-5841-422b-b8a3-ecd528222856',
    component: SubGroupList,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Categorias de produtos',
        },
      ],
    },
  },
  {
    path: '/table-prices/create',
    component: TablePriceCreate,
    pageId: 'ec588787-fee8-4068-87a2-87def5edb14e',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Tabela de Preço',
        },
      ],
    },
  },
  {
    path: '/table-prices/edit/:id',
    component: TablePriceEdit,
    pageId: '07224c44-bbe1-4d03-bcad-05dbbfb82bf6',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar Tabela de Preço',
        },
      ],
    },
  },
  {
    path: '/table-prices/list',
    component: TablePriceList,
    pageId: '1b7b118e-31e9-4750-8b7d-5bcb4de8a83b',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Tabelas de Preço',
        },
      ],
    },
  },
  {
    path: '/tags/create',
    pageId: 'c0150598-324d-44bc-84c8-89a959513c9e',
    component: TagCreate,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Tag',
        },
      ],
    },
  },
  {
    path: '/tags/edit/:id',
    component: TagEdit,
    pageId: 'e26d608e-dd93-4067-bb13-7f7888b8be6c',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar Tag',
        },
      ],
    },
  },
  {
    path: '/tags/list',
    component: TagList,
    pageId: '06c8cb25-5918-413c-98db-db72f93f0d60',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Tags',
        },
      ],
    },
  },
  {
    path: '/tributos-csosn/list',
    component: CsosnList,
    pageId: 'b11703da-ee53-4cbf-91ef-aa508b1ad8cd',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Tributos CSOSN',
        },
      ],
    },
  },
  {
    path: '/tributos-cst/list',
    pageId: 'b46b5c62-ac1b-4403-a6f8-06472af72b59',
    component: CstList,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Tributos CSTs',
        },
      ],
    },
  },
  {
    path: '/tributos-origem/list',
    component: OriginTaxesList,
    pageId: '5e6ad414-ef62-423f-b651-53da70ccc050',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Origens de Tributo',
        },
      ],
    },
  },
  {
    path: '/ufs/list',
    component: StateList,
    pageId: '15ab0b59-68b6-4a70-b82d-39223ea9d038',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: "UF's",
        },
      ],
    },
  },
  {
    path: '/user-access/user-group/create',
    component: AccessUserGroupCreate,
    pageId: 'e02b8a85-8a66-4dda-bf92-7bdeb722b65f',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar grupo de usuário',
        },
      ],
    },
  },
  {
    path: '/user-access/user-group/edit/:id',
    component: AccessUserGroupEdit,
    pageId: 'b0eb81dc-bfb7-44b2-8086-eb408e63a030',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar grupo de usuário',
        },
      ],
    },
  },
  {
    path: '/user-access/user-group/list',
    component: AccessUserGroupList,
    pageId: '2b402df3-e591-426a-88cc-8011ac0624df',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Grupo de Usúarios',
        },
      ],
    },
  },
  {
    path: '/users/create',
    component: UserCreate,
    pageId: '9fef6ef9-9a2f-4625-998b-cdde8dec755c',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Cadastro de Usúario',
        },
      ],
    },
  },
  {
    path: '/users/edit/:id',
    component: UserEdit,
    pageId: '371ac1b0-7b3e-4075-806c-10a05143b174',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Edição de usúario',
        },
      ],
    },
  },
  {
    path: '/users/list',
    component: UserList,
    pageId: 'f6817969-caed-4f26-8679-e94231de2f9a',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Usuários',
        },
      ],
    },
  },
  {
    path: '/variations-items/create',
    component: VariationItemCreate,
    pageId: '4cf77333-205b-45c4-87ce-f25b8c4d10f4',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Variação de Item',
        },
      ],
    },
  },
  {
    path: '/variations-items/edit/:id',
    component: VariationItemEdit,
    pageId: '136fcf3a-ba4c-4573-899b-7a5d92ce30b3',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar Variação de Item',
        },
      ],
    },
  },
  {
    path: '/variations-items/list',
    component: VariationItemList,
    pageId: 'e1dbb44c-be3f-4a7e-9ec7-a26f38c78ca3',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Lista de Variação de Item',
        },
      ],
    },
  },
  {
    path: '/variations/create',
    component: VariationCreate,
    pageId: '5494d5e5-d361-44a8-9c83-6e1d8cd80cdd',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar de variações de produtos',
        },
      ],
    },
  },
  {
    path: '/variations/edit/:id',
    component: VariationEdit,
    pageId: '8d183a6f-c7f8-4f33-a69b-31b9332c836b',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Editar de variações de produtos',
        },
      ],
    },
  },
  {
    path: '/variations/list',
    component: VariationList,
    pageId: 'd93ac6e7-c42d-4287-ad49-1d917cfcf101',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Listar variações de produtos',
        },
      ],
    },
  },
  {
    path: '/wallet/create',
    component: WalletCreate,
    pageId: 'e26a3bcb-b09c-4e24-a701-b887943ddbb1',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar Carteira',
        },
      ],
    },
  },
  {
    path: '/wallet/list',
    component: WalletList,
    pageId: '40c377bc-d757-41f1-8036-0161d65427f1',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Carteiras',
        },
      ],
    },
  },
  {
    path: '/wallet/resumo',
    component: WalletResume,
    pageId: '30ff7e90-57b1-11ed-a2ed-3b64e414edea',
    meta: {
      breadcrumb: [
        {
          parent: 'Carteira',
          label: 'Resumo carteira',
        },
      ],
    },
  },
  {
    path: '/report/wallet',
    component: ReportWallet,
    pageId: 'b5515cd0-ffb9-11ed-8539-0fd5fcaf22d6',
    meta: {
      breadcrumb: [
        {
          parent: 'Relatórios',
          label: 'Relatório resumo da carteira',
        },
      ],
    },
  },
  {
    path: '/reports/users',
    component: ReportsUser,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório de usuários',
        },
      ],
    },
  },
  {
    path: '/reports/transporters',
    component: TransportReport,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório de transportadoras',
        },
      ],
    },
  },
  {
    path: '/reports/providers',
    component: ReportProvider,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório de fornecedores',
        },
      ],
    },
  },
  {
    path: '/reports/products/salleds',
    component: ReportProductsSalled,
    pageId: '0f8e79ce-33c4-11ee-bf44-1f825057b820',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Produtos vendidos',
        },
      ],
    },
  },
  {
    path: '/reports/customers',
    component: ReportCustomer,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório contato dos clientes',
        },
      ],
    },
  },
  {
    path: '/reports/customers/contacts',
    component: ReportContactCustomer,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório contato dos clientes',
        },
      ],
    },
  },
  {
    path: '/reports/customers/birthdays',
    component: ReportCustomerBirthDate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório clientes aniversariantes do mês',
        },
      ],
    },
  },
  {
    path: '/reports/customers/active',
    component: ReportCustomerActive,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório de clientes ativos',
        },
      ],
    },
  },
  {
    path: '/reports/proposal/list',
    component: ReportProposal,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório propostas em aberto',
        },
      ],
    },
  },
  {
    path: '/reports/orders/list',
    component: ReportOrder,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório de pedidos',
        },
      ],
    },
  },
  {
    path: '/reports/orders/no-registred/list',
    component: ReportOrderNotRegistred,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório pedidos não registrados',
        },
      ],
    },
  },
  {
    path: '/reports/invetory/count',
    component: ReportsInventoryCount,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório contagem inventário',
        },
      ],
    },
  },
  {
    path: '/reports/register-cash/by-register',
    component: ReportCash,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório fechamento caixa',
        },
      ],
    },
  },
  {
    path: '/reports/table-prices',
    component: ReportTablePrice,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Relatório de tabela de preços.',
        },
      ],
    },
  },
  {
    path: '/reports/crm/cards',
    component: ReportCrmCards,
    exact: true,
    pageId: '007fd2c5-5472-459a-824b-203771a5f0f2',
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Relatório de cards CRM',
        },
      ],
    },
  },
  {
    path: '/reports/tags',
    component: ReportTicket,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório etiqueta',
        },
      ],
    },
  },
  {
    path: '/reports/tags/ring',
    component: ReportTagRing,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Etiqueta com alça',
        },
      ],
    },
  },
  {
    path: '/reports/tags/clothes',
    component: TagClothes,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Etiqueta roupas',
        },
      ],
    },
  },
  {
    path: '/reports/stock-control/current-count',
    component: ReportCurrentCount,
    pageId: '4e484d0a-cb0d-11ec-b934-e3c3689575e0',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Criar relatório posição atual do estoque',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/cedente/list',
    component: AssignorList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Cedentes',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/setup/list',
    component: SetupList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Setup Boletos',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/remessa/list',
    component: RemittanceList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Remessas',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/setup/create',
    component: SetupCreate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Configurações do boleto',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/setup/update/:id',
    component: SetupUpdate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Configurações do boleto',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/convenio/create',
    component: ConvenienceCreate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Convênio',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/convenio/list',
    component: ConvenienceList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Convênio',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/convenio/update/:id',
    component: ConvenienceEdit,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Convênio',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/conta/create',
    component: AccountCreate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Conta',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/conta/update/:id',
    component: AccountEdit,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Conta',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/conta/list',
    component: AccountList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Contas',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/titulo/create',
    component: TitleCreate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Faturar Boletos',
        },
      ],
    },
  },
  {
    path: '/boleto/v1/titulo/list',
    component: TitleList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Boletos',
        },
      ],
    },
  },
  {
    path: '/parameters',
    component: Parameters,
    pageId: '8190db28-1806-11ed-aade-23ff1b3fb250',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Parâmetros',
        },
      ],
    },
  },
  {
    path: '/products/smart-search',
    component: SmartSearch,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Busca Inteligente',
        },
      ],
    },
  },
  {
    path: '/cest/list',
    component: CestList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `CEST's`,
        },
      ],
    },
  },
  {
    path: '/cest/edit/:id',
    component: CestEdit,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Editar Cest`,
        },
      ],
    },
  },
  {
    path: '/cest/create',
    component: CestCreate,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Criar CEST`,
        },
      ],
    },
  },
  {
    path: '/mkt/consultor/novo',
    component: ConsultantCreate,
    pageId: '73f34230-52fd-11ed-9698-57fcb6fa3f74',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Novo Consultor`,
        },
      ],
    },
  },
  {
    path: '/mkt/rfb/search',
    component: RfbConsultaAvancada,
    pageId: 'cba773ba-5645-11ee-9c83-b354c22af5d7',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Marketing',
          label: `Site da receita`,
        },
      ],
    },
  },
  {
    path: '/mkt/crm/panels',
    component: CrmPanels,
    pageId: '9ad31f3a-8856-11ee-a171-0bd8d1b8897a',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Marketing',
          label: `CRM Paineis`,
        },
      ],
    },
  },
  {
    path: '/mkt/crm/panel/:id',
    component: CrmPanel,
    pageId: '9b68b586-8856-11ee-bd02-13fcabf4c14c',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Marketing',
          label: `CRM Painel`,
        },
      ],
    },
  },
  {
    path: '/mkt/crm/charts',
    component: CrmCharts,
    pageId: '9c1d6634-8856-11ee-9db9-f320cb522b6e',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Marketing',
          label: `CRM Gráficos`,
        },
      ],
    },
  },
  {
    path: '/mkt/consultor/editar/:id',
    component: ConsultantEdit,
    pageId: '7433486c-52fd-11ed-8f2b-eb542e57ec71',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Editar Consultor`,
        },
      ],
    },
  },
  {
    path: '/mkt/consultor/listar',
    component: ConsultantList,
    pageId: '747c86e4-52fd-11ed-b98e-dfbe026a380c',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Listar consultores`,
        },
      ],
    },
  },
  {
    path: '/mkt/consultant/report',
    component: ConsultantReport,
    pageId: '091cf6c2-552d-11ed-b8f0-0f57dc38f02a',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Relatório Consultores`,
        },
      ],
    },
  },
  {
    path: '/mkt/lead/novo',
    component: LeadsCreate,
    pageId: '59f4753e-52fd-11ed-a10f-1bc7588f5abe',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Novo Lead`,
        },
      ],
    },
  },
  {
    path: '/mkt/lead/listar',
    component: LeadsList,
    pageId: '72fbf14c-52fd-11ed-9e68-73b6a94a4c1e',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Leads`,
        },
      ],
    },
  },
  {
    path: '/mkt/lead/editar/:id',
    component: LeadsEdit,
    pageId: '71726568-52fd-11ed-8c37-5fbef7ab9f0c',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Leads`,
        },
      ],
    },
  },
  {
    path: '/mkt/lead/report',
    component: LeadsReport,
    pageId: '088cace8-552d-11ed-a008-db65b899b7fc',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Leads`,
        },
      ],
    },
  },
  {
    path: '/plans/upgrade',
    component: Plans,
    pageId: '6521f6cc-0771-4e7b-95a6-bed0a780186c',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Planos`,
        },
      ],
    },
  },
  {
    path: '/monthly-payments',
    component: Payments,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Mensalidades`,
        },
      ],
    },
  },
  {
    path: '/swaps',
    component: OrderSwapList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Troca de produtos`,
        },
      ],
    },
  },
  {
    path: '/exchanged-orders',
    component: OrderReplaceList,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Pedidos trocados`,
        },
      ],
    },
  },
  {
    path: '/reports/commissions/salles',
    component: ReportCommissionsSalles,
    pageId: 'c020dd4a-ff7c-11ec-b858-d320cd2783e7',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Relatório de comissões de vendas`,
        },
      ],
    },
  },
  {
    path: '/reports/commissions/os',
    component: ReportCommissionsOs,
    pageId: '68840588-c674-11ee-b201-d72a3587e579',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Relatório de comissões de O.S`,
        },
      ],
    },
  },
  {
    path: '/product/convert',
    component: ConvertProduct,
    pageId: 'cf339184-11cd-11ed-b9d7-2b2243f98e8b',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Conversão de Produtos`,
        },
      ],
    },
  },
  {
    path: '/bills/receivable/renegotiation',
    component: BillsReceivableRenegotiation,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Renegociação de contas a receber`,
        },
      ],
    },
  },
  {
    path: '/bills/receivable/drop-all',
    component: BillsreceivableDropByPeriod,
    pageId: 'cf6bffc4-3927-11ee-aaae-db3b20b49bdb',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: 'Baixar contas receber por período',
        },
      ],
    },
  },
  {
    path: '/chat',
    component: Chat,
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Dashboard',
          label: `Chat`,
        },
      ],
    },
  },
  {
    path: '/os/contract',
    component: osContract,
    pageId: '79b1acd6-c353-11ed-8b62-ef3afa30ed16',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Contrato OS',
        },
      ],
    },
  },
  {
    path: '/os/kind/object',
    component: osKindObject,
    exact: true,
    pageId: '79b3656c-c353-11ed-a737-ab35091e1085',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Tipo objeto OS',
        },
      ],
    },
  },
  {
    path: '/os/object',
    component: osObject,
    exact: true,
    pageId: '79b3f518-c353-11ed-9d47-1344a60a9076',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Objeto OS',
        },
      ],
    },
  },
  {
    path: '/os/service',
    component: osService,
    exact: true,
    pageId: '79b481f4-c353-11ed-8ce4-d7c84c16d583',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Serviço OS',
        },
      ],
    },
  },
  {
    path: '/os/service/unity',
    component: osServiceUnity,
    exact: true,
    pageId: '79b52280-c353-11ed-b966-b3720d8fe4cc',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Unidade serviço OS',
        },
      ],
    },
  },
  {
    path: '/os/status',
    component: osStatus,
    exact: true,
    pageId: '79b5bdf8-c353-11ed-b3a5-c752e063bbed',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Status O.S',
        },
      ],
    },
  },
  {
    path: '/os/new/:id',
    component: osNew,
    exact: false,
    pageId: '79b24470-c353-11ed-982f-dfb9e9260303',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Ordem de serviço',
        },
      ],
    },
  },
  {
    path: '/os/new',
    component: osNew,
    exact: false,
    pageId: '79b24470-c353-11ed-982f-dfb9e9260303',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Ordem de serviço',
        },
      ],
    },
  },
  {
    path: '/os/list',
    component: osList,
    exact: true,
    pageId: '79b2d674-c353-11ed-b216-bf8bf34b341e',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Listar OS',
        },
      ],
    },
  },
  {
    path: '/os/print/contract/:id',
    component: osPrintContract,
    exact: true,
    pageId: '',
    meta: {
      breadcrumb: [
        {
          parent: 'O.S',
          label: 'Impressão de contratos de serviço',
        },
      ],
    },
  },
  {
    path: '/consigned/route/list',
    component: ConsignedRouteList,
    pageId: '9f4d9370-8e22-11ee-afa7-17cb814a0a7c',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Listagem de consignados',
        },
      ],
    },
  },
  {
    path: '/consigned/route/open/:id',
    component: ConsignedRouteOpen,
    pageId: '9f4e6e9e-8e22-11ee-ba79-8358b22ff8c6',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Abertura de consignado',
        },
      ],
    },
  },
  {
    path: '/consigned/route/open',
    component: ConsignedRouteOpen,
    pageId: '9f4e6e9e-8e22-11ee-ba79-8358b22ff8c6',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Abertura de rota/consignado',
        },
      ],
    },
  },

  {
    path: '/consigned/order/create/:id',
    component: ConsignedOrderCreate,
    pageId: '9f4f31bc-8e22-11ee-882c-0b195d50751a',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Novo pedido',
        },
      ],
    },
  },
  {
    path: '/consigned/order/create',
    component: ConsignedOrderCreate,
    exact: false,
    pageId: '9f4f31bc-8e22-11ee-882c-0b195d50751a',
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Novo pedido',
        },
      ],
    },
  },
  {
    path: '/consigned/order/update/:id',
    component: ConsignedOrderUpdate,
    pageId: '9f4f31bc-8e22-11ee-882c-0b195d50751a',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Editar pedido',
        },
      ],
    },
  },
  {
    path: '/consigned/order/list',
    component: ConsignedOrderList,
    pageId: '9f4ff19c-8e22-11ee-9ef7-5f76e0d27764',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Listagem de pedidos consignados',
        },
      ],
    },
  },
  {
    path: '/consigned/devolution/create/:id',
    component: ConsignedDevolutionCreate,
    pageId: '9f50c25c-8e22-11ee-b8ad-eb60f940b7f4',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Nova devolução de consignado',
        },
      ],
    },
  },
  {
    path: '/consigned/devolution/create',
    component: ConsignedDevolutionCreate,
    pageId: '9f50c25c-8e22-11ee-b8ad-eb60f940b7f4',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Nova devolução de consignado',
        },
      ],
    },
  },
  {
    path: '/consigned/devolution/update/:id',
    component: ConsignedDevolutionUpdate,
    pageId: '2da5ffd0-9455-11ee-abf0-5b68a4dae01a',
    exact: false,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Editar devolução de consignado',
        },
      ],
    },
  },
  {
    path: '/consigned/devolution/list',
    component: ConsignedDevolutionList,
    pageId: '9f523e3e-8e22-11ee-b163-b747f30c35e4',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Listagem de devoluções consignadas',
        },
      ],
    },
  },
  {
    path: '/report/consigned/route/stock',
    component: ReportRouteStock,
    pageId: '43533ec3-4786-4f2c-bc8d-eac2d54036ca',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Relatório de estoque em rota',
        },
      ],
    },
  },
  {
    path: '/report/consigned/route/search',
    component: ReportRouteSearch,
    pageId: 'b6079990-8e26-11ee-a0ce-8f3ba5739ece',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Relatório de consignados',
        },
      ],
    },
  },
  {
    path: '/report/consigned/order/search',
    component: ReportOrderSearch,
    pageId: 'b6086c80-8e26-11ee-a4a5-677153c94073',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Relatório de pedidos consignados',
        },
      ],
    },
  },
  {
    path: '/report/consigned/devolution/search',
    component: ReportDevolutionSearch,
    pageId: 'b60936ec-8e26-11ee-ab14-23f2c5153b2a',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Consignado',
          label: 'Relatório de devoluções consignadas',
        },
      ],
    },
  },
  {
    path: '/report/inputs/by-payment',
    component: ReportInputsByPaymentType,
    pageId: '',
    exact: true,
    meta: {
      breadcrumb: [
        {
          parent: 'Vendas',
          label: 'Resumo entradas por tipo de pagamento ',
        },
      ],
    },
  },
];

export default routers;

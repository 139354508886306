import React from 'react';

const AppFooter = (): React.ReactElement => {
  const hoje = new Date();
  return (
    <div className="layout-footer">
      <div className="footer-logo-container">
        &#169; Next Tecnologia - 2019-{hoje.getFullYear()}
      </div>
      <span className="copyright">version 2.7.4</span>
    </div>
  );
};

export default AppFooter;

import React from 'react';

const renderColumnDecimal = (num: number) => {
  return (
    <span>
      {new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(num))}
    </span>
  );
};

export default renderColumnDecimal;

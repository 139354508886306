import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import InputProductsHeader from '../input-products-header';

const InputProductXml: React.FC = () => {
  /** useStates */
  const [load, setLoad] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<number>(0);

  /** hooks */
  const toast = useToastContext();
  const fileRef = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);
  const navigate = useHistory();

  /** functions */
  const onTemplateSelect = (e: any) => {
    let _totalSize = fileSize;
    let files = e.files;

    Object.keys(files).forEach(key => {
      _totalSize += files[key].size || 0;
    });

    setFileSize(_totalSize);
  };

  const onTemplateUpload = (e: any) => {
    let _totalSize = 0;

    e.files.forEach((file: any) => {
      _totalSize += file.size || 0;
    });

    setFileSize(_totalSize);
    toast('info', 'Sucesso', 'Arquivo anexado!');
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setFileSize(fileSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setFileSize(0);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = fileSize / 10000;
    const formatedValue =
      fileRef && fileRef.current ? fileRef.current.formatSize(fileSize) : '0 B';

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 MB</span>
          <ProgressBar
            value={value}
            showValue={false}
            style={{ width: '10rem', height: '12px' }}
            className="ml-2"
          ></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    // eslint-disable-next-line react/prop-types
    if (props.files.length > 1) {
      // eslint-disable-next-line react/prop-types
      props.files[0] = props.files[1];
      // eslint-disable-next-line react/prop-types
      onTemplateRemove(file, props.onRemove);
    }
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <i className="fa-solid fa-file"></i>
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          // eslint-disable-next-line react/prop-types
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto"
          // eslint-disable-next-line react/prop-types
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="fa-solid fa-file-code image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte seu arquivo .xml aqui.
        </span>
      </div>
    );
  };

  const chooseOptions = {
    icon: 'fa-solid fa-file-code',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded p-button-outlined',
  };
  const uploadOptions = {
    icon: 'fa-solid fa-upload',
    iconOnly: true,
    className:
      'custom-upload-btn p-button-success p-button-rounded p-button-outlined',
  };
  const cancelOptions = {
    icon: 'fa-solid fa-remove',
    iconOnly: true,
    className:
      'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined',
  };

  const handleSubmit = async () => {
    try {
      setLoad(true);

      const form = new FormData();
      form.append('xml', fileRef.current.files[0]);
      const res = await api.post('/input-products/xml', form);

      if (res.status >= 200 || res.status <= 299) {
        toast(
          'success',
          'Sucesso',
          'Importação da Nfe via XML foi bem sucedida! Clique em listagem de entradas e verifique sua entrada lá.',
          5000,
        );
        fileRef.current?.clear();
        navigate.push('/input-products/list');
      }
    } catch (e: any) {
      toast('error', 'Error', e.response?.data?.error, 10000);
      fileRef.current?.clear();
    } finally {
      setLoad(false);
    }
  };

  /** renders */

  /** useEffects */

  return (
    <>
      <InputProductsHeader />
      {load && (
        <ProgressBar
          mode="indeterminate"
          style={{ height: '4px' }}
          className="sm:w-full"
        />
      )}
      <div className="card">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h4>Entrada de produtos via XML</h4>
          <p>Como conseguir o xml da nota fiscal?</p>
          <p>
            R.: Para conseguir o xml da nfe vc deve entrar no{' '}
            <a
              href="https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g="
              target="_blank"
              rel="noreferrer"
            >
              site da sefaz
            </a>{' '}
            e digitar a chave de 44 caracteres da nfe que fica abaixo do código
            de barras da nota fiscal.
          </p>

          <Tooltip
            target=".custom-choose-btn"
            content="Escolher"
            position="bottom"
          />
          <Tooltip
            target=".custom-upload-btn"
            content="Enviar"
            position="bottom"
          />
          <Tooltip
            target=".custom-cancel-btn"
            content="Limpar"
            position="bottom"
          />

          <FileUpload
            ref={fileRef}
            name="file"
            multiple={false}
            accept="application/xml"
            maxFileSize={1000000}
            onUpload={onTemplateUpload}
            onSelect={onTemplateSelect}
            onError={onTemplateClear}
            onClear={onTemplateClear}
            headerTemplate={headerTemplate}
            itemTemplate={itemTemplate}
            emptyTemplate={emptyTemplate}
            chooseOptions={chooseOptions}
            uploadOptions={uploadOptions}
            cancelOptions={cancelOptions}
          />

          <div className="p-fluid grid formgrid mt-2">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label={load === false ? 'Enviar' : 'Processando'}
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={load}
              />
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default InputProductXml;

/* eslint-disable react/react-in-jsx-scope */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError, AxiosResponse } from 'axios';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { addDays, format, set, startOfDay } from 'date-fns';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { InputNumber as InNumber } from 'primereact/inputnumber';
import { InputSwitch as InputSW } from 'primereact/inputswitch';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import { DataTable } from '../../components/DataTable';
import { Divider } from '../../components/Divider';
import { InputAutoComplete } from '../../components/Inputs/InputAutoComplete';
import Calendar from '../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../components/Inputs/InputDropDown';
import { InputNumber } from '../../components/Inputs/InputNumber';
import { InputSwitch } from '../../components/Inputs/InputSwitch';
import { InputText } from '../../components/Inputs/InputText';
import { InputTextArea } from '../../components/Inputs/InputTextArea';
import { Tabs } from '../../components/Tab';
import api from '../../services/api';
import Round from '../../utils/Round';
import tiposFrete from '../../utils/all_kind_frete';
import ufs from '../../utils/all_state';
import { parseToCnpj, parseToCpf } from '../../utils/cpf_cnpj_format';
import getValidationErrors from '../../utils/getErrorsValidation';
import { currencyToFloat } from '../../utils/number';
import { IProductOpts } from '../order/Create/dtos/IProductOpts';

/** types */
import { Option } from '../../types/optional';
import IOrderTable from '../order/Create/dtos/IOrderTable';
import IPaymentConditionOpts from '../order/Create/dtos/IPaymentConditionOpts';
import ISummary from '../order/Create/dtos/ISummary';
import ITablePriceOpts from '../order/Create/dtos/ITablePriceOpts';
import IOrderPdvDTO from './dtos/IOrderPdvDTO';
import IPdvPaymentsDTO from './dtos/IPdvPaymentsDTO';
import IPdvTopSellerDTO from './dtos/IPdvTopSellerDTO';

/** icons */
import { InputTextarea } from 'primereact/inputtextarea';
import { Tag } from 'primereact/tag';
import { ReactComponent as NoImage } from '../../assets/icons/no-image.svg';

/** context */
import { Tooltip } from 'primereact/tooltip';
import { Dialog } from '../../components/Modal';
import { AppContext } from '../../context/AppContext';
import { FastFormPerson } from '../person/fast-form-person';
import { EnumPayments } from '../../enum/EnumPayments';
import messageRequestError from '../../utils/messageRequestError';
import useSearchCustomer from '../../hooks/useSearchCustomer';

const FrenteDeCaixa = () => {
  /** variaveis */
  const hoje = new Date();

  /** hooks */
  const navigate = useHistory();

  /** context */
  const { state, dispatch } = useContext(AppContext);

  /** refs */
  const formProductsRef = useRef<FormHandles>(null);
  const formCustomerRef = useRef<FormHandles>(null);
  const formSubmit = useRef<FormHandles>(null);

  /** states */
  const [returnList, setReturnList] = useState<boolean>(false);

  const [comLeitor, setComLeitor] = useState<boolean>(false);

  const [isLoad, setIsLoad] = useState<boolean>(false);

  const [tabEnable, setTabEnable] = useState<number>(1);

  const [maisVendidos, setMaisVendidos] = useState<IPdvTopSellerDTO[]>([]);

  const [pedidoId, setPedidoId] = useState<string>(v4());

  const [vendedor, setVendedor] = useState<string>('');
  const [vendedorOpts, setVendedorOpts] = useState<Option[]>([]);

  const [naturezaOperacacao, setNaturezaOperacao] = useState<string>('');
  const [naturezaOperacacaoOpts, setNaturezaOperacaoOpts] = useState<Option[]>(
    [],
  );

  const [loja, setLoja] = useState<string>('');
  const [lojaOpts, setLojaOpts] = useState<Option[]>([]);

  interface IEmpresaDTO {
    id: string;
    name: string;
    document: string;
    image_url: string;
  }
  const [empresa, setEmpresa] = useState<IEmpresaDTO>({
    id: '',
    name: '',
    document: '',
    image_url: '',
  });

  const [pedidoNum, setPedidoNum] = useState<number>(0);

  const [isPedidoProposta, setIsPedidoProposta] = useState<string>('PED');

  const [cliente, setCliente] = useState<Option>({ label: '', value: '' });
  const [clienteOpts, setClienteOpts] = useState<Option[]>([]);

  const [cliNomeRazaoSocial, setCliNomeRazaoSocial] = useState<string>();
  const [cliApelidoFantasia, setCliApelidoFantasia] = useState<string>();
  const [cliCpfCnpj, setCliCpfCnpj] = useState<string>();
  const [cliRgIe, setCliRgIe] = useState<string>();
  const [cliCep, setCliCep] = useState<string>();
  const [cliUf, setCliUf] = useState<string>();
  const [cliCidade, setCliCidade] = useState<string>();
  const [cliEndereco, setCliEndereco] = useState<string>();
  const [cliNum, setCliNum] = useState<string>();
  const [cliComplemento, setCliComplemento] = useState<string>();
  const [cliBairro, setCliBairro] = useState<string>();
  const [cliWhatsapp, setCliWhatsapp] = useState<string>();
  const [cliTelefone, setCliTelefone] = useState<string>();
  const [cliEmail, setCliEmail] = useState<string>();
  const [cliNascimento, setCliNascimento] = useState<Date>();
  const [cliObs, setCliObs] = useState<string>();

  const [condicaoPagamento, setCondicaoPagamento] =
    useState<IPaymentConditionOpts>({
      value: '',
      label: '',
      juros: 0,
      parcelas: 0,
      variacao_dias: 0,
      pre_datado: false,
      cartao: false,
    });
  const [condicaoPagamentoOpts, setCondicaoPagamentoOpts] = useState<
    IPaymentConditionOpts[]
  >([]);
  const [valorCondicaoPagamento, setValorCondicaoPagamento] =
    useState<number>(0);
  const [totalPagamentos, setTotalPagamentos] = useState<number>(0);

  const [itensPagamentos, setItensPagamentos] = useState<IPdvPaymentsDTO[]>([]);
  const [valorPagamento, setValorPagamento] = useState<number>(0);

  const [rowId, setRowId] = useState<string>('');
  const [itensPedido, setItensPedido] = useState<IOrderTable[]>([]);

  const [produto, setProduto] = useState<IProductOpts>({
    sku: '',
    label: '',
    value: '',
    qnt_out: 2,
    price_out: 2,
    stock: 0,
    cost: 0,
    image_url: null,
  });
  const [produtosOpts, setProdutosOpts] = useState<IProductOpts[]>([]);

  const [qntOut, setQntOut] = useState<number>(2);
  const [priceOut, setPriceOut] = useState<number>(2);

  const [tabelaPreco, setTabelaPreco] = useState<ITablePriceOpts>({
    label: '',
    value: '',
    price: 0,
  });
  const [tabelaPrecoOpts, setTabelaPrecoOpts] = useState<ITablePriceOpts[]>([]);

  const [estoque, setEstoque] = useState<number>(0);

  const [qnt, setQnt] = useState<number>(1);

  const [vlrUnit, setVlrUnit] = useState<number>(0);
  const [ttVlrUnit, setTtVlrUnit] = useState<number>(0);

  const [desc, setDesc] = useState<number>(0);
  const [descPercentual, setDescPercentual] = useState<number>(0);
  const [ttDesc, setTtDesc] = useState<number>(0);

  const [adic, setAdic] = useState<number>(0);
  const [adicPercentual, setAdicPercentual] = useState<number>(0);
  const [ttAdic, setTtAdic] = useState<number>(0);

  const [aliJuros, setAliJuros] = useState<number>(0);
  const [juros, setJuros] = useState<number>(0);
  const [ttJuros, setTtJuros] = useState<number>(0);

  const [vlrLiq, setVlrLiq] = useState<number>(0);
  const [ttVlrLiq, setTtVlrLiq] = useState<number>(0);

  const [descGlobal, setDescGlobal] = useState<number>(0);
  const [descGlobalApply, setDescGlobalApply] = useState<boolean>(false);
  const [modalDescGlobalVisible, setModalDescGlobalVisible] =
    useState<boolean>(false);

  const [totalItens, setTotalItens] = useState<number>(0);
  const [totalQnt, setTotalQnt] = useState<number>(0);
  const [totalUnit, setTotalUnit] = useState<number>(0);
  const [descComplementar, setDescComplementar] = useState<number>(0);
  const [totalDescontos, setTotalDescontos] = useState<number>(0);
  const [totalDescontoPercentual, setTotalDescontoPercentual] =
    useState<number>(0);
  const [adicComplementar, setAdicComplementar] = useState<number>(0);
  const [totalAdicionais, setTotalAdicionais] = useState<number>(0);
  const [totalAdicionalPercentual, setTotalAdicionalPercentual] =
    useState<number>(0);
  const [totalJuros, setTotalJuros] = useState<number>(0);
  const [totalPagar, setTotalPagar] = useState<number>(0);

  const [dataEntrega, setDataEntrega] = useState<Date | Date[] | undefined>(
    hoje,
  );

  const [transportadora, setTransportadora] = useState<string>('');
  const [transportadorasOpts, setTransportadorasOpts] = useState<Option[]>();

  const [geraNfe, setGeraNfe] = useState<boolean>(false);
  const [imprimePedido, setImprimePedido] = useState<boolean>(false);

  const [freteTipo, setFreteTipo] = useState<string>(
    tiposFrete.length !== 0 ? tiposFrete[1].value : 'FOB',
  );
  const [fretePlaca, setFretePlaca] = useState<string>('');
  const [freteMotorista, setFreteMotorista] = useState<string>('');
  const [freteDataSaida, setFreteDataSaida] = useState<
    Date | Date[] | undefined
  >(hoje);
  const [freteQnt, setFreteQnt] = useState<number>(0);
  const [freteEspecie, setFreteEspecie] = useState<string>('');
  const [freteMarca, setFreteMarca] = useState<string>('');
  const [freteNumLacre, setFreteNumLacre] = useState<string>('');
  const [fretePesoBruto, setFretePesoBruto] = useState<number>(0);
  const [fretePesoLiquido, setFretePesoLiquido] = useState<number>(0);
  const [obs, setObs] = useState<string>('');

  const [user, setUser] = useState<any>();

  const [vlrFocus, setVlrFocus] = useState<boolean>(false);
  const vlrRef = useRef<HTMLInputElement>(null);
  const [qntFocus, setQntFocus] = useState<boolean>(false);
  const qntRef = useRef<HTMLInputElement>(null);
  const [productFocus, setProductFocus] = useState<boolean>(true);
  const productRef = useRef<HTMLInputElement>(null);

  const [formCustomerVisible, setFormCustomerVisible] =
    useState<boolean>(false);

  /** Shortcuts */

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ESC':
          return navigate.push('/orders/list');
        case 'F10':
          return handleRowSelected(itensPedido.findLast(i => true));

        case 'F9':
          return setTabEnable(prevState =>
            prevState === 3 ? 1 : prevState + 1,
          );
        case 'F2':
          return handlePaymentConditionSelected(
            condicaoPagamentoOpts.find(p => p.sigla === EnumPayments.DINHEIRO),
          );
        case 'F3':
          return handlePaymentConditionSelected(
            condicaoPagamentoOpts.find(p => p.sigla === EnumPayments.PIX),
          );
        case 'F4':
          return handlePaymentConditionSelected(
            condicaoPagamentoOpts.find(
              p => p.sigla === EnumPayments.CARTAO_DEBITO,
            ),
          );
        case 'F6':
          return handlePaymentConditionSelected(
            condicaoPagamentoOpts.find(
              p => p.sigla === EnumPayments.CARTAO_CREDITO,
            ),
          );
        case 'F8':
          return handlePaymentConditionSelected(
            condicaoPagamentoOpts.find(
              p =>
                p.sigla === EnumPayments.BOLETO ||
                p.sigla === EnumPayments.DUPLICATA,
            ),
          );
        default:
          return;
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [condicaoPagamentoOpts, itensPedido]);

  useEffect(() => {
    if (tabEnable === 1) {
      productRef?.current?.focus();
      setProductFocus(true);
    }
  }, [tabEnable]);

  /** renders */
  const itemTemplate = (item: Option) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const formatTableColumnToDecimal = (row: any, property: string) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(row[property]);
  };

  const buttonRemItemTable = (rowId: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemItem(rowId)}
          disabled={tabEnable !== 1}
        />
      </div>
    );
  };

  const codeColumnBody = (rowData: any, attr: any) => {
    return <span>{attr?.rowIndex + 1}</span>;
  };

  const paymentsColumnDate = (rowData: any, attr: any) => {
    return <span>{format(rowData.vencimento, 'dd/M/yy')}</span>;
  };

  const paymentsColumnDecimal = (rowData: any, attr: any) => {
    return (
      <span>
        {new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(rowData.valor)}
      </span>
    );
  };

  const paymentsButtonRemItem = (id: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemItemPagamento(id)}
          disabled={tabEnable !== 3}
        />
      </div>
    );
  };

  /** functions */

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  useEffect(() => {
    loadUserData();
  }, []);

  const searchProduct = async (event: AutoCompleteCompleteMethodParams) => {
    if (cliente.value === '') {
      toast.warn('Informe um cliente!');
      return;
    }

    if (event.query.length >= 3) {
      api
        .get(`/products/v2/search?src=${event.query}`)
        .then(({ data }) => {
          const productsOptions = data.products.map((item: any) => {
            return {
              value: item.id,
              label: item.titulo,
              sku: item.sku || '',
              stock: item.estoque,
              price_out: item.price_out,
              qnt_out: item.qnt_out,
              image_url: item.image_url,
            };
          });

          if (productsOptions.length <= 0) {
            toast.warn('Nenhum produto encontrado!');
          }

          setProdutosOpts(productsOptions);
        })
        .catch((e: any) => {
          if (e.code === 'ERR_BAD_REQUEST') {
            toast.warn(e.response.data.error);
          } else {
            toast.warn(e.message);
          }
        });
    }
  };

  const searchProductByBarcode = async (
    event: AutoCompleteCompleteMethodParams,
  ) => {
    if (cliente.value === '') {
      toast.warn('Informe um cliente!');
      return;
    }

    if (event.query.length === 6) {
      setIsLoad(true);
      await api
        .get(`/products/v2/sku?short_sku=${event.query}`)
        .then(({ data }) => {
          if (data) {
            const item: IOrderTable = {
              id: v4(),
              position: itensPedido.length + 1,
              sku: data.listProductBySku.sku || '',
              product_id: data.listProductBySku.product_id,
              product_description: data.listProductBySku.product_description,
              stock: data.listProductBySku.stock,
              table_price_id: data.listProductBySku.table_price_id,
              table_price_description:
                data.listProductBySku.table_price_description,
              qnt_out: data.listProductBySku.qnt_out,
              price_out: data.listProductBySku.price_out,
              qnt: 1,
              vlr_unit: data.listProductBySku.vlr_unit,
              tt_unit: data.listProductBySku.vlr_unit * 1,
              desc: 0,
              tt_desc: 0,
              adic: 0,
              tt_adic: 0,
              ali_juros: 0,
              juros: 0,
              tt_juros: 0,
              vlr_liq: data.listProductBySku.vlr_unit * 1,
              vlr_liq_total: data.listProductBySku.vlr_unit * 1,
              inserted_at: hoje,
              cost: 0,
            };
            setItensPedido([...itensPedido, item]);
          }
        })
        .catch((e: any) => {
          if (e.code === 'ERR_BAD_REQUEST') {
            toast.warn(e.response.data.error);
          } else {
            toast.warn(e.message);
          }
        })
        .finally(() => setIsLoad(false));
    }

    if (event.query.length === 13) {
      setIsLoad(true);
      await api
        .get(`/products/v2/barcode?code=${event.query}`)
        .then(({ data }) => {
          if (data) {
            const item: IOrderTable = {
              id: v4(),
              position: itensPedido.length + 1,
              sku: data.listProductByBarcode.sku || '',
              product_id: data.listProductByBarcode.product_id,
              image_url: data.listProductByBarcode.image_url,
              product_description:
                data.listProductByBarcode.product_description,
              stock: data.listProductByBarcode.stock,
              table_price_id: data.listProductByBarcode.table_price_id,
              table_price_description:
                data.listProductByBarcode.table_price_description,
              qnt_out: data.listProductByBarcode.qnt_out,
              price_out: data.listProductByBarcode.price_out,
              qnt: 1,
              vlr_unit: data.listProductByBarcode.vlr_unit,
              tt_unit: data.listProductByBarcode.vlr_unit * 1,
              desc: 0,
              tt_desc: 0,
              adic: 0,
              tt_adic: 0,
              ali_juros: 0,
              juros: 0,
              tt_juros: 0,
              vlr_liq: data.listProductByBarcode.vlr_unit * 1,
              vlr_liq_total: data.listProductByBarcode.vlr_unit * 1,
              inserted_at: hoje,
              cost: 0,
            };
            setItensPedido([...itensPedido, item]);
          }
        })
        .catch((e: any) => {
          if (e.code === 'ERR_BAD_REQUEST') {
            toast.warn(e.response.data.error);
          } else {
            toast.warn(e.message);
          }
        })
        .finally(() => setIsLoad(false));
    }
  };

  const searchCustomer = async (search: string) => {
    setIsLoad(true);
    try {
      const customers = await useSearchCustomer({ search });

      if (customers) {
        setClienteOpts(
          customers.map((i: any) => {
            return {
              value: i.id,
              label: `${i.name} - ${i.codigo}`,
            };
          }),
        );
      }
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setIsLoad(false);
    }
  };

  const handleClearProductRowOnSelected = () => {
    setRowId('');
    setTabelaPreco({
      label: '',
      value: '',
      price: 0,
    });
    setEstoque(0);
    setQnt(0);
    setVlrUnit(0);
    setTtVlrUnit(0);
    setDesc(0);
    setDescPercentual(0);
    setTtDesc(0);
    setAdic(0);
    setTtAdic(0);
    setTtVlrLiq(0);
    setJuros(0);
    setVlrLiq(0);
    setTtVlrLiq(0);
  };

  const handleClearProductRow = () => {
    setRowId('');
    setProduto({
      sku: '',
      label: '',
      value: '',
      stock: 0,
      cost: 0,
      price_out: 2,
      qnt_out: 2,
      image_url: null,
    });
    setTabelaPreco({
      label: '',
      value: '',
      price: 0,
    });
    setTabelaPrecoOpts([]);
    setEstoque(0);
    setQnt(0);
    setVlrUnit(0);
    setTtVlrUnit(0);
    setDesc(0);
    setDescPercentual(0);
    setTtDesc(0);
    setAdic(0);
    setTtAdic(0);
    setTtVlrLiq(0);
    setJuros(0);
    setVlrLiq(0);
    setTtVlrLiq(0);
  };

  const handleClearPdv = async () => {
    setTabEnable(1);
    setCondicaoPagamento({} as IPaymentConditionOpts);
    handleClearProductRow();
    setItensPedido([]);
    setItensPagamentos([]);
    setDescGlobal(0);
    setDescGlobalApply(false);
  };

  const searchTablePriceByProductId = async (productId: string) => {
    await api
      .get(`/products/li/${productId}`)
      .then(({ data }) => {
        const tablePricesOpts = data.map((item: any) => {
          return {
            label: item.table_price_title,
            value: item.table_price_id,
            price: Number(item.vlr_venda),
          };
        });

        if (tablePricesOpts.length === 0) {
          toast.warn('Produto sem preço cadastrado!');
          return;
        }

        setTabelaPrecoOpts(tablePricesOpts);
        setTabelaPreco({
          label: tablePricesOpts[0].label,
          value: tablePricesOpts[0].value,
          price: tablePricesOpts[0].price,
        });

        setVlrUnit(tablePricesOpts[0].price);

        setQnt(1);

        setProductFocus(false);
        setQntFocus(true);
        qntRef?.current?.focus();
      })
      .catch((e: AxiosError) => {
        toast.error(`Erro: ${e.message}`);
        return;
      });
  };

  const handleProductSelect = async (e: any) => {
    setQntOut(e.qnt_out);
    setPriceOut(e.price_out);
    setEstoque(e.stock);

    searchTablePriceByProductId(e.value);

    setProductFocus(false);
    setQntFocus(true);
    qntRef?.current?.focus();
  };

  const handleTablePriceSelect = async (e: any) => {
    if (!produto) {
      toast.warn('Selecione um produto no sistema!');
      return;
    }

    const tp = tabelaPrecoOpts.find(t => t.value === e.value);

    if (tp) {
      setTabelaPreco({
        label: tp.label,
        value: tp.value,
        price: tp.price,
      });

      setVlrUnit(tp.price);

      setQnt(1);
    }
  };

  const handlePaymentConditionSelected = async (e: any) => {
    const cp = condicaoPagamentoOpts.find(f => f.value === e.value);

    if (cp) {
      setCondicaoPagamento({
        label: cp.label,
        value: cp.value,
        variacao_dias: cp.variacao_dias,
        juros: cp.juros,
        parcelas: cp.parcelas,
        pre_datado: cp.pre_datado,
        cartao: cp.cartao,
      });

      setVlrFocus(true);
      setQntFocus(false);
      setProductFocus(false);
      vlrRef.current?.focus();
    }
  };

  const handleAddPaymentValue = () => {
    // valida condicao de pagamento
    if (condicaoPagamento.value === '') {
      toast.warn('Informe uma condição de pagamento!');
      return;
    }

    // valida valor
    if (valorCondicaoPagamento === 0) {
      toast.warn('Informe um valor para o pagamento!');
      return;
    }

    if (valorCondicaoPagamento < 0) {
      toast.warn('Informe um valor válido para o pagamento!');
      return;
    }

    const payments: IPdvPaymentsDTO[] = [];
    let rest: number = valorCondicaoPagamento;
    let valor = 0;
    for (let i = 1; i <= condicaoPagamento.parcelas; i++) {
      if (i !== condicaoPagamento.parcelas) {
        valor = Round(valorCondicaoPagamento / condicaoPagamento.parcelas);
        rest -= valor;
      } else {
        valor = Round(rest);
      }

      payments.push({
        id: v4(),
        payment_condition_id: condicaoPagamento.value,
        descricao: `${condicaoPagamento.label} - ${i}#${condicaoPagamento.parcelas}`,
        dias: condicaoPagamento.variacao_dias * i,
        parcela: i,
        valor,
        vencimento: addDays(hoje, condicaoPagamento.variacao_dias * i),
      });
    }
    setItensPagamentos([...itensPagamentos, ...payments]);
    handleClearPaymentRow();
  };

  const handleRowSelected = (e: any) => {
    if (e) {
      const itemSelected: IOrderTable = e;
      setProduto({
        image_url: itemSelected.image_url || null,
        label: itemSelected.product_description,
        price_out: itemSelected.price_out,
        qnt_out: itemSelected.qnt_out,
        sku: itemSelected.sku,
        stock: itemSelected.stock,
        value: itemSelected.product_id,
        cost: itemSelected.cost,
      });
      searchTablePriceByProductId(itemSelected.product_id);
      const noId = itensPedido.filter(i => i.id !== itemSelected.id);

      setItensPedido(noId);
    }
  };

  const handleAddItensPedido = async (data: any) => {
    setIsLoad(true);

    formProductsRef.current?.setErrors({});

    if (qnt === 0 || qnt === null) {
      setProductFocus(true);
      return;
    }

    data.product_id = produto.value;
    data.table_price_id = tabelaPreco.value;

    data.adic = currencyToFloat(data.adic);
    data.desc = currencyToFloat(data.desc);
    data.qnt = currencyToFloat(qnt.toString());
    data.vlr_liq = currencyToFloat(data.vlr_liq);
    data.total = currencyToFloat(data.total);

    try {
      const validation = Yup.object().shape({
        product_id: Yup.string().required('Produto é obrigatório!'),
        table_price_id: Yup.string()
          .uuid()
          .required('Tabela de preços é obrigatória!'),
        qnt: Yup.number()
          .positive('Quantidade deve ser positiva!')
          .required('Quantidade é obrigatória!'),
        vlr_liq: Yup.number()
          .positive()
          .required('Valorlíquido é obrigatório!'),
        total: Yup.number().positive().required('Total é obrigatário!'),
      });

      await validation.validate(data, { abortEarly: false });

      const item: IOrderTable = {
        id: v4(),
        position: itensPedido.length + 1,
        sku: produto.sku,
        product_id: produto.value,
        product_description: produto.label,
        stock: produto.stock,
        table_price_id: tabelaPreco.value,
        table_price_description: tabelaPreco.label,
        qnt_out: produto.qnt_out,
        price_out: produto.price_out,
        qnt: Number(qnt),
        vlr_unit: vlrUnit,
        tt_unit: ttVlrUnit,
        desc: Number(desc),
        tt_desc: ttDesc,
        adic: Number(adic),
        tt_adic: ttAdic,
        ali_juros: aliJuros,
        juros: Number(juros),
        tt_juros: ttJuros,
        vlr_liq: vlrLiq,
        vlr_liq_total: ttVlrLiq,
        inserted_at: hoje,
        cost: 0,
      };

      setItensPedido([...itensPedido.filter(r => r.id !== rowId), item]);

      handleClearProductRowOnSelected();
      handleClearProductRow();
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formProductsRef.current?.setErrors(errors);
        toast.warn(`Falha: ${e.errors[0]}`);
        return;
      } else {
        toast.warn(`Falha: ${e.response?.data?.error}`);
        return;
      }
    } finally {
      setIsLoad(false);
    }
  };

  useEffect(() => {
    setQntFocus(false);
    setProductFocus(true);
    productRef.current?.focus();
  }, [itensPedido]);

  const handleRemItem = async (rowId: string) => {
    const itensNoId = itensPedido.filter(r => r.id !== rowId);
    setItensPedido(itensNoId);
    handleClearProductRow();
  };

  const handleCloseFormCustomer = () => {
    setFormCustomerVisible(false);
  };

  const handleClearPaymentRow = () => {
    setCondicaoPagamento({
      value: '',
      label: '',
      juros: 0,
      parcelas: 0,
      variacao_dias: 0,
      pre_datado: false,
      cartao: false,
    });
    setValorCondicaoPagamento(0);
  };

  const handleRemItemPagamento = (id: string) => {
    const item = itensPagamentos.find(i => i.id === id);
    const itensNoPaymentId = itensPagamentos.filter(
      i => i.payment_condition_id !== item?.payment_condition_id,
    );
    setItensPagamentos(itensNoPaymentId);
    handleClearPaymentRow();
  };

  const handleSelectCustomer = (e: any) => {
    setIsLoad(true);
    setCliente(e.value);
    setIsLoad(false);
  };

  const handleUnAppyDescGlobal = (arr: Array<IOrderTable>) => {
    arr.forEach((item: IOrderTable) => {
      item.desc = 0;
      item.tt_desc = 0;
    });
    setDescGlobalApply(false);
  };

  const handleApplyDescGlobal = (arr: Array<IOrderTable>, desc: number) => {
    if (descGlobalApply) {
      toast.warn(
        'Desconto global já aplicado, caso queira aplicar um novo limpe o anterior no botão Limpar!',
      );
      return;
    }
    const qntItems = arr.length;

    if (qntItems <= 0) {
      toast.warn(
        'Informe os itens na venda para depois aplicar o desconto global!',
      );
      return;
    }
    const descByItem = desc / qntItems;
    let totalDescApply: number = 0;

    arr.forEach((item: IOrderTable, index: number) => {
      const descItem = Number(descByItem / item.qnt);

      if (descItem <= item.vlr_unit) {
        item.desc = Number(descItem.toFixed(2));
        item.tt_desc = Number(descItem.toFixed(2)) * Number(item.qnt);
        item.vlr_liq -= Number(descItem);
        item.vlr_liq_total -= Number(descItem);
        totalDescApply += Number(descItem.toFixed(2)) * Number(item.qnt);
      } else {
        item.desc = 0;
        item.tt_desc = 0;
      }

      if (index === qntItems - 1 && totalDescApply < desc) {
        const difDesc: number = desc - totalDescApply;
        item.desc += Number(difDesc);
        item.tt_desc -= Number(difDesc.toFixed(2)) * Number(item.qnt);
        item.vlr_liq -= Number(descItem);
        item.vlr_liq_total -= Number(descItem);
        totalDescApply -= Number(difDesc.toFixed(2)) * Number(item.qnt);
      }
    });

    setItensPedido([...arr]);
    setModalDescGlobalVisible(false);
    setDescGlobalApply(true);
  };

  const handleSubmit = async () => {
    try {
      setIsLoad(true);

      const order: IOrderPdvDTO = {
        orderPdv: {
          store_id: loja,
          nat_ope_id: naturezaOperacacao,
          commissioned_id: vendedor,
          delivery_date: dataEntrega,
          customer: {
            customer_id: cliente.value,
          },
          itens: itensPedido.map(i => {
            return {
              position: i.position,
              product_id: i.product_id,
              table_price_id: i.table_price_id,
              qnt: i.qnt,
              additional: i.adic,
              discount: i.desc,
              vlr_unit: i.vlr_unit,
              sub_total: i.vlr_liq_total,
            };
          }),
          payments: itensPagamentos.map(i => {
            return {
              payment_condition_id: i.payment_condition_id,
              value: i.valor,
            };
          }),
          transporter: {
            transporter_id: transportadora,
            kind_frete: freteTipo,
            placa: fretePlaca,
            motorista: freteMotorista,
            especie: freteEspecie,
            qnt: freteQnt,
            marca: freteMarca,
            nr_lacre: freteNumLacre,
            peso_bruto: fretePesoBruto,
            peso_liquido: fretePesoLiquido,
          },
          fiscal: {
            gera_nfce: geraNfe,
            print_cupom: true,
          },
          additionals: totalAdicionais,
          discounts: totalDescontos,
          sub_total: totalUnit,
          total: totalPagar,
          obs,
        },
      };

      const res = await api.post('/pdv/faturar', order);

      await api.get(`/reports/coupom/${res.data}`);
      window.open(
        `${process.env.REACT_APP_SERVER_URL}/files/pdf/${state.user.id.replace(
          /-/g,
          '',
        )}.pdf`,
      );

      if (returnList === true) {
        navigate.push('/orders/list');
      } else {
        handleClearPdv();
        navigate.push('/frente-caixa');
      }
    } catch (e: any) {
      if (e instanceof AxiosError) {
        if (e.response?.data?.message === 'Validation failed') {
          toast.error(`Alerta: ${e.response?.data.validation.body.message}`);
        }
      }

      if (e instanceof Yup.ValidationError) {
        toast.warn(e.errors[0]);
      } else {
        toast.warn(e.response?.data?.error);
      }
    } finally {
      setIsLoad(false);
    }
  };

  /** useEffect's */
  useEffect(() => {
    itensPedido.forEach((item: IOrderTable) => {
      item.tt_unit = Number(item.vlr_unit) * Number(item.qnt);
      item.tt_desc = Number(item.desc) * Number(item.qnt);
      item.tt_adic = Number(item.adic) * Number(item.qnt);
      item.vlr_liq =
        Number(item.vlr_unit) + Number(item.adic) - Number(item.desc);
      item.vlr_liq_total =
        (Number(item.vlr_unit) + Number(item.adic) - Number(item.desc)) *
        Number(item.qnt);
      return item;
    });
  }, [descGlobal, itensPedido]);

  /** calcula itens da linha de inserção */
  useEffect(() => {
    const _ttVlrUnit = vlrUnit * qnt;
    setTtVlrUnit(_ttVlrUnit);

    const _ttDesc = desc * qnt;
    setTtDesc(_ttDesc);

    const _ttAdic = adic * qnt;
    setTtAdic(_ttAdic);

    const _juros = ((vlrUnit + adic - desc) * aliJuros) / 100;
    setJuros(Round(_juros));

    const _ttJuros = (((vlrUnit + adic - desc) * aliJuros) / 100) * qnt;
    setTtJuros(Round(_ttJuros));

    const _vlrLiq = vlrUnit + adic + juros - desc;
    setVlrLiq(_vlrLiq);

    const _ttVlrLiq = (vlrUnit + adic + juros - desc) * qnt;
    setTtVlrLiq(_ttVlrLiq);

    const _descPercentual = (desc * 100) / vlrUnit;
    setDescPercentual(Round(_descPercentual || 0));

    const _adicPercentual = (adic * 100) / vlrUnit;
    setAdicPercentual(Round(_adicPercentual || 0));
  }, [
    tabelaPreco,
    aliJuros,
    produto,
    tabelaPreco,
    estoque,
    qnt,
    vlrUnit,
    desc,
    adic,
    vlrLiq,
    ttVlrUnit,
  ]);

  /** calcula totais do pedido */
  useEffect(() => {
    const summaryTotais = itensPedido.reduce(
      (acc: ISummary, item: IOrderTable) => {
        acc.totalItens++;
        acc.totalQnt += item.qnt;
        acc.totalUnit += item.tt_unit;
        acc.totalDescontos += item.tt_desc;
        acc.totalAdicionais += item.tt_adic;
        acc.totalJuros += item.tt_juros;
        acc.totalPedido += item.vlr_liq_total;
        return acc;
      },
      {
        totalItens: 0,
        totalQnt: 0,
        totalUnit: 0,
        totalDescontos: 0,
        totalAdicionais: 0,
        totalJuros: 0,
        totalPedido: 0,
      },
    );

    setTotalItens(summaryTotais.totalItens);
    setTotalQnt(summaryTotais.totalQnt);
    setTotalUnit(summaryTotais.totalUnit);
    setTotalDescontos(summaryTotais.totalDescontos);
    setTotalAdicionais(summaryTotais.totalAdicionais);
    setTotalJuros(summaryTotais.totalJuros);
    setTotalPagar(summaryTotais.totalPedido);
  }, [itensPedido, tabelaPreco, descGlobal]);

  useEffect(() => {
    if (user) {
      setIsLoad(true);
      api
        .get('/commissioneds')
        .then(({ data }) => {
          const commissionedsActive = data.filter((i: any) => i.status === 'A');
          const opts = commissionedsActive.map((c: any) => {
            return {
              label: c.name,
              value: c.id,
            };
          });
          setVendedorOpts(opts);

          const userLogged = commissionedsActive.find(
            (c: any) => c.user_sis_id === user.id,
          );
          setVendedor(userLogged ? userLogged.id : opts[0].value);
        })
        .catch((e: AxiosError) => {
          toast.error(
            `Falha ao buscar os vendedores/comissionados. Erro: ${e.message}`,
          );
        })
        .finally(() => setIsLoad(false));
    }
  }, [user]);

  const loadTransporters = async () => {
    setIsLoad(true);
    await api
      .post('/persons/transporters', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then((res: AxiosResponse) => {
        if (res.data) {
          const transporters = res.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
            };
          });

          setTransportadorasOpts([
            { value: '', label: 'Nenhuma' },
            ...transporters,
          ]);
        }
      })
      .catch((e: AxiosError) => {
        toast.error(`Falha ao buscar as transportadoras!. Erro: ${e.message}`);
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadDefaultCustomer = async () => {
    setIsLoad(true);
    await api
      .get('/persons/default/consumidor')
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCliente({ value: res.data.id, label: res.data.name });
        }
      })
      .catch((e: AxiosResponse) => {
        toast.error(
          `Falha o CONSUMIDOR padrão do sistema!. Erro: ${e.data.message}`,
        );
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    setIsLoad(true);

    api
      .get('/stores')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });

        setLojaOpts(opts);
      })
      .catch((e: AxiosError) => {
        toast.error(`Falha ao buscar as lojas. Erro: ${e.message}`);
        setIsLoad(false);
      });

    api
      .post('/naturezas-operacao/list/short', {
        paginate: {
          page: 1,
          perPage: 100,
          status: ['A'],
          kind: ['saida'],
        },
      })
      .then(({ data }) => {
        setNaturezaOperacaoOpts(
          data.map((item: any) => {
            return {
              label: item.title,
              value: item.id,
            };
          }),
        );
      })
      .catch((e: AxiosError) => {
        toast.error(
          `Falha ao buscar as naturezas de operação. Erro: ${e.message}`,
        );
        setIsLoad(false);
      });

    api
      .get('/order-header/last-id')
      .then(({ data }) => {
        setPedidoNum(data);
      })
      .catch((e: AxiosError) => {
        setPedidoNum(0);
        toast.error(
          `Falha ao buscar o número do último pedido emitido!. Erro: ${e.message}`,
        );
        setIsLoad(false);
      });

    api
      .get('/payments-condition')
      .then(({ data }) => {
        const opts: IPaymentConditionOpts[] = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
            sigla: item.sigla,
            juros: Number(item.taxa_juros_base),
            parcelas: item.qnt_parcelas,
            variacao_dias: item.variacao_dias,
            pre_datado: item.pre_datado,
            cartao: item.cartao,
          };
        });

        setCondicaoPagamentoOpts(opts);
      })
      .catch((e: AxiosError) => {
        toast.error(
          `Falha ao buscar as condições de pagamento!. Erro: ${e.message}`,
        );
        setIsLoad(false);
      });

    api
      .get('/params')
      .then(({ data }) => {
        return null;
      })
      .catch((e: AxiosError) => {
        toast.error(
          `Falha ao buscar osparâmetrosda empresa!. Erro: ${e.message}`,
        );
        setIsLoad(false);
      });
    api
      .get('/params/salles/default')
      .then(({ data }) => {
        setEmpresa({
          id: data.business.id,
          name: data.business.name,
          document: data.business.document,
          image_url: data.business.image_url,
        });

        setLoja(data?.store?.id);

        setNaturezaOperacao(data?.pdv_nat_ope?.id);
      })
      .catch((e: AxiosError) => {
        toast.error(
          `Falha ao buscar osparâmetrosde venda!. Erro: ${e.message}`,
        );
        setIsLoad(false);
      });

    loadTransporters();
    loadDefaultCustomer();

    api
      .get('/products-stock/top/sellers')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            product_id: item.product_id,
            title: item.title,
            image_url: item.image_url,
            total: item.total,
          };
        });
        setMaisVendidos(opts);
      })
      .catch((e: AxiosError) => {
        toast.error(
          `Falha ao buscar produtos mais vendidos!. Erro: ${e.message}`,
        );
        setIsLoad(false);
      });

    setIsLoad(false);
  }, []);

  useEffect(() => {
    const summaryPagamentos = itensPagamentos.reduce(
      (acc, item) => {
        acc.total += item.valor;
        return acc;
      },
      {
        total: 0,
      },
    );

    setAliJuros(0);
    setTotalPagamentos(summaryPagamentos.total);
  }, [itensPagamentos]);

  useEffect(() => {
    if (validate(cliente.value) && cliente.label !== 'CONSUMIDOR') {
      api
        .get(`/persons/${cliente.value}`)
        .then(({ data }) => {
          setCliNomeRazaoSocial(data.name ? data.name : null);
          setCliApelidoFantasia(data.nickname ? data.nickname : null);

          if (data.cpf_cnpj) {
            if (data.cpf_cnpj.length === 11) {
              data.cpf_cnpj = parseToCpf(data.cpf_cnpj);
            } else {
              data.cpf_cnpj = parseToCnpj(data.cpf_cnpj);
            }
          }
          setCliCpfCnpj(data.cpf_cnpj ? data.cpf_cnpj : null);

          setCliRgIe(data.rg_ie ? data.rg_ie : null);
          if (data.addresses && data.addresses.length > 0) {
            setCliCep(
              data.addresses[0].post_code ? data.addresses[0].post_code : null,
            );
            setCliUf(
              data.addresses[0].city.uf.sigla
                ? data.addresses[0].city.uf.sigla
                : null,
            );
            setCliCidade(
              data.addresses[0].city.name ? data.addresses[0].city.name : null,
            );
            setCliEndereco(
              data.addresses[0].street ? data.addresses[0].street : null,
            );
            setCliNum(
              data.addresses[0].number ? data.addresses[0].number : null,
            );
            setCliComplemento(
              data.addresses[0].complement
                ? data.addresses[0].complement
                : null,
            );
            setCliBairro(
              data.addresses[0].neighborhood
                ? data.addresses[0].neighborhood
                : null,
            );
          }
          if (data.contacts && data.contacts.length > 0) {
            setCliWhatsapp(
              data.contacts[0].phone_whatsapp
                ? data.contacts[0].phone_whatsapp
                : null,
            );
            setCliTelefone(
              data.contacts[0].phone_fixed
                ? data.contacts[0].phone_fixed
                : null,
            );
            setCliEmail(data.contacts[0].email ? data.contacts[0].email : null);
          }
          setCliNascimento(data.birthday ? new Date(data.birthday) : undefined);
          setCliObs(data.obs ? data.obs : null);
        })
        .catch((e: AxiosError) => {
          toast.error(`Falha ao buscar dados do cliente!. Erro: ${e.message}`);
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  }, [cliente]);

  return (
    <>
      <div style={{ height: '100%', padding: '1rem' }}>
        {/** header */}
        <div className="field">
          <div className="card">
            <div className="flex justify-content-between flex-wrap card-container">
              <Link
                to="/orders/list"
                className="flex align-items-center justify-content-center"
              >
                <Avatar
                  image={
                    empresa.image_url === null || empresa.image_url === ''
                      ? `${process.env.REACT_APP_SERVER_URL}/files/img/no-photo.jpg`
                      : `${process.env.REACT_APP_SERVER_URL}/files/img/${empresa.image_url}`
                  }
                  size="large"
                  shape="circle"
                  style={{ border: '1px solid #666' }}
                />
                <i className="ml-3 fa-solid fa-arrow-left"></i>
                <span className="ml-3 align-items-center">Voltar</span>
              </Link>
              <div
                className="flex align-items-center justify-content-center"
                style={{ fontSize: '1.5rem' }}
              >
                <strong>Empresa.: {empresa.name}</strong>
                <div className="mx-3">
                  Cpf/Cnpj.:{' '}
                  {empresa.document.length === 11
                    ? cpf.format(empresa.document)
                    : cnpj.format(empresa.document)}
                </div>
                <div>Usuário.: {state.user.name}</div>
              </div>
              <div
                className="flex align-items-center justify-content-center"
                style={{ fontSize: '1.5rem' }}
              >
                DATA: {format(new Date(), 'dd/MM/yy')}
              </div>
            </div>
          </div>
        </div>

        {/** body */}
        <div className="field">
          <div className="grid">
            {/** antigos pedidos */}
            <div className="md:col-2">
              <div className="card" key={v4()}>
                <div className="flex justify-content-between flex-wrap">
                  <InputSW
                    className="mr-3"
                    checked={returnList}
                    onChange={e => setReturnList(e.value)}
                  />
                  <label htmlFor="dropdown" className="m-0">
                    Retorna a lista
                  </label>
                </div>
              </div>
            </div>

            {/** dados compra */}
            <div className="md:col-5">
              <div className="card relative" style={{ height: '80vh' }}>
                <div className="field flex justify-content-between">
                  <Tabs
                    enable={tabEnable}
                    setTabEnable={setTabEnable}
                    enableBefore={false}
                    index={1}
                  >
                    <i className="pi pi-box mr-3"></i>
                    Produtos
                  </Tabs>
                  <Tabs
                    enable={tabEnable}
                    setTabEnable={setTabEnable}
                    enableBefore={false}
                    index={2}
                  >
                    <i className="pi pi-user mr-3"></i>
                    Cliente
                  </Tabs>
                  <Tabs
                    enable={tabEnable}
                    setTabEnable={setTabEnable}
                    enableBefore={false}
                    index={3}
                  >
                    <i className="pi pi-dollar mr-3"></i>
                    Pagamentos
                  </Tabs>
                </div>

                {/** produtos */}
                <Form
                  ref={formProductsRef}
                  onSubmit={handleAddItensPedido}
                  placeholder={''}
                  onPointerEnterCapture={null}
                  onPointerLeaveCapture={null}
                >
                  <div style={{ display: tabEnable === 1 ? 'flex' : 'none' }}>
                    <div className="card shadow-none">
                      {comLeitor === false && (
                        <div className="p-fluid grid formgrid mb-3">
                          <div className="field col-12 md:col">
                            <label htmlFor="product_id">
                              Produto
                              <span className="ml-3 text-blue-500">
                                (ESTQ:{' '}
                                {new Intl.NumberFormat('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(estoque)}
                                )
                              </span>
                            </label>
                            <InputAutoComplete
                              inputRef={productRef}
                              name="product_id"
                              value={produto}
                              completeMethod={e => searchProduct(e)}
                              suggestions={produtosOpts}
                              field="label"
                              onSelect={e => handleProductSelect(e.value)}
                              onChange={e => setProduto(e.value)}
                              itemTemplate={itemTemplate}
                              placeholder="Buscar produto..."
                              autoFocus={productFocus}
                              onKeyPress={e => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                  setQntFocus(true);
                                  qntRef?.current?.focus();
                                }
                              }}
                            />
                          </div>
                        </div>
                      )}

                      {comLeitor === true && (
                        <div className="p-fluid grid formgrid mb-3">
                          <div className="field col-12 md:col-12">
                            <label htmlFor="product_id">
                              Apenas leitora de código
                              <span className="ml-3 text-blue-500">
                                (ESTQ:{' '}
                                {new Intl.NumberFormat('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(estoque)}
                                )
                              </span>
                            </label>
                            <div className="p-inputgroup">
                              <InputAutoComplete
                                name="barcode"
                                inputRef={productRef}
                                value={produto.label}
                                completeMethod={e => {
                                  searchProductByBarcode(e);
                                }}
                                itemTemplate={itemTemplate}
                                placeholder="Ler código de barras"
                                autoFocus={productFocus}
                              />
                              <span className="p-inputgroup-addon">
                                <i className="fa-solid fa-barcode"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-6">
                          <label htmlFor="basic">Tabela de Preço</label>
                          <InputDropDown
                            name="table_price_id"
                            options={tabelaPrecoOpts.sort((a, b) =>
                              a.label.localeCompare(b.label),
                            )}
                            value={tabelaPreco.value}
                            onChange={e => handleTablePriceSelect(e)}
                            disabled={
                              tabelaPrecoOpts.length === 0 ? true : false
                            }
                            className={
                              tabelaPrecoOpts.length <= 0 ? `surface-200` : ``
                            }
                            placeholder="Selecionar"
                          />
                        </div>

                        <div className="field col-12 md:col-6 flex align-items-center">
                          <label>Leitor código barras?</label>
                          <InputSwitch
                            checked={comLeitor}
                            className="ml-5"
                            name="gera_nfce"
                            falseValue={false}
                            trueValue={true}
                            onChange={e => setComLeitor(e.value as boolean)}
                          />
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-12">
                          <label htmlFor="basic">PRODUTOS MAIS VENDIDOS </label>
                          <div className="col p-0 flex flex-wrap">
                            {maisVendidos &&
                              maisVendidos.map((item, product_id) => {
                                return (
                                  <Avatar
                                    key={product_id}
                                    shape="circle"
                                    imageAlt={item.name}
                                    image={
                                      item.image_url === null
                                        ? `${process.env.REACT_APP_SERVER_URL}/files/img/no-photo.jpg`
                                        : `${process.env.REACT_APP_SERVER_URL}/files/img/${item.image_url}`
                                    }
                                    className="cursor-pointer text-white mr-3"
                                    size="large"
                                    onClick={() => null}
                                    style={{
                                      background: '#667',
                                      border: '1px solid #667',
                                    }}
                                  />
                                );
                              })}
                          </div>
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3">
                        <div className="field col-12 md:col-6 flex flex-column">
                          {produto.value ? (
                            <Image
                              src={
                                produto.image_url === null ||
                                produto.image_url === ''
                                  ? `${process.env.REACT_APP_SERVER_URL}/files/img/no-photo.jpg`
                                  : `${process.env.REACT_APP_SERVER_URL}/files/img/${produto.image_url}`
                              }
                              alt={produto.label}
                              width="100"
                              height="100"
                              preview
                            />
                          ) : (
                            <NoImage width={100} height={100} fill="#eee" />
                          )}
                        </div>
                        <div className="field col-12 md:col-6 flex justify-content-center align-items-center">
                          <h2>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(vlrUnit)}
                          </h2>
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid mb-3 flex">
                        <div className="field col-12 md:col-3">
                          <label htmlFor="basic">Qnt.</label>
                          <InputNumber
                            name="qnt"
                            inputRef={qntRef}
                            value={qnt}
                            onChange={e => setQnt(Number(e.value))}
                            showButtons
                            disabled={
                              produto.value === '' || tabelaPreco.value === ''
                                ? true
                                : false
                            }
                            onKeyDown={e => {
                              if (e.key === 'Enter') {
                                e.preventDefault();

                                formProductsRef.current?.submitForm();
                              }
                            }}
                            autoFocus={qntFocus}
                            minFractionDigits={qntOut}
                            maxFractionDigits={qntOut}
                            buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger"
                            incrementButtonClassName="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            useGrouping={false}
                          />
                        </div>
                        <div className="field col-12 md:col-2">
                          <label htmlFor="basic">
                            Desc.
                            <span
                              className="ml-2 text-pink-500"
                              style={{ fontSize: '0.8rem' }}
                            >
                              {new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(descPercentual)}
                              %
                            </span>
                          </label>
                          <InputNumber
                            name={'desc'}
                            value={desc}
                            onChange={e => setDesc(Number(e.value))}
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            disabled={
                              produto.value === '' || tabelaPreco.value === ''
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="field col-12 md:col-2">
                          <label htmlFor="basic">
                            Adic.{' '}
                            <span
                              className="ml-2 text-blue-500"
                              style={{ fontSize: '0.8rem' }}
                            >
                              {new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(adicPercentual)}
                              %
                            </span>
                          </label>
                          <InputNumber
                            name="adic"
                            value={adic}
                            onChange={e => setAdic(Number(e.value))}
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            disabled={
                              produto.value === '' || tabelaPreco.value === ''
                                ? true
                                : false
                            }
                          />
                        </div>
                        <div className="field col-12 md:col-2">
                          <label htmlFor="basic">Vlr. Liq.</label>
                          <InputNumber
                            name="vlr_liq"
                            value={vlrLiq}
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={priceOut}
                            placeholder="R$ 0,00"
                            className="surface-200"
                            disabled
                          />
                        </div>
                        <div className="field col-12 md:col-3">
                          <label htmlFor="basic">Total</label>
                          <InputNumber
                            name="total"
                            value={ttVlrLiq}
                            placeholder="R$ 0,00"
                            mode="decimal"
                            minFractionDigits={2}
                            maxFractionDigits={2}
                            disabled
                            className="surface-200"
                          />
                        </div>
                      </div>
                      <div className="p-fluid grid formgrid">
                        <div className="field col-12 md:col flex">
                          <Button
                            label="Adicionar produto"
                            type="submit"
                            loading={isLoad}
                            disabled={
                              produto.value === '' || tabelaPreco.value === ''
                                ? true
                                : false
                            }
                            className="p-button-success"
                            icon="pi pi-plus"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>

                {/** cliente */}
                <Form
                  ref={formCustomerRef}
                  onSubmit={() => null}
                  placeholder={''}
                  onPointerEnterCapture={null}
                  onPointerLeaveCapture={null}
                >
                  <div style={{ display: tabEnable === 2 ? 'block' : 'none' }}>
                    <Scope path="customer">
                      <div className="p-fluid grid formgrid">
                        <Divider align="left" className="my-0">
                          <div className="inline-flex align-items-center">
                            <i
                              className="pi pi-user mr-2"
                              style={{ color: 'var(--primary-color)' }}
                            ></i>
                            <b>Dados</b>
                          </div>
                        </Divider>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="customer_id">
                            Nome/Razão social
                            <i
                              className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
                              onClick={() => setFormCustomerVisible(true)}
                            ></i>
                          </label>
                          <InputAutoComplete
                            name="customer_id"
                            suggestions={clienteOpts}
                            value={cliente}
                            completeMethod={e => searchCustomer(e.query)}
                            field="label"
                            onChange={e => handleSelectCustomer(e)}
                            itemTemplate={itemTemplate}
                            placeholder="Buscar cliente..."
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="customer_name">
                            Fantasia/Apelido
                          </label>
                          <InputText
                            name="customer_name"
                            value={cliApelidoFantasia}
                            placeholder="João da Silva"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="cpf_cnpj">Cpf/Cnpj</label>
                          <InputText
                            name="cpf_cnpj"
                            value={cliCpfCnpj}
                            placeholder="000.000.000-00"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="cpf_cnpj">Rg/Ie</label>
                          <InputText
                            name="rg_ie"
                            value={cliRgIe}
                            placeholder="000.000.000"
                            readOnly
                          />
                        </div>

                        <Divider align="left" className="my-0">
                          <div className="inline-flex align-items-center">
                            <i
                              className="pi pi-map-marker mr-2"
                              style={{ color: 'var(--primary-color)' }}
                            ></i>
                            <b>Endereço</b>
                          </div>
                        </Divider>

                        <div className="field col-12 md:col-4">
                          <label htmlFor="cep">Cep</label>
                          <InputText
                            name="cep"
                            value={cliCep}
                            placeholder="00.000-000"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-2">
                          <label htmlFor="uf">Uf</label>
                          <InputDropDown
                            id="uf"
                            name="uf"
                            options={ufs.sort((a, b) =>
                              a.label.localeCompare(b.label),
                            )}
                            value={cliUf}
                            placeholder="Selecionar..."
                            filter
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="cep">Cidade</label>
                          <InputText
                            name="cep"
                            value={cliCep}
                            placeholder="Quixeramobim"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-9">
                          <label htmlFor="endereco">Endereço</label>
                          <InputText
                            name="endereco"
                            value={cliEndereco}
                            placeholder="Rua da amizade"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-3">
                          <label htmlFor="numero">Num.</label>
                          <InputText
                            name="numero"
                            value={cliNum}
                            placeholder="000"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="complemento">Complemento</label>
                          <InputText
                            name="complemento"
                            value={cliComplemento}
                            placeholder="qd 00 lt 00, apto 00"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="bairro">Bairro</label>
                          <InputText
                            name="bairro"
                            value={cliBairro}
                            placeholder="Baixo esperança"
                            readOnly
                          />
                        </div>

                        <Divider align="left" className="my-0">
                          <div className="inline-flex align-items-center">
                            <i
                              className="pi pi-phone mr-2"
                              style={{ color: 'var(--primary-color)' }}
                            ></i>
                            <b>Contato</b>
                          </div>
                        </Divider>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="whatsapp">Whatsapp</label>
                          <InputText
                            name="whatsapp"
                            value={cliWhatsapp}
                            placeholder="(00) 00000-0000"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="telefone">Telefone</label>
                          <InputText
                            name="telefone"
                            value={cliTelefone}
                            placeholder="(00) 00000-0000"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="email">Email</label>
                          <InputText
                            name="email"
                            value={cliEmail}
                            placeholder="seuemail@email.com"
                            readOnly
                          />
                        </div>

                        <div className="field col-12 md:col-6">
                          <label htmlFor="nascimento">Nascimento</label>
                          <Calendar
                            name="nascimento"
                            dateFormat="d/m/yy"
                            value={cliNascimento}
                            placeholder="00/00/00"
                            showIcon
                            disabled
                          />
                        </div>

                        <div className="field col-12 md:col-12">
                          <label htmlFor="obs">Obs</label>
                          <InputTextarea
                            name="obs"
                            value={cliObs}
                            placeholder="seuemail@email.com"
                            readOnly
                          />
                        </div>
                      </div>
                    </Scope>
                  </div>
                </Form>

                {/** pagamentos */}
                <Form
                  ref={formSubmit}
                  onSubmit={() => null}
                  placeholder={''}
                  onPointerEnterCapture={null}
                  onPointerLeaveCapture={null}
                >
                  <div
                    style={{ display: tabEnable === 3 ? 'block' : 'none' }}
                    className="overflow-hidden"
                  >
                    <Scope path="payments">
                      <div
                        className="p-fluid grid formgrid absolute overflow-auto surface-overlay"
                        style={{ height: '70vh' }}
                      >
                        <Divider type="dashed">
                          <span className="p-tag">Totais</span>
                        </Divider>

                        <div className="field md:col-12 my-0 py-0">
                          <div className="flex justify-content-between flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center font-bold">
                              <h4 style={{ color: 'var(--primary-color)' }}>
                                Total
                              </h4>
                            </div>
                            <div className="flex align-items-center justify-content-center font-bold">
                              <h4 style={{ color: 'var(--primary-color)' }}>
                                {new Intl.NumberFormat('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                }).format(totalPagar)}
                              </h4>
                            </div>
                          </div>

                          <div className="flex justify-content-between flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center">
                              <h5>Adicionais</h5>
                              {/* <span className="ml-3 text-green-500">
                                {new Intl.NumberFormat('pt-BR', {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }).format(adicPercentual)}
                                %
                              </span> */}
                            </div>
                            <div className="flex align-items-center justify-content-center">
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(totalAdicionais)}
                            </div>
                          </div>

                          <div className="flex justify-content-between flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center">
                              <h5>Descontos </h5>
                              <Tooltip
                                target=".icon-desc"
                                content="Aplique descontos de forma global dividindo o valor pelos itens a partir de suas quantidades.
                                 Obs.: Essa divisão pode não ser pois o cupom fiscal n aceita número quebrados ex.: 0.056."
                              />
                              <i
                                className="icon-desc fa-solid fa-circle-minus text-pink-500 ml-2 cursor-pointer"
                                onClick={() => setModalDescGlobalVisible(true)}
                              ></i>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(totalDescontos)}
                            </div>
                          </div>

                          <div className="flex justify-content-between flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center">
                              <h5>Pagamento</h5>
                            </div>
                            <div className="flex align-items-center justify-content-center">
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(totalPagamentos)}
                            </div>
                          </div>

                          <div className="flex justify-content-between flex-wrap card-container">
                            <div className="flex align-items-center justify-content-center">
                              <h5>Troco</h5>
                            </div>
                            <div className="flex align-items-center justify-content-center font-bold">
                              {new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(
                                totalPagamentos - totalPagar < 0
                                  ? 0
                                  : totalPagamentos - totalPagar,
                              )}
                            </div>
                          </div>
                        </div>

                        <Divider type="dashed">
                          <span className="p-tag">Atalhos</span>
                        </Divider>

                        <div className="field md:col-12 my-0 py-0">
                          <div className="flex justify-content-start flex-wrap card-container">
                            <div className="flex mr-3 align-items-center justify-content-center">
                              F2 - Dinheiro
                            </div>
                            <div className="flex mr-3 align-items-center justify-content-center">
                              F3 - PIX
                            </div>
                            <div className="flex mr-3 align-items-center justify-content-center">
                              F4 - Cartão débito
                            </div>
                            <div className="flex mr-3 align-items-center justify-content-center">
                              F6 - Cartão crédito
                            </div>
                            <div className="flex align-items-center justify-content-start">
                              F8 - Depósito bancário
                            </div>
                          </div>
                        </div>

                        <Divider type="dashed">
                          <span className="p-tag">Pagamentos</span>
                        </Divider>

                        <div className="field md:col-6 my-2 py-0 ">
                          <label htmlFor="payment_condition_id">
                            Condição de pagamento
                          </label>
                          <InputDropDown
                            name="payment_condition_id"
                            options={condicaoPagamentoOpts.sort((a, b) =>
                              a.label.localeCompare(b.label),
                            )}
                            value={condicaoPagamento.value}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecionar..."
                            onChange={e => handlePaymentConditionSelected(e)}
                            filter
                            emptyFilterMessage="Nenhum item encontrado!"
                          />
                        </div>

                        <div className="field md:col-3 my-1 py-0">
                          <label htmlFor="payment_condition_value">
                            Valor
                            <Tag
                              key={v4()}
                              severity="info"
                              value={new Intl.NumberFormat('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                totalPagar - totalPagamentos < 0
                                  ? 0
                                  : totalPagar - totalPagamentos,
                              )}
                              className="ml-3"
                            ></Tag>
                          </label>
                          <InputNumber
                            inputRef={vlrRef}
                            name="payment_condition_value"
                            value={valorCondicaoPagamento}
                            onChange={e =>
                              setValorCondicaoPagamento(Number(e.value))
                            }
                            onFocus={e => {
                              e.target.select();
                              setValorCondicaoPagamento(
                                totalPagar - totalPagamentos,
                              );
                            }}
                            onKeyDown={e => {
                              if (
                                e.key === 'Enter' &&
                                valorCondicaoPagamento === 0 &&
                                itensPagamentos.length > 0
                              ) {
                                handleSubmit();
                              }
                            }}
                            autoFocus={vlrFocus}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>

                        <div className="field md:col-2 my-1 py-0 relative">
                          <Button
                            label="Adicionar"
                            className="p-button-success bottom-0 absolute bottom-0"
                            loading={isLoad}
                            onClick={handleAddPaymentValue}
                            icon="pi pi-dollar"
                          />
                        </div>

                        {itensPagamentos.length > 0 && (
                          <div className="field md:col-12 mb-0 pb-2">
                            <DataTable
                              value={itensPagamentos}
                              responsiveLayout="scroll"
                              emptyMessage="Nenhum pagamento adicionado!"
                              footer={`${new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(totalPagamentos)}`}
                              paginator
                              rows={10}
                              rowsPerPageOptions={[10, 20, 30]}
                              size="small"
                            >
                              <Column
                                field="id"
                                header="#"
                                body={codeColumnBody}
                                className="p-1"
                              ></Column>
                              <Column
                                field="dias"
                                header="Dias"
                                className="p-1"
                              ></Column>
                              <Column
                                field="vencimento"
                                header="Data"
                                body={paymentsColumnDate}
                                className="p-1"
                              ></Column>
                              <Column
                                field="valor"
                                header="Valor"
                                className="p-1"
                                body={paymentsColumnDecimal}
                              ></Column>
                              <Column
                                field="descricao"
                                header="Forma"
                                className="p-1"
                              ></Column>
                              <Column
                                body={row => paymentsButtonRemItem(row.id)}
                                className="p-1 px-0"
                              ></Column>
                            </DataTable>
                          </div>
                        )}

                        <Divider type="dashed">
                          <span className="p-tag">Dados</span>
                        </Divider>

                        <div className="field md:col-12 my-1 py-0">
                          <label htmlFor="natureza_operacao_id">
                            Natureza de operação
                          </label>
                          <InputDropDown
                            name="natureza_operacao_id"
                            options={naturezaOperacacaoOpts.sort((a, b) =>
                              a.label.localeCompare(b.label),
                            )}
                            value={naturezaOperacacao}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecionar..."
                            onChange={e => setNaturezaOperacao(e.value)}
                            filter
                            emptyFilterMessage="Nenhum item encontrado!"
                          />
                        </div>

                        <div className="field md:col-8 my-1 py-0">
                          <label htmlFor="commissioned_id">
                            Vendedor/Comissionado
                          </label>
                          <InputDropDown
                            name="commissioned_id"
                            options={vendedorOpts}
                            value={vendedor}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecionar..."
                            onChange={e => setVendedor(e.target.value)}
                            filter
                            emptyFilterMessage="Nenhum item encontrado!"
                          />
                        </div>

                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="store_id">Loja</label>
                          <InputDropDown
                            name="store_id"
                            options={lojaOpts.sort((a, b) =>
                              a.label.localeCompare(b.label),
                            )}
                            value={loja}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="Selecionar..."
                            onChange={e => handlePaymentConditionSelected(e)}
                            filter
                            emptyFilterMessage="Nenhum item encontrado!"
                          />
                        </div>

                        <Divider type="dashed">
                          <span className="p-tag">Transporte</span>
                        </Divider>
                        <div className="field md:col-8 my-1 py-0">
                          <label htmlFor="basic">Transportadora</label>
                          <InputDropDown
                            name="transporter_id"
                            options={
                              transportadorasOpts
                                ? transportadorasOpts.sort((a, b) =>
                                    a.label.localeCompare(b.label),
                                  )
                                : []
                            }
                            value={transportadora}
                            onChange={e => setTransportadora(e.value)}
                            placeholder="Selecionar..."
                            optionLabel="label"
                            optionValue="value"
                            filter
                            emptyFilterMessage="Nenhum item encontrado!"
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="kind_frete">Tipo de frete</label>
                          <InputDropDown
                            name="kind_frete"
                            options={tiposFrete.sort((a, b) =>
                              a.label.localeCompare(b.label),
                            )}
                            value={freteTipo}
                            onChange={e => setFreteTipo(e.value)}
                            optionLabel="label"
                            optionValue="value"
                            placeholder="selecionar"
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="vehicle_plate">Veículo</label>
                          <InputText
                            name="vehicle_plate"
                            placeholder="AAA-1234"
                            value={fretePlaca}
                            onChange={e => setFretePlaca(e.currentTarget.value)}
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="driver">Motorista</label>
                          <InputText
                            name="driver"
                            placeholder="João do Frete"
                            value={freteMotorista}
                            onChange={e =>
                              setFreteMotorista(e.currentTarget.value)
                            }
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="data_entrega">Data entrega</label>
                          <Calendar
                            name="data_entrega"
                            value={dataEntrega}
                            onChange={e => setDataEntrega(e.value)}
                            dateFormat="d/m/yy"
                            minDate={startOfDay(hoje)}
                            maxDate={addDays(hoje, 30)}
                            showIcon
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="frete_qnt">Espécie</label>
                          <InputText
                            name="frete_qnt"
                            placeholder="VOL, PC, KIT..."
                            value={freteEspecie}
                            onChange={e =>
                              setFreteEspecie(e.currentTarget.value)
                            }
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="frete_quantidade">Qnt.</label>
                          <InputNumber
                            name="frete_quantidade"
                            value={freteQnt}
                            onChange={e => setFreteQnt(Number(e.value))}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="frete_marca">Marca</label>
                          <InputText
                            name="frete_marca"
                            placeholder="Própia/Terceiros"
                            value={freteMarca}
                            onChange={e => setFreteMarca(e.currentTarget.value)}
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="seal_number">Número Lacre</label>
                          <InputText
                            name="seal_number"
                            value={freteNumLacre}
                            onChange={e =>
                              setFreteNumLacre(e.currentTarget.value)
                            }
                            placeholder="0000"
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="weight_brute">Peso Bruto (KG)</label>
                          <InputNumber
                            name="weight_brute"
                            value={fretePesoBruto}
                            placeholder="0,00"
                            onChange={e => setFretePesoBruto(Number(e.value))}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field md:col-4 my-1 py-0">
                          <label htmlFor="weight_liquid">
                            Pesolíquido (kG)
                          </label>
                          <InputNumber
                            name="weight_liquid"
                            value={fretePesoLiquido}
                            placeholder="0,00"
                            onChange={e => setFretePesoLiquido(Number(e.value))}
                            minFractionDigits={2}
                            maxFractionDigits={2}
                          />
                        </div>
                        <div className="field col-12 md:col">
                          <label htmlFor="dropdown">Obs</label>
                          <InputTextArea
                            name="obs"
                            value={obs}
                            onChange={e => setObs(e.currentTarget.value)}
                            placeholder="Ex: Entregar a mercadoria na vendinha da esquina..."
                            rows={10}
                            cols={30}
                          />
                        </div>
                      </div>
                    </Scope>
                  </div>
                </Form>
              </div>
            </div>

            {/** itens pedido */}
            <div className="md:col-5">
              <div className="card" style={{ height: '80vh' }}>
                <DataTable
                  value={itensPedido}
                  responsiveLayout="scroll"
                  emptyMessage="Adicionar produtos à venda!"
                  selectionMode="single"
                  scrollable
                  scrollHeight="72vh"
                  scrollDirection="vertical"
                  loading={isLoad}
                  onRowSelect={e => handleRowSelected(e.data)}
                  footer={`Produtos: ${totalItens} # Itens: ${totalQnt}`}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 20, 30]}
                  size="small"
                >
                  <Column
                    field="id"
                    header="#"
                    className="p-1"
                    body={codeColumnBody}
                  ></Column>
                  <Column
                    field="product_description"
                    header="Descrição"
                    className="p-1"
                    sortable
                  ></Column>
                  <Column
                    field="qnt"
                    header="Qnt."
                    className="p-1"
                    body={row => formatTableColumnToDecimal(row, 'qnt')}
                  ></Column>
                  <Column
                    field="vlr_unit"
                    header="Vlr.Unit."
                    className="p-1"
                    body={row => formatTableColumnToDecimal(row, 'vlr_unit')}
                  ></Column>
                  <Column
                    field="vlr_liq"
                    header="Vlr.Liq."
                    className="p-1"
                    body={row => formatTableColumnToDecimal(row, 'vlr_liq')}
                  ></Column>
                  <Column
                    field="vlr_liq_total"
                    header="Total"
                    className="p-1"
                    body={row =>
                      formatTableColumnToDecimal(row, 'vlr_liq_total')
                    }
                  ></Column>
                  <Column
                    body={row => buttonRemItemTable(row.id)}
                    className="p-1"
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>

        {/** footer */}
        <div className="field my-0 py-0">
          <div className="card my-0 py-2">
            <div className="flex justify-content-between flex-wrap card-container">
              <div className="flex align-items-center justify-content-center">
                <Button
                  label="LIMPAR"
                  className="p-button-danger"
                  icon="pi pi-trash"
                  onClick={() => handleClearPdv()}
                />
              </div>
              <div className="flex align-items-center justify-content-center">
                <Button
                  label="FATURAR"
                  className="p-button-lx p-button-success"
                  icon="pi pi-wallet"
                  loading={isLoad}
                  onClick={handleSubmit}
                  disabled={
                    itensPedido.length === 0 || itensPagamentos.length === 0
                  }
                />
              </div>
              <div
                className="flex align-items-center justify-content-center"
                style={{ fontSize: '2rem' }}
              >
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(totalPagar)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />

      <FastFormPerson
        isOpen={formCustomerVisible}
        onRequestClose={handleCloseFormCustomer}
      />

      <Dialog
        header="Desconto global"
        visible={modalDescGlobalVisible}
        onHide={() => setModalDescGlobalVisible(false)}
        style={{ width: '30vw' }}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <InNumber
              value={descGlobal}
              onChange={e => setDescGlobal(e.value || 0)}
              placeholder="0,00"
              className="w-full"
              minFractionDigits={2}
              maxFractionDigits={2}
              disabled={itensPedido.length === 0}
            />
          </div>
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              className="p-button-danger"
              loading={isLoad}
              onClick={() => {
                handleUnAppyDescGlobal(itensPedido);
                setDescGlobal(0);
                setModalDescGlobalVisible(false);
              }}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Aplicar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
              onClick={() => handleApplyDescGlobal(itensPedido, descGlobal)}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default FrenteDeCaixa;

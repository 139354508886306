import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectParams } from 'primereact/datatable';
import React, { useRef, useState } from 'react';
import { v4, validate } from 'uuid';
import * as Yup from 'yup';
import useToastContext from '../../../hooks/toast';
import { useOrderService } from '../../../hooks/useOrderService';
import IOptionsDTO from '../../../pages/business/dtos/IOptionsDTO';
import IOsObject from '../../../pages/os/dtos/IOsObject';
import FastFormOsObject from '../../../pages/os/osObject/FastFormOsObject';
import { emptyOsObject } from '../../../pages/os/types/EmptyOsObject';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import { DataTable } from '../../DataTable';
import renderColumnDecimal from '../../DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../DataTableColumns/RenderColumnPosition';
import RenderResumeText from '../../DataTableColumns/RenderResumeText';
import { InputAutoComplete } from '../../Inputs/InputAutoComplete';
import { InputNumber } from '../../Inputs/InputNumber';
import { InputTextArea } from '../../Inputs/InputTextArea';

const InputRowObjectOs = () => {
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [load, setLoad] = useState(false);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const [objectFocus, setObjectFocus] = useState(false);
  const objectRef = useRef(null);

  const [qntFocus, setQntFocus] = useState(false);
  const qntRef = useRef(null);

  const [objects, setObjects] = useState<IOptionsDTO[]>([]);
  const [object, setObject] = useState<IOptionsDTO>({ value: '', label: '' });
  const [item, setItem] = useState<IOsObject>(emptyOsObject);

  const { orderService, addObject, remObject } = useOrderService();

  const searchObject = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      api
        .get(`/os/object/v1/search?src=${e.query}`)
        .then(({ data }) => {
          setObjects(
            data.searchOsObject.map((item: any) => {
              return {
                label: item.title,
                value: item.id,
              };
            }),
          );
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        })
        .finally(() => setLoad(false));
    }
  };

  const clearRow = () => {
    setObject({ value: '', label: '' });
    setItem({ ...emptyOsObject, id: v4() });
    setObjectFocus(true);
    objectRef.current?.focus();
  };

  const handleAddObject = async (d: any) => {
    try {
      setLoad(true);

      formRef.current?.setErrors({});

      d.object_id = item.object_id;
      d.qnt = item.qnt;

      const schema = Yup.object({
        object_id: Yup.string()
          .uuid('Informe um objeto na O.S!')
          .required('Informe um objeto na O.S!'),
        qnt: Yup.number()
          .positive('Informe uma quantidade!')
          .required('Informe uma quantidade!'),
      });

      await schema.validate(d, { abortEarly: false });

      addObject(item);
      clearRow();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', err.errors[0]);
      } else {
        toast('error', 'Error', err.response?.data?.error);
      }
    } finally {
      setLoad(false);
    }
  };

  const handleRemObject = (id: string) => {
    remObject(id);
  };

  const handleSelectObject = (e: any) => {
    setObject({ value: e.value, label: e.label });
    setItem({ ...item, object_id: e.value, object_title: e.label });
    setQntFocus(true);
    qntRef.current?.focus();
  };

  const handleSelectRow = (e: DataTableSelectParams) => {
    setObject({ value: e.data.object_id, label: e.data.object_title });
    setItem({ ...e.data });
  };

  const itemTemplate = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const renderColumnObjectButtons = (rowId: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-xmark"
          className="p-button-danger"
          onClick={() => handleRemObject(rowId)}
          type="button"
        />
      </div>
    );
  };

  return (
    <>
      <Form
        ref={formRef}
        onSubmit={d => handleAddObject(d)}
        className="p-fluid grid formgrid"
      >
        <div className="field col-12 md:col-4">
          <label htmlFor="object_id">
            Objeto
            <i
              className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
              onClick={() => setSidebarVisible(true)}
            ></i>
          </label>
          <InputAutoComplete
            inputRef={objectRef}
            name="object_id"
            suggestions={objects}
            value={object}
            completeMethod={e => searchObject(e)}
            field="label"
            onSelect={e => handleSelectObject(e.value)}
            onChange={e => setObject(e.value)}
            itemTemplate={itemTemplate}
            placeholder="Buscar objeto..."
            autoFocus={objectFocus}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setQntFocus(true);
                qntRef.current?.focus();
              }
            }}
          />
        </div>
        <div className="field col-12 md:col-1">
          <label htmlFor="qnt">Qnt.</label>
          <InputNumber
            inputRef={qntRef}
            name="qnt"
            value={item.qnt}
            onValueChange={e => setItem({ ...item, qnt: Number(e.value) })}
            mode="decimal"
            minFractionDigits={2}
            placeholder="0,00"
            maxFractionDigits={2}
            autoFocus={qntFocus}
          />
        </div>

        <div className="field col-12 md:col-6">
          <label htmlFor="object_obs">Observações equipamento</label>
          <InputTextArea
            name="obs"
            value={item.obs}
            onChange={e => setItem({ ...item, obs: e.currentTarget.value })}
            placeholder="Ex: Equipamento com carregador, ranhuras no canto..."
          />
        </div>

        <div className="field col-12 md:col-1 pr-0 flex align-items-center justify-content-center">
          <Button
            type="submit"
            loading={load}
            disabled={!validate(item.id)}
            className="p-button-success"
            icon="pi pi-plus"
          />
        </div>

        {orderService.osObjects.length > 0 && (
          <DataTable
            value={orderService.osObjects}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Objetos não informados..."
            selectionMode="single"
            onRowSelect={e => handleSelectRow(e)}
            className="field col-12 md:col-12"
          >
            <Column
              header="#"
              className="my-0 py-1"
              body={renderColumnPosition}
              sortable
            ></Column>
            <Column
              field="object_title"
              header="Objeto"
              className="my-0 py-1"
              sortable
            ></Column>
            <Column
              header="Qnt."
              className="my-0 py-1"
              body={row => renderColumnDecimal(row.qnt)}
              sortable
            ></Column>
            <Column
              header="Obs"
              className="my-0 py-1"
              body={row => RenderResumeText(row.obs, 32)}
            ></Column>
            <Column
              header="*"
              className="my-0 py-1"
              body={row => renderColumnObjectButtons(row.id)}
            ></Column>
          </DataTable>
        )}
      </Form>

      <FastFormOsObject
        isOpen={sidebarVisible}
        onRequestClose={() => setSidebarVisible(false)}
      />
    </>
  );
};

export default InputRowObjectOs;

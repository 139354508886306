/* eslint-disable react/jsx-no-undef */
import { Form } from '@unform/web';
import { cnpj } from 'cpf-cnpj-validator';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../../components/DataTable';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { InputMask } from '../../../../components/Inputs/InputMask';
import { InputText } from '../../../../components/Inputs/InputText';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api, { apiRfb } from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import { situacaoCadastral } from '../types/situacaoCadastral';
import Progress from '../../../../components/progress-bar';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string;
}

const SidebarRfbData = ({
  isOpen,
  onRequestClose,
  id,
}: IProps): React.ReactElement => {
  const toast = useToastContext();

  const [load, setLoad] = useState(false);

  const [data, setData] = useState<any>({});

  const loadData = async (id: string) => {
    setLoad(true);
    await api
      .get(`/rfb/estabelecimento/cnpj/${id}`)
      .then(({ data }) => {
        if (data) {
          setData({
            ...data,
          });
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    if (id) {
      loadData(id);
    }
  }, [isOpen]);

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        style={{ width: '80vw' }}
      >
        <Progress isLoad={load} />
        <h4>CADASTRO NACIONAL DA PESSOA JURÍDICA</h4>
        <Form
          ref={null}
          onSubmit={() => null}
          className="p-fluid grid formgrid"
        >
          <div className="field col-6 mb-1 pb-1">
            <label htmlFor="cnpj">Número de inscrição</label>
            <InputText
              name="cnpj"
              value={cnpj.format(data.cnpj) || ''}
              placeholder="00.000.000/0000-00"
              className="surface-300"
              readOnly
            />
          </div>
          <div className="field col-6 mb-1 pb-1">
            <label htmlFor="data_inicio_atividade">Data de abertura</label>
            <InputMask
              name="data_inicio_atividade"
              value={data.data_inicio_atividade || '***'}
              mask="9999-99-99"
              placeholder="00/00/00"
              className="text-center surface-300"
              readOnly
            />
          </div>

          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="razao_social">
              Nome empresarial (Razão social)
            </label>
            <InputText
              name="razao_social"
              value={data.empresa ? data.empresa.razao_social : '***'}
              placeholder="Ex.: Empresa Acme Ltda"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-8 mb-1 pb-1">
            <label htmlFor="nome_fantasia">
              Título do estabelecimento (Nome de fantásia)
            </label>
            <InputText
              name="nome_fantasia"
              value={data.nome_fantasia || ''}
              placeholder="Ex.: Empresa Acme Ltda"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="porte">Porte</label>
            <InputText
              name="porte"
              value={'***'}
              placeholder="Ex.: Empreendedor indivídual"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="cnae_pricipal">
              Código e descrição da atividade econômica principal (CNAE)
            </label>
            <InputText
              name="cnae_pricipal"
              value={
                data.cnaePrincipal
                  ? `${data.cnaePrincipal.codigo} - ${data.cnaePrincipal.descricao}`
                  : '***'
              }
              placeholder="Ex.: 99.99-9-99 - Ramo de atividade xyz"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="cnae_secundarios">
              Código e descrição das atividades econômicas secundárias (CNAE)
            </label>
            <InputText
              name="cnae_secundarios"
              value={data.cnae_fiscal_secundaria || ''}
              placeholder="Ex.: 99.99-9-99 - Ramo de atividade xyz"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="nat_ju">
              Código e descrição da natureza jurídica
            </label>
            <InputText
              name="nat_ju"
              value={'***'}
              placeholder="Ex.: 999-9 - Sociedade XYZ"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-6 mb-1 pb-1">
            <label htmlFor="logradouro">Logradouro/Endereço</label>
            <InputText
              name="logradouro"
              value={data.logradouro || ''}
              placeholder="Ex.: Rua da amizade"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-2 mb-1 pb-1">
            <label htmlFor="logradouro">Número</label>
            <InputText
              name="logradouro"
              value={data.numero || ''}
              placeholder="Ex.: 9999"
              className="text-center surface-300"
              readOnly
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="complemento">Complemento</label>
            <InputText
              name="complemento"
              value={data.complemento || ''}
              placeholder="Ex.: Qd 99 Lt 99"
              className="text-center surface-300"
              readOnly
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="cep">Cep</label>
            <InputMask
              name="cep"
              value={data.cep || ''}
              mask="99.999-999"
              placeholder="Ex.: Qd 99 Lt 99"
              className="text-center surface-300"
              readOnly
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="bairro">Bairro/Distrito</label>
            <InputText
              name="bairro"
              value={data.bairro || ''}
              placeholder="Ex.: Qd 99 Lt 99"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="cidade_uf">Municipio/UF</label>
            <InputText
              name="cidade_uf"
              value={
                data.cidade ? `${data.cidade.descricao} - ${data.uf}` : '***'
              }
              placeholder="Ex.: Qd 99 Lt 99"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-12 mb-1 pb-1 select-none">
            <label htmlFor="endereco_eletronico">
              Endereço eletrônico/Email
            </label>
            <InputText
              name="endereco_eletronico"
              value={data.correio_eletronico || ''}
              placeholder="Ex.: seuMelhor@email.com"
              className="surface-300 select-none"
              readOnly
              onClick={e => e.preventDefault()}
              onSelect={e => e.preventDefault()}
              onFocus={e => e.preventDefault()}
              security="high"
            />
          </div>

          <div className="field col-6 mb-1 pb-1">
            <label htmlFor="phone_1">Telefone</label>
            <InputMask
              name="phone_1"
              value={data.telefone_1 ? `${data.ddd_1}${data.telefone_1}` : ''}
              mask="(99) 9999-9999"
              placeholder="Ex.: (99)99999-9999"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-6 mb-1 pb-1">
            <label htmlFor="phone_2">Telefone</label>
            <InputMask
              name="phone_2"
              value={data.telefone_2 ? `${data.ddd_2}${data.telefone_2}` : ''}
              mask="(99) 9999-9999"
              placeholder="Ex.: (99)99999-9999"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-12 mb-1 pb-1">
            <label htmlFor="ente_federativo">
              Ente federativo responsável (EFR)
            </label>
            <InputText
              name="ente_federativo"
              value={data.pais || ''}
              placeholder="Ex.: BRASIL"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-8 mb-1 pb-1">
            <label htmlFor="situacao_cadastral">Situação cadastral</label>
            <InputText
              name="situacao_cadastral"
              value={
                situacaoCadastral.find(
                  (i: IOptionsDTO) => i.value === data.situacao_cadastral,
                )?.label
              }
              placeholder="Ex.: ATIVA"
              className="surface-300"
              readOnly
            />
          </div>

          <div className="field col-4 mb-1 pb-1">
            <label htmlFor="data_situacao_cadastral">
              Data da situação cadastral
            </label>
            <InputText
              name="data_situacao_cadastral"
              placeholder="Ex.: ATIVA"
              className="surface-300"
              readOnly
            />
          </div>

          <DataTable
            header={<h5>Sócios</h5>}
            value={[]}
            responsiveLayout="scroll"
            paginator
            rows={10}
            rowsPerPageOptions={[10, 20, 30]}
            size="small"
            emptyMessage="Sem sócios..."
            selectionMode="single"
            loading={load}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate={`Exibindo {first} / {last} de {totalRecords} sócios.`}
            className="field col-12 mb-1 pb-1"
          >
            <Column header="#" body={renderColumnPosition}></Column>
            <Column header="Nome"></Column>
            <Column header="Cpf"></Column>
            <Column header="Data entrada"></Column>
          </DataTable>
        </Form>
      </SideBar>
    </>
  );
};

export default SidebarRfbData;

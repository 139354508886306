/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';

/** react-router-dom */
import { useHistory } from 'react-router-dom';

/** yup */
import * as Yup from 'yup';

/** api */
import api from '../../../services/api';

/** hooks */
import useToastContext from '../../../hooks/toast';

/** primereact */
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTableSelectionChangeParams } from 'primereact/datatable';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { TabPanel, TabView } from 'primereact/tabview';
import { Tag } from 'primereact/tag';

/** Components */
import { DataTable } from '../../../components/DataTable';
import { Divider } from '../../../components/Divider';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import Calendar from '../../../components/Inputs/InputCalendar';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { InputText } from '../../../components/Inputs/InputText';
import { InputTextEdit } from '../../../components/Inputs/InputTextEdit';
import {
  SideBar as SideBarSearchCest,
  SideBar as SideBarSearchNcm,
} from '../../../components/Sidebar';

/** unform */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';

/** types */
import condicaoProdutoOpts from '../types/CondicaoProdutoOpts';
import formatoProdutoOpts from '../types/FormatoProdutoOpts';
import freteGratisProdutoOpts from '../types/FreteGratisProdutoOpts';
import producaoProdutoOpts from '../types/ProducaoProdutoOpts';
import tipoProdutoOpts from '../types/TipoProdutoOpts';

/** dtos */
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import IItensTransporterDTO from '../dtos/IItensTransporterDTO';

import { format, startOfDay } from 'date-fns';

import { AxiosError } from 'axios';
import { Tooltip } from 'primereact/tooltip';
import { v4 } from 'uuid';
import CompletNumberWithZeros from '../../../utils/CompleteNumberWithZeros';
import getValidationErrors from '../../../utils/getErrorsValidation';
import IProductDTO from '../dtos/IProductDTO';
import ProductHeaderButtons from '../header-buttons';

const AddProduct = (): React.ReactElement => {
  const hoje = new Date();

  /** hooks */
  const navigate = useHistory();
  const toast = useToastContext();

  /** refs */
  const formRef = useRef<FormHandles>(null);
  const fileUploadRef = useRef<any>(null);

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [dadosIsError, setDadosIsError] = useState<boolean>(false);
  const [tagsIsError, setTagsIsError] = useState<boolean>(false);
  const [estoqueIsError, setEstoqueIsError] = useState<boolean>(false);
  const [fornecedoresIsError, setFonecedoresIsError] = useState<boolean>(false);
  const [informacoesIsError, setInformacoesIsError] = useState<boolean>(false);
  const [adicionaisIsError, setAdicionaisIsError] = useState<boolean>(false);
  const [variacoesIsError, setVariacoesIsError] = useState<boolean>(false);
  const [fotosIsError, setFotosIsError] = useState<boolean>(false);
  const [tributosIsError, setTributosIsError] = useState<boolean>(false);

  const [titulo, setTitulo] = useState<string>('');

  const [codigo, setCodigo] = useState<number>(0);

  const [sku, setSku] = useState<string>('');
  const [gtin, setGtin] = useState<string>('');
  const [tipoProduto, setTipoProduto] = useState<string>(
    tipoProdutoOpts[0].value,
  );

  const [formatoProduto, setFormatoProduto] = useState<string>(
    formatoProdutoOpts[0].value,
  );
  const [unidade, setUnidade] = useState<string>('UND');
  const [status, setStatus] = useState<string>('A');

  const [pesoLiquido, setPesoLiquido] = useState<number>(0);
  const [pesoBruto, setPesoBruto] = useState<number>(0);
  const [altura, setAltura] = useState<number>(0);
  const [largura, setLargura] = useState<number>(0);
  const [comprimento, setComprimento] = useState<number>(0);
  const [vencimento, setVencimento] = useState<Date | Date[] | undefined>(hoje);

  const [producao, setProducao] = useState<string>(
    producaoProdutoOpts[1].value,
  );
  const [condicao, setCondicao] = useState<string>(
    condicaoProdutoOpts[0].value,
  );

  const [freteGratis, setFreteGratis] = useState<string>(
    freteGratisProdutoOpts[1].value,
  );

  const [marcaOpts, setMarcaOpts] = useState<IOptionsDTO[]>([]);
  const [marca, setMarca] = useState<string>('');

  const [categorias, setCategorias] = useState<string[]>([]);
  const [categoriaOpts, setCategoriaOpts] = useState<IOptionsDTO[]>([]);

  const [showStore, setShowStore] = useState<boolean>(false);
  const [destaque, setDestaque] = useState<string>('N');
  const [lancamento, setLancamento] = useState<string>('N');
  const [etiqueta, setEtiqueta] = useState<string>('N');
  const [obs, setObs] = useState<string>('');

  const [tagsOpts, setTagsOpts] = useState<IOptionsDTO[]>([]);
  const [tags, setTags] = useState<string[]>([]);

  const [estoque, setEstoque] = useState<number>(0);
  const [estqMinimo, setEstqMinimo] = useState<number>(0);
  const [estqMaximo, setEstqMaximo] = useState<number>(0);
  const [localizacao, setLocalizacao] = useState<string>('');
  const [qntEntrada, setQntEntrada] = useState<number>(2);
  const [qntVenda, setQntVenda] = useState<number>(2);
  const [precoEntrada, setPrecoEntrada] = useState<number>(2);
  const [precoVenda, setPrecoVenda] = useState<number>(2);
  const [valorCompra, setValorCompra] = useState<number>(0);
  const [custoEmpresa, setCustoEmpresa] = useState<number>(0);
  const [custoTotal, setCustoTotal] = useState<number>(0);

  const [fornecedorOpts, setFornecedorOpts] = useState<IItensTransporterDTO[]>(
    [],
  );
  const [fornecedor, setFornecedor] = useState<IItensTransporterDTO>();
  const [itensFornecedor, setItensFornecedor] = useState<
    IItensTransporterDTO[]
  >([]);

  const [linkProduto, setLinkProduto] = useState<string>('');
  const [videoProduto, setVideoProduto] = useState<string>('');
  const [descricaoCurta, setDescricaoCurta] = useState<string>('');
  const [descricaoCompleta, setDescricaoCompleta] = useState<string>('');

  const [adicionaisOpts, setAdicionaisOpts] = useState<IOptionsDTO[]>([]);
  const [adicionais, setAdicionais] = useState<IOptionsDTO>();

  const [variacoesOpts, setVariacoesOpts] = useState<IOptionsDTO[]>([]);
  const [variacoes, setVariacoes] = useState<IOptionsDTO>();

  const [totalSize, setTotalSize] = useState<number>(0);

  const [origemOpts, setOrigemOpts] = useState<IOptionsDTO[]>([]);
  const [origem, setOrigem] = useState<string>('');

  const [ncmOpts, setNcmOpts] = useState<
    { label: string; value: string; codigo: string }[]
  >([]);
  const [ncm, setNcm] = useState<IOptionsDTO>({
    label: '',
    value: '',
  });

  const [cestOpts, setCestOpts] = useState<IOptionsDTO[]>([]);
  const [cest, setCest] = useState<IOptionsDTO>({
    label: '',
    value: '',
  });

  const [cfopOpts, setCfopOpts] = useState<IOptionsDTO[]>([]);
  const [cfopIn, setCfopIn] = useState<string>('');
  const [cfopOut, setCfopOut] = useState<string>('');

  const [icmsStituacaoTributariaOpts, setIcmsStituacaoTributariaOpts] =
    useState<IOptionsDTO[]>([]);
  const [icmsSituacaoTributaria, setIcmsSituacaoTributaria] =
    useState<string>('');
  const [icmsAliq, setIcmsAliq] = useState<number>(0);
  const [icmsBaseCalculo, setIcmsBaseCalculo] = useState<number>(0);
  const [icmsValor, setIcmsValor] = useState<number>(0);

  const [ipiStituacaoTributariaOpts, setIpiStituacaoTributariaOpts] = useState<
    IOptionsDTO[]
  >([]);
  const [ipiSituacaoTributaria, setIpiSituacaoTributaria] =
    useState<string>('');
  const [ipiAliq, setIpiAliq] = useState<number>(0);
  const [ipiBaseCalculo, setIpiBaseCalculo] = useState<number>(0);
  const [ipiValor, setIpiValor] = useState<number>(0);

  const [pisSituacaoTributariaOpts, setPisStituacaoTributariaOpts] = useState<
    IOptionsDTO[]
  >([]);
  const [pisSituacaoTributaria, setPisSituacaoTributaria] =
    useState<string>('');
  const [pisAliq, setPisAliq] = useState<number>(0);
  const [pisBaseCalculo, setPisBaseCalculo] = useState<number>(0);
  const [pisValor, setPisValor] = useState<number>(0);

  const [cofinsStituacaoTributariaOpts, setCofinsStituacaoTributariaOpts] =
    useState<IOptionsDTO[]>([]);
  const [cofinsSituacaoTributaria, setCofinsSituacaoTributaria] =
    useState<string>('');
  const [cofinsAliq, setCofinsAliq] = useState<number>(0);
  const [cofinsBaseCalculo, setCofinsBaseCalculo] = useState<number>(0);
  const [cofinsValor, setCofinsValor] = useState<number>(0);

  const [modalSearchNcmVisible, setModalSearchNcmVisible] =
    useState<boolean>(false);
  const [modalSearchCestVisible, setModalSearchCestVisible] =
    useState<boolean>(false);

  const [ncmKeyword, setNcmKeyword] = useState<string>('');
  const [cestKeyword, setCestKeyword] = useState<string>('');

  /** functions */
  const handleSubmit = async (e: any) => {
    setLoadingStatus(true);
    formRef.current?.setErrors({});

    titulo !== '' ? setDadosIsError(false) : setDadosIsError(true);
    categorias.length !== 0 ? setDadosIsError(false) : setDadosIsError(true);
    ncm.value !== '' ? setTributosIsError(false) : setTributosIsError(true);
    cest.value !== '' ? setTributosIsError(false) : setTributosIsError(true);

    try {
      // montar objeto produto
      const product: IProductDTO = {
        id: v4(),
        product_dados: {
          product_id: v4(),
          title: titulo,
          sku: sku,
          ean: gtin,
          kind_product: tipoProduto,
          format_product: formatoProduto,
          unity: unidade,
          status: status,
          weight_liquid: pesoLiquido,
          weight_brute: pesoBruto,
          height: altura,
          width: largura,
          lenght: comprimento,
          expiration_date: vencimento as Date,
          product_groups: categorias,
          production: producao,
          condiction: condicao,
          free_shipping: freteGratis,
          product_brand: marca,
          obs: obs,
          show_store: showStore,
          emphasis: destaque,
          release: lancamento,
          seal: etiqueta,
        },
        product_tags: tags.map(i => i),
        product_stock: {
          stock_min: estqMinimo,
          stock_max: estqMaximo,
          stock_location: localizacao,
          buy_value: valorCompra,
          buy_cost: custoEmpresa,
          cost_billing: custoTotal,
          decimal_houses: {
            qnt_in: qntEntrada,
            qnt_out: qntVenda,
            price_in: precoEntrada,
            price_out: precoVenda,
          },
        },
        product_provider: itensFornecedor.map(i => i.value),
        product_info: {
          link_product: linkProduto,
          link_video: videoProduto,
          description_short:
            descricaoCurta && descricaoCurta !== null ? descricaoCurta : '',
          description_full:
            descricaoCompleta && descricaoCompleta !== null
              ? descricaoCompleta
              : '',
        },
        product_additionals: [],
        product_variations: [],
        product_images: [],
        product_tributes: {
          origin_id: origem,
          ncm_id: ncm.value,
          cest_id: cest.value,
          icms: {
            cst_id: icmsSituacaoTributaria,
            base_calculo: icmsBaseCalculo,
            aliquota: icmsAliq,
            valor: icmsValor,
          },
          ipi: {
            cst_id: ipiSituacaoTributaria,
            base_calculo: ipiBaseCalculo,
            aliquota: ipiAliq,
            valor: ipiValor,
          },
          pis: {
            cst_id: pisSituacaoTributaria,
            base_calculo: pisBaseCalculo,
            aliquota: pisAliq,
            valor: pisValor,
          },
          cofins: {
            cst_id: cofinsSituacaoTributaria,
            base_calculo: cofinsBaseCalculo,
            aliquota: cofinsAliq,
            valor: cofinsValor,
          },
        },
        product_cfops: {
          cfop_in: cfopIn || null,
          cfop_out: cfopOut || null,
        },
      };

      // validar campos obrigatórios
      const productValidation = Yup.object()
        .required()
        .shape({
          product_dados: Yup.object()
            .required()
            .shape({
              product_id: Yup.string().uuid(),
              title: Yup.string()
                .min(3, 'No mínimo 3 caracteres!')
                .max(60, 'No máximo 60 caracteres!')
                .required('Informar nome do produto!'),
              sku: Yup.string(),
              ean: Yup.string(),
              kind_product: Yup.string().required('Informar tipo do produto!'),
              format_product: Yup.string().required(
                'Informar formato do produto!',
              ),
              unity: Yup.string().required('Informar unidade do produto!'),
              status: Yup.string().required('Produto ativo/desativado?'),
              weight_liquid: Yup.number().min(0),
              weight_brute: Yup.number().min(0),
              height: Yup.number().min(0),
              width: Yup.number().min(0),
              lenght: Yup.number().min(0),
              expiration_date: Yup.date().min(startOfDay(hoje)),
              product_groups: Yup.array()
                .min(1, 'Selecione pelo menos 1 categoria!')
                .required('Informe a categoria!')
                .of(Yup.string().uuid()),
              production: Yup.string().required('Informe a produção!'),
              condiction: Yup.string().required('Informe a condição!'),
              free_shipping: Yup.string().required(
                'Informe se é frete grátis!',
              ),
              product_brand: Yup.string().required('Informe a marca!'),
              obs: Yup.string(),
              show_store: Yup.boolean(),
              emphasis: Yup.string(),
              release: Yup.string(),
              seal: Yup.string(),
            }),
          product_tags: Yup.object()
            .shape({
              tags: Yup.array().min(1),
            })
            .nullable(),
          product_stock: Yup.object()
            .required()
            .shape({
              stock_min: Yup.number().min(0, 'No mínimo 0!'),
              stock_max: Yup.number().min(0, 'No mínimo 0!'),
              stock_location: Yup.string().max(8, 'No máximo 8 caracteres!'),
              buy_value: Yup.number().min(0, 'No mínimo 0!'),
              buy_cost: Yup.number().min(0, 'No mínimo 0!'),
              cost_billing: Yup.number().min(0, 'No mínimo 0!'),
              decimal_houses: Yup.object().shape({
                qnt_in: Yup.number()
                  .min(2, 'No mínimo 2 casas deciimais!')
                  .max(6, 'No máximo 6 casas decimais!'),
                qnt_out: Yup.number()
                  .min(2, 'No mínimo 2 casas deciimais!')
                  .max(6, 'No máximo 6 casas decimais!'),
                price_in: Yup.number()
                  .min(2, 'No mínimo 2 casas deciimais!')
                  .max(6, 'No máximo 6 casas decimais!'),
                price_out: Yup.number()
                  .min(2, 'No mínimo 2 casas deciimais!')
                  .max(6, 'No máximo 6 casas decimais!'),
              }),
            }),
          product_provider: Yup.array().of(Yup.string().uuid()),
          product_info: Yup.object().required().shape({
            link_product: Yup.string(),
            link_video: Yup.string(),
            description_short: Yup.string(),
            description_full: Yup.string(),
          }),
          product_additionals: Yup.array().of(Yup.string().uuid()),
          product_variations: Yup.array().of(Yup.string().uuid()),
        });

      await productValidation.validate(product, { abortEarly: false });

      const { data: productResponse } = await api.post('/products/v2', product);

      // upload das imagens
      if (fileUploadRef.current?.files) {
        const formData = new FormData();

        fileUploadRef?.current?.files.forEach(async (item: any) => {
          formData.delete('product_id');
          formData.delete('image_url');
          formData.append('product_id', productResponse?.createProduct?.id);
          formData.append('image_url', item);
          await api.patch(`/products-images`, formData);
        });
      }
      navigate.push('/products/list');
    } catch (error: any) {
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', error.errors[0]);
      } else {
        toast('error', 'Error', error?.response?.data.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const handleGenerateSkuByCodigo = () => {
    setSku(CompletNumberWithZeros({ number: codigo }));
  };

  const handleGenerateGtinByCodigo = () => {
    setGtin(CompletNumberWithZeros({ number: codigo }));
  };

  const handleClearForm = () => {
    setDadosIsError(false);
    setTagsIsError(false);
    setEstoqueIsError(false);
    setFonecedoresIsError(false);
    setInformacoesIsError(false);
    setAdicionaisIsError(false);
    setVariacoesIsError(false);
    setFotosIsError(false);
    setTributosIsError(false);
    setTitulo('');
    setSku('');
    setGtin('');
    setTipoProduto('');
    setFormatoProduto('');
    setUnidade('');
    setStatus('A');
    setPesoLiquido(0);
    setPesoBruto(0);
    setAltura(0);
    setLargura(0);
    setComprimento(0);
    setVencimento(hoje);
    setProducao('');
    setCondicao('');
    setFreteGratis('');
    setMarcaOpts([]);
    setMarca('');
    setCategorias([]);
    setCategoriaOpts([]);
    setShowStore(false);
    setDestaque('');
    setLancamento('');
    setEtiqueta('');
    setObs('');
    setTagsOpts([]);
    setTags([]);
    setEstoque(0);
    setEstqMinimo(0);
    setEstqMaximo(0);
    setLocalizacao('');
    setQntEntrada(0);
    setQntVenda(0);
    setPrecoEntrada(0);
    setPrecoVenda(0);
    setValorCompra(0);
    setCustoEmpresa(0);
    setCustoTotal(0);
    setFornecedorOpts([]);
    setFornecedor(undefined);
    setItensFornecedor([]);
    setLinkProduto('');
    setVideoProduto('');
    setDescricaoCurta('');
    setDescricaoCompleta('');
    setAdicionaisOpts([]);
    setAdicionais({ label: '', value: '' });
    setVariacoesOpts([]);
    setVariacoes({ label: '', value: '' });
    setTotalSize(0);
    setOrigemOpts([]);
    setOrigem('');
    setNcmOpts([]);
    setNcm({ label: '', value: '' });
    setCestOpts([]);
    setCest({ label: '', value: '' });
    setIcmsStituacaoTributariaOpts([]);
    setIcmsSituacaoTributaria('');
    setIcmsAliq(0);
    setIcmsBaseCalculo(0);
    setIcmsValor(0);
    setIpiStituacaoTributariaOpts([]);
    setIpiSituacaoTributaria('');
    setIpiAliq(0);
    setIpiBaseCalculo(0);
    setIpiValor(0);
    setPisStituacaoTributariaOpts([]);
    setPisSituacaoTributaria('');
    setPisAliq(0);
    setPisBaseCalculo(0);
    setPisValor(0);
    setCofinsStituacaoTributariaOpts([]);
    setCofinsSituacaoTributaria('');
    setCofinsAliq(0);
    setCofinsBaseCalculo(0);
    setCofinsValor(0);
    setModalSearchNcmVisible(false);
    setModalSearchCestVisible(false);
    setNcmKeyword('');
    setCestKeyword('');
  };

  const searchNcm = async (keyword: string) => {
    if (keyword.length >= 3) {
      setLoadingStatus(true);
      await api
        .get(`/ncm/search?str=${keyword}`)
        .then(({ data }) => {
          if (data.length === 0)
            toast('warn', 'Alerta', 'Nenhum ncm encontrado!');

          const opts = data.map((item: any) => {
            return {
              label: item.descricao,
              value: item.id,
              codigo: item.codigo,
            };
          });

          setNcmOpts(opts);
        })
        .catch((error: any) => {
          toast('error', 'Erro', error.response.data.error);
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const searchCest = async (keyword: string) => {
    if (keyword.length >= 3) {
      setLoadingStatus(true);
      await api
        .get(`/cest/search?str=${keyword}`)
        .then(({ data }) => {
          if (data.length === 0)
            toast('warn', 'Alerta', 'Nenhum cest encontrado!');

          const opts = data.map((item: any) => {
            return {
              label: item.descricao,
              value: item.id,
              codigo: item.codigo,
            };
          });

          setCestOpts(opts);
        })
        .catch((error: any) => {
          toast('error', 'Erro', error.response.data.error);
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleSelectNcm = (e: DataTableSelectionChangeParams) => {
    const selectedNcm = ncmOpts.find(i => i.value === e.value);
    if (selectedNcm) {
      setNcm({
        label: selectedNcm.label,
        value: selectedNcm.value,
      });

      setModalSearchNcmVisible(false);
    } else {
      toast(
        'warn',
        'Alerta',
        'Falha ao selecionar o ncm. Atualize o navegador!',
      );
      return;
    }
  };

  const handleSelectCest = (e: DataTableSelectionChangeParams) => {
    const selectedCest = cestOpts.find(i => i.value === e.value);
    if (selectedCest) {
      setCest({
        label: selectedCest.label,
        value: selectedCest.value,
      });

      setModalSearchCestVisible(false);
    } else {
      toast(
        'warn',
        'Alerta',
        'Falha ao selecionar o cest. Atualize o navegador!',
      );
      return;
    }
  };

  const handleOpenModalSearchNcm = () => {
    setModalSearchNcmVisible(true);
  };

  const handleOpenModalSearchCest = () => {
    setModalSearchCestVisible(true);
  };

  const handleAddProviderInProduct = () => {
    if (fornecedor) {
      const hasThisProvider = itensFornecedor.find(
        i => i.value === fornecedor.value,
      );

      if (hasThisProvider) {
        toast('warn', 'Alerta', 'O fornecedor já está vinculado ao produto!');
        return;
      }

      setItensFornecedor([...itensFornecedor, fornecedor]);
      setFornecedor(undefined);
    }
  };

  const searchProvider = async (e: AutoCompleteCompleteMethodParams) => {
    if (e.query.length >= 3) {
      setLoadingStatus(true);
      await api
        .get(`/persons/provider/src?search=${e.query}`)
        .then(({ data }) => {
          const opts = data.providers.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              codigo: item.codigo,
              doc: item.cpf_cnpj,
            };
          });

          if (opts.length === 0) {
            toast('error', 'Error', 'Nenhum fornecedor encontrado!');
            return;
          }

          setFornecedorOpts(opts);
        })
        .catch((error: any) => {
          toast('error', 'Erro', error.response.data.error);
          return;
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  const handleRemItemFornecedor = (rowId: string) => {
    const itensNoId = itensFornecedor.filter(i => i.value !== rowId);
    setItensFornecedor(itensNoId);
    setFornecedor(undefined);
  };

  const onTemplateRemove = (file: any, callback: any) => {
    setTotalSize(totalSize - file.size);
    callback(file);
  };

  /** render */
  const buttonRemItemTable = (rowId: string) => {
    return (
      <div>
        <Button
          icon="pi pi-trash"
          className="p-button-danger"
          onClick={() => handleRemItemFornecedor(rowId)}
        />
      </div>
    );
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div
        className={className}
        style={{
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {chooseButton}
      </div>
    );
  };

  const itemTemplate = (file: any, props: any) => {
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img
            alt={file.name}
            role="presentation"
            src={file.objectURL}
            width={100}
          />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="px-3 py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger ml-auto p-2"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-image mt-3 p-5"
          style={{
            fontSize: '5em',
            borderRadius: '50%',
            backgroundColor: 'var(--surface-b)',
            color: 'var(--surface-d)',
          }}
        ></i>
        <span
          style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}
          className="my-5"
        >
          Arraste e solte a imagem aqui
        </span>
      </div>
    );
  };

  const tabHeaderTemplate = (options: any, isError: boolean) => {
    return (
      <button
        type="button"
        onClick={options.onClick}
        className={`${options.className} mx-3`}
        style={
          isError
            ? { background: '#ff623e', color: '#fff', fontWeight: 200 }
            : { fontWeight: 200 }
        }
      >
        <i
          className={
            isError
              ? 'fa-solid fa-triangle-exclamation mr-2'
              : 'fa-regular fa-check mr-2'
          }
        />
        {options.titleElement}
      </button>
    );
  };

  const itemTemplateAutoComplete = (item: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{item.label}</div>
      </div>
    );
  };

  const formatTableColumnId = (rowData: any, attr: any) => {
    return <span>{attr.rowIndex + 1}</span>;
  };

  const formatTableColumnToDecimal = (row: any, property: string) => {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(row[property]);
  };

  const formatTableColumnToDate = (rowData: any) => {
    return <span>{format(rowData.vencimento, 'dd/MM/yyyy')}</span>;
  };

  /** useEffect */
  useEffect(() => {
    setLoadingStatus(true);
    api
      .get('/brands')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setMarcaOpts(opts);
        setMarca(opts[0].value);
      })
      .finally(() => {
        setLoadingStatus(false);
      });

    setLoadingStatus(true);
    api
      .get('/groups')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setCategoriaOpts(opts);
        setCategorias([opts[0].value]);
      })
      .finally(() => {
        setLoadingStatus(false);
      });

    setLoadingStatus(true);
    api
      .get('/tags')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.tag,
            value: item.id,
          };
        });
        setTagsOpts(opts);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/additionals')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: item.title,
            value: item.id,
          };
        });
        setAdicionaisOpts(opts);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/tributos-origem')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.codigo} - ${item.descricao}`,
            value: item.id,
          };
        });
        setOrigemOpts(opts);
        setOrigem(opts[0].value);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/trib-cst/icms')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`,
            value: item.id,
          };
        });
        setIcmsStituacaoTributariaOpts(opts);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/trib-cst/ipi')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`,
            value: item.id,
          };
        });
        setIpiStituacaoTributariaOpts(opts);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/trib-cst/pis')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`,
            value: item.id,
          };
        });
        setPisStituacaoTributariaOpts(opts);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/trib-cst/cofins')
      .then(({ data }) => {
        const opts = data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`,
            value: item.id,
          };
        });
        setCofinsStituacaoTributariaOpts(opts);
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/cfops')
      .then(({ data }) => {
        if (data.length > 0) {
          const cfopsOptsAux: IOptionsDTO[] = [
            { label: '0000 - Indefinido', value: '' },
          ];

          data.forEach((e: any) => {
            cfopsOptsAux.push({
              label: `${e.codigo}-${e.descricao.slice(0, 84)}`,
              value: e.id,
            });
          });

          setCfopOpts(cfopsOptsAux);
        }
      })
      .finally(() => setLoadingStatus(false));

    setLoadingStatus(true);
    api
      .get('/products/v2/codigo')
      .then(({ data }) => {
        if (data) {
          setCodigo(Number(data.codigo));
        } else {
          setCodigo(1);
        }
      })
      .finally(() => setLoadingStatus(false));
  }, []);

  useEffect(() => {
    setCustoTotal(valorCompra + custoEmpresa);
  }, [valorCompra, custoEmpresa]);

  return (
    <>
      <ProductHeaderButtons />

      {loadingStatus && (
        <ProgressBar mode="indeterminate" style={{ height: '4px' }} />
      )}
      <div className="card">
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Scope path="product_dados">
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-12">
                <label htmlFor="title">Produto</label>
                <InputText
                  name="title"
                  value={titulo}
                  onChange={e => setTitulo(e.currentTarget.value)}
                  placeholder="Ex.: Iphone X99 Pro Max"
                />
              </div>
            </div>
          </Scope>

          <TabView renderActiveOnly={false} scrollable>
            <TabPanel
              header="Dados"
              headerTemplate={e => tabHeaderTemplate(e, dadosIsError)}
            >
              <div className="p-fluid grid formgrid">
                <Scope path="product_dados">
                  <Divider align="left" className="my-2">
                    <div className="inline-flex align-items-center">
                      <i
                        className="fa-solid fa-barcode mr-2"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <b>Rastreio</b>
                    </div>
                  </Divider>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="sku">
                      SKU
                      <Tooltip
                        target=".gen-sku"
                        content="Clique para gerar um sku com 13 posições"
                      />
                      <i
                        className="gen-sku fa-solid fa-gear text-green-500 ml-2 cursor-pointer"
                        onClick={() => handleGenerateSkuByCodigo()}
                      ></i>
                    </label>
                    <InputText
                      name="sku"
                      value={sku}
                      onChange={e => setSku(e.currentTarget.value)}
                      placeholder="Ex.: 0000"
                      tooltip={'Código interno do produto para buscas'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="sku">
                      EAN/GTIN
                      <Tooltip
                        target=".gen-gtin"
                        content="Clique para gerar um gtin com 13 posições"
                      />
                      <i
                        className="gen-gtin fa-solid fa-gear text-green-500 ml-2 cursor-pointer"
                        onClick={() => handleGenerateGtinByCodigo()}
                      ></i>
                    </label>
                    <InputText
                      name="gtin"
                      value={gtin}
                      onChange={e => setGtin(e.currentTarget.value)}
                      placeholder="Ex.: 7890000000001"
                      tooltip={'O código de barras do produto'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="kind_product">Produto/Servico?</label>
                    <InputDropDown
                      name="kind_product"
                      options={tipoProdutoOpts}
                      value={tipoProduto}
                      placeholder="Ex.: Selecionar..."
                      onChange={e => setTipoProduto(e.value)}
                      tooltip={'É um produto ou serviço?'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="format_product">Formato</label>
                    <InputDropDown
                      name="format_product"
                      options={formatoProdutoOpts}
                      value={formatoProduto}
                      placeholder="Ex.: Selecionar..."
                      onChange={e => setFormatoProduto(e.value)}
                      tooltip={
                        'Produto simples; Produto com variações Ex: Tamanho: P, M, G; Produto composto. Ex: Capinha + Iphone = Iphone com capinha'
                      }
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="unity">Unidade</label>
                    <InputText
                      name="unity"
                      value={unidade}
                      onChange={e => setUnidade(e.currentTarget.value)}
                      placeholder="Ex.: KG"
                      tooltip={
                        'Unidade de medida do produto: Ex. Lt, Kg, Und, Pc, Pct...'
                      }
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0 flex align-items-end justify-content-center">
                    <InputSwitch
                      className="mr-3"
                      name="status"
                      checked={status}
                      onChange={e => setStatus(`${e.value}`)}
                      falseValue="E"
                      trueValue="A"
                    />
                    <label htmlFor="status">Ativo</label>
                  </div>

                  <Divider align="left" className="my-2">
                    <div className="inline-flex align-items-center">
                      <i
                        className="fa-solid fa-truck mr-2"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <b>Transporte</b>
                    </div>
                  </Divider>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="weight_liquid">Pesolíquido (g)</label>
                    <InputNumber
                      name="weight_liquid"
                      value={pesoLiquido}
                      onChange={e => setPesoLiquido(Number(e.value))}
                      placeholder="Ex.: 000 gm"
                      minFractionDigits={2}
                      tooltip={'Peso é em gramas. Ex: 1kg = 1000g'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="weight_brute">Peso bruto (g)</label>
                    <InputNumber
                      name="weight_bruto"
                      value={pesoBruto}
                      onChange={e => setPesoBruto(Number(e.value))}
                      placeholder="Ex.: 000 gm"
                      minFractionDigits={2}
                      tooltip={'Peso é em gramas. Ex: 1kg = 1000g'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="height">Altura (cm)</label>
                    <InputNumber
                      name="height"
                      value={altura}
                      onChange={e => setAltura(Number(e.value))}
                      placeholder="Ex.: 000 cm"
                      minFractionDigits={2}
                      tooltip={'Altura é em centimetros: Ex. 1Mt = 100cm'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="width">Largura (cm)</label>
                    <InputNumber
                      name="width"
                      value={largura}
                      onChange={e => setLargura(Number(e.value))}
                      placeholder="Ex.: 000 cm"
                      minFractionDigits={2}
                      tooltip={'Largura é em centimetros: Ex. 1Mt = 100cm'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="length">Comprimento (cm)</label>
                    <InputNumber
                      name="length"
                      value={comprimento}
                      onChange={e => setComprimento(Number(e.value))}
                      placeholder="Ex.: 000 cm"
                      minFractionDigits={2}
                      tooltip={'Comprimento é em centimetros: Ex. 1Mt = 100cm'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="expiration_date">Vencimento</label>
                    <Calendar
                      name="expiration_date"
                      value={vencimento}
                      onChange={e => setVencimento(e.value)}
                      showIcon
                      dateFormat="dd/mm/yy"
                      tooltip={'Caso produto pericível, informar o vencimento!'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <Divider align="left" className="my-2">
                    <div className="inline-flex align-items-center">
                      <i
                        className="fa-solid fa-box mr-2"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <b>Detalhes</b>
                    </div>
                  </Divider>

                  <div className="field col-12 md:col-4 mx-0">
                    <label htmlFor="format_product">Categoria</label>
                    <MultiSelect
                      filter={true}
                      name="product_groups"
                      options={categoriaOpts}
                      value={categorias}
                      placeholder="Ex.: Selecionar..."
                      onChange={e => setCategorias(e.value)}
                      tooltip={'Categorias do produto'}
                      tooltipOptions={{ position: 'bottom' }}
                      display="chip"
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="format_product">Produção</label>
                    <InputDropDown
                      name="production"
                      options={producaoProdutoOpts}
                      value={producao}
                      placeholder="Ex.: Selecionar..."
                      onChange={e => setProducao(e.value)}
                      tooltip={
                        'Própria você fabrica; \n Terceiros vc compra pronto;'
                      }
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="format_product">Condição</label>
                    <InputDropDown
                      name="condiction"
                      options={condicaoProdutoOpts}
                      value={condicao}
                      placeholder="Ex.: Selecionar..."
                      onChange={e => setCondicao(e.value)}
                      tooltip={'Novo, nunca usado; \n Usado, segunda mão;'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="format_product">Frete grátis</label>
                    <InputDropDown
                      name="free_shipping"
                      options={freteGratisProdutoOpts}
                      value={freteGratis}
                      placeholder="Ex.: Selecionar..."
                      onChange={e => setFreteGratis(e.value)}
                      tooltip={'Sim, Não;'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2 mx-0">
                    <label htmlFor="brand_id">Marca</label>
                    <InputDropDown
                      filter={true}
                      name="brand_id"
                      options={marcaOpts}
                      value={marca}
                      onChange={e => setMarca(e.value)}
                      placeholder="Ex.: Selecionar..."
                      tooltip={'A marca do produto'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-8">
                    <InputTextEdit
                      name="obs"
                      style={{ height: '10vh' }}
                      value={obs}
                      onTextChange={e => setObs(e.htmlValue || '')}
                      placeholder="Ex.: Este produto tem uma ótima saída!"
                    />
                  </div>
                  <div className="field col-12 md:col-4">
                    <div className="field col-12 md:col-12 flex align-items-center">
                      <InputSwitch
                        className="mr-4"
                        name="show_store"
                        checked={showStore}
                        onChange={e => setShowStore(e.value as boolean)}
                        falseValue={false}
                        trueValue={true}
                      />
                      <label htmlFor="show_store">Ativo na loja</label>
                    </div>
                    <div className="field col-12 md:col-12 flex align-items-center">
                      <InputSwitch
                        className="mr-4"
                        name="emphasis"
                        checked={destaque}
                        onChange={e => setDestaque(`${e.value}`)}
                        falseValue="N"
                        trueValue="S"
                      />
                      <label htmlFor="dropdown">Com destaque</label>
                    </div>
                    <div className="field col-12 md:col-12 flex align-items-center">
                      <InputSwitch
                        className="mr-4"
                        name="release"
                        checked={lancamento}
                        onChange={e => setLancamento(`${e.value}`)}
                        falseValue="N"
                        trueValue="S"
                      />
                      <label htmlFor="dropdown">Lançamento</label>
                    </div>
                    <div className="field col-12 md:col-12 flex align-items-center">
                      <InputSwitch
                        className="mr-4"
                        name="seal"
                        checked={etiqueta}
                        onChange={e => setEtiqueta(`${e.value}`)}
                        falseValue="N"
                        trueValue="S"
                      />
                      <label htmlFor="dropdown">Etiqueta</label>
                    </div>
                  </div>
                </Scope>
              </div>
            </TabPanel>

            <TabPanel
              header="Tags"
              headerTemplate={e => tabHeaderTemplate(e, tagsIsError)}
            >
              <div className="p-fluid grid formgrid">
                <Scope path="product_tags">
                  <div className="field col-12 md:col-8 mx-0">
                    <label htmlFor="tags">Tags</label>
                    <MultiSelect
                      name="tags"
                      options={tagsOpts}
                      value={tags}
                      placeholder="Ex.: Selecionar..."
                      filter={true}
                      onChange={e => setTags(e.value)}
                      tooltip={
                        'Tags te auxiliam a gerar relatórios dos produtos'
                      }
                      tooltipOptions={{ position: 'bottom' }}
                      display="chip"
                    />
                  </div>
                </Scope>
              </div>
            </TabPanel>

            <TabPanel
              header="Estoque"
              headerTemplate={e => tabHeaderTemplate(e, estoqueIsError)}
            >
              <div className="p-fluid grid formgrid">
                <Scope path="product_stock">
                  <Divider align="left" className="my-2">
                    <div className="inline-flex align-items-center">
                      <i
                        className="fa-solid fa-boxes mr-2"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <b>Armazenamento</b>
                    </div>
                  </Divider>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="estoque">Estoque ({unidade})</label>
                    <InputNumber
                      name="estoque"
                      value={estoque}
                      placeholder="Ex.: 0"
                      minFractionDigits={qntVenda || 2}
                      maxFractionDigits={qntVenda || 2}
                      tooltip={'Posição atual do estoque do produto'}
                      tooltipOptions={{ position: 'bottom' }}
                      disabled
                      className="surface-400"
                      style={{ borderRadius: 4 }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="stock_min">Estq. mínimo</label>
                    <InputNumber
                      name="stock_min"
                      value={estqMinimo}
                      onChange={e => setEstqMinimo(Number(e.value))}
                      placeholder="Ex.: 0"
                      minFractionDigits={qntVenda || 2}
                      maxFractionDigits={qntVenda || 2}
                      tooltip={'Quantidade mínima de produtos no estoque'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="stock_max">Estq. máximo</label>
                    <InputNumber
                      name="stock_max"
                      value={estqMaximo}
                      onChange={e => setEstqMaximo(Number(e.value))}
                      placeholder="Ex.: 0,00"
                      minFractionDigits={qntVenda || 2}
                      maxFractionDigits={qntVenda || 2}
                      tooltip={'Quantidade máxima de produtos no estoque'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="stock_location">Localização</label>
                    <InputText
                      name="stock_location"
                      value={localizacao}
                      onChange={e => setLocalizacao(e.currentTarget.value)}
                      placeholder="Ex.: Galpão:2, Prateleira: 11"
                      tooltip={'Localização física do produto na empresa'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <Divider align="left" className="my-2">
                    <div className="inline-flex align-items-center">
                      <i
                        className="fa-solid fa-divide mr-2"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <b>Custos produto</b>
                    </div>
                  </Divider>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="buy_value">Valor compra</label>
                    <InputNumber
                      name="buy_value"
                      value={valorCompra}
                      onChange={e => setValorCompra(Number(e.value))}
                      placeholder="Ex.: 0,00"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      tooltip={'Custo aquisição do produto'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="buy_cost">Custo empresa</label>
                    <InputNumber
                      name="buy_cost"
                      value={custoEmpresa}
                      onChange={e => setCustoEmpresa(Number(e.value))}
                      placeholder="Ex.: 0,00"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      tooltip={'Outros custos aquisição do produto'}
                      tooltipOptions={{ position: 'bottom' }}
                    />
                  </div>

                  <div className="field col-12 md:col-2">
                    <label htmlFor="cost_billing">Custo total</label>
                    <InputNumber
                      name="cost_billing"
                      value={custoTotal}
                      onChange={e => setCustoTotal(Number(e.value))}
                      placeholder="Ex.: 0,00"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      tooltip={'Total de custos'}
                      tooltipOptions={{ position: 'bottom' }}
                      readOnly
                      className="surface-400"
                      disabled
                      style={{ borderRadius: 4 }}
                    />
                  </div>

                  <Divider align="left" className="my-2">
                    <div className="inline-flex align-items-center">
                      <i
                        className="fa-solid fa-divide mr-2"
                        style={{ color: 'var(--primary-color)' }}
                      ></i>
                      <b>Casas decimais</b>
                    </div>
                  </Divider>

                  <Scope path="decimal_houses">
                    <div className="field col-12 md:col-2">
                      <label htmlFor="qnt_in">Qnt casas entrada</label>
                      <InputNumber
                        name="qnt_in"
                        value={qntEntrada}
                        onChange={e => setQntEntrada(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        showButtons
                        buttonLayout="horizontal"
                        min={2}
                        max={6}
                        tooltip={
                          'Casas decimais para entrada de produtos no estoque'
                        }
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="qnt_out">Qnt casas venda</label>
                      <InputNumber
                        name="qnt_out"
                        value={qntVenda}
                        onChange={e => setQntVenda(Number(e.value))}
                        placeholder="Ex.: 0"
                        showButtons
                        buttonLayout="horizontal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        min={2}
                        max={6}
                        tooltip={
                          'Casas decimais para venda de produtos no estoque'
                        }
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="price_in">Qnt preço entrada</label>
                      <InputNumber
                        name="price_in"
                        value={precoEntrada}
                        onChange={e => setPrecoEntrada(Number(e.value))}
                        placeholder="Ex.: 0"
                        showButtons
                        buttonLayout="horizontal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        min={2}
                        max={6}
                        tooltip={
                          'Casas decimais para entrada de produtos no estoque'
                        }
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="price_out">Qnt preço venda</label>
                      <InputNumber
                        name="price_out"
                        value={precoVenda}
                        onChange={e => setPrecoVenda(Number(e.value))}
                        placeholder="Ex.: 0"
                        showButtons
                        buttonLayout="horizontal"
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        min={2}
                        max={6}
                        tooltip={
                          'Casas decimais para venda de produtos no estoque'
                        }
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  </Scope>
                </Scope>
              </div>
            </TabPanel>

            <TabPanel
              header="Fornecedores"
              headerTemplate={e => tabHeaderTemplate(e, fornecedoresIsError)}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6">
                  <label htmlFor="provider_id">
                    Nome/Razão social
                    <i
                      className="pi pi-plus-circle text-green-500 ml-2 cursor-pointer"
                      onClick={() => null}
                    ></i>
                  </label>
                  <InputAutoComplete
                    name="provider_id"
                    suggestions={fornecedorOpts}
                    value={fornecedor}
                    completeMethod={e => searchProvider(e)}
                    field="label"
                    onChange={e => setFornecedor(e.value)}
                    itemTemplate={itemTemplateAutoComplete}
                    placeholder="Ex.: Buscar cliente..."
                  />
                </div>

                <div className="field col-12 md:col-3 flex align-items-end justify-content-center">
                  <Button
                    icon="fa-solid fa-plus"
                    label="Adicionar"
                    onClick={() => handleAddProviderInProduct()}
                    disabled={!fornecedor ? true : false}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <DataTable
                    value={itensFornecedor}
                    responsiveLayout="scroll"
                    selectionMode="single"
                    paginator
                    rows={10}
                    rowsPerPageOptions={[10, 20, 30]}
                    size="small"
                    emptyMessage="Nenhuma proposta encontrada!"
                  >
                    <Column
                      field="value"
                      header="#"
                      body={formatTableColumnId}
                    ></Column>
                    <Column field="label" header="Nome/Razão Social"></Column>
                    <Column field="doc" header="Cpf/Cnpj"></Column>
                    <Column
                      header="*"
                      body={row => buttonRemItemTable(row.value)}
                    ></Column>
                  </DataTable>
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header="Informações"
              headerTemplate={e => tabHeaderTemplate(e, informacoesIsError)}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6">
                  <label htmlFor="link_produto">Link produto</label>
                  <InputText
                    name="link_produto"
                    value={linkProduto}
                    onChange={e => setLinkProduto(e.currentTarget.value)}
                    placeholder="Ex.: http://www.site.com"
                    type={'url'}
                    tooltip={'Link do produto em alguma loja virtual'}
                    tooltipOptions={{ position: 'bottom' }}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="video_produto">Vídeo produto</label>
                  <InputText
                    name="video_produto"
                    value={videoProduto}
                    onChange={e => setVideoProduto(e.currentTarget.value)}
                    placeholder="Ex.: http://www.site.com"
                    type={'url'}
                    tooltip={'Link de algum vídeo de uso do produto'}
                    tooltipOptions={{ position: 'bottom' }}
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <label htmlFor="descricao_curta">Descrição curta</label>
                  <InputTextEdit
                    name="descricao_curta"
                    style={{ height: '10vh' }}
                    value={descricaoCurta}
                    onTextChange={e => setDescricaoCurta(e.htmlValue || '')}
                    placeholder="Ex.: Produto Iphone X99 Pro Max 256gb branco..."
                  />
                </div>

                <div className="field col-12 md:col-12">
                  <label htmlFor="descricao_completa">Descrição completa</label>
                  <InputTextEdit
                    name="descricao_completa"
                    style={{ height: '30vh' }}
                    value={descricaoCompleta}
                    onTextChange={e => setDescricaoCompleta(e.htmlValue || '')}
                    placeholder="Ex.: O produto Iphone X99 Pro Max 256gb branco tem ótima saída no mercado e todo mundo tem o usado..."
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header="Adicionais"
              headerTemplate={e => tabHeaderTemplate(e, adicionaisIsError)}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6 mx-0">
                  <label htmlFor="format_product">
                    Adicionais{' '}
                    <Tag
                      className="ml-2"
                      icon="pi pi-exclamation-triangle"
                      severity="warning"
                      value="Modulo desabilitado"
                      style={{ color: '#ffffff' }}
                    ></Tag>
                  </label>
                  <InputDropDown
                    name="adicionais"
                    options={adicionaisOpts}
                    value={adicionais?.value}
                    placeholder="Ex.: Selecionar..."
                    onChange={e => setAdicionais(e.value)}
                    tooltip={'Vincular itens adicionais aos produtos'}
                    tooltipOptions={{ position: 'bottom' }}
                    disabled
                  />
                </div>

                <div className="field col-12 md:col-3 flex align-items-end justify-content-center">
                  <Button
                    icon="fa-solid fa-plus"
                    label="Adicionar"
                    onClick={() => handleAddProviderInProduct()}
                    disabled
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header="Variações"
              headerTemplate={e => tabHeaderTemplate(e, variacoesIsError)}
            >
              <div className="p-fluid grid formgrid">
                <div className="field col-12 md:col-6 mx-0">
                  <label htmlFor="format_product">
                    Variações{' '}
                    <Tag
                      className="ml-2"
                      icon="pi pi-exclamation-triangle"
                      severity="warning"
                      value="Modulo desabilitado"
                      style={{ color: '#ffffff' }}
                    ></Tag>
                  </label>
                  <InputDropDown
                    name="variacoes"
                    options={variacoesOpts}
                    value={variacoes?.value}
                    placeholder="Ex.: Selecionar..."
                    onChange={e => setAdicionais(e.value)}
                    tooltip={'Vincular itens adicionais aos produtos'}
                    tooltipOptions={{ position: 'bottom' }}
                    disabled
                  />
                </div>

                <div className="field col-12 md:col-3 flex align-items-end justify-content-center">
                  <Button
                    icon="fa-solid fa-plus"
                    label="Adicionar"
                    onClick={() => handleAddProviderInProduct()}
                    disabled
                  />
                </div>
              </div>
            </TabPanel>

            <TabPanel
              header="Fotos"
              headerTemplate={e => tabHeaderTemplate(e, fotosIsError)}
            >
              <div className="p-fluid grid formgrid">
                <FileUpload
                  ref={fileUploadRef}
                  name="file"
                  maxFileSize={100000}
                  multiple={false}
                  accept="image/*"
                  onError={onTemplateClear}
                  onClear={onTemplateClear}
                  headerTemplate={headerTemplate}
                  chooseOptions={{
                    icon: 'pi pi-plus',
                    label: 'Escolher Imagem',
                    className: 'custom-choose-btn',
                  }}
                  itemTemplate={itemTemplate}
                  emptyTemplate={emptyTemplate}
                  invalidFileSizeMessageSummary=""
                  invalidFileSizeMessageDetail="Tamanho de arquivo inválido,
                o tamanho máximo de upload é de 1 MB."
                  className="field col-12 md:col-6"
                />
              </div>
            </TabPanel>

            <TabPanel
              header="Tributos"
              headerTemplate={e => tabHeaderTemplate(e, tributosIsError)}
            >
              <div className="p-fluid grid formgrid">
                <Divider align="left" className="my-2">
                  <div className="inline-flex align-items-center">
                    <i
                      className="fa-solid fa-building-columns mr-2"
                      style={{ color: 'var(--primary-color)' }}
                    ></i>
                    <b>Códigos tributários</b>
                  </div>
                </Divider>

                <div className="field col-12 md:col-4">
                  <label htmlFor="origem">Origem</label>
                  <InputDropDown
                    name="origem"
                    options={origemOpts}
                    value={origem}
                    placeholder="Ex.: Selecionar..."
                    onChange={e => setOrigem(e.value)}
                    tooltip={'Origem dar mercadoria'}
                    tooltipOptions={{ position: 'bottom' }}
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end justify-content-center ml-auto">
                  <Button
                    label={
                      ncm.value === '' ? 'Buscar NCM' : ncm.label.slice(0, 24)
                    }
                    type="button"
                    icon="fa-solid fa-search"
                    className={`p-button-outlined ${
                      ncm.value === '' ? 'p-button-danger' : 'p-button-info'
                    } ml-auto`}
                    onClick={handleOpenModalSearchNcm}
                  />
                </div>

                <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
                  <Button
                    label={
                      cest.value === ''
                        ? 'Buscar CEST'
                        : cest.label.slice(0, 24)
                    }
                    type="button"
                    icon="fa-solid fa-search"
                    className={`p-button-outlined ${
                      cest.value === '' ? 'p-button-danger' : 'p-button-info'
                    } ml-auto`}
                    onClick={handleOpenModalSearchCest}
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="cfop_in">Cfop interno</label>
                  <InputDropDown
                    name="cfop_in"
                    options={cfopOpts}
                    value={cfopIn !== '' ? cfopIn : null}
                    placeholder="Ex.: Selecionar..."
                    onChange={e => setCfopIn(e.value)}
                    tooltip={'Cfop dentro do estado'}
                    tooltipOptions={{ position: 'bottom' }}
                    filter
                  />
                </div>

                <div className="field col-12 md:col-6">
                  <label htmlFor="cfop_out">Cfop externo</label>
                  <InputDropDown
                    name="cfop_out"
                    options={cfopOpts}
                    value={cfopOut !== '' ? cfopOut : null}
                    placeholder="Ex.: Selecionar..."
                    onChange={e => setCfopOut(e.value)}
                    tooltip={'Cfop operação fora do estado'}
                    tooltipOptions={{ position: 'bottom' }}
                    filter
                  />
                </div>

                <Divider align="left" className="my-2">
                  <div className="inline-flex align-items-center">
                    <i
                      className="fa-solid fa-calculator mr-2"
                      style={{ color: 'var(--primary-color)' }}
                    ></i>
                    <b>Cálculos</b>
                  </div>
                </Divider>

                <TabView className="field col-12 md:col-12 px-0">
                  <TabPanel
                    header="Icms"
                    headerTemplate={e => tabHeaderTemplate(e, false)}
                    className="p-fluid grid formgrid"
                    contentClassName="p-fluid grid formgrid px-0"
                  >
                    <div className="field col-12 md:col-6">
                      <label htmlFor="icms_cst">CST - Icms</label>
                      <InputDropDown
                        name="icms_cst"
                        options={icmsStituacaoTributariaOpts}
                        value={icmsSituacaoTributaria}
                        placeholder="Ex.: Selecionar..."
                        onChange={e => setIcmsSituacaoTributaria(e.value)}
                        tooltip={'Código da situação tributária'}
                        tooltipOptions={{ position: 'bottom' }}
                        filter
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="icms_aliq">Aliquota</label>
                      <InputNumber
                        name="icms_aliq"
                        value={icmsAliq}
                        prefix="%"
                        onChange={e => setIcmsAliq(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Porcentagem de aliquota do icms'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="icms_base_calculo">Base de cálculo</label>
                      <InputNumber
                        name="icms_base_calculo"
                        value={icmsBaseCalculo}
                        prefix="%"
                        onChange={e => setIcmsBaseCalculo(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Base de cálculo do icms'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="icms_valor">Valor</label>
                      <InputNumber
                        name="icms_valor"
                        value={icmsValor}
                        onChange={e => setIcmsValor(Number(e.value))}
                        placeholder="Ex.: 0,00"
                        minFractionDigits={2}
                        tooltip={'Valor fixo do icms'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel
                    header="Ipi"
                    headerTemplate={e => tabHeaderTemplate(e, false)}
                    className="p-fluid grid formgrid"
                    contentClassName="p-fluid grid formgrid px-0"
                  >
                    <div className="field col-12 md:col-6">
                      <label htmlFor="ipi_cst">CST - Ipi</label>
                      <InputDropDown
                        name="ipi_cst"
                        options={ipiStituacaoTributariaOpts}
                        value={ipiSituacaoTributaria}
                        placeholder="Ex.: Selecionar..."
                        onChange={e => setIpiSituacaoTributaria(e.value)}
                        tooltip={'Código da situação tributária'}
                        tooltipOptions={{ position: 'bottom' }}
                        filter
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="ipi_aliq">Aliquota</label>
                      <InputNumber
                        name="ipi_aliq"
                        value={ipiAliq}
                        prefix="%"
                        onChange={e => setIpiAliq(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Porcentagem de aliquota do ipi'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="ipi_base_calculo">Base de cálculo</label>
                      <InputNumber
                        name="ipi_base_calculo"
                        value={ipiBaseCalculo}
                        prefix="%"
                        onChange={e => setIpiBaseCalculo(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Base de cálculo do ipi'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="ipi_valor">Valor</label>
                      <InputNumber
                        name="ipi_valor"
                        value={ipiValor}
                        onChange={e => setIpiValor(Number(e.value))}
                        placeholder="Ex.: 0,00"
                        minFractionDigits={2}
                        tooltip={'Valor fixo do ipi'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel
                    header="Pis"
                    headerTemplate={e => tabHeaderTemplate(e, false)}
                    className="p-fluid grid formgrid"
                    contentClassName="p-fluid grid formgrid px-0"
                  >
                    <div className="field col-12 md:col-6">
                      <label htmlFor="ipi_cst">CST - Pis</label>
                      <InputDropDown
                        name="ipi_cst"
                        options={pisSituacaoTributariaOpts}
                        value={pisSituacaoTributaria}
                        placeholder="Ex.: Selecionar..."
                        onChange={e => setPisSituacaoTributaria(e.value)}
                        tooltip={'Código da situação tributária'}
                        tooltipOptions={{ position: 'bottom' }}
                        filter
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="pis_aliq">Aliquota</label>
                      <InputNumber
                        name="pis_aliq"
                        value={pisAliq}
                        prefix="%"
                        onChange={e => setPisAliq(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Porcentagem de aliquota do pis'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="pis_base_calculo">Base de cálculo</label>
                      <InputNumber
                        name="pis_base_calculo"
                        value={pisBaseCalculo}
                        prefix="%"
                        onChange={e => setPisBaseCalculo(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Base de cálculo do pis'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="pis_valor">Valor</label>
                      <InputNumber
                        name="pis_valor"
                        value={pisValor}
                        onChange={e => setPisValor(Number(e.value))}
                        placeholder="Ex.: 0,00"
                        minFractionDigits={2}
                        tooltip={'Valor fixo do pis'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel
                    header="Cofins"
                    headerTemplate={e => tabHeaderTemplate(e, false)}
                    className="p-fluid grid formgrid"
                    contentClassName="p-fluid grid formgrid px-0"
                  >
                    <div className="field col-12 md:col-6">
                      <label htmlFor="cofins_cst">CST - Cofins</label>
                      <InputDropDown
                        name="cofins_cst"
                        options={cofinsStituacaoTributariaOpts}
                        value={cofinsSituacaoTributaria}
                        placeholder="Ex.: Selecionar..."
                        onChange={e => setCofinsSituacaoTributaria(e.value)}
                        tooltip={'Código da situação tributária'}
                        tooltipOptions={{ position: 'bottom' }}
                        filter
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="cofins_aliq">Aliquota</label>
                      <InputNumber
                        name="cofins_aliq"
                        value={cofinsAliq}
                        prefix="%"
                        onChange={e => setCofinsAliq(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Porcentagem de aliquota do cofins'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="cofins_base_calculo">
                        Base de cálculo
                      </label>
                      <InputNumber
                        name="cofins_base_calculo"
                        value={cofinsBaseCalculo}
                        prefix="%"
                        onChange={e => setCofinsBaseCalculo(Number(e.value))}
                        placeholder="Ex.: 0"
                        minFractionDigits={2}
                        tooltip={'Base de cálculo do cofins'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>

                    <div className="field col-12 md:col-2">
                      <label htmlFor="cofins_valor">Valor</label>
                      <InputNumber
                        name="cofins_valor"
                        value={cofinsValor}
                        onChange={e => setCofinsValor(Number(e.value))}
                        placeholder="Ex.: 0,00"
                        minFractionDigits={2}
                        tooltip={'Valor fixo do cofins'}
                        tooltipOptions={{ position: 'bottom' }}
                      />
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </TabPanel>
          </TabView>

          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-6">
              <Button
                label="Limpar"
                type="reset"
                icon="pi pi-times"
                onClick={() => handleClearForm()}
                className="p-button-danger"
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Confirmar"
                icon="pi pi-check"
                className="p-button-success"
                type="submit"
                loading={loadingStatus}
              />
            </div>
          </div>
        </Form>
      </div>

      <SideBarSearchNcm
        visible={modalSearchNcmVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchNcmVisible(false)}
      >
        <h3>Buscar NCM</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="ncm_search"
                placeholder="Ex.: codigo ou descrição"
                type={'text'}
                value={ncmKeyword}
                onChange={e => setNcmKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={ncmKeyword.length < 3 ? true : false}
                onClick={() => searchNcm(ncmKeyword)}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={ncmOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectNcm(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={formatTableColumnId}
                ></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="label" header="Descrição"></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchNcm>

      <SideBarSearchCest
        visible={modalSearchCestVisible}
        position="right"
        style={{ width: '50vw' }}
        onHide={() => setModalSearchCestVisible(false)}
      >
        <h3>Buscar CEST</h3>
        <Form ref={null} onSubmit={() => null}>
          <div className="p-fluid grid formgrid">
            <div className="field col-12 md:col-8">
              <label htmlFor="dropdown">Pesquisa</label>
              <InputText
                name="cest_search"
                placeholder="Ex.: codigo ou descrição"
                type={'text'}
                value={cestKeyword}
                onChange={e => setCestKeyword(e.currentTarget.value)}
                autoFocus={true}
              />
            </div>
            <div className="field col-12 md:col-4 flex align-items-end justify-content-center">
              <Button
                icon="pi pi-search"
                className="p-button-info"
                label="Buscar"
                type="submit"
                disabled={cestKeyword.length < 3 ? true : false}
                onClick={() => searchCest(cestKeyword)}
                loading={loadingStatus}
              />
            </div>
            <div className="field col-12 md:col-12">
              <DataTable
                value={cestOpts}
                responsiveLayout="scroll"
                selectionMode="single"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 20, 30]}
                size="small"
                emptyMessage="Nenhum item encontrado!"
                loading={loadingStatus}
                onSelectionChange={e => handleSelectCest(e.value)}
              >
                <Column
                  field="value"
                  header="#"
                  body={formatTableColumnId}
                ></Column>
                <Column field="codigo" header="Código"></Column>
                <Column field="label" header="Descrição"></Column>
              </DataTable>
            </div>
          </div>
        </Form>
      </SideBarSearchCest>
    </>
  );
};

export default AddProduct;

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import axios from 'axios';
import { endOfMonth, startOfMonth } from 'date-fns';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { validate } from 'uuid';
import { DataTable } from '../../../components/DataTable';
import renderColumnDate from '../../../components/DataTableColumns/RenderColumnDate';
import renderColumnDecimal from '../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnSitutacao from '../../../components/DataTableColumns/RenderColumnSitutacao';
import Calendar from '../../../components/Inputs/InputCalendar';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { InputText } from '../../../components/Inputs/InputText';
import MenuPopUp from '../../../components/MenuPopPup';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import messageRequestError from '../../../utils/messageRequestError';
import OsMenuHeader from '../../os/osMenuHeader';
import INfseDTO from '../dtos/INfseDTO';
import statusOpts from '../types/statusOpts';

const NfseList = (): React.ReactElement => {
  /** const */
  const hoje = new Date();

  /** hooks */
  const router = useHistory();
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** states */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>('');
  const [dateStart, setDateStart] = useState<Date | Date[] | undefined>(
    startOfMonth(hoje),
  );
  const [dateEnd, setDateEnd] = useState<Date | Date[] | undefined>(
    endOfMonth(hoje),
  );

  const [situtation, setSituation] = useState<Array<string>>([]);

  const [itemsNfse, setItemsNfse] = useState<Array<INfseDTO>>([]);

  /** functions */
  const listNfse = async () => {
    setLoadingStatus(true);
    await api
      .post('/nfse/v1/search', {
        filter: {
          date_start: dateStart,
          date_end: dateEnd,
          src: null,
          situation: [],
        },
      })
      .then(data => {
        if (data.status === 201) {
          setItemsNfse(data.data.nfses);
        }
      })
      .catch((e: any) => {
        toast('error', 'Erro', messageRequestError(e), 10000);
      })
      .finally(() => setLoadingStatus(false));
  };

  const searchNfse = async () => {
    setLoadingStatus(true);
    await api
      .post('/nfse/v1/search', {
        filter: {
          date_start: dateStart,
          date_end: dateEnd,
          src: keyword || null,
          situation: situtation || [],
        },
      })
      .then(data => {
        if (data.status === 201) {
          setItemsNfse(data.data.nfses);
        }
      })
      .catch((e: any) => {
        setItemsNfse([]);
        toast('error', 'Erro', messageRequestError(e), 10000);
      })
      .finally(() => setLoadingStatus(false));
  };

  /** useEffect */
  useEffect(() => {
    listNfse();
  }, []);

  /** renders */
  const mountMenu = (row: INfseDTO) => {
    const items: any[] = [];

    if (row.situacao !== 'AUTORIZADA' && !validate(row.user_cancel_id)) {
      items.push({
        label: 'Atualizar',
        icon: 'fa-solid fa-refresh',
        command: async () => {
          setLoadingStatus(true);
          await api
            .get(`/nfse/${row.ts_id}`)
            .then(async d => {
              if (d.status === 201) {
                await searchNfse();
              }
            })
            .catch((e: any) => {
              toast('error', 'Erro', messageRequestError(e), 10000);
            })
            .finally(() => {
              setLoadingStatus(false);
            });
        },
      });
    }

    if (validate(row.user_cancel_id) && row.situacao !== 'CANCELADA') {
      items.push({
        label: 'Atualizar canc.',
        icon: 'fa-solid fa-refresh',
        command: async () => {
          setLoadingStatus(true);
          await api
            .get(`/nfse/cancel/${row.ts_id}`)
            .then(async d => {
              if (d.status === 201) {
                await searchNfse();
              }
            })
            .catch((e: any) => {
              toast('error', 'Erro', messageRequestError(e), 10000);
            })
            .finally(() => {
              setLoadingStatus(false);
            });
        },
      });
    }

    if (row.situacao === 'AUTORIZADA') {
      items.push({
        label: 'Download pdf',
        icon: 'fa-solid fa-file-pdf',
        command: async () => {
          const headers = {
            'x-api-key': '78481c63-d75f-49ae-a7e5-aa5caaa91316',
          };
          await axios
            .get(
              `${process.env.REACT_APP_TS_URL_PRODUCAO}/nfse/pdf/${row.ts_id}`,
              {
                headers,
                responseType: 'blob',
              },
            )
            .then(({ data }) => {
              const url = window.URL.createObjectURL(data);
              const a = document.createElement('a');
              a.href = url;
              a.download = `wg-nfse-${row.numero}`;
              a.click();
              window.open(url);
            })
            .catch(e => {
              toast('error', 'Erro', messageRequestError(e), 10000);
            });
        },
      });

      items.push({
        label: 'Download xml',
        icon: 'fa-solid fa-file-code',
        command: async () => {
          const headers = {
            'x-api-key': '78481c63-d75f-49ae-a7e5-aa5caaa91316',
          };
          await axios
            .get(
              `${process.env.REACT_APP_TS_URL_PRODUCAO}/nfse/xml/${row.ts_id}`,
              {
                headers,
                responseType: 'blob',
              },
            )
            .then(({ data }) => {
              const url = window.URL.createObjectURL(data);
              const a = document.createElement('a');
              a.href = url;
              a.download = `wg-nfe-${row.numero}`;
              a.click();
              window.open(url);
            })
            .catch(e => {
              toast('error', 'Erro', messageRequestError(e), 10000);
            });
        },
      });

      items.push({
        label: 'Cancelar',
        icon: 'fa-solid fa-xmark',
        command: async () => {
          setLoadingStatus(true);
          await api
            .post('/nfse/v1/cancel', {
              data: {
                ts_id: row.ts_id,
                codigo: '000',
                motivo:
                  'Cancelamento para correção de informações lançadas incorretamente!',
              },
            })
            .then(async d => {
              if (d.status === 201) {
                toast(
                  'success',
                  'Sucesso',
                  'Solicitação de cancelamento de Nfse realizado com sucesso, aguardar a aprovação!',
                );
                await searchNfse();
              }
            })
            .catch((e: any) => {
              toast('error', 'Erro', messageRequestError(e), 10000);
            })
            .finally(() => {
              setLoadingStatus(false);
            });
        },
      });
    }

    return items;
  };

  const renderCollumnMenu = (row: INfseDTO) => {
    const mountOpts = mountMenu(row);

    return (
      <>
        <MenuPopUp model={mountOpts} rowData={row} setData={() => {}} />
      </>
    );
  };

  return (
    <>
      <OsMenuHeader />
      <div className="card my-2">
        <Form
          ref={formRef}
          onSubmit={() => searchNfse()}
          className="p-fluid grid formgrid"
        >
          <div className="field col-12 md:col-6">
            <label htmlFor="keyword">Nome</label>
            <InputText
              name="keyword"
              placeholder="Ex.: João da Silva"
              value={keyword}
              onChange={e => setKeyword(e.currentTarget.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="date_start">Data início</label>
            <Calendar
              name="date_start"
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              showIcon
              value={dateStart}
              onChange={e => setDateStart(e.value)}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="date_end">Data fim</label>
            <Calendar
              name="date_end"
              dateFormat="dd/mm/yy"
              placeholder="00/00/00"
              showIcon
              value={dateEnd}
              onChange={e => setDateEnd(e.value)}
            />
          </div>

          <div className="field col-12 md:col-2 flex align-items-end">
            <Button
              name="btn_search"
              label="Buscar"
              icon="fa-solid fa-search"
              type="submit"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-6">
            <label htmlFor="tags">Tags</label>
            <MultiSelect
              name="tags"
              options={statusOpts}
              value={situtation}
              filter
              placeholder="Selecionar..."
              onChange={e => setSituation(e.value)}
              tooltip={'Tags te auxiliam a gerar relatórios dos produtos'}
              tooltipOptions={{ position: 'bottom' }}
              display="chip"
            />
          </div>
        </Form>
      </div>
      <div className="card my-2">
        <DataTable
          value={itemsNfse}
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 30]}
          size="small"
          emptyMessage="O.S não encontradas..."
          selectionMode="single"
          loading={loadingStatus}
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} O.S"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column field="os_number" header="O.S" align={'center'}></Column>
          <Column field="customer" header="Cliente" align={'left'}></Column>
          <Column field="serie" header="SR" align={'center'}></Column>
          <Column field="lote" header="LT" align={'center'}></Column>
          <Column field="numero" header="Número" align={'center'}></Column>
          <Column
            field="emissao"
            header="Emissão"
            align={'center'}
            body={e => renderColumnDate(e.emissao)}
          ></Column>
          <Column
            field="autorizacao"
            header="Autorização"
            align={'center'}
            body={e => renderColumnDate(e.autorizacao)}
          ></Column>

          <Column
            field="total"
            header="Total"
            align={'center'}
            body={e => renderColumnDecimal(e.total)}
          ></Column>

          <Column
            field="situacao"
            header="Status"
            align={'center'}
            body={e => renderColumnSitutacao(e.situacao)}
          ></Column>
          <Column field="user_emit" header="Usuário" align={'center'}></Column>
          <Column header="*" body={e => renderCollumnMenu(e)}></Column>
        </DataTable>
      </div>
    </>
  );
};

export default NfseList;

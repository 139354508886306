import { useField } from '@unform/core';
import { InputNumber as Input, InputNumberProps } from 'primereact/inputnumber';
import React, { useEffect, useRef } from 'react';

interface PropsInput extends InputNumberProps {
  name: string;
}

export const InputNumber: React.FC<PropsInput> = ({
  name,
  placeholder,
  className,
  onFocus,
  ...rest
}) => {
  const inputRef = useRef(null);

  const { fieldName, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Input
      placeholder={placeholder}
      name={name}
      inputRef={inputRef}
      {...rest}
      className={error ? `p-invalid ${className}` : `${className}`}
      inputClassName="text-center"
      onFocus={onFocus ? e => onFocus(e) : e => e.target.select()}
    />
  );
};

import React, { useEffect, useState } from 'react';

/* react-router-dom */
import { useHistory } from 'react-router-dom';

/** services */
import api from '../../../services/api';

/* components */
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Paginator, PaginatorTemplate } from 'primereact/paginator';
import { DataTable } from '../../../components/DataTable';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import { InputNumber } from '../../../components/Inputs/InputNumber';
import { Dialog as ConfirmModal } from '../../../components/Modal';
import Progress from '../../../components/progress-bar';
import useToastContext from '../../../hooks/toast';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import { IProductPriceSearch } from '../../product/dtos/IProductDTO';
import { IProductPrice } from '../dtos/IProductPriceDTO';
import IProductsPricesDTO from '../dtos/IProductsPricesDTO';
import ProductPriceHeader from '../top-menu';
import { InputAutoComplete } from '../../../components/Inputs/InputAutoComplete';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import { nullItem } from '../../order/types/IOrderStore';
import { IProductRow } from '../../consigned/order/dtos/IConOrderDTO';

const ProductPriceList = (): React.ReactElement => {
  /** hooks */
  const toast = useToastContext();
  const navigate = useHistory();

  /** state */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [confirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);

  const [productsPrices, setProductsPrices] = useState<IProductPrice[]>([]);
  const [filteredProductsPrices, setFilteredProductsPrices] = useState<
    IProductPrice[]
  >([]);
  const [price, setPrice] = useState<IProductPrice>();

  const [itens, setItens] = useState<IProductsPricesDTO[]>([]);
  const [item, setItem] = useState<IProductRow>(nullItem);

  interface IFilter {
    product_id: string;
    table_prices: string[];
    price: number;
  }

  const emptyFilter: IFilter = {
    product_id: '',
    table_prices: [],
    price: 0,
  };

  const [filter, setFilter] = useState<IFilter>(emptyFilter);
  const [tablePrices, setTablePrices] = useState<IOptionsDTO[]>([]);

  // Pagination
  const [first, setFirst] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [totalRecordsFiltered, setTotalRecordsFiltered] = useState<number>(0);

  const [products, setProducts] = useState<IProductPriceSearch[]>([]);
  const [product, setProduct] = useState<IProductPriceSearch | null>(null);

  useEffect(() => {
    setFilteredProductsPrices(productsPrices);
    setTotalRecordsFiltered(totalRecords);
  }, [productsPrices]);

  useEffect(() => setTotalRecordsFiltered(totalRecords), [totalRecords]);

  const handleFilter = async () => {
    if (
      filter.product_id === '' &&
      filter.table_prices.length === 0 &&
      filter.price === 0
    ) {
      setFilteredProductsPrices(productsPrices);
      setTotalRecordsFiltered(totalRecords);
    } else {
      setLoadingStatus(true);

      await api
        .post('/products-prices/v2/search', {
          data: {
            ...filter,
          },
        })
        .then(({ data }) => {
          if (data) {
            setFilteredProductsPrices(
              data.map((pp: any) => {
                return {
                  ...pp,
                  vlr_venda: Number(pp.value),
                  status: pp.status,
                  tablePrice: {
                    id: pp.table_price_id,
                    title: pp.table_price,
                  },
                  product: {
                    id: pp.product_id,
                    title: pp.product,
                    sku: pp.sku,
                    codigo: pp.codigo,
                    decimalHouses: [{ price_out: pp.price_out }],
                  },
                } as IProductPrice;
              }),
            );

            setTotalRecordsFiltered(data.length);
          }
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
        })
        .finally(() => setLoadingStatus(false));
    }
  };

  /** functions */
  const handleDeleteProductPrice = async () => {
    if (price) {
      setLoadingStatus(true);
      await api
        .delete(`/products-prices/v2/${price.id}`)
        .then(data => {
          if (data.status >= 201 && data.status <= 299) {
            toast(
              'success',
              'Sucesso',
              `O preço do produto ${price.product.title} foi excluido com sucesso!`,
            );

            const newList = productsPrices.filter(p => p.id !== price.id);
            setProductsPrices(newList);
            setPrice({} as IProductPrice);
          }
        })
        .catch((e: any) =>
          toast('error', 'Erro', `Falha ao excluir o preço do produto! [${e}]`),
        )
        .finally(() => setLoadingStatus(false));

      setConfirmModalVisible(false);
    } else {
      toast('warn', 'Alerta', 'Selecione o item a ser excluido!');
      return;
    }
  };

  const itemTemplateProduct = (i: IProductPriceSearch) => {
    return (
      <div className="country-item">
        <div>{`${i.title} # ${i.sku}`}</div>
      </div>
    );
  };

  const handleOpenModalConfirmDelete = (id: string) => {
    setConfirmModalVisible(true);

    const price = filteredProductsPrices.find(e => e.id === id);

    if (price) {
      setPrice(price);
    }
  };
  /** render */

  const renderColumnPosition = (rowData: IProductsPricesDTO, attr: any) => {
    return <>{attr?.rowIndex + 1 + first}</>;
  };

  const renderColumnPrice = (rowData: IProductsPricesDTO) => {
    return (
      <>
        {new Intl.NumberFormat('pt-BR', {
          minimumFractionDigits:
            rowData.product.decimalHouses.length > 0
              ? rowData.product.decimalHouses[0].price_out
              : 2,
          maximumFractionDigits:
            rowData.product.decimalHouses.length > 0
              ? rowData.product.decimalHouses[0].price_out
              : 2,
        }).format(rowData.vlr_venda)}
      </>
    );
  };

  const renderColumnStatus = (rowData: IProductsPricesDTO) => {
    return rowData.status === 'A' ? (
      <i className="fa-solid fa-check"></i>
    ) : (
      <i className="fa-solid fa-xmark text-pink-500"></i>
    );
  };

  const renderColumnButtons = (rowData: IProductsPricesDTO) => {
    return (
      <div className="flex justify-content-center">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/products-prices/edit/${rowData.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => handleOpenModalConfirmDelete(rowData.id)}
        />
      </div>
    );
  };

  const renderConfirmDialogButtons = (
    <div className="flex justify-content-center">
      <Button
        label="Não"
        icon="pi pi-times"
        className="p-button-danger p-button-raised w-full"
        onClick={() => setConfirmModalVisible(false)}
      />
      <Button
        label="Sim"
        icon="pi pi-check"
        className="p-button-success p-button-raised w-full"
        onClick={() => handleDeleteProductPrice()}
      />
    </div>
  );

  const handleSelectProduct = (e: any) => {
    const p: IProductPriceSearch = e.value;
    if (p.prices.length === 0) {
      toast(
        'warn',
        'Alerta',
        `O produto: ${p.title} não tem nenhum preço cadastrado no sistema!`,
      );
      setItem(nullItem);
      return;
    }

    setFilter({ ...filter, product_id: p.id });

    setItem({
      ...item,
      value: p.id,
      label: p.title,
    });
  };

  const loadProductsPrices = async (page: number) => {
    setLoadingStatus(true);
    await api
      .post('/products-prices/v2/list', {
        pagination: { page, perPage: page === 1 ? 3000 : 100, status: ['A'] },
      })
      .then(({ data }) => {
        setProductsPrices([...productsPrices, ...data.products_prices]);
        setTotalRecords(data.totalRecords);
      })
      .catch((e: any) => {
        if (e.code === 'ERR_BAD_REQUEST') {
          toast('warn', 'Alerta', e.response.data.error);
        } else {
          toast('error', 'Erro', e.message);
        }
        setPage(prev => prev--);
      })
      .finally(() => setLoadingStatus(false));
  };

  const loadTablePrices = async () => {
    setLoadingStatus(true);

    await api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data && data.length > 0) {
          setTablePrices(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setLoadingStatus(false));
  };

  const searchProducts = async (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      await api
        .post(`/products/v2/prices/search`, {
          data: {
            keyword: event.query,
            show: { page: 1, perPage: 1000, status: 'A' },
          },
        })
        .then(({ data }) => {
          if (data && data.length > 0) {
            setProducts(data.map((i: IProductPriceSearch) => i));
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    }
  };

  const onPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
  };

  useEffect(() => {
    loadTablePrices();
    loadProductsPrices(1);
  }, []);

  useEffect(() => {
    if (page !== 1) loadProductsPrices(page);
  }, [page]);

  useEffect(() => {
    if (first + rows >= productsPrices.length) {
      setPage(prev => prev + 1);
    }
  }, [first, rows]);

  const template = {
    layout:
      'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown',

    FirstPageLink: (options: any) => {
      return (
        <Button
          type="button"
          icon="pi pi-angle-double-left"
          style={{
            background: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            color: 'var(--text-color)',
          }}
          onClick={options.onClick}
          disabled={options.disabled}
          tooltip="Primeira página"
        />
      );
    },
    PrevPageLink: (options: any) => {
      return (
        <Button
          type="button"
          icon="pi pi-angle-left"
          style={{
            background: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            color: 'var(--text-color)',
          }}
          onClick={options.onClick}
          disabled={options.disabled}
          tooltip="Página anterior"
        />
      );
    },
    NextPageLink: (options: any) => {
      return (
        <Button
          type="button"
          icon="pi pi-angle-right"
          style={{
            background: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            color: 'var(--text-color)',
          }}
          onClick={options.onClick}
          disabled={options.disabled}
          tooltip="Próxima página"
        />
      );
    },
    RowsPerPageDropdown: (options: any) => {
      return (
        <Form
          ref={null}
          onSubmit={() => null}
          className="p-ml-3"
          placeholder={''}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <InputDropDown
            name="rows"
            inputRef={null}
            value={rows}
            options={[
              { label: '10', value: 10 },
              { label: '20', value: 20 },
              { label: '30', value: 30 },
            ]}
            onChange={e => setRows(e.value)}
          />
        </Form>
      );
    },

    LastPageLink: () => {
      return (
        <Button
          type="button"
          icon="pi pi-angle-double-right"
          style={{
            background: 'rgba(0,0,0,0)',
            borderColor: 'rgba(0,0,0,0)',
            color: 'var(--text-color)',
          }}
          onClick={() => {
            setFirst(
              prev => prev - prev + filteredProductsPrices.length - rows,
            );
          }}
          tooltip="Última página"
        />
      );
    },
  };

  return (
    <>
      <ProductPriceHeader />

      <Progress isLoad={loadingStatus} />

      <div className="card mb-1 py-2">
        <Form
          ref={null}
          onSubmit={handleFilter}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <div className="field col-12 md:col-4">
            <label htmlFor="product_id">Produto</label>

            <InputAutoComplete
              name="product"
              value={item}
              field="label"
              completeMethod={e => searchProducts(e)}
              suggestions={products}
              onSelect={e => handleSelectProduct(e)}
              onChange={e => {
                if (e.value === '') setFilter({ ...filter, product_id: '' });
                setItem(e.value);
              }}
              itemTemplate={e => itemTemplateProduct(e)}
              placeholder="Buscar produto..."
              disabled={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="product_id">Tabela</label>
            <MultiSelect
              name="table_prices"
              value={filter.table_prices}
              options={tablePrices}
              onChange={e => setFilter({ ...filter, table_prices: e.value })}
              placeholder="Selecionar..."
            />
          </div>

          <div className="field col-12 md:col-2">
            <label htmlFor="price">Preço</label>
            <InputNumber
              name="price"
              value={filter.price}
              onChange={e => {
                if (e.value !== null) setFilter({ ...filter, price: e.value });
              }}
              onFocus={e => e.target.select()}
              placeholder="0,00"
              maxFractionDigits={2}
              minFractionDigits={2}
            />
          </div>

          <div className="field col-6 md:col-2 flex align-items-end">
            <Button
              label="Limpar"
              onClick={() => {
                setFilter(emptyFilter);
              }}
              type="button"
              icon="pi pi-times"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>
          <div className="field col-6 md:col-2 flex align-items-end">
            <Button
              name="submit"
              type="submit"
              label="Buscar"
              icon="fa-solid fa-search"
            />
          </div>
        </Form>
      </div>

      <div className="card mt-1">
        <DataTable
          value={filteredProductsPrices.slice(first, first + rows)}
          selection={item}
          onSelectionChange={e => setItem(e.value)}
          dataKey="id"
          lazy
          loading={loadingStatus}
          loadingIcon="pi pi-spinner text-primary"
          first={first}
          rows={rows}
          totalRecords={totalRecordsFiltered}
          size="small"
          responsiveLayout="scroll"
          className="datatable-responsive"
          emptyMessage="Nenhum preço encontrado..."
          currentPageReportTemplate="Mostrando de {first} até {last} de {totalRecords} preços"
        >
          <Column header="#" body={renderColumnPosition}></Column>
          <Column field="product.sku" header="Sku"></Column>
          <Column field="product.codigo" header="Código"></Column>
          <Column field="product.title" header="Produto"></Column>
          <Column field="tablePrice.title" header="Tabela"></Column>
          <Column header="Preço" body={e => renderColumnPrice(e)}></Column>
          <Column header="Status" body={e => renderColumnStatus(e)}></Column>
          <Column header="*" body={e => renderColumnButtons(e)}></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecordsFiltered}
          rowsPerPageOptions={[10, 20, 30]}
          onPageChange={onPage}
          currentPageReportTemplate="Mostrando de {first} até {last} de {totalRecords} preços"
          template={template as unknown as PaginatorTemplate}
        />
      </div>

      <ConfirmModal
        visible={confirmModalVisible}
        header="Excluir?"
        modal
        footer={renderConfirmDialogButtons}
        onHide={() => setConfirmModalVisible(false)}
        style={{ width: '384px' }}
      >
        <>
          A exclusão do preço do produto{' '}
          <strong>
            [{price && price.product ? price?.product.title : ''}]
          </strong>{' '}
          é irreversível. Deseja continuar?
        </>
      </ConfirmModal>
    </>
  );
};

export default ProductPriceList;

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import PersonsHeaderButtons from '../header-buttons';
import Progress from '../../../components/progress-bar';
import { TabPanel, TabView } from 'primereact/tabview';
import { InputText } from '../../../components/Inputs/InputText';
import { emptyPersonUpdate, ICity, IPersonUpdate } from '../dtos/IPerson';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import { cnpj } from 'cpf-cnpj-validator';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import tipoPessoaOpts from '../types/TipoPessoaOpts';
import statusCivilOpts from '../types/StatusCivilOpts';
import generoOpts from '../types/GeneroOpts';
import contribuinteOpts from '../types/ContribuinteOpts';
import regimeTributarioOpts from '../types/RegimeTributarioOpts';
import ufsOpts from '../types/UfsOpts';
import { InputMask } from '../../../components/Inputs/InputMask';
import Calendar from '../../../components/Inputs/InputCalendar';
import { Button } from 'primereact/button';
import { useHistory, useParams } from 'react-router-dom';
import { Divider } from '../../../components/Divider';
import { InputPhone } from '../../../components/Inputs/InputPhone';
import { InputTextArea } from '../../../components/Inputs/InputTextArea';
import useToastContext from '../../../hooks/toast';
import api, { apiCep, apiTS } from '../../../services/api';
import IOptionsDTO, { emptyOption } from '../../business/dtos/IOptionsDTO';
import ContentPedidos from './content-pedidos';
import ContentNfe from './content-nfe';
import ContentNfce from './content-nfce';
import ContentBillPayable from './content-bill-payable';
import ContentBillReceivable from './content-bill-receivable';
import * as Yup from 'yup';
import { AxiosError, AxiosResponse } from 'axios';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import LimpaCpfCnpj from '../../../utils/LimpaCpfCnpj';
import formatDecimal from '../../../utils/numbers/FormatDecimal';

const PersonUpdate: React.FC = () => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();
  const navigate = useHistory();

  const params: { id: string } = useParams();

  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [data, setData] = useState<IPersonUpdate>(emptyPersonUpdate);
  const [cities, setCities] = useState<ICity[]>([]);

  const loadCities = async () => {
    setIsLoad(true);
    await api
      .post(`/cities/list`, {
        pagination: { page: 1, perPage: 10000, status: ['A'] },
      })
      .then((res: AxiosResponse) => {
        if (res.data) {
          setCities(
            res.data.map((i: any) => {
              return {
                value: i.id,
                ibge: i.ibge,
                siafi: i.siafi,
                label: i.name,
                uf: i.uf.sigla,
              };
            }),
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const loadAddress = async (cep: string) => {
    if (!cep || cep.length < 8) {
      toast('warn', 'Alerta', 'Informe um CEP válido!');
      return;
    }

    setIsLoad(true);

    const clearCep = LimpaCpfCnpj(cep);

    await apiCep
      .get(`/${clearCep}/json/`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          const city = cities.find(i => i.ibge === res.data.ibge);
          setData({
            ...data,
            person: { ...data.person },
            contact: { ...data.contact },
            address: {
              ...data.address,
              post_code: cep,
              street: res.data.logradouro,
              complement:
                res.data.complemento !== ''
                  ? res.data.complemento
                  : data.address.complement,
              neighborhood: res.data.bairro,
              city_id: city.value,
              city: {
                ...data.address.city,
                uf: {
                  ...data.address.city?.uf,
                  sigla: city.uf,
                },
              },
            },
          });

          toast(
            'info',
            'Endereço',
            'Dados de endereço encontrados com sucesso!',
          );
        }
      })
      .catch((e: AxiosError) => {
        toast('warn', 'Alerta', messageRequestError(e));
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  const handleClearForm = () => {
    setData(emptyPersonUpdate);
  };

  useEffect(() => {
    if (LimpaCpfCnpj(data.address.post_code).length === 8) {
      loadAddress(data.address.post_code);
    }
  }, [data.address.post_code]);

  const handleSearchCnpj = async (doc: string) => {
    if (!cnpj.isValid(doc)) {
      toast(
        'warn',
        'Alerta',
        `O CNPJ informado: ${cnpj.format(doc)} não é válido!`,
      );
    } else {
      setIsLoad(true);
      const headers = { 'x-api-key': `${process.env.REACT_APP_TS_TOKEN_NEXT}` };

      await apiTS
        .get(`/cnpj/${LimpaCpfCnpj(doc)}`, { headers })
        .then((res: AxiosResponse) => {
          if (res.data) {
            if (res.data.situacao === 'BAIXADA')
              toast(
                'warn',
                'Alerta',
                `A empresa portadora do CNPJ ${doc} está baixada!`,
                6000,
              );

            const _obs = `Sócio: ${
              res.data.socios && res.data.socios.length > 0
                ? res.data.socios.map((i: any) => {
                    return `\n${i.nome} ~ ${i.qualificacao}`;
                  })
                : ''
            }, \nData abertura: ${
              res.data.data_abertura
            }, Capital sócial: ${formatDecimal(
              res.data.capital_social,
            )}, \nAtividades: ${
              res.data.atividades && res.data.atividades.length > 0
                ? res.data.atividades.map((i: any) => {
                    return `\n${i.codigo} - ${i.descricao}`;
                  })
                : ''
            }`;

            setData({
              person: {
                ...data.person,
                name: res.data.razao_social,
                nickname: res.data.fantasia,
                type: 'J',
                cpf_cnpj: res.data.cpf_cnpj,
                rg_ie: '',
                insc_municipal: '',
                insc_suframa: '',
                contribuinte: '0',
                regime_tributario: 'N',
                customer: 'S',
                provider: 'N',
                transporter: 'N',
                birthday: new Date(),
                block: 'N',
                civil_status: 'S',
                sex: 'O',
                obs:
                  _obs.length < 1024 ? _obs : `${_obs.substring(0, 1020)}...`,
              },
              address: {
                ...data.address,
                post_code: res.data.endereco.cep,
                complement: res.data.complemento,
                number: res.data.endereco.numero,
              },
              contact: {
                ...data.contact,
                phone_whatsapp: res.data.telefone,
                email: res.data.email,
              },
            });

            toast('info', 'Dados', 'Dados carregados na receita com sucesso!');
          }
        })
        .catch((e: AxiosError) => {
          toast(
            'warn',
            'Falha',
            `Falha ao buscar os dados da receita, preencha manualmente ou tente novamente mais tarde: ${e.message}`,
          );
        })
        .finally(() => {
          setIsLoad(false);
        });
    }
  };

  const handleSubmit = async (d: any) => {
    try {
      setIsLoad(true);
      formRef.current?.setErrors({});

      if (
        data.contact.phone_whatsapp === '' &&
        data.contact.phone_fixed === '' &&
        data.contact.phone_mobile === '' &&
        data.contact.phone_cel === ''
      ) {
        toast('warn', 'Alerta', 'Informe pelo menos 1 telefone para contato!');
        return;
      }

      const personSchema = Yup.object({
        id: Yup.string().uuid().required('Informe o id do cadastro.'),
        codigo: Yup.number().required('Informe um código válido!'),
        type: Yup.string().required('Informe se é pessoa física ou jurídica!'),
        customer: Yup.string().required('Informe se é Cliente Sim/Não.'),
        provider: Yup.string().required('Informe se é Fornecedor Sim/Não.'),
        transporter: Yup.string().required(
          'Informe se é Transportadora Sim/Não.',
        ),
        name: Yup.string()
          .min(3, 'Nome/Razão social deve ter no mínimo 3 caracteres.')
          .max(64, 'Nome/Razão social deve ter no máximo 64 caracteres.')
          .required('Informe o Nome/Razão Social'),
        nickname: Yup.string()
          .min(3, 'Apelido/Nome Fantasia deve ter no mínimo 3 caracteres.')
          .max(64, 'Apelido/Nome Fantasia deve ter no máximo 64 caracteres.'),
        cpf_cnpj: Yup.string().required('Informe um Cpf/Cnpj.'),
        rg_ie: Yup.string(),
        sex: Yup.string().when('type', {
          is: 'F',
          then: Yup.string().required('Por favor, informe seu sexo!'),
        }),
        birthday: Yup.date().when('type', {
          is: 'F',
          then: Yup.date()
            .typeError('Por favor, Informe uma data valida!')
            .max(new Date()),
        }),
        civil_status: Yup.string().when('type', {
          is: 'F',
          then: Yup.string().required('Por favor, informe seu status civil!'),
        }),
        block: Yup.string(),
        regime_tributario: Yup.string(),
        insc_suframa: Yup.string(),
        insc_municipal: Yup.string(),
        contribuinte: Yup.string().required(
          'Pro favor, informe o contribuinte!',
        ),
        obs: Yup.string(),
      });
      await personSchema.validate(data.person, { abortEarly: false });

      const addressSchema = Yup.object({
        post_code: Yup.string().required('Por favor, informe o cep!'),
        street: Yup.string().required('Por favor, informe a rua!'),
        number: Yup.string().required('Por favor, informe o numero!'),
        neighborhood: Yup.string().required('Por favor, informe o bairro'),
        complement: Yup.string(),
        city_id: Yup.string().required('Por favor, informe a cidade!'),
        city: Yup.object({
          uf: Yup.object({
            sigla: Yup.string(),
          }),
        }),
      });
      await addressSchema.validate(data.address, { abortEarly: false });

      const contactSchema = Yup.object({
        phone_cel: Yup.string(),
        phone_fixed: Yup.string(),
        phone_mobile: Yup.string(),
        phone_whatsapp: Yup.string(),
        email: Yup.string().email(),
        site: Yup.string().url(),
        facebook: Yup.string().url(),
        instagram: Yup.string().url(),
        youtube: Yup.string().url(),
        twitter: Yup.string().url(),
      });
      await contactSchema.validate(data.contact, { abortEarly: false });

      delete data.address.city;

      await api.put(`/persons`, {
        ...data,
      });

      toast('success', 'Sucesso', 'Registro salvo com sucesso!');

      navigate.push('/persons/list');
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', messageRequestError(e));
      }
    } finally {
      setIsLoad(false);
    }
  };

  const handleChangePersonStatus = async (isBlock: string) => {
    setIsLoad(true);

    await api
      .put(
        `/persons/${isBlock === 'S' ? 'disable' : 'enable'}/${data.person.id}`,
      )
      .then(({ data: res }) => {
        if (res) {
          toast(
            'success',
            'Sucesso',
            `Cliente ${
              isBlock === 'S' ? 'bloqueado' : 'desbloqueado'
            } com sucesso!`,
          );

          setData({ ...data, person: { ...data.person, block: isBlock } });
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const loadPerson = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/persons/${id}`)
      .then(res => {
        if (res) {
          if (res.data.codigo !== 1) {
            if (res.data.addresses) {
              setCities([
                {
                  value: res.data.addresses[0].city_id,
                  label: res.data.addresses[0].city.name,
                },
              ]);
            }

            setData({
              person: {
                id: res.data.id,
                codigo: res.data.codigo,
                type: res.data.type,
                customer: res.data.customer,
                provider: res.data.provider,
                transporter: res.data.transporter,
                name: res.data.name,
                nickname: res.data.nickname,
                cpf_cnpj: res.data.cpf_cnpj,
                rg_ie: res.data.rg_ie,
                sex: res.data.sex !== null ? res.data.sex : 'O',
                birthday: res.data.birthday,
                civil_status:
                  res.data.civil_status !== null ? res.data.civil_status : 'S',
                block: res.data.block,
                image_url: res.data.image_url,
                regime_tributario: res.data.regime_tributario,
                insc_suframa: res.data.insc_suframa,
                insc_municipal: res.data.insc_municipal,
                contribuinte: res.data.contribuinte,
                obs: res.data.obs,
              },
              address: {
                street: res.data.addresses[0].street,
                number: res.data.addresses[0].number,
                complement: res.data.addresses[0].complement,
                neighborhood: res.data.addresses[0].neighborhood,
                city_id: res.data.addresses[0].city_id,
                post_code: res.data.addresses[0].post_code,
                city: {
                  name: res.data.addresses[0].city.name,
                  uf: { sigla: res.data.addresses[0].city.uf.sigla },
                },
              },
              contact: {
                phone_cel: res.data.contacts[0].phone_cel || '',
                phone_fixed: res.data.contacts[0].phone_fixed || '',
                phone_mobile: res.data.contacts[0].phone_mobile || '',
                phone_whatsapp: res.data.contacts[0].phone_whatsapp || '',
                email: res.data.contacts[0].email || '',
                site: res.data.contacts[0].site || '',
                facebook: res.data.contacts[0].facebook || '',
                instagram: res.data.contacts[0].instagram || '',
                youtube: res.data.contacts[0].youtube || '',
                twitter: res.data.contacts[0].twitter || '',
              },
            });
          } else {
            toast(
              'info',
              'Alerta',
              'Você não pode alterar os dados do CONSUMIDOR padrão.',
              8000,
            );
            navigate.push('/persons/list');
          }
        }
      })
      .catch((e: any) => {
        toast(
          'error',
          'Erro',
          `Falha ao carregar os dados da entidade: ${e.message}`,
          8000,
        );
        return;
      })
      .finally(() => {
        setIsLoad(false);
      });
  };

  useEffect(() => {
    loadPerson(params.id);
    loadCities();
  }, []);

  return (
    <>
      <PersonsHeaderButtons />
      <Progress isLoad={isLoad} />
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
        className="card mt-0 pt-0"
      >
        <TabView className="mt-0 pt-0">
          <TabPanel header={`Cadastro`} leftIcon="fa-solid fa-user mr-2">
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-4">
                <label htmlFor="name">
                  {data.person.type === 'F' ? 'Nome' : 'Razão social'}
                </label>
                <InputText
                  name="name"
                  placeholder={`Ex.: ${
                    data.person.type === 'F'
                      ? 'João da Silva'
                      : 'Acme Industria Ltda'
                  }`}
                  value={data.person.name}
                  onChange={e =>
                    setData({
                      ...data,
                      person: { ...data.person, name: e.currentTarget.value },
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="dropdown">
                  {data.person.type === 'F' ? 'Apelido' : 'Nome Fantasia'}
                </label>
                <InputText
                  name="nickname"
                  placeholder={`Ex.: ${
                    data.person.type === 'F'
                      ? 'João Borracheiro'
                      : 'Acme Alimentos'
                  }`}
                  value={data.person.nickname}
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        nickname: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-2">
                <label htmlFor="dropdown">Código</label>
                <InputText
                  name="codigo"
                  disabled
                  className="surface-200 text-center"
                  value={data.person.codigo}
                />
              </div>

              <div className="field col-12 md:col-2 flex align-items-end">
                <InputSwitch
                  name="block"
                  className="mr-3"
                  checked={data.person.block}
                  trueValue="S"
                  falseValue="N"
                  onChange={e => handleChangePersonStatus(e.value.toString())}
                  disabled={isLoad}
                />
                <label htmlFor="block" className="m-0">
                  Bloqueado
                </label>
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="type">
                  Tipo
                  {data.person.type === 'J' &&
                    cnpj.isValid(data.person.cpf_cnpj) && (
                      <span
                        className="ml-2 p-tag cursor-pointer"
                        onClick={e => handleSearchCnpj(data.person.cpf_cnpj)}
                      >
                        <i className="fa-solid fa-search mr-1"></i>
                        Buscar Receita
                      </span>
                    )}
                </label>
                <InputDropDown
                  name="type"
                  options={tipoPessoaOpts}
                  value={data.person.type}
                  placeholder="Selecionar..."
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        type: e.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">
                  {data.person.type === 'F' ? 'CPF' : 'CNPJ'}
                </label>
                {data.person.type === 'F' && (
                  <InputMask
                    name="cpf_cnpj"
                    value={data.person.cpf_cnpj}
                    mask="999.999.999-99"
                    placeholder={'000.000.000-00'}
                    autoClear={false}
                    onChange={e =>
                      setData({
                        ...data,
                        person: {
                          ...data.person,
                          cpf_cnpj: e.value,
                        },
                      })
                    }
                  />
                )}
                {data.person.type === 'J' && (
                  <InputMask
                    name="cpf_cnpj"
                    value={data.person.cpf_cnpj}
                    mask="99.999.999/9999-99"
                    placeholder={'00.000.000/0000-00'}
                    autoClear={false}
                    onChange={e =>
                      setData({
                        ...data,
                        person: {
                          ...data.person,
                          cpf_cnpj: e.value,
                        },
                      })
                    }
                    onComplete={e => handleSearchCnpj(e.value || '')}
                    disabled={isLoad}
                  />
                )}
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">
                  {data.person.type === 'F' ? 'RG' : 'Insc. Estadual'}
                </label>
                {data.person.type === 'J' && (
                  <a
                    className="ml-2 p-tag cursor-pointer"
                    target="_blank"
                    rel="noreferrer"
                    href="http://www.sintegra.gov.br/"
                  >
                    <i className="fa-solid fa-search mr-1"></i>
                    Buscar Inscrição
                  </a>
                )}
                <InputText
                  name="rg_ie"
                  value={data.person.rg_ie}
                  placeholder={
                    data.person.rg_ie === 'F'
                      ? 'Ex.: 000.000.000'
                      : 'Ex.: 00.000.000.0'
                  }
                  keyfilter="int"
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        rg_ie: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="insc_municipal">Insc. Municipal</label>
                <InputText
                  name="insc_municipal"
                  value={data.person.insc_municipal}
                  placeholder={
                    data.person.type === 'F' ? '000.000.000' : '00.000.000.0'
                  }
                  keyfilter="int"
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        insc_municipal: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>

              {data.person.type === 'F' && (
                <>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="dropdown">Estado Civil</label>
                    <InputDropDown
                      name="civil_status"
                      options={statusCivilOpts}
                      value={data.person.civil_status}
                      placeholder="Selecionar..."
                      onChange={e =>
                        setData({
                          ...data,
                          person: {
                            ...data.person,
                            civil_status: e.value,
                          },
                        })
                      }
                    />
                  </div>
                  <div className="field col-12 md:col-3">
                    <label htmlFor="dropdown">Aniversário</label>
                    <Calendar
                      name="birthday"
                      dateFormat="dd/mm/yy"
                      showIcon={true}
                      value={new Date(data.person.birthday)}
                      placeholder={'00/00/00'}
                      maxDate={hoje}
                      onChange={e =>
                        setData({
                          ...data,
                          person: {
                            ...data.person,
                            birthday: e.value as Date,
                          },
                        })
                      }
                    />
                  </div>

                  <div className="field col-12 md:col-3">
                    <label htmlFor="dropdown">Sexo</label>
                    <InputDropDown
                      name="sex"
                      options={generoOpts}
                      value={data.person.sex}
                      placeholder="Selecionar..."
                      onChange={e =>
                        setData({
                          ...data,
                          person: {
                            ...data.person,
                            sex: e.value,
                          },
                        })
                      }
                    />
                  </div>
                </>
              )}

              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">Contribuinte</label>
                <InputDropDown
                  name="contribuinte"
                  value={data.person.contribuinte}
                  options={contribuinteOpts}
                  placeholder="Selecionar..."
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        contribuinte: e.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-2 flex align-items-center">
                <InputSwitch
                  name="customer"
                  className="mr-3"
                  checked={data.person.customer}
                  trueValue="S"
                  falseValue="N"
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        customer: e.value.toString(),
                      },
                    })
                  }
                />
                <label htmlFor="dropdown" className="m-0">
                  Cliente
                </label>
              </div>

              <div className="field col-12 md:col-2 flex align-items-center">
                <InputSwitch
                  name="provider"
                  className="mr-3"
                  trueValue="S"
                  falseValue="N"
                  checked={data.person.provider}
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        provider: e.value.toString(),
                      },
                    })
                  }
                />
                <label htmlFor="dropdown" className="m-0">
                  Fornecedor
                </label>
              </div>

              <div className="field col-12 md:col-2 flex align-items-center">
                <InputSwitch
                  name="transporter"
                  checked={data.person.transporter}
                  className="mr-3"
                  trueValue="S"
                  falseValue="N"
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        transporter: e.value.toString(),
                      },
                    })
                  }
                />
                <label htmlFor="dropdown" className="m-0">
                  Transportador
                </label>
              </div>

              <div className="field col-12 md:col-3 flex align-items-end">
                <label htmlFor="dropdown"></label>
                <Button
                  label="Análise De Crédito"
                  onClick={() => navigate.push('/credit-analise/create')}
                  loading={isLoad}
                  icon="fa-regular fa-credit-card"
                />
              </div>

              <Divider align="left">
                <span className="p-tag">Endereço</span>
              </Divider>

              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">CEP</label>
                <InputMask
                  mask="99.999-999"
                  name="post_code"
                  placeholder="Ex.: 00.000-000"
                  value={data.address.post_code || ''}
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        post_code: e.value.toString(),
                      },
                    })
                  }
                  disabled={isLoad}
                />
              </div>

              <div className="field col-12 md:col-2 flex align-items-end">
                <Button
                  label="Preencher"
                  type="button"
                  onClick={() => loadAddress(data.address.post_code)}
                  loading={isLoad}
                  icon="fa-solid fa-location-dot"
                />
              </div>
            </div>

            <div className="p-fluid grid formgrid mb-4">
              <div className="field col-12 md:col-6">
                <label htmlFor="street">Rua/Avenida</label>
                <InputText
                  name="street"
                  placeholder="Ex.: Rua da amizade"
                  value={data.address.street}
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        street: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="number">Número</label>
                <InputText
                  name="number"
                  placeholder="Ex.: 000"
                  value={data.address.number}
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        number: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="complement">Complemento | Referência</label>
                <InputText
                  name="complement"
                  placeholder="Ex.: Qnd 00 Lt 00, Apto 000"
                  value={data.address.complement}
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        complement: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-4">
                <label htmlFor="neighborhood">Bairro</label>
                <InputText
                  name="neighborhood"
                  placeholder="Ex.: Bairro da saudade"
                  value={data.address.neighborhood}
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        neighborhood: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-2">
                <label htmlFor="dropdown">UF (Estado)</label>
                <InputDropDown
                  name="state"
                  options={ufsOpts}
                  value={data.address.city?.uf.sigla}
                  placeholder="Selecionar..."
                  emptyMessage="Não existe UF..."
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        city: {
                          ...data.address.city,
                          uf: {
                            ...data.address.city?.uf,
                            sigla: e.value,
                          },
                        },
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="dropdown">Cidade (Munincípio)</label>
                <InputDropDown
                  name="city_id"
                  value={data.address.city_id || ''}
                  options={cities}
                  placeholder="Selecionar..."
                  emptyMessage="Não existe cidades listadas..."
                  onChange={e =>
                    setData({
                      ...data,
                      address: {
                        ...data.address,
                        city_id: e.value,
                      },
                    })
                  }
                />
              </div>

              <Divider align="left">
                <span className="p-tag">Dados fiscais</span>
              </Divider>

              <div className="field col-12 md:col-4">
                <label htmlFor="dropdown">Regime Tributário</label>
                <InputDropDown
                  name="regime_tributario"
                  value={data.person.regime_tributario}
                  options={regimeTributarioOpts}
                  placeholder="Selecionar..."
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        regime_tributario: e.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-4">
                <label htmlFor="insc_suframa">Inscrição suframa</label>
                <InputText
                  name="insc_suframa"
                  placeholder="Ex.: 000.000.00"
                  value={data.person.insc_suframa}
                  keyfilter="int"
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        insc_suframa: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>

              <Divider align="left">
                <span className="p-tag">Contato</span>
              </Divider>

              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">Whatsapp</label>
                <InputPhone
                  name="phone_whatsapp"
                  value={data.contact.phone_whatsapp}
                  placeholder="Ex.: (00) 00000-0000"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        phone_whatsapp: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">Telefone Fixo</label>
                <InputPhone
                  name="phone_fixed"
                  value={data.contact.phone_fixed}
                  placeholder="Ex.: (00) 00000-0000"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        phone_fixed: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">Telefone Celular 1</label>
                <InputPhone
                  name="phone_cel"
                  value={data.contact.phone_cel}
                  placeholder="Ex.: (00) 00000-0000"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        phone_cel: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-3">
                <label htmlFor="dropdown">Telefone Celular 2</label>
                <InputPhone
                  name="phone_mobile"
                  value={data.contact.phone_mobile}
                  placeholder="Ex.: (00) 00000-0000"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        phone_mobile: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">E-mail</label>
                <InputText
                  name="email"
                  type={'email'}
                  value={data.contact.email}
                  placeholder="Ex.: exemplo@email.com"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        email: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Site | Loja virtual</label>
                <InputText
                  name="site"
                  type={'url'}
                  value={data.contact.site}
                  placeholder="Ex.: https://"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        site: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Facebook</label>
                <InputText
                  name="facebook"
                  type={'url'}
                  value={data.contact.facebook}
                  placeholder="Ex.: https://"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        facebook: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Instagram</label>
                <InputText
                  name="instagram"
                  type={'url'}
                  value={data.contact.instagram}
                  placeholder="Ex.: https://"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        instagram: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </div>
            <div className="p-fluid grid formgrid">
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Youtube</label>
                <InputText
                  name="youtube"
                  type={'url'}
                  value={data.contact.youtube}
                  placeholder="Ex.: https://"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        youtube: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col-6">
                <label htmlFor="dropdown">Twiter</label>
                <InputText
                  name="twitter"
                  type={'url'}
                  value={data.contact.twitter}
                  placeholder="Ex.: https://"
                  onChange={e =>
                    setData({
                      ...data,
                      contact: {
                        ...data.contact,
                        twitter: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
              <div className="field col-12 md:col">
                <label htmlFor="dropdown">Observações Adicionais</label>
                <InputTextArea
                  name="obs"
                  value={data.person.obs}
                  placeholder="Ex.: Entregar a mercadoria na vendinha da esquina..."
                  rows={10}
                  cols={30}
                  maxLength={1024}
                  onChange={e =>
                    setData({
                      ...data,
                      person: {
                        ...data.person,
                        obs: e.currentTarget.value,
                      },
                    })
                  }
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Pedidos" leftIcon="fa-solid fa-list mr-2">
            <ContentPedidos customer_id={params.id} />
          </TabPanel>
          <TabPanel header="Nfe" leftIcon="fa-solid fa-list mr-2">
            <ContentNfe customer_id={params.id} />
          </TabPanel>
          <TabPanel header="Nfce" leftIcon="fa-solid fa-list mr-2">
            <ContentNfce customer_id={params.id} />
          </TabPanel>
          <TabPanel header="Contas a pagar" leftIcon="fa-solid fa-list mr-2">
            <ContentBillPayable provider_id={params.id} />
          </TabPanel>
          <TabPanel header="Contas a receber" leftIcon="fa-solid fa-list mr-2">
            <ContentBillReceivable customer_id={params.id} />
          </TabPanel>
        </TabView>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              onClick={e => handleClearForm()}
              className="p-button-danger"
              loading={isLoad}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={isLoad}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default PersonUpdate;

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { MultiSelect } from '../../../components/Inputs/InputMultSelect';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getErrorsValidation';
import openReports from '../../../utils/openReports';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import ProductPriceHeader from '../../product-price/top-menu';
import { InputSwitch } from '../../../components/Inputs/InputSwitch';
import messageRequestError from '../../../utils/messageRequestError';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import formatCurrency from '../../../utils/numbers/FormatCurrency';
import formatDecimal from '../../../utils/numbers/FormatDecimal';

const ReportTablePrice = (): React.ReactElement => {
  /** const */
  /** hooks */
  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  /** useState */
  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);

  const [tablePriceOpts, setTablePriceOpts] = useState<Array<IOptionsDTO>>([]);
  const [tablePrice, setTablePrice] = useState<IOptionsDTO>(tablePriceOpts[0]);

  const [categoriesOpts, setCategoriesOpts] = useState<Array<IOptionsDTO>>([]);
  const [categories, setCategories] = useState<Array<string>>([]);

  const [tagsOpts, setTagsOpts] = useState<Array<IOptionsDTO>>([]);
  const [tags, setTags] = useState<Array<string>>([]);

  const [brandsOpts, setBrandsOpts] = useState<Array<IOptionsDTO>>([]);
  const [brands, setBrands] = useState<Array<string>>([]);

  const [simplified, setSimplified] = useState(false);

  const [statusOpts, setStatusOpts] = useState<Array<IOptionsDTO>>([
    { label: 'Com estoque', value: 'C' },
    { label: 'Sem estoque', value: 'S' },
    { label: 'Todas as opções', value: 'T' },
  ]);
  const [status, setStatus] = useState<string>('T');

  const exportExcel = async () => {
    try {
      setLoadingStatus(true);

      const schema = Yup.object({
        table_price: Yup.string()
          .uuid('Informe um id valido para a tabela de preços!')
          .required('Tabela de preços é obrigatório!'),
        status: Yup.string().required('Status é obrigatório!'),
      });
      await schema.validate(
        { table_price: tablePrice, status },
        { abortEarly: false },
      );

      await api
        .get(`/table-prices/excel/${tablePrice}`)
        .then(({ data }) => {
          if (data) {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet(
              'Webger - Relatório tabela de Preços',
            );

            worksheet.addRow(['Webger - Tabela de preços']);
            worksheet.addRow(['']);
            worksheet.addRow([`Tabela de preços:`, data.title]);

            let tableRows = [];

            let index = 0;
            for (const item of data.rows) {
              index++;
              tableRows.push([index, item.sku, formatDecimal(item.vlr_venda)]);
            }

            worksheet.addTable({
              name: 'Preços cadastrados na tabela',
              ref: 'A6',
              headerRow: true,
              totalsRow: false,
              columns: [
                { name: '#', filterButton: true },
                { name: 'SKU', filterButton: false },
                { name: 'VALOR', filterButton: false },
              ],
              rows: tableRows,
            });

            worksheet.columns = [
              {
                style: {
                  alignment: { vertical: 'middle', horizontal: 'center' },
                },
                width: 10,
              },
              {
                style: {
                  alignment: { vertical: 'middle', horizontal: 'center' },
                },
                width: 10,
              },
              {
                style: {
                  alignment: { vertical: 'middle', horizontal: 'center' },
                },
                width: 10,
              },
            ];

            worksheet.addRow(['']);
            worksheet.addRow([`PRODUTOS:`, data.rows.length]);

            workbook.xlsx.writeBuffer().then(buffer => {
              const blob = new Blob([buffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              });

              saveAs(blob, 'webger-relatorio-tabela-precos.xlsx');
            });
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  /** functions */
  const handleSubmit = async (d: any) => {
    try {
      setLoadingStatus(true);
      const schema = Yup.object({
        table_price: Yup.string()
          .uuid('Informe um id valido para a tabela de preços!')
          .required('Tabela de preços é obrigatório!'),
        status: Yup.string().required('Status é obrigatório!'),
      });
      await schema.validate(d, { abortEarly: false });

      if (simplified) {
        const data = await api.get(
          `/reports/table-prices/simplified/${tablePrice}`,
        );
        if (data.status >= 200 || data.status <= 299) {
          toast('success', 'Sucesso', 'Relatório gerado com sucesso!');
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.data.fileName}`,
            'webger-tabela-precos-simplificado',
          );
        }
      } else {
        const data = await api.post('/reports/table-prices', {
          tablePriceId: tablePrice,
          categories: categories,
          brands: brands,
          tags: tags,
          withStock: status,
        });
        if (data.status >= 200 || data.status <= 299) {
          toast('success', 'Sucesso', 'Relatório gerado com sucesso!');
          openReports(
            `${process.env.REACT_APP_SERVER_URL}/files/pdf/${data.data.fileName}`,
            'webger-tabela-precos',
          );
        }
      }
    } catch (e: any) {
      if (e instanceof Yup.ValidationError) {
        const errors = getValidationErrors(e);
        formRef.current?.setErrors(errors);
        toast('error', 'Error', e.errors[0]);
      } else {
        toast('error', 'Error', e.response?.data?.error);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  const listTablePrices = async () => {
    setLoadingStatus(true);
    await api
      .post('/table-prices/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        setTablePriceOpts(
          data.map((i: any) => {
            return {
              value: i.id,
              label: i.title,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao listar as tabelas de preços!');
      })
      .finally(() => setLoadingStatus(false));
  };

  const listCategories = async () => {
    setLoadingStatus(true);
    await api
      .get('/groups')
      .then(({ data }) => {
        setCategoriesOpts(
          data.map((i: any) => {
            return {
              label: i.title,
              value: i.id,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao listar as categorias!');
      })
      .finally(() => setLoadingStatus(false));
  };

  const listTags = async () => {
    setLoadingStatus(true);
    await api
      .get('/tags')
      .then(({ data }) => {
        setTagsOpts(
          data.map((i: any) => {
            return {
              label: i.tag,
              value: i.id,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao listar as tags!');
      })
      .finally(() => setLoadingStatus(false));
  };

  const listBrands = async () => {
    setLoadingStatus(true);
    await api
      .get('/brands')
      .then(({ data }) => {
        setBrandsOpts(
          data.map((i: any) => {
            return {
              label: i.title,
              value: i.id,
            };
          }),
        );
      })
      .catch((e: any) => {
        toast('error', 'Erro', 'Falha ao listar as marcas!');
      })
      .finally(() => setLoadingStatus(false));
  };

  /** useEffect */
  useEffect(() => {
    listTablePrices();
    listCategories();
    listTags();
    listBrands();
  }, []);

  return (
    <>
      <ProductPriceHeader />
      <Form ref={formRef} onSubmit={d => handleSubmit(d)} className="card w-6" placeholder={''} onPointerEnterCapture={null} onPointerLeaveCapture={null}>
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <label htmlFor="table_price">Tabela de preços</label>
            <InputDropDown
              name="table_price"
              options={tablePriceOpts}
              value={tablePrice}
              placeholder="Selecionar..."
              onChange={e => setTablePrice(e.value)}
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="categories">Categorias</label>
            <MultiSelect
              name="categories"
              placeholder="Selecionar..."
              options={categoriesOpts}
              value={categories}
              onChange={e => setCategories(e.value)}
              display="chip"
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="brands">Marcas</label>
            <MultiSelect
              name="brands"
              placeholder="Selecionar..."
              options={brandsOpts}
              value={brands}
              onChange={e => setBrands(e.value)}
              display="chip"
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="tags">Tags</label>
            <MultiSelect
              name="tags"
              placeholder="Selecionar..."
              options={tagsOpts}
              value={tags}
              onChange={e => setTags(e.value)}
              display="chip"
            />
          </div>

          <div className="field col-12 md:col-12">
            <label htmlFor="status">Estoque</label>
            <InputDropDown
              name="status"
              options={statusOpts}
              value={status}
              placeholder="Selecionar..."
              onChange={e => setStatus(e.value)}
            />
          </div>

          <div className="field col-6 mb-1 pb-1">
            <div className="flex justify-content-start py-2">
              <InputSwitch
                name="simplified"
                trueValue={true}
                falseValue={false}
                checked={simplified}
                onChange={e => setSimplified(e.value)}
              ></InputSwitch>
              <span className="block pl-2">Simplificado</span>
            </div>
          </div>
        </div>

        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-4">
            <Button
              label="Limpar"
              type="reset"
              icon="fa-solid fa-xmark"
              className="p-button-danger"
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-4">
            <Button
              label="Exportar excel"
              icon="fa-solid fa-file-export"
              className="p-button-success"
              type="button"
              onClick={() => exportExcel()}
              loading={loadingStatus}
            />
          </div>

          <div className="field col-12 md:col-4">
            <Button
              label="Imprimir"
              icon="fa-solid fa-print"
              className="p-button-primary"
              type="submit"
              loading={loadingStatus}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default ReportTablePrice;

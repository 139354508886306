import { Form } from '@unform/web';
import { Divider } from '../../Divider';
import { useEffect, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import useToastContext from '../../../hooks/toast';

import * as Yup from 'yup';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import { v4, validate } from 'uuid';
import { useOrder } from '../../../hooks/useOrder';
import { IProductRow } from '../../../pages/consigned/order/dtos/IConOrderDTO';
import { nullItem } from '../../../pages/order/types/IOrderStore';
import { InputAutoComplete } from '../../Inputs/InputAutoComplete';
import { AutoCompleteCompleteMethodParams } from 'primereact/autocomplete';
import api from '../../../services/api';
import {
  IPrice,
  IProductPriceSearch,
} from '../../../pages/product/dtos/IProductDTO';
import IOptionsDTO from '../../../pages/business/dtos/IOptionsDTO';
import { Tag } from 'primereact/tag';
import formatDecimal from '../../../utils/numbers/FormatDecimal';
import { InputDropDown } from '../../Inputs/InputDropDown';
import formatCurrency from '../../../utils/FormatCurrency';
import { InputNumber } from '../../Inputs/InputNumber';
import { Button } from 'primereact/button';
import { SelectButton } from 'primereact/selectbutton';
import toNumber from '../../../utils/toNumber';
import { DataTable } from '../../DataTable';
import { DataTableRowClassNameOptions } from 'primereact/datatable';
import renderColumnPosition from '../../DataTableColumns/RenderColumnPosition';
import { Column } from 'primereact/column';
import renderColumnDecimal from '../../DataTableColumns/RenderColumnDecimal';
import { InputTextArea } from '../../Inputs/InputTextArea';
import TooltipImage from '../../TooltipImage';
import { Image } from 'primereact/image';
import { ReactComponent as NoImage } from '../../../assets/icons/no-image.svg';
import { IOrderBody } from '../../../pages/order/Create/dtos/IOrderDTO';

interface IProps {
  show: {
    title: boolean;
    obs: boolean;
  };
  setup: {
    isKeyboard: boolean;
  };
  className?: string;
}

const InputRowProductOrder: React.FC<IProps> = ({
  show,
  setup,
  className,
}: IProps) => {
  /** refs & focus */
  const formRef = useRef<FormHandles>(null);
  const productRef = useRef<HTMLInputElement>(null);
  const qntRef = useRef<HTMLInputElement>(null);

  const [productFocus, setProductFocus] = useState<boolean>(true);
  const [qntFocus, setQntFocus] = useState<boolean>(true);

  /** toast */
  const toast = useToastContext();

  /** context */
  const { order, addProduct, remProduct, stockParams } = useOrder();

  /** states */
  const [load, setLoad] = useState(false);
  const today = new Date();

  const [products, setProducts] = useState<IProductPriceSearch[]>([]);
  const [product, setProduct] = useState<IProductPriceSearch | null>(null);

  const [item, setItem] = useState<IProductRow>({ ...nullItem, id: '' });
  const [stock, setStock] = useState<number>(0);

  const [qnt, setQnt] = useState<number>(1);
  const [vlrUnit, setVlrUnit] = useState<number>(0);
  const [desc, setDesc] = useState<number>(0);
  const [add, setAdd] = useState<number>(0);
  const [vlrLiq, setVlrLiq] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);

  const addDescType: IOptionsDTO[] = [
    { label: '$', value: 'V' },
    { label: '%', value: 'P' },
  ];

  const [addType, setAddType] = useState<string>('V');
  const [descType, setDescType] = useState<string>('V');

  const [tooltipAdd, setTooltipAdd] = useState<string>('');
  const [tooltipDesc, setTooltipDesc] = useState<string>('');

  const [tooltipImageVisible, setTooltipImageVisible] = useState<{
    [key: string]: boolean;
  }>({});

  /** effects */
  useEffect(() => {
    calculateRow();
  }, [product, qnt, vlrUnit, add, desc, addType, descType]);

  /** functions */

  const handleAddProduct = async () => {
    setLoad(true);

    try {
      formRef.current?.setErrors({});

      const schema = Yup.object({
        product_id: Yup.string()
          .uuid('Informe um produto para adicionar à venda!')
          .required('Informe um produto para adicionar à venda!'),
        table_price_id: Yup.string()
          .uuid('Informe uma tabela de preços para o produto!')
          .required('Informe uma tabela de preços para o produto!'),
        qnt: Yup.number()
          .positive('A quantidade do produto não pode ser negativa!')
          .required('Informe uma quantidade!'),
      });

      await schema.validate(item, { abortEarly: false });

      // setItem({ ...item, pos: order.orderBody.length });

      const _item = {
        tt_unit: item.total,
        adic: item.adic,
        ali_juros: 0,
        desc: item.desc,
        image_url: item.image_url,
        id: item.id,
        inserted_at: today,
        juros: 0,
        obs: item.obs,
        position: order.orderBody.length,
        product_description: item.product_description,
        product_id: item.product_id,
        qnt: item.qnt,
        sku: item.sku,
        stock: item.stock,
        table_price_description: item.table_price_description,
        table_price_id: item.table_price_id,
        tt_adic: item.adic,
        tt_desc: item.desc,
        tt_juros: 0,
        vlr_liq: item.vlr_liq,
        vlr_liq_total: item.total,
        vlr_unit: item.vlr_unit,
        qnt_out: item.qnt_out,
        price_out: item.price_out,
      } as IOrderBody;

      addProduct(_item);

      clearRow();
    } catch (err: any) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);
        toast('error', 'Erro', err.errors[0]);
      } else {
        toast('error', 'Erro', messageRequestError(err));
      }
    } finally {
      setLoad(false);
    }
  };

  const handleRemProduct = (id: string) => {
    remProduct(id);
  };

  const searchProducts = async (event: AutoCompleteCompleteMethodParams) => {
    if (event.query.length >= 3) {
      await api
        .post(`/products/v2/prices/search`, {
          data: {
            keyword: event.query,
            show: { page: 1, perPage: 1000, status: 'A' },
          },
        })
        .then(({ data }) => {
          if (data && data.length > 0) {
            setProducts(data.map((i: IProductPriceSearch) => i));
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    }
  };

  const searchProductById = async (id: string) => {
    if (validate(id)) {
      await api
        .get(`/products/v2/prices/${id}`)
        .then(({ data }) => {
          if (data) {
            setProduct({ ...data });
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
        });
    }
  };

  const handleSelectProduct = (e: any) => {
    const p: IProductPriceSearch = e.value;
    if (p.prices.length === 0) {
      toast(
        'warn',
        'Alerta',
        `O produto: ${p.title} não tem nenhum preço cadastrado no sistema!`,
      );
      setItem(nullItem);
      return;
    }

    let price = p.prices.find(
      i => i.table_price_id === order.orderHeader.table_price_id,
    );

    if (!price) {
      price = p.prices[0];
    }

    setProduct(p);
    setStock(Number(p.stock));
    setQnt(1);
    setVlrUnit(Number(price.vlr_venda || 0));
    setAdd(0);
    setDesc(0);
    setVlrLiq(0);
    setTotal(0);

    const _lucre = Number(price.vlr_venda) - Number(p.cost_billing);
    const _lucreAliq = (_lucre * 100) / Number(price.vlr_venda);

    setItem({
      ...item,
      id: v4(),
      product_id: p.id,
      value: p.id,
      product_description: p.title,
      label: p.title,
      table_price_id: price.table_price_id,
      table_price_description: price.tablePrice.title,
      stock: p.stock || 0,
      lucre: _lucre,
      lucre_aliq: _lucreAliq,
      qnt: qnt,
      vlr_unit: Number(price.vlr_venda),
      qnt_out: Number(p.decimalHouses[0]?.qnt_out) || 2,
      price_out: Number(p.decimalHouses[0]?.price_out) || 2,
      total: Number(price.vlr_venda) * qnt,
      image_url:
        p.productImages &&
        p.productImages.length > 0 &&
        p.productImages[0].image_url
          ? p.productImages[0].image_url
          : null,
    });
  };

  const handleRowSelect = async (e: any) => {
    const p: IProductRow = e.data;

    if (p) {
      setItem({
        ...p,
        label: p.product_description,
      });

      await searchProductById(p.product_id);

      setStock(Number(p?.stock));
      setQnt(Number(p.qnt));
      setVlrUnit(Number(p.vlr_unit));
      setAdd(Number(p.adic));
      setDesc(Number(p.desc));

      productRef.current?.focus();
      setProductFocus(true);
    }
  };

  const handleSelectTablePrice = (e: any) => {
    const pp = product?.prices.find(
      (i: IPrice) => i.table_price_id === e.value,
    );

    if (pp) {
      const _lucre = Number(pp.vlr_venda) - Number(product?.cost_billing);
      const _lucreAliq = (_lucre * 100) / Number(pp.vlr_venda);

      setItem({
        ...item,
        table_price_id: pp.table_price_id,
        table_price_description: pp.tablePrice.title,
        lucre: _lucre,
        lucre_aliq: _lucreAliq,
        vlr_unit: pp.vlr_venda,
      });

      setVlrUnit(pp.vlr_venda);
    }
  };

  const calculateRow = () => {
    const _add = addType === 'V' ? add : vlrUnit * (Number(add) / 100);
    const _desc = descType === 'V' ? desc : vlrUnit * (Number(desc) / 100);

    const _liq = Number(vlrUnit || 0) + Number(_add || 0) - Number(_desc || 0);

    const _addAliq = addType === 'P' ? _add : (add * 100) / vlrUnit;
    const _descAliq = descType === 'P' ? _desc : (desc * 100) / vlrUnit;
    const _total = _liq * Number(qnt);

    if (add === 0) {
      setTooltipAdd('');
    } else {
      if (addType === 'V') {
        setTooltipAdd(
          `R$${formatCurrency(add)} equivale a ${formatCurrency(
            _addAliq,
          )}% de adicional`,
        );
      } else {
        setTooltipAdd(
          `${formatCurrency(add)}% equivale a R$${formatCurrency(
            _add,
          )} de adicional`,
        );
      }
    }

    if (desc === 0) {
      setTooltipDesc('');
    } else {
      if (descType === 'V') {
        setTooltipDesc(
          `R$${formatCurrency(desc)} equivale a ${formatCurrency(
            _descAliq,
          )}% de desconto`,
        );
      } else {
        setTooltipDesc(
          `${formatCurrency(desc)}% equivale a R$${formatCurrency(
            _desc,
          )} de desconto`,
        );
      }
    }

    setVlrLiq(Number(_liq));
    setTotal(Number(_total));

    setItem({
      ...item,
      qnt: qnt,
      vlr_unit: vlrUnit,
      adic: _add,
      desc: _desc,
      adic_aliq: _addAliq,
      desc_aliq: _descAliq,
      vlr_liq: toNumber(_liq),
      total: toNumber(_total),
    });
  };

  const clearRow = () => {
    setItem(nullItem);
    setProduct(null);
    setQnt(0);
    setVlrUnit(0);
    setAdd(0);
    setDesc(0);
    setVlrLiq(0);
    setTotal(0);
    setStock(0);
  };

  /** templates */

  const itemTemplateProduct = (i: IProductPriceSearch) => {
    return (
      <div className="country-item">
        <div>{`${i.title} # ${i.sku}`}</div>
      </div>
    );
  };

  const addTemplate = (option: IOptionsDTO) => {
    if (option.value === 'V') {
      return (
        <div
          className="flex w-full justify-content-center font-bold p-1"
          style={{ fontSize: '9px' }}
        >
          <p className="mt-1">{`$${
            add > 0 ? formatCurrency(addType === 'V' ? add : item.adic) : ''
          }`}</p>
        </div>
      );
    } else {
      return (
        <div
          className="flex w-full justify-content-center font-bold p-1"
          style={{ fontSize: '9px' }}
        >
          <p className="mt-1">
            {`${
              add > 0
                ? formatCurrency(
                    addType === 'P'
                      ? add
                      : item.adic_aliq !== null
                      ? item.adic_aliq
                      : 0,
                  )
                : ''
            }%`}
          </p>
        </div>
      );
    }
  };

  const descTemplate = (option: IOptionsDTO) => {
    if (option.value === 'V') {
      return (
        <div
          className="flex w-full justify-content-center font-bold p-1"
          style={{ fontSize: '9px' }}
        >
          <p className="mt-1">{`$${
            desc > 0 ? formatCurrency(descType === 'V' ? desc : item.desc) : ''
          }`}</p>
        </div>
      );
    } else {
      return (
        <div
          className="flex w-full justify-content-center font-bold p-1"
          style={{ fontSize: '9px' }}
        >
          <p className="mt-1">
            {`${
              desc > 0
                ? formatCurrency(
                    descType === 'P'
                      ? desc
                      : item.desc_aliq !== null
                      ? item.desc_aliq
                      : 0,
                  )
                : ''
            }%`}
          </p>
        </div>
      );
    }
  };

  const rowClassName = (
    data: any,
    options: DataTableRowClassNameOptions,
  ): string | object => {
    return stockParams &&
      stockParams.consome_estoque_prod === true &&
      Number(data.stock) < Number(data.qnt)
      ? 'highlighted-row'
      : ' ';
  };

  const renderCollumnMenu = (id: string) => {
    return (
      <>
        <Button
          type="button"
          icon="fa-solid fa-xmark"
          onClick={() => handleRemProduct(id)}
          className="p-button-danger p-button-rounded"
        />
      </>
    );
  };

  const renderProductObs = (obs: string | null) => {
    return (
      <div className="flex justify-content-start" style={{ maxWidth: '15vw' }}>
        <span>{obs !== '' && obs !== null ? obs : '-'}</span>
      </div>
    );
  };

  const renderProductDescription = (id: string) => {
    const product = order.orderBody.find(p => p.id === id);

    if (product) {
      return (
        <div
          onMouseEnter={() =>
            setTooltipImageVisible(prevState => ({ ...prevState, [id]: true }))
          }
          onMouseLeave={() =>
            setTooltipImageVisible(prevState => ({ ...prevState, [id]: false }))
          }
          className="flex align-items-center"
        >
          <p className="m-0">{product.product_description}</p>

          <TooltipImage
            image_url={product.image_url || null}
            show={tooltipImageVisible[id]}
            position="bottom"
          />
        </div>
      );
    }
  };

  return (
    <>
      {show.title && (
        <Divider align="left" type="dashed">
          <div className="inline-flex align-items-center">
            <span className="p-tag">PRODUTOS</span>
          </div>
        </Divider>
      )}

      <Form
        ref={formRef}
        onSubmit={() => {
          handleAddProduct();
        }}
        className={`p-fluid grid formgroup ${className ? className : ''}`}
        placeholder={''}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div className="field col-12 flex mb-0">
          {item.image_url &&
            item.image_url !== '' &&
            item.image_url !== null && (
              <div
                className="flex align-items-center justify-content-center ml-1 bg-gray-700"
                style={{ height: '84px', width: '84px' }}
              >
                <Image
                  className="flex"
                  alt={item.product_description}
                  height="80"
                  width="80"
                  preview
                  src={`${process.env.REACT_APP_SERVER_URL}/files/img/${item.image_url}`}
                />
              </div>
            )}

          {!(
            item.image_url &&
            item.image_url !== '' &&
            item.image_url !== null
          ) && (
            <NoImage
              fill="#ccc"
              className="ml-1"
              style={{ height: '84px', width: '84px' }}
            />
          )}

          {show.obs && (
            <div
              className="ml-2 p-1 md:p-2"
              style={{ flexGrow: 1, marginTop: '-10px' }}
            >
              <label htmlFor="obs">Observações do produto</label>
              <InputTextArea
                name="obs"
                value={item.obs || ''}
                onChange={e => setItem({ ...item, obs: e.currentTarget.value })}
                placeholder="Ex: Da cor vermelha com suporte..."
                style={{ height: '70px' }}
                disabled={
                  !validate(order.orderHeader.customer_id || '') ||
                  !validate(item.id)
                }
              />
            </div>
          )}
        </div>

        <div className="field col-12 md:col-3 md:m-0 md:p-2 md:pl-3 p-1 m-0">
          <label htmlFor="product">Produto</label>
          <InputAutoComplete
            inputRef={productRef}
            name="product"
            value={item}
            field="label"
            completeMethod={e => searchProducts(e)}
            suggestions={products}
            onSelect={e => handleSelectProduct(e)}
            onChange={e => {
              setItem(e.value);
            }}
            itemTemplate={e => itemTemplateProduct(e)}
            placeholder="Buscar produto..."
            autoFocus={productFocus}
            onKeyPress={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                setQntFocus(true);
                qntRef?.current?.focus();
              }
            }}
            disabled={!validate(order.orderHeader.customer_id || '')}
            className={
              !validate(order.orderHeader.customer_id || '')
                ? 'surface-100'
                : ''
            }
          />
          <Tag
            severity={
              stockParams &&
              stockParams.consome_estoque_prod === true &&
              (qnt > Number(stock) || Number(stock) <= 0)
                ? 'danger'
                : qnt < Number(stock) &&
                  stockParams &&
                  stockParams.consome_estoque_prod === true
                ? 'info'
                : 'success'
            }
            value={`Estoque: ${formatDecimal(product?.stock || 0)} `}
            className="py-0"
          ></Tag>
        </div>

        <div className="field col-6 md:col-2 md:m-0 md:p-2 p-1 m-0">
          <label htmlFor="table_price">Tabela de preço</label>
          <InputDropDown
            name="table_price"
            options={product?.prices.map((i: IPrice) => {
              return {
                value: i.table_price_id,
                label: i.tablePrice.title,
              };
            })}
            value={item?.table_price_id}
            onChange={e => handleSelectTablePrice(e)}
            disabled={
              product?.prices.length === 0 ||
              !validate(order.orderHeader.customer_id || '') ||
              !validate(item.id)
            }
            className={product?.prices.length === 0 ? `surface - 200` : ``}
            placeholder="Selecionar..."
          />
          <Tag
            severity={
              Number(item?.lucre) < 0
                ? 'danger'
                : Number(item?.lucre) >= 0
                ? 'success'
                : 'info'
            }
            value={`Lcr.: ${formatCurrency(item?.lucre || 0)} ~${formatDecimal(
              item?.lucre_aliq || 100,
            )}% `}
            className="py-0"
          ></Tag>
        </div>
        <div className="field col-3 md:col-1 md:m-0 md:p-2 p-1 m-0">
          <label htmlFor="qnt">Qnt.</label>
          <InputNumber
            inputRef={qntRef}
            name="qnt"
            value={qnt}
            onChange={e => {
              setQnt(Number(e.value));
            }}
            mode="decimal"
            minFractionDigits={item.qnt_out}
            placeholder="0,00"
            maxFractionDigits={item.qnt_out}
            autoFocus={qntFocus}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                formRef.current?.submitForm();

                setQntFocus(false);
                setProductFocus(true);
                productRef.current?.focus();
              }
            }}
            disabled={
              product?.prices.length === 0 ||
              !validate(order.orderHeader.customer_id || '') ||
              !validate(item.id)
            }
            className={product?.prices.length === 0 ? `surface - 200` : ``}
          />
        </div>
        <div className="field col-3 md:col-1 md:m-0 md:p-2 p-1 m-0">
          <label htmlFor="vlr_unit">Vlr.</label>
          <InputNumber
            name="vlr_unit"
            value={vlrUnit}
            onChange={e => {
              setVlrUnit(Number(e.value));
            }}
            mode="decimal"
            placeholder="R$ 0,00"
            minFractionDigits={item.price_out}
            maxFractionDigits={item.price_out}
            disabled={
              product?.prices.length === 0 ||
              !validate(order.orderHeader.customer_id || '') ||
              !validate(item.id)
            }
            className={product?.prices.length === 0 ? `surface - 200` : ``}
          />
        </div>
        <div className="field col-4 md:col-1 md:m-0 md:p-2 p-1 m-0">
          <label htmlFor="add">Add.</label>
          <InputNumber
            name="adic"
            value={add}
            onChange={e => {
              setAdd(Number(e.value));
            }}
            mode="decimal"
            placeholder={addType === 'V' ? 'R$0,00' : '0,00%'}
            minFractionDigits={item.price_out}
            maxFractionDigits={item.price_out}
            min={0}
            disabled={
              product?.prices.length === 0 ||
              !validate(order.orderHeader.customer_id || '') ||
              !validate(item.id)
            }
            className={product?.prices.length === 0 ? `surface - 200` : ``}
            tooltip={tooltipAdd}
          />

          <div className="flex" style={{ height: '15px' }}>
            <SelectButton
              options={addDescType}
              value={addType}
              onChange={e => {
                if (e.value !== null) setAddType(e.value);
              }}
              tooltip="Adicional em valor ($) ou em porcentagem (%)"
              tooltipOptions={{ position: 'bottom' }}
              className="w-full"
              itemTemplate={addTemplate}
              disabled={
                product?.prices.length === 0 ||
                !validate(order.orderHeader.customer_id || '') ||
                !validate(item.id)
              }
            />
          </div>
        </div>
        <div className="field col-4 md:col-1 md:m-0 md:p-2 p-1 m-0">
          <label htmlFor="add">Desc.</label>
          <InputNumber
            name="desc"
            value={desc}
            onChange={e => {
              setDesc(Number(e.value));
            }}
            mode="decimal"
            placeholder={descType === 'V' ? 'R$0,00' : '0,00%'}
            minFractionDigits={item.price_out}
            maxFractionDigits={item.price_out}
            min={0}
            tooltip={tooltipDesc}
            disabled={
              product?.prices.length === 0 ||
              !validate(order.orderHeader.customer_id || '') ||
              !validate(item.id)
            }
            className={product?.prices.length === 0 ? `surface - 200` : ``}
          />
          <div
            className="flex justify-content-center"
            style={{ height: '15px' }}
          >
            <SelectButton
              options={addDescType}
              value={descType}
              onChange={e => {
                if (e.value !== null) setDescType(e.value);
              }}
              tooltip="Desconto em valor ($) ou em porcentagem (%)"
              tooltipOptions={{ position: 'bottom' }}
              itemTemplate={descTemplate}
              className="w-full"
              disabled={
                product?.prices.length === 0 ||
                !validate(order.orderHeader.customer_id || '') ||
                !validate(item.id)
              }
            />
          </div>
        </div>
        <div className="field col-4 md:col-1 md:m-0 md:p-2 p-1 m-0">
          <label htmlFor="vlr_liq">Liq.</label>
          <InputNumber
            name="vlr_liq"
            value={vlrLiq}
            mode="decimal"
            placeholder="R$ 0,00"
            disabled
            className="surface-200"
            minFractionDigits={item.price_out}
            maxFractionDigits={item.price_out}
          />
        </div>
        <div className="field col-6 md:col-1 md:m-0 md:p-2 p-1 m-0 mb-2">
          <label htmlFor="total">Total</label>
          <InputNumber
            name="total"
            value={total}
            mode="decimal"
            placeholder="R$ 0,00"
            disabled
            className="surface-200"
            minFractionDigits={item.price_out}
            maxFractionDigits={item.price_out}
          />
        </div>

        <div className="field col-6 md:col-1 flex align-items-end justify-content-center pt-5 pl-2">
          <Button
            type="submit"
            loading={load}
            className="p-button-success w-12"
            icon="fa-solid fa-plus"
            disabled={
              product?.prices.length === 0 ||
              !validate(order.orderHeader.customer_id || '') ||
              !validate(item.id)
            }
          />
        </div>
      </Form>

      <DataTable
        value={order.orderBody}
        rowClassName={rowClassName}
        responsiveLayout="scroll"
        paginator
        rows={10}
        rowsPerPageOptions={[10, 20, 30]}
        size="small"
        emptyMessage="Produtos não encontrados..."
        selectionMode="single"
        onRowSelect={async e => handleRowSelect(e)}
        loading={load}
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        currentPageReportTemplate="Exibindo {first} / {last} de {totalRecords} produtos do pedido."
        className="w-full mb-2"
      >
        <Column header="#" body={renderColumnPosition}></Column>
        <Column
          header="Produto"
          body={r => renderProductDescription(r.id)}
          sortable
          field="product_description"
        ></Column>
        <Column header="Obs." body={r => renderProductObs(r.obs)}></Column>
        <Column header="Tabela" field="table_price_description"></Column>
        <Column header="Qnt." body={r => renderColumnDecimal(r.qnt)}></Column>
        <Column
          header="Vlr."
          body={r => renderColumnDecimal(r.vlr_unit)}
        ></Column>
        <Column header="Add." body={r => renderColumnDecimal(r.adic)}></Column>
        <Column header="Desc." body={r => renderColumnDecimal(r.desc)}></Column>
        <Column
          header="Liq."
          body={r => renderColumnDecimal(r.vlr_liq)}
        ></Column>
        <Column
          header="Total"
          body={r => renderColumnDecimal(r.vlr_liq_total)}
        ></Column>
        <Column
          header="*"
          body={e => renderCollumnMenu(e.id)}
          align={'center'}
        ></Column>
      </DataTable>
    </>
  );
};

export default InputRowProductOrder;

import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Progress from '../../components/progress-bar';
import useToastContext from '../../hooks/toast';
import api from '../../services/api';
import messageRequestError from '../../utils/messageRequestError';
import { InputAutoComplete } from '../../components/Inputs/InputAutoComplete';
import IOptionsDTO, { emptyOption } from '../business/dtos/IOptionsDTO';
import { Divider } from '../../components/Divider';
import { EnumStatus } from '../../enum/EnumStatus';

const ControlPanel = () => {
  const [load, setLoad] = useState<boolean>(false);
  const [user, setUser] = useState<any>();

  const formRef = useRef();
  const navigate = useHistory();
  const toast = useToastContext();

  const [companies, setCompanies] = useState<IOptionsDTO[]>([]);
  const [company, setCompany] = useState<any>();

  useEffect(() => {
    loadUserData();
  }, []);

  const handleSubmit = async () => {};

  const searchBusiness = async (e: any) => {
    if (e.query.length >= 3) {
      setLoad(true);
      api
        .post(`/business/search/`, {
          filter: {
            search: e.query,
          },
          pagination: {
            page: 1,
            perPage: 10,
            status: [EnumStatus.ATIVO],
          },
        })
        .then(({ data }) => {
          if (data.length > 0) {
            setCompanies(
              data.map((i: any) => {
                return {
                  value: i.id,
                  label: `${i.nickname ? i.nickname : '***'} / ${i.name} - ${
                    i.status === EnumStatus.ATIVO
                      ? 'Ativa'
                      : i.status === EnumStatus.CANCELADO
                      ? 'Bloqueada'
                      : ''
                  }`,
                };
              }),
            );
          } else {
            toast('error', 'Erro', 'Nenhuma empresa encontrada!');
            return;
          }
        })
        .catch((err: any) => {
          toast('error', 'Erro', messageRequestError(err));
          return;
        })
        .finally(() => setLoad(false));
    }
  };

  const unblockBusiness = async () => {
    setLoad(true);

    await api
      .put(`/business/unblock/${company.value}`)
      .then(({ data }) => {
        if (data)
          toast('success', 'Sucesso', 'Empresa desbloqueada com sucesso!');
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setLoad(false));
  };

  const clearCacheAll = async () => {
    setLoad(true);
    await api
      .post('/cache/clear-all')
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Cache limpo com sucesso!');
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');

    if (u.id && u.id !== '26cba209-30d2-4d1e-83c0-45361f89f939')
      navigate.push('/access');
    if (u) setUser(u);
  };

  const itemTemplateBusiness = (i: IOptionsDTO) => {
    return (
      <div className="country-item">
        <div>{i.label}</div>
      </div>
    );
  };

  return (
    <>
      {user && user.id === '26cba209-30d2-4d1e-83c0-45361f89f939' && (
        <>
          <Progress isLoad={load} className="mb-2" />
          <Form
            ref={null}
            onSubmit={handleSubmit}
            placeholder={''}
            className="card p-fluid grid formgrid"
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className="field col-8 md:col-4">
              <label htmlFor="business">Desbloquear empresa</label>
              <InputAutoComplete
                name="business"
                suggestions={companies}
                value={company}
                completeMethod={e => searchBusiness(e)}
                field="label"
                onChange={e => {
                  setCompany(e.value);
                }}
                itemTemplate={itemTemplateBusiness}
                placeholder="Buscar empresa (Nome/CNPJ)"
              />
            </div>

            <div className="field col-4 md:col-2 flex align-items-end">
              <Button
                name="unblock_business"
                label="Desbloquear"
                onClick={unblockBusiness}
                type="button"
                icon="fa-solid fa-lock-open"
                loading={load}
                disabled={!company || company === ''}
              />
            </div>

            <Divider />

            <div className="field col-6 md:col-2">
              <label htmlFor="cache">Cache</label>
              <Button
                name="clear_cache"
                onClick={() => clearCacheAll()}
                label="Limpar cache total"
                type="button"
              />
            </div>
          </Form>
        </>
      )}
    </>
  );
};

export default ControlPanel;

// prettier-ignore-start

import { Form } from '@unform/web';
import { endOfDay, format, isAfter, isBefore, startOfDay } from 'date-fns';
import { Button } from 'primereact/button';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Board from 'react-trello';
import { v4, validate } from 'uuid';
import CalendarRange from '../../../../components/Inputs/CalendarRange';
import { MultiSelect } from '../../../../components/Inputs/InputMultSelect';
import InputNumberRange from '../../../../components/Inputs/InputNumberRange';
import { InputText } from '../../../../components/Inputs/InputText';
import { Dialog } from '../../../../components/Modal';
import Progress from '../../../../components/progress-bar';
import { EnumPriority } from '../../../../enum/EnumPriority';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import formatCurrency from '../../../../utils/FormatCurrency';
import defaultPriorities from '../../../../utils/defaultPriorities';
import messageRequestError from '../../../../utils/messageRequestError';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';
import {
  FLAT_TRANSLATION_TABLE,
  IBoard,
  ICard,
  ICrmAction,
  ICrmTag,
  IUser,
  Ilanes,
  emptyBoard,
} from '../dtos/ICrmDTO';
import CrmSidebarCard from '../sidebar-card';
import CrmMenuTop from '../top-menu';
import DialogTransferCRMContacts from './dialog-transfer-contacts';

const PER_PAGE = 15;

const CrmPanel = () => {
  const hoje = new Date();
  const params: { id: string } = useParams();
  const toast = useToastContext();

  const [load, setLoad] = useState(false);
  const [sidebarCardVisible, setSidebarCardVisible] = useState(false);
  const [cardId, setCardId] = useState<string>('');
  const [laneId, setLaneId] = useState<string>('');

  const [tags, setTags] = useState<ICrmTag[]>([]);
  const [tagsOpts, setTagsOpts] = useState<IOptionsDTO[]>([]);

  const [actions, setActions] = useState<ICrmAction[]>([]);
  const [actionsOpts, setActionsOpts] = useState<IOptionsDTO[]>([]);

  const [users, setUsers] = useState<IUser[]>([]);
  const [usersOpts, setUsersOpts] = useState<IOptionsDTO[]>([]);

  const [visibleConfirmDeleteCardDialog, setVisibleConfirmDeleteCardDialog] =
    useState(false);

  const [panelComplete, setPanelComplete] = useState<IBoard>(emptyBoard);
  const [panelFiltered, setPanelFiltered] = useState<IBoard>(emptyBoard);
  const [visiblePanel, setVisiblePanel] = useState<IBoard>(emptyBoard);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(true);

  const [transferContactsDialogVisible, setTransferContactsDialogVisible] =
    useState<boolean>(false);

  function delayedPromise(durationInMs: number, resolutionPayload: ICard[]) {
    return new Promise(function (resolve) {
      setTimeout(function () {
        resolve(resolutionPayload);
      }, durationInMs);
    });
  }

  function generateCards(requestedPage: number, laneId: string) {
    const cards: ICard[] = [];
    let fetchedItems = (requestedPage - 1) * PER_PAGE;

    const indexColumn = panelFiltered.lanes.findIndex(l => l.id === laneId);

    for (let i = fetchedItems; i <= fetchedItems + PER_PAGE - 1; i++) {
      const cardAdded = panelFiltered.lanes[indexColumn].cards[i];
      if (
        cardAdded &&
        !visiblePanel.lanes[indexColumn].cards.some(c => c.id === cardAdded.id)
      ) {
        cards.push(cardAdded);
      }
    }
    return cards;
  }

  function paginate(requestedPage: number, laneId: string) {
    const indexColumn = panelFiltered.lanes.findIndex(l => l.id === laneId);
    let pagesInColumn = Math.floor(
      panelFiltered.lanes[indexColumn].cards.length / PER_PAGE,
    );
    if (panelFiltered.lanes[indexColumn].cards.length % PER_PAGE !== 0)
      pagesInColumn++;

    requestedPage = visiblePanel.lanes[indexColumn].currentPage + 1;

    if (requestedPage > pagesInColumn) {
      return delayedPromise(0, []);
    }
    let newCards = generateCards(requestedPage, laneId);

    const newPanel = visiblePanel;
    newCards.forEach(c => newPanel.lanes[indexColumn].cards.push(c));
    newPanel.lanes[indexColumn].currentPage = requestedPage;

    setVisiblePanel(newPanel);

    return delayedPromise(2000, newCards);
  }

  const handleSidebarCardClosed = async () => {
    setCardId('');
    setLaneId('');

    await loadPanel(params.id);
  };

  const refreshPanelComplete = (newPanel: IBoard) => {
    setPanelComplete({
      title: newPanel.title,
      description: newPanel.description,
      created_at: newPanel.created_at,
      user: {
        id: newPanel.user.id,
        name: newPanel.user.name,
      },
      lanes: newPanel.lanes
        .sort((a: any, b: any) => (a.position = b.position))
        .map((_i: any, indexColumn) => {
          return {
            id: newPanel.lanes[indexColumn].id,
            title: newPanel.lanes[indexColumn].title,
            label: `${formatCurrency(
              newPanel.lanes[indexColumn].cards.reduce(
                (a, c) => a + Number(c.label.replace(',', '.')),
                0,
              ),
            )} / ${newPanel.lanes[indexColumn].cards.length || 0}`,
            position: newPanel.lanes[indexColumn].position,
            cards: newPanel.lanes[indexColumn].cards
              .sort((a, b) => a.position - b.position)
              .map((_j: any, indexCard) => {
                return {
                  id: newPanel.lanes[indexColumn].cards[indexCard].id,
                  title: newPanel.lanes[indexColumn].cards[indexCard].title,
                  laneId: newPanel.lanes[indexColumn].cards[indexCard].laneId,
                  position:
                    newPanel.lanes[indexColumn].cards[indexCard].position,
                  label: newPanel.lanes[indexColumn].cards[indexCard].label,
                  draggable: true,
                  description:
                    newPanel.lanes[indexColumn].cards[indexCard].description,
                  members: newPanel.lanes[indexColumn].cards[indexCard].members,
                  interactions:
                    newPanel.lanes[indexColumn].cards[indexCard].interactions,
                  priority:
                    newPanel.lanes[indexColumn].cards[indexCard].priority,
                  value: newPanel.lanes[indexColumn].cards[indexCard].value,

                  tags: newPanel.lanes[indexColumn].cards[indexCard].tags.map(
                    (k: any) => {
                      return {
                        id: k.id,
                        title: k.title || '',
                        bgcolor: k.bgcolor,
                      };
                    },
                  ),
                  schedules:
                    newPanel.lanes[indexColumn].cards[indexCard].schedules,
                };
              }),
          } as Ilanes;
        }),
    });
  };

  const refreshPanelFiltered = (newPanel: IBoard) => {
    setPanelFiltered({
      title: newPanel.title,
      description: newPanel.description,
      created_at: newPanel.created_at,
      user: {
        id: newPanel.user.id,
        name: newPanel.user.name,
      },
      lanes: newPanel.lanes
        .sort((a: any, b: any) => (a.position = b.position))
        .map((_i: any, indexColumn) => {
          return {
            id: newPanel.lanes[indexColumn].id,
            title: newPanel.lanes[indexColumn].title,
            label: `${formatCurrency(
              newPanel.lanes[indexColumn].cards.reduce(
                (a, c) => a + Number(c.label.replace(',', '.')),
                0,
              ),
            )} / ${newPanel.lanes[indexColumn].cards.length || 0}`,
            position: newPanel.lanes[indexColumn].position,
            cards: newPanel.lanes[indexColumn].cards
              .sort((a, b) => a.position - b.position)
              .map((_j: any, indexCard) => {
                return {
                  id: newPanel.lanes[indexColumn].cards[indexCard].id,
                  title: newPanel.lanes[indexColumn].cards[indexCard].title,
                  laneId: newPanel.lanes[indexColumn].cards[indexCard].laneId,
                  position:
                    newPanel.lanes[indexColumn].cards[indexCard].position,
                  label: newPanel.lanes[indexColumn].cards[indexCard].label,
                  draggable: true,
                  description:
                    newPanel.lanes[indexColumn].cards[indexCard].description,
                  members: newPanel.lanes[indexColumn].cards[indexCard].members,
                  interactions:
                    newPanel.lanes[indexColumn].cards[indexCard].interactions,
                  priority:
                    newPanel.lanes[indexColumn].cards[indexCard].priority,
                  value: newPanel.lanes[indexColumn].cards[indexCard].value,

                  tags: newPanel.lanes[indexColumn].cards[indexCard].tags.map(
                    (k: any) => {
                      return {
                        id: k.id,
                        title: k.title || '',
                        bgcolor: k.bgcolor,
                      };
                    },
                  ),
                  schedules:
                    newPanel.lanes[indexColumn].cards[indexCard].schedules,
                };
              }),
          } as Ilanes;
        }),
    });
  };

  const refreshPanelVisible = (newPanel: IBoard) => {
    setVisiblePanel({
      title: newPanel.title,
      description: newPanel.description,
      created_at: newPanel.created_at,
      user: {
        id: newPanel.user.id,
        name: newPanel.user.name,
      },
      lanes: newPanel.lanes
        .sort((a: any, b: any) => (a.position = b.position))
        .map((_i: any, indexColumn) => {
          return {
            id: newPanel.lanes[indexColumn].id,
            title: newPanel.lanes[indexColumn].title,
            label: `${formatCurrency(
              newPanel.lanes[indexColumn].cards.reduce(
                (a, c) => a + Number(c.label.replace(',', '.')),
                0,
              ),
            )} / ${newPanel.lanes[indexColumn].cards.length || 0}`,
            position: newPanel.lanes[indexColumn].position,
            cards: newPanel.lanes[indexColumn].cards
              .sort((a, b) => a.position - b.position)
              .map((_j: any, indexCard) => {
                return {
                  id: newPanel.lanes[indexColumn].cards[indexCard].id,
                  title: newPanel.lanes[indexColumn].cards[indexCard].title,
                  laneId: newPanel.lanes[indexColumn].cards[indexCard].laneId,
                  position:
                    newPanel.lanes[indexColumn].cards[indexCard].position,
                  label: newPanel.lanes[indexColumn].cards[indexCard].label,
                  draggable: true,
                  description:
                    newPanel.lanes[indexColumn].cards[indexCard].description,
                  members: newPanel.lanes[indexColumn].cards[indexCard].members,
                  interactions:
                    newPanel.lanes[indexColumn].cards[indexCard].interactions,
                  priority:
                    newPanel.lanes[indexColumn].cards[indexCard].priority,
                  value: newPanel.lanes[indexColumn].cards[indexCard].value,
                  tags: newPanel.lanes[indexColumn].cards[indexCard].tags.map(
                    (k: any) => {
                      return {
                        id: k.id,
                        title: k.title || '',
                        bgcolor: k.bgcolor,
                      };
                    },
                  ),
                  schedules:
                    newPanel.lanes[indexColumn].cards[indexCard].schedules,
                };
              }),
          } as Ilanes;
        }),
    });
  };

  interface IFilter {
    title: string;
    tags: string[];
    valueRange: number[];
    interactions: string[];
    priorities: EnumPriority[];
    members: string[];
    schedules: Date[];
  }

  const emptyFilter: IFilter = {
    title: '',
    tags: [],
    valueRange: [0, 100000],
    interactions: [],
    priorities: [],
    members: [],
    schedules: [],
  };

  const [filter, setFilter] = useState<IFilter>(emptyFilter);

  useEffect(() => {
    const loadTags = async () => {
      setLoad(true);
      try {
        const { data } = await api.post('/crm/tag/all', {
          dataShow: { page: 1, perPage: 100, status: 'A' },
        });
        setTags(data);
        setTagsOpts(
          data.map((tag: ICrmTag) => ({
            value: tag.id,
            label: tag.title,
            pos: tag.position,
          })),
        );
      } catch (err) {
        toast('warn', 'Alerta', messageRequestError(err));
      } finally {
        setLoad(false);
      }
    };

    const loadActions = async () => {
      setLoad(true);
      try {
        const { data } = await api.post('/crm/action/all', {
          dataShow: { page: 1, perPage: 100, status: 'A' },
        });
        setActions(data);
        setActionsOpts(
          data.map((action: any) => ({
            value: action.id,
            label: action.title,
          })),
        );
      } finally {
        setLoad(false);
      }
    };

    const loadUsers = async () => {
      setLoad(true);
      try {
        const { data } = await api.post('/users/list', {
          pagination: { page: 1, perPage: 1000, status: ['A'] },
        });
        setUsers(data);
        setUsersOpts(
          data.map((user: IUser) => ({ label: user.name, value: user.id })),
        );
      } finally {
        setLoad(false);
      }
    };

    const initializeData = async () => {
      await Promise.all([loadTags(), loadActions(), loadUsers()]);
      if (validate(params.id)) {
        await loadPanel(params.id);
      }
    };

    initializeData();
  }, [params.id, toast]);

  const loadPanel = async (id: string) => {
    setLoad(true);
    try {
      const { data } = await api.get(`/crm/panel/${id}`);
      refreshPanelComplete(data);
      refreshPanelFiltered(data);
    } catch (err) {
      toast('warn', 'Alerta', messageRequestError(err));
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    setVisiblePanel({
      ...panelFiltered,
      lanes: panelFiltered.lanes.map(l => {
        return {
          ...l,
          cards: l.cards.splice(0, PER_PAGE),
        };
      }),
    } as IBoard);
  }, [panelFiltered]);

  const priorityTemplate = (option: IOptionsDTO) => {
    const color =
      option.value === EnumPriority.BAIXA
        ? '#689F38'
        : option.value === EnumPriority.MEDIA
        ? '#FBC02C'
        : '#D32F30';

    return (
      <div className="flex align-items-center">
        <i className="pi pi-circle-fill" style={{ color }}></i>
        <span className="ml-2">{option.label}</span>
      </div>
    );
  };

  const interactionsTemplate = (option: IOptionsDTO) => {
    const action = actions.find(a => a.id === option.value);

    if (action) {
      return (
        <div className="flex align-items-center">
          <i className={action.icon}></i>
          <span className="ml-2">{option.label}</span>
        </div>
      );
    }
  };

  const tagsTemplate = (option: IOptionsDTO) => {
    const tag = tags.find(t => t.id === option.value);
    if (tag) {
      return (
        <div className="flex align-items-center">
          <i className="pi pi-circle-fill" style={{ color: tag.color }}></i>
          <span className="ml-2">{option.label}</span>
        </div>
      );
    }
  };

  const handleFilter = () => {
    let cardsFiltered: any[] = [];

    panelComplete.lanes.forEach(l =>
      l.cards.forEach(c => cardsFiltered.push(c)),
    );

    if (filter.title !== '') {
      cardsFiltered = cardsFiltered.filter(c =>
        c.title.toLowerCase().includes(filter.title.toLowerCase()),
      );
    }

    if (filter.tags.length > 0) {
      cardsFiltered = cardsFiltered.filter(c =>
        c.tags.some((t: any) => filter.tags.includes(t.id)),
      );
    }

    if (
      filter.priorities.length > 0 &&
      filter.priorities.length < defaultPriorities.length
    ) {
      cardsFiltered = cardsFiltered.filter(c =>
        filter.priorities.some(p => {
          if (
            p === EnumPriority.ALTA &&
            (c.priority === 'A' || c.priority === EnumPriority.ALTA)
          )
            return true;
          if (
            p === EnumPriority.MEDIA &&
            (c.priority === 'M' || c.priority === EnumPriority.MEDIA)
          )
            return true;
          if (
            p === EnumPriority.BAIXA &&
            (c.priority === 'B' || c.priority === EnumPriority.BAIXA)
          )
            return true;
          return false;
        }),
      );
    }

    if (filter.interactions.length > 0) {
      cardsFiltered = cardsFiltered.filter(c =>
        c.interactions.some((i: string) => filter.interactions.includes(i)),
      );
    }

    if (filter.members.length > 0) {
      cardsFiltered = cardsFiltered.filter(c =>
        c.members.some((i: any) => filter.members.includes(i)),
      );
    }

    if (
      filter.schedules.length === 2 &&
      filter.schedules[0] !== null &&
      filter.schedules[1] !== null
    ) {
      cardsFiltered = cardsFiltered.filter(c =>
        c.schedules.some(
          (r: Date) =>
            isBefore(startOfDay(filter.schedules[0]), new Date(r)) &&
            isAfter(endOfDay(filter.schedules[1]), new Date(r)),
        ),
      );
    }

    cardsFiltered = cardsFiltered.filter(c => {
      return (
        Number(c.label.replaceAll('.', '').replaceAll(',', '.')) >=
          filter.valueRange[0] &&
        Number(c.label.replaceAll('.', '').replaceAll(',', '.')) <=
          filter.valueRange[1]
      );
    });

    if (cardsFiltered.length === 0) {
      toast(
        'warn',
        'Alerta',
        'Nenhum card encontrado com os parâmetros informados!',
      );
    } else {
      toast('success', 'Sucesso', 'Filtro aplicado com sucesso!');
    }

    refreshPanelFiltered({
      ...panelComplete,
      lanes: panelComplete.lanes.map(l => {
        return {
          ...l,
          cards: l.cards.filter(c =>
            cardsFiltered.some(card => card.id === c.id),
          ),
        };
      }),
    } as IBoard);

    refreshPanelVisible({
      ...panelComplete,
      lanes: panelComplete.lanes.map(l => {
        return {
          ...l,
          cards: l.cards
            .filter(c => cardsFiltered.some(card => card.id === c.id))
            .splice(0, PER_PAGE),
        };
      }),
    } as IBoard);
  };

  const handleSelectCard = (
    cardId: string,
    _metadata: string,
    laneId: string,
  ) => {
    setCardId(cardId);
    setLaneId(laneId);
    setSidebarCardVisible(true);
  };

  const handleMoveCardAcrossLanes = async (
    fromLaneId: string,
    toLaneId: string,
    cardId: string,
    index: number,
  ) => {
    const panelAux = visiblePanel;

    const indexSourceColumn = panelAux.lanes.findIndex(
      l => l.id === fromLaneId,
    );

    const cardDetails = panelAux.lanes[indexSourceColumn].cards.find(
      c => c.id === cardId,
    );

    if (cardDetails) {
      // Roda função apenas se tiver de fato movido
      if (!(fromLaneId === toLaneId && index === cardDetails.position)) {
        // Realiza movimentação dentro da mesma lane
        if (fromLaneId === toLaneId) {
          // Descendo o card
          if (index > cardDetails.position) {
            panelAux.lanes[indexSourceColumn].cards.forEach(c => {
              if (c.position <= index && c.position > cardDetails.position)
                c.position--;

              if (c.id === cardId) c.position = index;
            });

            // Subindo o card
          } else {
            panelAux.lanes[indexSourceColumn].cards.forEach(c => {
              if (c.position >= index && c.position < cardDetails.position)
                c.position++;

              if (c.id === cardId) c.position = index;
            });
          }

          // Movimentação entre colunas diferentes
        } else {
          const indexTargetColumn = panelAux.lanes.findIndex(
            l => l.id === toLaneId,
          );

          panelAux.lanes[indexSourceColumn].cards.forEach(c => {
            if (c.position > cardDetails.position) c.position--;
          });

          panelAux.lanes[indexTargetColumn].cards.forEach(c => {
            if (c.position >= index) c.position++;
          });

          // Remove card da coluna em que estava
          panelAux.lanes[indexSourceColumn].cards = panelAux.lanes[
            indexSourceColumn
          ].cards.filter(c => c.id !== cardId);

          // Adiciona card na nova coluna
          panelAux.lanes[indexTargetColumn].cards.push({
            ...cardDetails,
            laneId: toLaneId,
            position: index,
          });
        }

        refreshPanelVisible(panelAux);

        await api
          .put('/crm/card/move', {
            data: {
              card_id: cardId,
              panel_id: params.id,
              source_column_id: fromLaneId,
              target_column_id: toLaneId,
              position: index,
            },
          })
          .catch(async (err: any) => {
            toast('warn', 'Alerta', messageRequestError(err));
            await loadPanel(params.id);
          });
      }
    }
  };

  const handleMoveColumn = async (
    posStart: number,
    posEnd: number,
    lane: any,
  ) => {
    await api
      .put('/crm/column/change/position', {
        data: {
          id: lane.id,
          posTarget: posEnd,
        },
      })
      .catch(async (err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
        await loadPanel(params.id);
      });
  };

  const handleCardAdd = async (
    card: { id: string; title: string; label?: number; description?: string },
    laneId: string,
  ) => {
    const idCard = v4();
    const indexColumnSource = panelComplete.lanes.findIndex(
      l => l.id === laneId,
    );

    panelComplete.lanes[indexColumnSource].cards.push({
      id: idCard,
      title: card.title,
      position: panelComplete.lanes[indexColumnSource].cards.length,
      label: formatCurrency(card.label || '') || '0,00',
      description: card.description || '',
      laneId,
      draggable: true,
      style: undefined,
      cardStyle: undefined,
      tags: [],
      schedules: [],
      interactions: [],
      members: [],
      priority: EnumPriority.ALTA,
      value: 0,
    });

    visiblePanel.lanes[indexColumnSource].cards.push({
      id: idCard,
      title: card.title,
      position: panelComplete.lanes[indexColumnSource].cards.length,
      label: formatCurrency(card.label || '') || '0,00',
      description: card.description || '',
      laneId,
      draggable: true,
      style: undefined,
      cardStyle: undefined,
      tags: [],
      schedules: [],
      interactions: [],
      members: [],
      priority: EnumPriority.ALTA,
      value: 0,
    });

    refreshPanelComplete(panelComplete);
    refreshPanelVisible(visiblePanel);

    await api
      .post('/crm/card', {
        data: {
          id: idCard,
          column_id: laneId,
          panel_id: params.id,
          title: card.title,
          description: card.description,
          value: Number(card.label) || 0,
        },
      })
      .catch(async (err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
        await loadPanel(params.id);
      });
  };

  const handleOpenConfirmDeleteCardDialog = (
    cardId: string,
    laneId: string,
  ) => {
    setVisibleConfirmDeleteCardDialog(true);
    setCardId(cardId);
    setLaneId(laneId);
  };

  const handleCloseConfirmDeleteCardDialog = () => {
    setVisibleConfirmDeleteCardDialog(false);
    setCardId('');
    setLaneId('');
  };

  const handleArchiveCard = async (id: string) => {
    const indexColumn = panelComplete.lanes.findIndex(l => l.id === laneId);

    panelComplete.lanes[indexColumn].cards = panelComplete.lanes[
      indexColumn
    ].cards.filter(c => c.id !== id);

    visiblePanel.lanes[indexColumn].cards = visiblePanel.lanes[
      indexColumn
    ].cards.filter(c => c.id !== id);

    refreshPanelComplete(panelComplete);
    refreshPanelVisible(visiblePanel);

    await api
      .post(`/crm/card/archive`, {
        data: {
          id: id,
          column_id: laneId,
          panel_id: params.id,
        },
      })
      .catch(async (err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
        await loadPanel(params.id);
      });
  };

  const handleColumnAdd = async (e: { id: string; title: string }) => {
    const idColumn = v4();

    panelComplete.lanes.push({
      id: idColumn,
      position: panelComplete.lanes.length,
      currentPage: 1,
      title: e.title,
      cards: [],
      label: '0,00 / 0',
      draggable: true,
      metadata: undefined,
    });

    visiblePanel.lanes.push({
      id: idColumn,
      position: panelComplete.lanes.length,
      currentPage: 1,
      title: e.title,
      cards: [],
      label: '0,00 / 0',
      draggable: true,
      metadata: undefined,
    });

    refreshPanelComplete(panelComplete);
    refreshPanelVisible(visiblePanel);

    await api
      .post('/crm/column', {
        data: {
          id: idColumn,
          panel_id: params.id,
          position: panelComplete.lanes.length,
          title: e.title,
          description: '',
          color: '#ffffff',
        },
      })
      .catch(async (err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
        await loadPanel(params.id);
      });
  };

  const handleColumnDelete = async (id: string) => {
    const indexColumn = panelComplete.lanes.findIndex(l => l.id === id);

    if (panelComplete.lanes[indexColumn].cards.length > 0) {
      toast(
        'warn',
        'Alerta',
        'Não é possível desabilitar essa fase pois existem cards nela, remova todos os cards antes de desabilitar!',
      );

      refreshPanelComplete(panelComplete);
      refreshPanelVisible(visiblePanel);
    } else {
      panelComplete.lanes = panelComplete.lanes.filter(l => l.id !== id);
      visiblePanel.lanes = visiblePanel.lanes.filter(l => l.id !== id);

      refreshPanelComplete(panelComplete);
      refreshPanelVisible(visiblePanel);

      await api
        .post(`/crm/column/archive`, {
          data: {
            id: id,
            panel_id: params.id,
          },
        })
        .catch(async (err: any) => {
          toast('warn', 'Alerta', messageRequestError(err));
          await loadPanel(params.id);
        });
    }
  };

  return (
    <>
      <CrmMenuTop />
      <Progress isLoad={load} className="mb-2" />
      <div className="card w-full mb-2">
        <Form
          ref={null}
          onSubmit={handleFilter}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          {filtersVisible && (
            <>
              <div className="field col-12 md:col-4">
                <label htmlFor="title">Título</label>
                <InputText
                  name="title"
                  value={filter.title}
                  onChange={e =>
                    setFilter({ ...filter, title: e.target.value })
                  }
                  placeholder="João da Silva LTDA.."
                />
              </div>

              <div className="field col-12 md:col-3 mb-0 pb-0">
                <label htmlFor="tags">
                  Tags{' '}
                  {filter.tags && filter.tags.length > 0
                    ? `[${filter.tags.length}]`
                    : ''}
                </label>
                <MultiSelect
                  name="tags"
                  options={tagsOpts}
                  value={filter.tags}
                  onChange={e => setFilter({ ...filter, tags: e.value })}
                  itemTemplate={tagsTemplate}
                  placeholder="Selecione..."
                  display="chip"
                  filter
                />
              </div>

              <div className="field col-12 md:col-3">
                <label htmlFor="priorities">Prioridade</label>
                <MultiSelect
                  name="priority"
                  options={defaultPriorities}
                  value={filter.priorities}
                  itemTemplate={priorityTemplate}
                  onChange={e => setFilter({ ...filter, priorities: e.value })}
                  placeholder="Selecione..."
                  display="chip"
                />
              </div>

              <div className="field col-12 md:col-2 mt-4">
                <Button
                  label="Limpar"
                  icon="fa-solid fa-xmark"
                  type="button"
                  className="p-button-raised p-button-danger"
                  onClick={() => {
                    setFilter(emptyFilter);
                  }}
                />
              </div>

              <div className="field col-6 md:col-2 mb-0 pb-0">
                <label htmlFor="schedules">Lembretes entre</label>
                <CalendarRange
                  name="schedules"
                  value={filter.schedules}
                  onChange={e => {
                    setFilter({ ...filter, schedules: e.value as Date[] });
                  }}
                  placeholder="00/00/00 ~ 00/00/00"
                />
              </div>

              <div className="field col-6 md:col-2">
                <label htmlFor="valueRange">Valores entre</label>
                <InputNumberRange
                  name="value_range"
                  range={filter.valueRange}
                  defaultMinValue={0}
                  defaultMaxValue={1000000}
                  onMinValueChange={e =>
                    setFilter({
                      ...filter,
                      valueRange: [e as number, filter.valueRange[1]],
                    })
                  }
                  onMaxValueChange={e =>
                    setFilter({
                      ...filter,
                      valueRange: [filter.valueRange[0], e as number],
                    })
                  }
                />
              </div>

              <div className="field col-12 md:col-3 mb-0 pb-0">
                <label htmlFor="interactions">
                  Interações{' '}
                  {filter.interactions && filter.interactions.length > 0
                    ? `[${filter.interactions.length}]`
                    : ''}
                </label>
                <MultiSelect
                  name="interactions"
                  options={actionsOpts}
                  value={filter.interactions}
                  onChange={e =>
                    setFilter({ ...filter, interactions: e.value })
                  }
                  placeholder="Selecione..."
                  itemTemplate={interactionsTemplate}
                  display="chip"
                  filter
                />
              </div>

              <div className="field col-12 md:col-3 mb-0 pb-0">
                <label htmlFor="members">
                  Membros{' '}
                  {filter.members && filter.members.length > 0
                    ? `[${filter.members.length}]`
                    : ''}
                </label>
                <MultiSelect
                  name="members"
                  options={usersOpts}
                  value={filter.members}
                  onChange={e => setFilter({ ...filter, members: e.value })}
                  placeholder="Selecione..."
                  display="chip"
                />
              </div>

              <div className="field col-12 md:col-2 mt-4">
                <Button
                  label="Buscar"
                  icon="fa-solid fa-search"
                  type="submit"
                  loading={load}
                />
              </div>
            </>
          )}

          <div className="field col-12 md:col-2">
            <Button
              label="Tranferir contatos"
              icon="fa-solid fa-paper-plane"
              type="button"
              loading={load}
              onClick={() => setTransferContactsDialogVisible(true)}
            />
          </div>

          <div className="field col-9">
            <p className="font-semibold mt-2">
              Painel: {panelComplete.title}, Usuário:{' '}
              {panelComplete.user ? panelComplete.user.name : '#'}, Criado:{' '}
              {format(
                new Date(
                  panelComplete.created_at ? panelComplete.created_at : hoje,
                ),
                'dd/MM/yy H:mm',
              )}
            </p>
          </div>

          <div className="field col-2 md:col-1 flex justify-content-center align-items-center">
            <i
              className={
                filtersVisible ? 'p-2 pi pi-arrow-up' : 'p-2 pi pi-arrow-down'
              }
              style={{
                backgroundColor: 'var(--primary-color)',
                borderRadius: '50%',
                color: '#fff',
                cursor: 'pointer',
              }}
              onClick={() => setFiltersVisible(prevState => !prevState)}
            ></i>
          </div>
        </Form>
      </div>
      <Board
        ref={null}
        key={v4()}
        t={(key: string) => FLAT_TRANSLATION_TABLE[key]}
        data={visiblePanel}
        editable
        draggable
        collapsibleLanes
        cardDragClass="draggingCard"
        laneSortFunction={(card1: ICard, card2: ICard) =>
          card1.position - card2.position
        }
        onCardClick={handleSelectCard}
        onCardAdd={handleCardAdd}
        onCardDelete={handleOpenConfirmDeleteCardDialog}
        onLaneAdd={handleColumnAdd}
        onLaneDelete={handleColumnDelete}
        onLaneScroll={paginate}
        handleLaneDragEnd={handleMoveColumn}
        onCardMoveAcrossLanes={handleMoveCardAcrossLanes}
        canAddLanes
        style={{
          borderRadius: 8,
          background: 'var(--primary-color)',
          draggable: 'true',
        }}
      />
      <CrmSidebarCard
        id={cardId}
        columnId={laneId}
        panelId={params.id}
        isOpen={sidebarCardVisible}
        onRequestClose={() => {
          setSidebarCardVisible(false);
          handleSidebarCardClosed();
        }}
      />
      <Dialog
        visible={visibleConfirmDeleteCardDialog}
        style={{ width: '450px' }}
        header="Excluir?"
        modal
        onHide={() => handleCloseConfirmDeleteCardDialog()}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '1.5rem' }}
          />
          {cardId && laneId && (
            <span>
              Você tem certeza que deseja excluir/arquivar esse card? Lembrando
              que os cards nunca são exlcuidos por tem histórico de consumo de
              créditos de contatos gerados.
            </span>
          )}
          <div className="p-fluid grid formgrid pt-5">
            <div className="field col-12 md:col-6">
              <Button
                label="Não"
                type="button"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
                onClick={() => handleCloseConfirmDeleteCardDialog()}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Sim"
                icon="pi pi-check"
                className="p-button-success"
                type="button"
                loading={load}
                onClick={() => {
                  handleArchiveCard(cardId);
                  handleCloseConfirmDeleteCardDialog();
                }}
              />
            </div>
          </div>
        </div>
      </Dialog>
      ;
      <DialogTransferCRMContacts
        isOpen={transferContactsDialogVisible}
        onRequestClose={async () => {
          setTransferContactsDialogVisible(false);
          loadPanel(params.id);
        }}
        lanes={panelComplete.lanes}
      />
    </>
  );
};

export default CrmPanel;

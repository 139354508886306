/* eslint-disable react/jsx-no-undef */
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { addMinutes, format, isBefore, subHours, subMinutes } from 'date-fns';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { Editor } from 'primereact/editor';
import { Tag } from 'primereact/tag';
import React, { useEffect, useRef, useState } from 'react';
import { validate } from 'uuid';
import { Divider } from '../../../../components/Divider';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputText } from '../../../../components/Inputs/InputText';
import { Dialog } from '../../../../components/Modal';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import DialogAction from '../dialog-action';
import DialogPriority from '../dialog-priority';
import DialogSchedule from '../dialog-schedule';
import {
  ICrmAction,
  ICrmCard,
  ICrmCardActivity,
  ICrmCardSellers,
  ICrmTag,
  IUser,
  emptyCrmCard,
} from '../dtos/ICrmDTO';
import ClearName from '../../../../utils/ClearName';
import DialogTags from '../dialog-tags';
import { EnumStatus } from '../../../../enum/EnumStatus';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import IOptionsDTO from '../../../business/dtos/IOptionsDTO';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  id: string;
  panelId: string;
  columnId: string;
}

interface ITransferCardsData {
  card_id: string;
  user_id: string;
  panel_id: string;
  column_id: string;
}

const emptyTransferCardsData: ITransferCardsData = {
  card_id: '',
  user_id: '',
  panel_id: '',
  column_id: '',
};

const CrmSidebarCard = ({
  isOpen,
  onRequestClose,
  id,
  panelId,
  columnId,
}: IProps): React.ReactElement => {
  const hoje = new Date();

  const formRef = useRef<FormHandles>(null);
  const toast = useToastContext();

  const [isLoad, setIsLoad] = useState(false);

  const [card, setCard] = useState<ICrmCard>({} as ICrmCard);
  const [user, setUser] = useState<any>({} as any);

  const [transferCardsData, setTransferCardsData] =
    useState<ITransferCardsData>(emptyTransferCardsData);

  const [actions, setActions] = useState<ICrmAction[]>([]);

  const [activityText, setActivityText] = useState('');

  const [dialogSellerVisible, setDialogSellerVisible] = useState(false);
  const [dialogTagsVisible, setDialogTagsVisible] = useState(false);
  const [dialogScheduleVisible, setDialogScheduleVisible] = useState(false);
  const [dialogArchiveCardVisible, setDialogArchiveCardVisible] =
    useState(false);
  const [dialogPriorityVisible, setDialogPriorityVisible] = useState(false);
  const [dialogActionVisible, setDialogActionVisible] = useState(false);
  const [dialogTransferCardsVisisble, setDialogTransferCardsVisible] =
    useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);

  const [messageRemoveTagVisible, setMessageRemoveTagVisible] =
    useState<boolean>(false);
  const [messageRemoveSellerVisible, setMessageRemoveSellerVisible] =
    useState<boolean>(false);

  const [panels, setPanels] = useState<IOptionsDTO[]>([]);
  const [columns, setColumns] = useState<IOptionsDTO[]>([]);

  const loadUsers = async () => {
    setIsLoad(true);
    await api
      .post('/users/list', {
        pagination: { page: 1, perPage: 1000, status: ['A'] },
      })
      .then(({ data }) => {
        if (data) {
          setUsers(
            data.map((u: any) => {
              return {
                ...u,
                label: u.name,
                value: u.id,
              };
            }),
          );
        }
      })
      .finally(() => setIsLoad(false));
  };

  const loadActions = async () => {
    setIsLoad(true);
    await api
      .post('/crm/action/all', {
        dataShow: { page: 1, perPage: 100, status: 'A' },
      })
      .then(({ data }) => {
        if (data) {
          setActions(
            data
              .sort((a: any, b: any) => a.position - b.position)
              .map((i: ICrmAction) => {
                return {
                  id: i.id,
                  position: i.position,
                  icon: i.icon,
                  title: i.title,
                };
              }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const loadUserData = () => {
    const u = JSON.parse(localStorage.getItem('@wg:user') || '{}');
    if (u) setUser(u);
  };

  const handleCreateActivity = async () => {
    await handleSetCard();

    setIsLoad(true);
    await api
      .post('/crm/card/activity', {
        data: {
          card_id: id,
          description: activityText,
          action_id: null,
          time_start: 0,
          time_end: 0,
          time: 0,
        },
      })
      .then(async ({ data }) => {
        if (data) {
          setActivityText('');
          await loadCard(id);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const handleSetCard = async () => {
    setIsLoad(true);
    await api
      .put(`/crm/card`, {
        data: {
          id: card.id,
          column_id: card.column_id,
          panel_id: panelId,
          title: card.title,
          description: card.description,
          value: card.value,
          priority: card.priority,
        },
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const hasUserInSellers = (): boolean => {
    if (card) {
      if (card.crmCardSellers) {
        const inCard = card.crmCardSellers.find(
          (i: ICrmCardSellers) => i.seller_id === user.id,
        );
        if (inCard) {
          return true;
        }
      }
    }
    return false;
  };

  const handleSetSeller = async (id: string) => {
    setIsLoad(true);
    api
      .post('/crm/cards/sellers', {
        data: {
          card_id: card.id,
          panel_id: panelId,
          seller_id: id,
        },
      })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Membro adicionado com sucesso!');
          loadCard(card.id);
          setDialogSellerVisible(false);
        } else {
          toast('warn', 'Alerta', 'Vendedor já vinculado a este card!');
        }
      })
      .finally(() => setIsLoad(false));
  };

  const handleArchiveCard = async (id: string) => {
    setIsLoad(true);
    await api
      .post(`/crm/card/archive`, {
        data: {
          id: id,
          column_id: columnId,
          panel_id: panelId,
        },
      })
      .then(async ({ data }) => {
        if (data) {
          setDialogArchiveCardVisible(false);
          onRequestClose();
          setCard(emptyCrmCard);
        }
      })
      .finally(() => setIsLoad(false));
  };

  const loadCard = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/crm/card/${id}`)
      .then(async ({ data }) => {
        if (data) {
          setCard({
            ...data,
            crmCardActivities:
              data.crmCardActivities && data.crmCardActivities.length > 0
                ? data.crmCardActivities.filter(
                    (a: ICrmCardActivity) => a.status === EnumStatus.ATIVO,
                  )
                : [],
          });
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  interface ISetAction {
    action_id: string;
    title: string;
  }
  const handleSetAction = async ({ action_id, title }: ISetAction) => {
    await handleSetCard();

    setIsLoad(true);
    await api
      .post(`/crm/card/activity`, {
        data: {
          card_id: id,
          action_id: action_id,
          description: `interagiu com: ${title}`,
          time_start: 0,
          time_end: 0,
          time: 0,
        },
      })
      .then(async ({ data }) => {
        if (data) {
          await loadCard(id);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  interface IHandleFollowCard {
    card_id: string;
    seller_id: string;
  }

  const handleFollowCard = async ({
    card_id,
    seller_id,
  }: IHandleFollowCard) => {
    setIsLoad(true);
    await api
      .post('/crm/cards/sellers', { data: { card_id, seller_id } })
      .then(({ data }) => {
        if (data) {
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const buildComponent = () => {
    if (card.rfb_id || card.person_id) {
      return <div dangerouslySetInnerHTML={{ __html: card.component || '' }} />;
    }
  };

  const removeTag = async (tag_id: string) => {
    setIsLoad(true);
    await api
      .delete(`/crm/cards/tags/${card.id}/${tag_id}`)
      .then(async ({ data }) => {
        if (data) {
          loadCard(card.id);
          setMessageRemoveTagVisible(false);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const removeSeller = async (id: string) => {
    setIsLoad(true);
    await api
      .delete(`/crm/cards/sellers/${card.id}/${id}/${panelId}`)
      .then(async ({ data }) => {
        if (data) {
          loadCard(card.id);
          setMessageRemoveSellerVisible(false);
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const deleteActivity = async (id: string) => {
    setIsLoad(true);

    api
      .delete(`/crm/card/activity/${id}`)
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Atividade removida com sucesso!');
          loadCard(card.id);
        }
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const handleTransferCard = async () => {
    setIsLoad(true);

    await api
      .post('/crm/card/transfer', {
        data: {
          card_ids: [card.id],
          column_id: transferCardsData.column_id,
        },
      })
      .then(({ data }) => {
        if (data) {
          toast('success', 'Sucesso', 'Card(s) transferido(s) com sucesso!');
          setDialogTransferCardsVisible(false);
          onRequestClose();
        }
      })
      .catch((err: any) => toast('error', 'Erro', messageRequestError(err)))
      .finally(() => setIsLoad(false));
  };

  const loadPanelsByUser = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/crm/panels/user/${id}`)
      .then(({ data }) => {
        if (data) {
          setPanels(
            data.map((i: any) => {
              return {
                value: i.id,
                label: i.title,
              };
            }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  const loadColumnsByPanel = async (id: string) => {
    setIsLoad(true);
    await api
      .get(`/crm/column/panel/${id}`)
      .then(({ data }) => {
        if (data) {
          setColumns(
            data
              .sort((a: any, b: any) => a.position - b.position)
              .map((i: any) => {
                return {
                  value: i.id,
                  label: i.title,
                };
              }),
          );
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setIsLoad(false));
  };

  useEffect(() => {
    loadUserData();
    loadUsers();
  }, []);

  useEffect(() => {
    if (isOpen && validate(id)) {
      loadActions();
      loadCard(id);
    }
  }, [
    isOpen,
    dialogActionVisible,
    dialogPriorityVisible,
    dialogTagsVisible,
    dialogTagsVisible,
    dialogScheduleVisible,
    dialogScheduleVisible,
  ]);

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => {
          onRequestClose();
          setCard(emptyCrmCard);
        }}
        className="w-full md:w-7"
      >
        <Form
          ref={formRef}
          onSubmit={d => null}
          className="flex flex-wrap md:flex-nowrap"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div
            className="card w-full md:w-9 bg-gray-100 mx-2"
            style={{ minHeight: '70vh' }}
          >
            <div className="p-fluid grid formgrid">
              <div className="field col-10 md:col-11">
                <label htmlFor="title">Título</label>
                <InputText
                  name="title"
                  value={card.title}
                  onChange={e =>
                    setCard({ ...card, title: e.currentTarget.value })
                  }
                  onKeyDown={async e => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      await handleSetCard();
                      e.preventDefault();
                    }
                  }}
                  placeholder="Ex.: João da Silva / Ligar cliente"
                  className="p-inputtext-lg"
                />
              </div>
              <div className="col-1  align-items-center justify-content-center">
                <Button
                  icon="fa-solid fa-flag"
                  aria-label="Filter"
                  className={
                    card.priority === 'B'
                      ? 'p-button-rounded p-button-success'
                      : card.priority === 'M'
                      ? 'p-button-rounded p-button-warning'
                      : 'p-button-rounded p-button-danger'
                  }
                  type="button"
                  onClick={() => setDialogPriorityVisible(true)}
                />
              </div>
              <div className="field col-9 mb-2">
                <div className="flex flex-wrap justify-content-start align-items-center">
                  {card.crmTags
                    ? card.crmTags
                        .sort((a, b) => a.position - b.position)
                        .map((i: ICrmTag) => (
                          <div
                            onClick={() => removeTag(i.id)}
                            onMouseEnter={() =>
                              setMessageRemoveTagVisible(true)
                            }
                            onMouseLeave={() =>
                              setMessageRemoveTagVisible(false)
                            }
                          >
                            <Tag
                              key={i.id}
                              value={i.title}
                              className="mr-2 mt-1 cursor-pointer"
                              style={{ background: `${i.color}` }}
                            ></Tag>
                          </div>
                        ))
                    : ''}
                </div>
                {messageRemoveTagVisible && (
                  <label className="mt-1">Clique para remover</label>
                )}
              </div>
              <div className="field col-8 md:col-9 mb-2">
                <label>
                  {card.crmCardSellers &&
                  card.crmCardSellers.find(s => s.status === 'A')
                    ? 'Membros'
                    : 'Nenhum membro adicionado'}
                </label>
                <div className="flex flex-wrap justify-content-start align-items-center">
                  {card.crmCardSellers
                    ? card.crmCardSellers.map((s: ICrmCardSellers) =>
                        s.status === 'A' ? (
                          <div
                            onClick={() => removeSeller(s.id)}
                            className="flex align-items-center m-2 justify-content-center"
                            onMouseEnter={() =>
                              setMessageRemoveSellerVisible(true)
                            }
                            onMouseLeave={() =>
                              setMessageRemoveSellerVisible(false)
                            }
                          >
                            <Avatar
                              image={`${
                                process.env.REACT_APP_SERVER_URL
                              }/files/img/${
                                s.seller.avatar_url &&
                                s.seller.avatar_url !== null
                                  ? user.avatar_url
                                  : 'no-photo.jpg'
                              }`}
                              size="normal"
                              shape="circle"
                              className="flex align-items-center justify-content-center mr-3"
                            />
                            <div className="">
                              <p className="mt-1 pt-1 mb-0 pb-0 text-sm">
                                {ClearName(s.seller.name)}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ''
                        ),
                      )
                    : ''}
                </div>
                {messageRemoveSellerVisible && (
                  <label className="mt-1">Clique para remover</label>
                )}
              </div>

              <div className="field col-4 md:col-3 mb-2">
                <Button
                  label="Seguir"
                  icon="fa-solid fa-eye"
                  className="p-button-secondary"
                  type="button"
                  disabled={hasUserInSellers()}
                  onClick={async () =>
                    await handleFollowCard({
                      card_id: card.id,
                      seller_id: user.id,
                    })
                  }
                />
              </div>
              <div className="field col-6 mb-2 mt-2">
                <label htmlFor="customer">
                  Cliente
                  <i
                    className="pi pi-search mx-3"
                    style={{ color: 'var(--primary-color)' }}
                  ></i>
                </label>
                <InputText
                  name="customer"
                  placeholder="Ex.: João da Silva"
                  readOnly
                  disabled
                />
              </div>
              <div className="field col-3">
                <label htmlFor="remember">Lembrete</label>
                <InputText
                  name="remember"
                  value={
                    card.crmCardDates && card.crmCardDates.length > 0
                      ? format(
                          new Date(
                            card.crmCardDates[
                              card.crmCardDates.length - 1
                            ].date_alert,
                          ),
                          'dd/MM/yy H:m',
                        )
                      : ''
                  }
                  placeholder="00/00/00 00:00"
                  disabled
                  readOnly
                />
              </div>
              <div className="field col-3 mb-2">
                <label htmlFor="value">Valor</label>
                <InputNumber
                  name="value"
                  value={card.value}
                  onChange={e => {
                    setCard({ ...card, value: e.value as number });
                  }}
                  onKeyDown={async e => {
                    if (e.key === 'Enter' || e.key === 'Tab') {
                      await handleSetCard();
                      e.preventDefault();
                    }
                  }}
                  placeholder="0,00"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  mode="decimal"
                />
              </div>
              <div className="field col-12">
                <div className="flex flex-wrap justify-content-between align-items-center gap-3 mt-3">
                  <h5 className="my-2">
                    <i className="fa-solid fa-align-left mr-3"></i>
                    Descrição
                  </h5>
                  <div className="">
                    <Button
                      type="button"
                      label="Salvar"
                      className="p-button-sm"
                      icon="fa-solid fa-floppy-disk"
                      onClick={() => handleSetCard()}
                    />
                  </div>
                </div>

                <Editor
                  value={card.description}
                  onTextChange={e =>
                    setCard({ ...card, description: e.textValue })
                  }
                  style={{ height: 128 }}
                  className="line-heigth-3"
                />
              </div>
              <div className="field col-12">
                <h5>
                  <span className="fa-solid fa-hand-pointer mr-3"></span>
                  Interações
                </h5>
                <div className="flex flex-wrap justify-content-center">
                  {actions
                    .sort((a, b) => a.title.length - b.title.length)
                    .map((i: ICrmAction) => (
                      <Button
                        key={i.id}
                        icon={`${i.icon}`}
                        label={i.title}
                        style={{ width: `${20 + 13 * i.title.length}px` }}
                        className="flex p-button-rounded p-button-raised p-button-info mr-2 mb-2 p-auto"
                        onClick={async () =>
                          await handleSetAction({
                            action_id: i.id,
                            title: i.title,
                          })
                        }
                        type="button"
                      />
                    ))}
                </div>
              </div>
              <Divider />
              <div className="field col-12 my-2">
                <h5>
                  <span className="fa-solid fa-comments mr-3"></span>
                  Atividades
                </h5>
                <div className="flex justify-content-start">
                  <Avatar
                    image={`${process.env.REACT_APP_SERVER_URL}/files/img/${
                      user.avatar_url || 'no-photo.jpg'
                    }`}
                    size="large"
                    shape="circle"
                    className="flex align-items-center justify-content-center mr-3"
                  />
                  <InputText
                    name="input_description"
                    value={activityText}
                    onChange={e => setActivityText(e.currentTarget.value)}
                    onKeyDown={async e => {
                      if (e.key === 'Enter' || e.key === 'Tab') {
                        await handleCreateActivity();
                        e.preventDefault();
                      }
                    }}
                    className="flex align-items-center justify-content-center"
                    placeholder="Ex.: O cliente gostou do produto xyz!"
                  />
                </div>

                <Divider />

                <div
                  style={{ maxHeight: 512 }}
                  className="overflow-scroll w-full"
                >
                  {card.crmCardActivities
                    ? card.crmCardActivities
                        .sort(
                          (a, b) =>
                            new Date(b.created_at).getTime() -
                            new Date(a.created_at).getTime(),
                        )
                        .map((i: ICrmCardActivity) => (
                          <div className="flex">
                            <div
                              key={i.id}
                              className="flex justify-content-start relative my-3 w-11"
                            >
                              <Avatar
                                image={`${
                                  process.env.REACT_APP_SERVER_URL
                                }/files/img/${
                                  i.user.avatar_url || 'no-photo.jpg'
                                }`}
                                size="large"
                                shape="circle"
                                className="flex align-items-center justify-content-center mr-3"
                              />
                              <div className="">
                                <p className="mt-1 pt-1 mb-0 pb-0">
                                  <strong>{i.user.name || ''}</strong>:{' '}
                                  {i.description}
                                </p>
                                <p
                                  className="mt-0 pt-0"
                                  style={{ marginTop: 2 }}
                                >
                                  {format(
                                    subHours(new Date(i.created_at), 3),
                                    'dd/MM/yy H:mm',
                                  )}
                                </p>
                              </div>
                            </div>
                            {isBefore(
                              hoje,
                              addMinutes(
                                subHours(new Date(i.created_at), 3),
                                10,
                              ),
                            ) && (
                              <div
                                className="flex justify-content-center align-items-center w-1 cursor-pointer"
                                onClick={() => deleteActivity(i.id)}
                              >
                                <i className="pi pi-trash"></i>
                              </div>
                            )}
                          </div>
                        ))
                    : ''}
                </div>
              </div>
            </div>
          </div>
          <div
            className="card w-full md:w-3 bg-gray-100 mx-2"
            style={{ minHeight: '70vh' }}
          >
            <h5>Funções</h5>
            <Button
              label="Membros"
              className="w-12 p-button-secondary mb-2"
              icon="fa-solid fa-users"
              type="button"
              onClick={() => setDialogSellerVisible(true)}
            />

            <Button
              label="Interações"
              className="w-12 p-button-secondary mb-2"
              icon="fa-solid fa-hand-pointer"
              type="button"
              onClick={() => setDialogActionVisible(true)}
            />

            <Button
              label="Prioridade"
              className="w-12 p-button-secondary mb-2"
              icon="fa-solid fa-ranking-star"
              type="button"
              onClick={() => setDialogPriorityVisible(true)}
            />

            <Button
              label="Tags"
              className="w-12 p-button-secondary mb-2"
              icon="fa-solid fa-tags"
              type="button"
              onClick={() => setDialogTagsVisible(true)}
            />

            <Button
              label="Anexo"
              className="w-12 p-button-secondary mb-2"
              icon="fa-solid fa-paperclip"
              type="button"
              onClick={() =>
                toast(
                  'warn',
                  'Alerta',
                  'Essa função só é possível no plano Platinum!',
                )
              }
            />

            <Button
              label="Lembretes"
              className="w-12 p-button-secondary mb-2"
              icon="fa-solid fa-calendar"
              type="button"
              onClick={() => setDialogScheduleVisible(true)}
            />

            <Divider />

            <Button
              label="Transferir"
              className="w-12 p-button-secundary mb-2"
              icon="fa-solid fa-paper-plane"
              type="button"
              loading={isLoad}
              onClick={() => setDialogTransferCardsVisible(true)}
            />

            <Button
              label="Arquivar"
              className="w-12 p-button-danger mb-2"
              icon="fa-solid fa-box-archive"
              type="button"
              onClick={() => setDialogArchiveCardVisible(true)}
            />

            <Divider />

            {buildComponent()}
          </div>
        </Form>
      </SideBar>

      <Dialog
        header="Equipe"
        visible={dialogSellerVisible}
        style={{ width: 512 }}
        onHide={() => setDialogSellerVisible(false)}
      >
        {users.map((u: IUser) => (
          <div
            className="flex justify-content-start relative my-3 hover:bg-blue-100 cursor-pointer"
            onClick={() => handleSetSeller(u.id)}
          >
            <Avatar
              image={`${process.env.REACT_APP_SERVER_URL}/files/img/${
                u.avatar_url && u.avatar_url !== null
                  ? user.avatar_url
                  : 'no-photo.jpg'
              }`}
              size="large"
              shape="circle"
              className="flex align-items-center justify-content-center mr-3"
            />
            <div className="">
              <p className="mt-1 pt-1 mb-0 pb-0 text-xl">{u.name}</p>
            </div>
          </div>
        ))}
      </Dialog>

      <DialogSchedule
        isOpen={dialogScheduleVisible}
        onRequestClose={() => setDialogScheduleVisible(false)}
        card_id={id}
        schedules={card.crmCardDates}
        panel_id={panelId}
      />

      <Dialog
        header="Confirmar arquivamento?"
        visible={dialogArchiveCardVisible}
        style={{ width: 512 }}
        onHide={() => setDialogArchiveCardVisible(false)}
      >
        <Form
          ref={null}
          onSubmit={() => null}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
        >
          <div className="field col-12">
            Você tem certeza que deseja excluir/arquivar esse card? Lembrando
            que os cards nunca são exlcuidos por tem histórico de consumo de
            créditos de contatos gerados.
          </div>
          <div className="field col-6">
            <Button
              label="Não"
              icon="fa-solid fa-check"
              type="button"
              className="p-button-info"
              onClick={() => null}
            />
          </div>

          <div className="field col-6">
            <Button
              label="Sim"
              icon="fa-solid fa-box-archive"
              type="button"
              className="p-button-danger"
              onClick={async () => await handleArchiveCard(id)}
            />
          </div>
        </Form>
      </Dialog>

      <Dialog
        header="Transferir card"
        visible={dialogTransferCardsVisisble}
        className="w-10 md:w-4"
        onHide={() => setDialogTransferCardsVisible(false)}
      >
        <Form
          ref={null}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={null}
          onPointerLeaveCapture={null}
          onSubmit={handleTransferCard}
        >
          <div className="field col-12">
            <label htmlFor="user">Usuário</label>
            <InputDropDown
              name="user"
              options={users}
              value={transferCardsData.user_id}
              placeholder="Selecione..."
              onChange={async e => {
                setTransferCardsData({
                  ...transferCardsData,
                  user_id: e.value,
                });
                await loadPanelsByUser(e.value);
              }}
              filter
            />
          </div>

          <div className="field col-6">
            <label htmlFor="panel">Painel</label>
            <InputDropDown
              name="panel"
              options={panels}
              value={transferCardsData.panel_id}
              placeholder="Selecione..."
              disabled={!validate(transferCardsData.user_id)}
              onChange={async e => {
                setTransferCardsData({
                  ...transferCardsData,
                  panel_id: e.value,
                });
                await loadColumnsByPanel(e.value);
              }}
              filter
            />
          </div>

          <div className="field col-6">
            <label htmlFor="collumn">Coluna</label>
            <InputDropDown
              name="collumn"
              value={transferCardsData.column_id}
              options={columns}
              placeholder="Selecione..."
              disabled={!validate(transferCardsData.panel_id)}
              onChange={e => {
                setTransferCardsData({
                  ...transferCardsData,
                  column_id: e.value,
                });
              }}
              filter
            />
          </div>

          <div className="field col-12">
            <Button
              label="Transferir"
              icon="fa-solid fa-check"
              type="submit"
              className="p-button-success"
              disabled={!validate(transferCardsData.column_id)}
              loading={isLoad}
            />
          </div>
        </Form>
      </Dialog>

      <DialogPriority
        isOpen={dialogPriorityVisible}
        onRequestClose={() => setDialogPriorityVisible(false)}
        card={card}
        panelId={panelId}
      />

      <DialogAction
        isOpen={dialogActionVisible}
        onRequestClose={() => setDialogActionVisible(false)}
      />

      <DialogTags
        isOpen={dialogTagsVisible}
        onRequestClose={() => setDialogTagsVisible(false)}
        card_id={card.id}
      />
    </>
  );
};

export default CrmSidebarCard;

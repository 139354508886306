/* eslint-disable react/react-in-jsx-scope */
import { FormHandles, Scope } from '@unform/core';
import { Form } from '@unform/web';
import { AxiosError } from 'axios';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Divider } from '../../../components/Divider';
import { InputDropDown } from '../../../components/Inputs/InputDropDown';
import { InputMask } from '../../../components/Inputs/InputMask';
import { InputPhone } from '../../../components/Inputs/InputPhone';
import { InputText } from '../../../components/Inputs/InputText';
import useToastContext from '../../../hooks/toast';
import api, { apiCep } from '../../../services/api';
import { City } from '../../../types/city';
import { Option } from '../../../types/optional';
import LimpaCpfCnpj from '../../../utils/LimpaCpfCnpj';
import getValidationErrors from '../../../utils/getErrorsValidation';
import messageRequestError from '../../../utils/messageRequestError';
import IOptionsDTO from '../../business/dtos/IOptionsDTO';
import { ICreateCustomerShort } from '../dtos/IPerson';
import PersonsHeaderButtons from '../header-buttons';
import UfsOpts from '../types/UfsOpts';
import { useOrder } from '../../../hooks/useOrder';

const createCustomerShort = (): React.ReactElement => {
  const router = useHistory();
  const toast = useToastContext();

  const formRef = useRef<FormHandles>(null);

  const [load, setLoad] = useState<boolean>(false);
  const [customer, setCustomer] = useState<ICreateCustomerShort>(
    {} as ICreateCustomerShort,
  );

  const [allCities, setAllcities] = useState<City[]>([]);
  const [city, setCity] = useState<string>();
  const [citiesOpts, setCitiesOpts] = useState<IOptionsDTO[]>([]);
  const [uf, setUf] = useState<string>();
  const [disableAddressFields, setDisableAddressFields] =
    useState<boolean>(true);

  const defineOptionsCity = (uf: string) => {
    const filterCity = allCities.filter(item => item.uf.sigla === uf);
    const optionsCity = filterCity.map(item => ({
      label: item.name,
      value: item.id,
    }));
    setCitiesOpts(optionsCity);
    return optionsCity;
  };

  const filterOptionCity = (city: string, cityFiltred: Option[]) => {
    return cityFiltred.filter(item => item.label === city);
  };

  const searchCityInBackend = async (city: string, uf: string) => {
    if (!city) {
      toast('warn', 'Cidade não informada!');
      return;
    }

    if (!uf) {
      toast('warn', 'Uf não informado!');
      return;
    }

    try {
      let cities = [];
      let citiesFiltered = [];

      if (allCities.length <= 0) {
        const { data } = await api.get('/cities');

        if (!data) {
          toast('warn', 'Falha ao buscar as cidades no backend!');
        }

        cities = data;
        setAllcities(data);
      }

      if (citiesOpts.length === 0) {
        citiesFiltered = cities.filter((item: City) => item.uf?.sigla === uf);
      }

      if (allCities.length > 0) {
        citiesFiltered = allCities.filter((item: City) => item.uf.sigla === uf);
      }

      const myCities: IOptionsDTO[] = citiesFiltered.map((c: City) => {
        return {
          label: c.name,
          value: c.id,
        };
      });

      setCitiesOpts(myCities);

      const cidadeSelecionada = myCities.find(
        (i: IOptionsDTO) => i.label === city,
      );

      if (cidadeSelecionada) {
        setCity(cidadeSelecionada.value);
      }

      setUf(uf);
    } catch (e: any) {
      toast('warn', e);
    }
  };

  const handleSearchAddressByCep = async (e: any) => {
    try {
      e.preventDefault();
      setLoad(true);

      if (customer.address?.post_code === '') {
        toast(
          'warn',
          'Alerta',
          `O cep informado [${customer.address.post_code}] é inválido!`,
        );
        setLoad(false);
        return;
      }

      const cleanCep = LimpaCpfCnpj(customer.address?.post_code || e);
      if (cleanCep.length === 8) {
        await apiCep
          .get(`https://viacep.com.br/ws/${cleanCep}/json`)
          .then(async ({ data }) => {
            if (data.erro === true) {
              toast(
                'error',
                'O Cep informado está incompleto ou foi desativado nos correios!',
              );
              return;
            }

            setCustomer({
              ...customer,
              address: {
                ...customer.address,
                street: data.logradouro,
                number: 's/n',
                neighborhood: data.bairro,
                complement: data.complemento,
              },
            });

            await searchCityInBackend(data.localidade, data.uf);

            setDisableAddressFields(false);
          })
          .catch((e: any) => {
            setDisableAddressFields(true);
            toast('error', e);
            return;
          });
      } else {
        toast('warn', 'O Cep informado é inválido!');
        return;
      }
    } catch (e: any) {
      toast(
        'warn',
        'Alerta',
        'Falha ao buscar o cep da empresa nos correios! Digite manualmente.',
      );
      return;
    } finally {
      setLoad(false);
    }
  };

  const handleClearForm = async () => {
    setLoad(true);
    setCustomer({} as ICreateCustomerShort);
    setLoad(false);
  };

  const handleSubmit = async () => {
    formRef.current?.setErrors({});
    customer.cpf_cnpj = LimpaCpfCnpj(customer.cpf_cnpj || '');

    try {
      setLoad(true);

      const validation = Yup.object({
        name: Yup.string()
          .required('Informe pelo menos o nome do cliente!')
          .max(64, 'O nome não pode ter mais de 64 caracteres!'),
        cpf_cnpj: customer.cpf_cnpj
          ? Yup.string().length(11, 'CPF inválido!')
          : Yup.string().optional(),
        phone: customer.phone
          ? Yup.string().min(13, 'Número de telefone inválido!')
          : Yup.string().optional(),
        address: customer.address
          ? Yup.object({
              post_code: Yup.string().required(
                'Caso for informar os dados de endereço, informe o CEP!',
              ),
              street: Yup.string().required(
                'Caso for informar os dados de endereço, informe a rua!',
              ),
              neighborhood: Yup.string().required(
                'Caso for informar os dados de endereço, informe o bairro!',
              ),
              number: Yup.string().required(
                'Caso for informar os dados de endereço, informe o número ou (s/n)!',
              ),
              complement: Yup.string(),
              city_id: Yup.string().required(
                'Caso for informar os dados de endereço, informe a cidade!',
              ),
            })
          : Yup.object().optional(),
      });
      await validation.validate(customer, { abortEarly: false });

      await api
        .post('/persons/customer/short', { customer })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Cliente criado com sucesso!');
            handleClearForm();
            router.push('/persons/list');
          }
        })
        .catch(err => toast('warn', 'Alerta', messageRequestError(err)));
    } catch (error: any) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
        toast('warn', 'Alerta', error.errors[0]);
      } else {
        toast('error', 'Error', error.response?.data?.error);
      }
      if (error instanceof AxiosError) {
        if (error.code === 'ERR_NETWORK') {
          toast(
            'error',
            'Ops...',
            'Falha no sinal da rede! Aguarde reestabelecer conexão!',
          );
          return;
        }
      }
    } finally {
      setLoad(false);
    }
  };

  useEffect(() => {
    if (city) {
      setCustomer({
        ...customer,
        address: { ...customer.address, city_id: city },
      });
    }
  }, [city]);

  useEffect(() => {
    if (customer.address?.post_code === '') {
      setCustomer({ ...customer, address: undefined });
    }
  }, [customer.address?.post_code]);

  useEffect(() => {
    setLoad(true);
    api
      .get('/cities')
      .then(({ data }) => {
        setAllcities(data);
      })
      .finally(() => setLoad(false));
  }, []);

  return (
    <>
      <PersonsHeaderButtons />
      {load && <ProgressBar mode="indeterminate" style={{ height: '4px' }} />}
      <Form
        ref={formRef}
        onSubmit={handleSubmit}
        className="card"
        style={{ width: '60vh' }}
        placeholder={''}
        onPointerEnterCapture={null}
        onPointerLeaveCapture={null}
      >
        <div className="p-fluid grid formgrid">
          <div className="field col-12 md:col-12">
            <label htmlFor="name">Nome</label>
            <InputText
              name="name"
              placeholder="Ex.: Nome do cliente..."
              value={customer.name}
              onChange={e =>
                setCustomer({ ...customer, name: e.currentTarget.value })
              }
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="cpf_cnpj">CPF</label>
            <InputMask
              name="cpf_cnpj"
              value={customer.cpf_cnpj}
              mask={'999.999.999-99'}
              placeholder={'000.000.000-00'}
              autoClear={false}
              onChange={e => setCustomer({ ...customer, cpf_cnpj: e.value })}
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="phone">Telefone</label>
            <InputPhone
              name="phone"
              value={customer.phone}
              onChange={e =>
                setCustomer({ ...customer, phone: e.currentTarget.value })
              }
              placeholder="Ex.: (00) 00000-0000"
            />
          </div>

          <Divider align="left">
            <span className="p-tag">Endereço</span>
          </Divider>

          <Scope path="address">
            <div className="field col-12 md:col-6">
              <label htmlFor="post_code">CEP</label>
              <InputMask
                mask="99.999-999"
                name="post_code"
                placeholder="Ex.: 00.000-000"
                value={customer.address?.post_code}
                onChange={e =>
                  setCustomer({
                    ...customer,
                    address: { ...customer.address, post_code: e.value },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-6 flex align-items-end">
              <Button
                label="Preencher"
                onClick={e => handleSearchAddressByCep(e)}
                loading={load}
                icon="fa-solid fa-location-dot"
                type="button"
                disabled={customer.address?.post_code ? false : true}
              />
            </div>

            <div className="field col-12 md:col-9">
              <label htmlFor="street">Rua/Avenida</label>
              <InputText
                name="street"
                placeholder="Ex.: Rua da amizade"
                value={customer.address?.street}
                disabled={disableAddressFields || !customer.address?.post_code}
                onChange={e =>
                  setCustomer({
                    ...customer,
                    address: {
                      ...customer.address,
                      street: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>
            <div className="field col-12 md:col-3">
              <label htmlFor="number">Número</label>
              <InputText
                name="number"
                placeholder="Ex.: 000"
                value={customer.address?.number}
                disabled={disableAddressFields || !customer.address?.post_code}
                onChange={e =>
                  setCustomer({
                    ...customer,
                    address: {
                      ...customer.address,
                      number: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="neighborhood">Bairro</label>
              <InputText
                name="neighborhood"
                placeholder="Ex.: Bairro da saudade"
                value={customer.address?.neighborhood}
                disabled={disableAddressFields || !customer.address?.post_code}
                onChange={e =>
                  setCustomer({
                    ...customer,
                    address: {
                      ...customer.address,
                      neighborhood: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="complement">Complemento | Referência</label>
              <InputText
                name="complement"
                placeholder="Ex.: Qnd 00 Lt 00, Apto 000"
                value={customer.address?.complement}
                disabled={disableAddressFields || !customer.address?.post_code}
                onChange={e =>
                  setCustomer({
                    ...customer,
                    address: {
                      ...customer.address,
                      complement: e.currentTarget.value,
                    },
                  })
                }
              />
            </div>

            <div className="field col-12 md:col-6">
              <label htmlFor="state">UF (Estado)</label>
              <InputDropDown
                name="state"
                options={UfsOpts}
                disabled={disableAddressFields || !customer.address?.post_code}
                value={uf}
                filter={true}
                onChange={e => {
                  setUf(e.value);
                  defineOptionsCity(e.value);
                }}
                placeholder="Ex.: Selecionar..."
                emptyMessage="Não existe UF..."
              />
            </div>
            <div className="field col-12 md:col-6">
              <label htmlFor="city_id">Cidade (Munincípio)</label>
              <InputDropDown
                name="city_id"
                value={customer.address?.city_id}
                options={citiesOpts}
                filter={true}
                disabled={disableAddressFields || !customer.address?.post_code}
                onChange={e =>
                  setCustomer({
                    ...customer,
                    address: { ...customer.address, city_id: e.value },
                  })
                }
                placeholder="Ex.: Selecionar..."
                emptyMessage="Não existe cidades listadas..."
              />
            </div>
          </Scope>

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="pi pi-times"
              onClick={e => handleClearForm()}
              className="p-button-danger"
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="pi pi-check"
              className="p-button-success"
              type="submit"
              loading={load}
            />
          </div>
        </div>
      </Form>
    </>
  );
};

export default createCustomerShort;

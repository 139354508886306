import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { DataTable } from '../../../components/DataTable';
import { Dialog } from '../../../components/Modal';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Tag } from 'primereact/tag';
import renderColumnPosition from '../../../components/DataTableColumns/RenderColumnPosition';
import renderColumnStatus from '../../../components/DataTableColumns/RenderColumnStatus';
import useToastContext from '../../../hooks/toast';
import api from '../../../services/api';
import { parseToCnpj, parseToCpf } from '../../../utils/cpf_cnpj_format';
import messageRequestError from '../../../utils/messageRequestError';
import IPersonsTableDTO from '../dtos/IPersonsTableDTO';
import PersonsHeaderButtons from '../header-buttons';
import DialogSendCrmData from '../dialog-send-crm-data';

const PersonsList: React.FC = () => {
  const [load, setLoad] = useState<boolean>(false);

  const [visibleConfirmDialog, setVisibleConfirmDialog] =
    useState<boolean>(false);
  const [persons, setPersons] = useState<IPersonsTableDTO[]>([]);
  const [person, setPerson] = useState<IPersonsTableDTO>(
    {} as IPersonsTableDTO,
  );

  const [personsSelected, setPersonsSelected] = useState<IPersonsTableDTO[]>(
    [],
  );

  const [dialogSendCrmDataVisible, setDialogSendCrmDataVisible] =
    useState(false);

  const [globalFilter, setGlobalFilter] = useState<string>('');

  const formRef = useRef<FormHandles>(null);
  const [keyword, setKeyword] = useState<string>('');

  const toast = useToastContext();
  const navigate = useHistory();

  const listPersons = async () => {
    setLoad(true);
    await api
      .post('/persons/table', {
        pagination: {
          page: 1,
          perPage: 10000,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        setPersons(data);
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const deletePerson = async (id: string) => {
    setLoad(true);
    await api
      .delete(`/persons/${id}`)
      .then(({ data }) => {
        toast('success', 'Sucesso', 'Cliente exlcuído com sucesso!');
        listPersons();
      })
      .catch((err: any) => {
        toast('error', 'Erro', messageRequestError(err));
      })
      .finally(() => {
        setLoad(false);
      });
  };

  const searchPerson = async (keyword: string) => {
    if (keyword.length < 3) {
      toast('warn', 'Alerta', 'A busca deve ter no mínimo 3 caracteres!');
      return;
    }

    setLoad(true);
    await api
      .post(`/persons/src`, {
        data: {
          search: keyword,
          status: ['A'],
        },
      })
      .then(({ data }) => {
        if (data.length === 0) {
          toast(
            'warn',
            'Alerta',
            'Nenhum resultado encontrado com osparâmetrosinformados! ',
          );
        } else {
          setPersons(data);

          toast('success', 'Sucesso', 'Busca concluída com sucesso!');
        }
      })
      .catch((err: any) => {
        toast('warn', 'Alerta', messageRequestError(err));
      })
      .finally(() => setLoad(false));
  };

  const handleOpenConfirmDialog = (id: string) => {
    setVisibleConfirmDialog(true);
    const _p = persons.find(i => i.id === id);
    if (_p) {
      setPerson(_p);
    }
  };

  const handleCloseConfirmDialog = () => {
    setVisibleConfirmDialog(false);
    setPerson({} as IPersonsTableDTO);
  };

  const confirmDeletePerson = async (id: string) => {
    await deletePerson(id);
    setVisibleConfirmDialog(false);
  };

  const nameBodyTemplate = (rowData: any) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        {rowData.image_url ? (
          <img
            src={`${process.env.REACT_APP_SERVER_URL}/files/img/${rowData.image_url}`}
            alt={rowData.title}
            className="shadow-2"
            width="50"
          />
        ) : (
          <i
            className="fa-solid fa-image text-6xl"
            style={{ color: 'var(--gray-300)' }}
          ></i>
        )}
      </>
    );
  };

  const personBodyTemplate = (rowData: any) => {
    return (
      <span>
        {rowData.cpf_cnpj.length > 11
          ? parseToCnpj(rowData.cpf_cnpj)
          : parseToCpf(rowData.cpf_cnpj)}
      </span>
    );
  };

  const templateColumnTipo = (rowData: any) => {
    return (
      <>
        {rowData.customer === 'S' && (
          <Tag icon="fa-solid fa-user" className="surface-500 pl-2 mr-1"></Tag>
        )}
        {rowData.transporter === 'S' && (
          <Tag icon="fa-solid fa-truck" className="surface-500 pl-2 mr-1"></Tag>
        )}
        {rowData.provider === 'S' && (
          <Tag
            icon="fa-solid fa-industry"
            className="surface-500 pl-2 mr-1"
          ></Tag>
        )}
      </>
    );
  };

  const statusBodyTemplate = (rowData: any) => {
    return (
      rowData.status === 'A' && (
        <Tag
          icon="fa-solid fa-check"
          className="pl-2 mr-1"
          style={{ background: 'var(--green-500)' }}
        ></Tag>
      )
    );
  };

  const renderColumnCityUf = (rowData: any) => {
    if (rowData.city) {
      return <span>{`${rowData.city}-${rowData.uf}`}</span>;
    } else {
      return <span>-</span>;
    }
  };

  const actionBodyTemplate = (row: IPersonsTableDTO) => {
    return (
      <div className="flex justify-content-between">
        <Button
          icon="pi pi-pencil"
          className="p-button-raised mr-4"
          onClick={() => navigate.push(`/persons/edit/${row.id}`)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-raised p-button-danger"
          onClick={() => handleOpenConfirmDialog(row.id)}
        />
      </div>
    );
  };

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
      <h5 className="m-0">Lista de Clientes</h5>
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onChange={e => setGlobalFilter(e.currentTarget.value)}
          placeholder="Buscar..."
          autoComplete="off"
        />
      </span>
    </div>
  );

  useEffect(() => {
    listPersons();
  }, []);

  const handleOpenDialogSendCrmData = () => {
    setDialogSendCrmDataVisible(true);
  };

  return (
    <>
      <PersonsHeaderButtons />
      <div className="card">
        <Form
          ref={formRef}
          onSubmit={() => searchPerson(keyword)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
        >
          <div className="field col-12 md:col-5">
            <label htmlFor="keyword">Busca inteligente</label>
            <InputText
              name="keyword"
              type="keyword"
              value={keyword}
              onChange={e => setKeyword(e.currentTarget.value)}
              placeholder="Ex: João da silva / cpf / cnpj"
              min={3}
              max={32}
            />
          </div>
          <div className="field col-12 md:col-3 flex align-items-end">
            <Button
              label="Buscar"
              icon="pi pi-filter"
              className="p-button p-button-info"
              type="submit"
            />
          </div>
        </Form>
      </div>

      <div className="card">
        {personsSelected && personsSelected.length > 0 && (
          <div className="field col-4 mb-1">
            <Button
              label="Enviar CRM"
              icon="fa-solid fa-hand-point-right"
              type="button"
              loading={load}
              onClick={() => handleOpenDialogSendCrmData()}
            />
          </div>
        )}

        <DataTable
          value={persons}
          dataKey="id"
          paginator
          rows={10}
          loading={load}
          rowsPerPageOptions={[10, 20, 30]}
          className="datatable-responsive"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} clientes"
          globalFilter={globalFilter}
          emptyMessage="Não existe cliente."
          header={header}
          responsiveLayout="scroll"
          size="small"
          selectionMode="multiple"
          selection={personsSelected}
          onRowClick={e => {
            let newSelection = personsSelected;

            if (newSelection.find(p => p.id === e.data.id)) {
              newSelection = newSelection.filter(p => p.id !== e.data.id);
            } else {
              newSelection.push(e.data as IPersonsTableDTO);
            }

            setPersonsSelected([...newSelection]);
          }}
        >
          <Column field="id" header="N°" body={renderColumnPosition}></Column>
          <Column header="Cod" field="codigo" align={'center'}></Column>
          <Column
            field="image_url"
            header="Avatar"
            body={nameBodyTemplate}
          ></Column>
          <Column field="name" header="Nome" align={'left'} sortable></Column>
          <Column
            field="required"
            header="Cpf/Cnpj"
            body={personBodyTemplate}
          ></Column>

          <Column
            header="Tipo"
            body={templateColumnTipo}
            align={'center'}
          ></Column>
          <Column header="Cidade" body={renderColumnCityUf}></Column>
          <Column
            field="required"
            header="Status"
            body={d => renderColumnStatus(d)}
            align={'center'}
          ></Column>
          <Column
            header="*"
            body={d => actionBodyTemplate(d)}
            headerStyle={{ width: '5vw' }}
            align={'center'}
          ></Column>
        </DataTable>
      </div>

      <Dialog
        visible={visibleConfirmDialog}
        style={{ width: '450px' }}
        header="Excluir?"
        modal
        onHide={() => handleCloseConfirmDialog()}
        draggable={false}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: '1.5rem' }}
          />
          {person && (
            <span>
              Tem certeza de que deseja excluir.: &nbsp;
              <b>{person.name}</b>?
            </span>
          )}
          <div className="p-fluid grid formgrid pt-5">
            <div className="field col-12 md:col-6">
              <Button
                label="Não"
                type="button"
                icon="pi pi-times"
                className="p-button-danger"
                loading={load}
                onClick={() => handleCloseConfirmDialog()}
              />
            </div>

            <div className="field col-12 md:col-6">
              <Button
                label="Sim"
                icon="pi pi-check"
                className="p-button-success"
                type="button"
                loading={load}
                onClick={() => confirmDeletePerson(person.id)}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <DialogSendCrmData
        companies={personsSelected}
        isOpen={dialogSendCrmDataVisible}
        onRequestClose={() => {
          setPersonsSelected([]);
          setDialogSendCrmDataVisible(false);
        }}
      />
    </>
  );
};

export default PersonsList;

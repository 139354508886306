/* eslint-disable react/react-in-jsx-scope */
import { ReactNode, createContext, useEffect, useState } from 'react';
import { IProductRowOs } from '../pages/consigned/order/dtos/IConOrderDTO';
import IOs from '../pages/os/dtos/IOs';
import IOsObject from '../pages/os/dtos/IOsObject';
import IOsPayment from '../pages/os/dtos/IOsPayment';
import IOsService from '../pages/os/dtos/IOsService';
import { emptyOs } from '../pages/os/types/EmptyOs';
import api from '../services/api';

interface IContextData {
  orderService: IOs;
  setData: (os: IOs) => void;
  clearData: () => void;
  fetchData: (id: string) => void;

  addObject: (item: Partial<IOsObject>) => void;
  remObject: (id: string) => void;

  addService: (item: Partial<IOsService>) => void;
  remService: (id: string) => void;

  addProduct: (item: Partial<IProductRowOs>) => void;
  remProduct: (id: string) => void;

  addPayments: (item: Partial<IOsPayment>[]) => void;
  remPayment: (id: string) => void;
}

export const OrderServiceContext = createContext<IContextData | undefined>(
  undefined,
);

export function OrderServiceProvider({ children }: { children: ReactNode }) {
  const [orderService, setOrderService] = useState<IOs>(emptyOs);

  const setData = (item: IOs) => {
    setOrderService({ ...item });
  };

  const clearData = () => {
    setOrderService(emptyOs);
  };

  const fetchData = (id: string) => {
    api.get(`/os/${id}`).then(({ data }) => {
      if (data) {
        const editOs: IOs = {
          isEdit: true,
          osHeader: {
            id: data.listByIdOs.id,
            b_id: data.listByIdOs.b_id,
            os_number: data.listByIdOs.os_number,
            nat_ope_id: data.listByIdOs.nat_ope_id,
            customer_id: data.listByIdOs.customer_id,
            customer: {
              id: data.listByIdOs.customer.id,
              name: data.listByIdOs.customer.name,
            },
            description: data.listByIdOs.description,
            kind_atendimento: data.listByIdOs.kind_atendimento,
            date_open: new Date(data.listByIdOs.date_open),
            date_close: new Date(data.listByIdOs.date_close),
            date_prevision: new Date(data.listByIdOs.date_prevision),
            contract_id: data.listByIdOs.contract_id,
            technical_id: data.listByIdOs.technical_id,
            commissioned_id: data.listByIdOs.commissioned_id || '',
            priority: data.listByIdOs.priority,
            os_status_id: data.listByIdOs.os_status_id,
            total_objects: data.listByIdOs.total_objects,
            total_services: data.listByIdOs.total_products,
            total_products: data.listByIdOs.total_products,
            total_desc: data.listByIdOs.total_desc,
            perc_desc: data.listByIdOs.perc_desc,
            total_adic: data.listByIdOs.total_adic,
            perc_adic: data.listByIdOs.perc_adic,
            juros: data.listByIdOs.juros,
            total: data.listByIdOs.total,
            obs_public: data.listByIdOs.obs_public,
            obs_private: data.listByIdOs.obs_private,
            user_id: data.listByIdOs.user_id,
            status: data.listByIdOs.status,
            close: data.listByIdOs.close,
            os_registered: data.listByIdOs.os_registered,
          },
          osStatus: data.listByIdOs.osStatus,
          osObjects: data.listByIdOs.osObjects.map((i: any) => {
            return { ...i, object_title: i.object.title || '' };
          }),
          osServices: data.listByIdOs.osServices.map((i: any) => {
            return {
              ...i,
              service_title: i.service.title,
              technical_title: i.technical?.name || null,
            };
          }),
          osProducts: data.listByIdOs.osProducts.map((i: any) => {
            return {
              ...i,
              product_description: i.product.title || '',
              table_price_description: i.tablePrice.title || '',
            };
          }),
          osPayments: data.listByIdOs.osPayments.map((i: any) => {
            return { ...i };
          }),
          osTransporter: {
            tracking_code: data.listByIdOs.osTransporter.tracking_code,
            post_date: data.listByIdOs.osTransporter.post_date,
            delivery_date: data.listByIdOs.osTransporter.delivery_date,
            transporter_id: data.listByIdOs.osTransporter.transporter_id,
            vehicle_plate: data.listByIdOs.osTransporter.vehicle_plate,
            driver: data.listByIdOs.osTransporter.driver,
            kind_freight: data.listByIdOs.osTransporter.kind_freight,
            quantity: data.listByIdOs.osTransporter.quantity,
            kind: data.listByIdOs.osTransporter.kind,
            brand: data.listByIdOs.osTransporter.brand,
            seal_number: data.listByIdOs.osTransporter.seal_number,
            weight_brute: data.listByIdOs.osTransporter.weight_brute,
            weight_liquid: data.listByIdOs.osTransporter.weight_liquid,
            cubage: data.listByIdOs.osTransporter.cubage,
            cost: data.listByIdOs.osTransporter.cost,
            obs: data.listByIdOs.osTransporter.obs,
          },
        };

        setData({ ...editOs });
      }
    });
  };

  const addObject = (item: Partial<IOsObject>) => {
    setOrderService({
      ...orderService,
      osObjects: [
        ...orderService.osObjects.filter(i => i.id !== item.id),
        item,
      ],
    });
  };
  const remObject = (id: string) => {
    setOrderService({
      ...orderService,
      osObjects: [...orderService.osObjects.filter(i => i.id !== id)],
    });
  };

  const addService = (item: Partial<IOsService>) => {
    setOrderService({
      ...orderService,
      osServices: [
        ...orderService.osServices.filter(i => i.id !== item.id),
        item,
      ],
    });
  };
  const remService = (id: string) => {
    setOrderService({
      ...orderService,
      osServices: [...orderService.osObjects.filter(i => i.id !== id)],
    });
  };

  const addProduct = (item: Partial<IProductRowOs>) => {
    setOrderService({
      ...orderService,
      osProducts: [
        ...orderService.osProducts.filter(i => i.id !== item.id),
        item,
      ],
    });
  };

  const remProduct = (id: string) => {
    setOrderService({
      ...orderService,
      osProducts: [...orderService.osProducts.filter(i => i.id !== id)],
    });
  };

  const addPayments = async (item: Partial<IOsPayment>[]) => {
    setOrderService({
      ...orderService,
      osPayments: [...orderService.osPayments, ...item],
    });
  };

  const remPayment = (id: string) => {
    setOrderService({
      ...orderService,
      osPayments: [...orderService.osPayments.filter(i => i.id !== id)],
    });
  };

  useEffect(() => {
    let add: number = 0;
    add += orderService.osServices.reduce((a, i) => {
      a += Number(i.adic) * Number(i.qnt);
      return a;
    }, 0);
    add += orderService.osProducts.reduce((a, i) => {
      a += Number(i.adic) * Number(i.qnt);
      return a;
    }, 0);

    let desc: number = 0;
    desc += orderService.osServices.reduce((a, i) => {
      a += Number(i.desc) * Number(i.qnt);
      return a;
    }, 0);
    desc += orderService.osProducts.reduce((a, i) => {
      a += Number(i.desc) * Number(i.qnt);
      return a;
    }, 0);

    let total: number = 0;
    total += orderService.osServices.reduce((a, i) => {
      a += Number(i.total);
      return a;
    }, 0);
    total += orderService.osProducts.reduce((a, i) => {
      a += Number(i.total);
      return a;
    }, 0);

    setData({
      ...orderService,
      osHeader: {
        ...orderService.osHeader,
        total_objects: orderService.osObjects.length,
        total_services: orderService.osServices.reduce((a, i) => {
          a += Number(i.total);
          return a;
        }, 0),
        total_products: orderService.osProducts.reduce((a, i) => {
          a += Number(i.total);
          return a;
        }, 0),
        total_adic: Number(add),
        total_desc: Number(desc),
        total: Number(total),
      },
    });
  }, [
    orderService.osObjects,
    orderService.osServices,
    orderService.osProducts,
  ]);

  return (
    <OrderServiceContext.Provider
      value={{
        orderService,
        setData,
        clearData,
        fetchData,
        addObject,
        remObject,
        addService,
        remService,
        addProduct,
        remProduct,
        addPayments,
        remPayment,
      }}
    >
      {children}
    </OrderServiceContext.Provider>
  );
}

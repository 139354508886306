import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4, validate } from 'uuid';
import { DataTable } from '../../../../components/DataTable';
import renderColumnDecimal from '../../../../components/DataTableColumns/RenderColumnDecimal';
import renderColumnPosition from '../../../../components/DataTableColumns/RenderColumnPosition';
import { Divider } from '../../../../components/Divider';
import { InputDropDown } from '../../../../components/Inputs/InputDropDown';
import { InputNumber } from '../../../../components/Inputs/InputNumber';
import { InputSwitch } from '../../../../components/Inputs/InputSwitch';
import { InputTextArea } from '../../../../components/Inputs/InputTextArea';
import { SideBar } from '../../../../components/Sidebar';
import useToastContext from '../../../../hooks/toast';
import api from '../../../../services/api';
import messageRequestError from '../../../../utils/messageRequestError';
import PerfectDivision from '../../../../utils/perfectDivision';
import { IPaymentCondition } from '../../../os/osPayments/dtos/IPaymentCondition';
import {
  IConOrder,
  IConOrderItem,
  IConOrderPayment,
  IProductRow,
} from '../dtos/IConOrderDTO';
import { useParamsConsigned } from '../../../../hooks/useParamsConsigned';

interface IProps {
  isOpen: boolean;
  onRequestClose: () => void;
  invoiceDTO: Partial<IConOrder>;
  routeCustomer: string;
}

const SidebarInvoiceCheckout = ({
  isOpen,
  onRequestClose,
  invoiceDTO,
  routeCustomer,
}: IProps): React.ReactElement => {
  const toast = useToastContext();
  const formRef = useRef<FormHandles>(null);
  const router = useHistory();

  const [load, setLoad] = useState<boolean>(false);
  const [payments, setPayments] = useState<IConOrderPayment[]>([]);
  const [paymentConditions, setPaymentsConditions] = useState<
    Array<IPaymentCondition>
  >([]);
  const [paymentCondition, setPaymentCondition] = useState<string>('');
  const [rest, setRest] = useState<number>(0);

  const [isOrder, setIsOrder] = useState('PRO');

  const { paramsConsigned } = useParamsConsigned();

  const [obs, setObs] = useState<string>('');

  const handleSubmit = async (d: any) => {
    if (isOrder === 'PED' && invoiceDTO.customer_id !== routeCustomer) {
      if (payments.length === 0) {
        toast(
          'warn',
          'Alerta',
          'Para pedidos é necessário informar a forma de pagamento!',
        );
        return;
      }

      if (rest !== 0) {
        toast('warn', 'Alerta', 'Informe o total dos pagamentos corretamente!');
        return;
      }
    }

    if (invoiceDTO.id && validate(invoiceDTO.id)) {
      // edita ped/pro

      setLoad(true);
      await api
        .put('/consigned/invoice/update', {
          data: {
            consigned: {
              id: invoiceDTO.id,
              route_id: invoiceDTO.route_id,
              customer_id: invoiceDTO.customer_id,
              commissioned_id: invoiceDTO.commissioned_id,
              nat_ope_id: invoiceDTO.nat_ope_id,
              order_type: isOrder,
              discount: invoiceDTO.discount,
              additional: invoiceDTO.additional,
              fees: invoiceDTO.fees,
              qnt_products: invoiceDTO.qnt_products,
              qnt_itens: invoiceDTO.qnt_itens,
              total_comission: invoiceDTO.total_comission,
              order_value: invoiceDTO.order_value,
              order_total: invoiceDTO.order_total,
              obs: obs,
            },
            items: invoiceDTO.items?.map((i: Partial<IProductRow>) => {
              return {
                id: i.id,
                pos: i.pos,
                table_price_id: i.table_price_id,
                product_id: i.product_id,
                qnt: i.qnt,
                vlr_unit: i.vlr_unit,
                add: i.add,
                desc: i.desc,
                vlr_liq: i.vlr_liq,
                total: i.total,
              };
            }),
            payments: payments.map((i: IConOrderPayment) => {
              return {
                id: i.id,
                pos: i.pos,
                payment_id: i.payment_id,
                serie: i.serie,
                parcel: i.parcel,
                total: i.total,
              };
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Pedido/Proposta editado com sucesso!');
            router.push(`/consigned/route/open/${invoiceDTO.route_id}`);
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err), 10000);
        })
        .finally(() => setLoad(false));
    } else {
      // cria ped/pro
      setLoad(true);
      await api
        .post('/consigned/invoice/create', {
          data: {
            consigned: {
              route_id: invoiceDTO.route_id,
              customer_id: invoiceDTO.customer_id,
              commissioned_id: invoiceDTO.commissioned_id,
              nat_ope_id: invoiceDTO.nat_ope_id,
              order_type: isOrder,
              discount: invoiceDTO.discount,
              additional: invoiceDTO.additional,
              fees: invoiceDTO.fees,
              qnt_products: invoiceDTO.qnt_products,
              qnt_itens: invoiceDTO.qnt_itens,
              total_comission: invoiceDTO.total_comission,
              order_value: invoiceDTO.order_value,
              order_total: invoiceDTO.order_total,
              obs: obs,
            },
            items: invoiceDTO.items?.map((i: IConOrderItem) => {
              return {
                id: i.id,
                pos: i.pos,
                table_price_id: i.table_price_id,
                product_id: i.product_id,
                product: {
                  title: i.product.title,
                },
                qnt: i.qnt,
                vlr_unit: i.vlr_unit,
                add: i.add,
                desc: i.desc,
                vlr_liq: i.vlr_liq,
                total: i.total,
              };
            }),
            payments: payments.map((i: IConOrderPayment) => {
              return {
                id: i.id,
                pos: i.pos,
                payment_id: i.payment_id,
                serie: i.serie,
                parcel: i.parcel,
                total: i.total,
              };
            }),
          },
        })
        .then(({ data }) => {
          if (data) {
            toast('success', 'Sucesso', 'Pedido/Proposta editado com sucesso!');
            router.push(`/consigned/route/open/${invoiceDTO.route_id}`);
          }
        })
        .catch((err: any) => {
          toast('warn', 'Alerta', messageRequestError(err), 10000);
        })
        .finally(() => setLoad(false));
    }
  };

  const clearRow = () => {
    setPaymentCondition('');
    calculateRest();
  };

  const clearForm = () => {
    clearRow();
    setPayments([]);
    setIsOrder('PRO');
    setObs('');
  };

  const handleAddPayment = (id: string, value: number) => {
    const paymentCondition = paymentConditions.find(
      (i: Partial<IPaymentCondition>) => i.value === id,
    );
    if (!paymentCondition) {
      toast('warn', 'Alerta', 'Condição de pagamento não encontrada!');
      return;
    }
    if (value <= 0 || isNaN(value)) {
      toast('warn', 'Alerta', 'Informe um valor válido!');
      return;
    }

    if (paymentCondition.pre_datado) {
      // pre datado
      const parcels = PerfectDivision(value, paymentCondition.qnt_parcelas);
      const pays = parcels.map((parcel, index) => {
        return {
          id: v4(),
          pos: payments.length + 1,
          con_order_id: invoiceDTO.id,
          payment_id: paymentCondition.value,
          payment: paymentCondition.label,
          serie: `${index + 1}#${paymentCondition.qnt_parcelas}`,
          parcel: parcel,
          total: value,
        };
      });
      setPayments([...payments, ...pays]);
    } else {
      // à vista
      const pay = {
        id: v4(),
        pos: payments.length + 1,
        con_order_id: invoiceDTO.id,
        payment_id: paymentCondition.value,
        payment: paymentCondition.label,
        serie: '1#1',
        parcel: value,
        total: value,
      };
      setPayments([...payments, pay]);
    }

    clearRow();
  };

  const calculateRest = () => {
    const pays = payments.reduce((a, i) => {
      a += Number(i.parcel);
      return a;
    }, 0);
    const total = Number(invoiceDTO.order_total);

    setRest(total - pays);
  };

  const handleRemPayment = (id: string) => {
    const noId = payments.filter((i: IConOrderPayment) => i.payment_id !== id);
    setPayments(noId);
  };

  const loadPaymentConditions = async () => {
    setLoad(false);
    await api
      .get('/payments-condition')
      .then(({ data }) => {
        if (data) {
          setPaymentsConditions(
            data
              .sort((a: any, b: any) => a.title - b.title)
              .map((i: any) => {
                return {
                  value: i.id,
                  label: i.title,
                  tipo_juros: i.tipo_juros,
                  pre_datado: i.pre_datado,
                  cartao: i.cartao,
                  qnt_parcelas: Number(i.qnt_parcelas),
                  variacao_dias: Number(i.variacao_dias),
                  taxa_juros_min: Number(i.taxa_juros_min),
                };
              }),
          );
        }
      })
      .catch((e: any) => {
        toast('Falha ao listar as condições de pagamento!');
      })
      .finally(() => setLoad(false));
  };

  useEffect(() => {
    setObs(invoiceDTO.obs || '');
  }, [isOpen]);

  const renderCollumnMenu = (id: string) => {
    return (
      <div>
        <Button
          icon="fa-solid fa-xmark"
          className="p-button-danger p-button-rounded"
          onClick={() => handleRemPayment(id)}
        />
      </div>
    );
  };

  useEffect(() => {
    loadPaymentConditions();
  }, []);

  useEffect(() => {
    calculateRest();
  }, [payments]);

  return (
    <>
      <SideBar
        visible={isOpen}
        position="right"
        onHide={() => onRequestClose()}
        className='w-10 md:w-4'
      >
        <h3>Checkout</h3>
        <Form
          ref={formRef}
          onSubmit={d => handleSubmit(d)}
          className="p-fluid grid formgrid"
          placeholder={''}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <Divider />

          <div className="flex w-full justify-content-center">
            <div className="flex justify-content-between">
              <span className="">Proposta</span>
              <InputSwitch
                name="order_type"
                checked={isOrder}
                falseValue="PRO"
                trueValue="PED"
                onChange={e => setIsOrder(e.value.toString())}
                className='mx-2'
              />
              <span className="">Pedido</span>
            </div>
          </div>

          <Divider />

          {isOrder === 'PED' && !(
            paramsConsigned && paramsConsigned.stock_control === true &&
            invoiceDTO.customer_id === routeCustomer
          ) && (
              <>
                <div className="field col-6 md:col-6">
                  <label htmlFor="payment_id">Condição de pagamento</label>
                  <InputDropDown
                    name="payment_id"
                    options={paymentConditions}
                    placeholder="Selecionar..."
                    value={paymentCondition}
                    onChange={e => {
                      setPaymentCondition(e.value);
                      calculateRest();
                    }}
                    filter
                  />
                </div>

                <div className="field col-6 md:col-3">
                  <label htmlFor="value">Valor</label>
                  <InputNumber
                    name="value"
                    value={!validate(paymentCondition) ? 0 : rest}
                    onChange={e => setRest(Number(e.value))}
                    placeholder="0,00"
                    disabled={!validate(paymentCondition)}
                    className={!validate(paymentCondition) ? 'surface-200' : ''}
                    onFocus={e => {
                      e.target.select();
                      calculateRest();
                    }}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        handleAddPayment(paymentCondition, rest);
                      }
                    }}
                    mode="decimal"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                  />
                </div>

                <div className="field col-3 md:col-3 flex align-items-end">
                  <Button
                    label="Adicionar"
                    type="button"
                    icon="fa-solid fa-plus"
                    disabled={!validate(paymentCondition)}
                    onClick={() => handleAddPayment(paymentCondition, rest)}
                  />
                </div>

                <Divider />

                <DataTable
                  value={payments}
                  responsiveLayout="scroll"
                  paginator
                  rows={10}
                  rowsPerPageOptions={[10, 20, 30]}
                  size="small"
                  emptyMessage="Pagamentos não informados..."
                  selectionMode="single"
                  loading={load}
                  paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                  currentPageReportTemplate="Exibindo {first} to {last} de {totalRecords} pagamentos"
                  className="field col-12"
                  footerColumnGroup={null}
                >
                  <Column header="#" body={renderColumnPosition}></Column>
                  <Column
                    field="payment"
                    header="Descrição"
                    align={'center'}
                  ></Column>
                  <Column header="Serie" field="serie"></Column>
                  <Column
                    header="Valor"
                    align={'center'}
                    body={e => renderColumnDecimal(e.total)}
                  ></Column>
                  <Column
                    header="Parcela"
                    align={'center'}
                    body={e => renderColumnDecimal(e.parcel)}
                    footer={new Intl.NumberFormat('BRL', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }).format(
                      payments.reduce((a, i) => {
                        a += i.parcel;
                        return a;
                      }, 0),
                    )}
                  ></Column>
                  <Column
                    header="*"
                    body={e => renderCollumnMenu(e.payment_id)}
                  ></Column>
                </DataTable>
              </>
            )}

          <div className="field col-12">
            <label htmlFor="obs">Observações/Anotações.:</label>
            <InputTextArea
              name="obs"
              value={obs}
              onChange={e => setObs(e.currentTarget.value)}
              placeholder="Ex: O cliente já fez 2 trocas..."
              rows={5}
              cols={30}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Limpar"
              type="reset"
              icon="fa-solid fa-xmark"
              className="p-button-danger"
              onClick={() => clearForm()}
              loading={load}
            />
          </div>

          <div className="field col-12 md:col-6">
            <Button
              label="Salvar"
              icon="fa-solid fa-hand-holding-dollar"
              className="p-button-success"
              type="submit"
              loading={load}
              disabled={isOrder === 'PED' && payments.length === 0 && invoiceDTO.customer_id !== routeCustomer}
            />
          </div>
        </Form>
      </SideBar>
    </>
  );
};

export default SidebarInvoiceCheckout;

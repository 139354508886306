import React from 'react';
import { classNames } from 'primereact/utils';
import { Divider } from '../../Divider';
import { useHistory } from 'react-router-dom';
import './customStyles.css';

const AppRightMenu = (props: any): React.ReactElement => {
  const sidebarClassName = classNames('layout-sidebar-right', {
    'layout-sidebar-right-active': props.rightMenuActive,
  });

  const navigate = useHistory();

  return (
    <div className={sidebarClassName} onClick={props.onRightMenuClick}>
      <Divider>
        <span className="flex text-lg align-items-center font-bold">
          <i className="fa-solid fa-keyboard mx-2"></i>
          Atalhos
        </span>
      </Divider>

      <ul className="p-0" style={{ listStyle: 'none' }}>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/frente-caixa')}
        >
          <span className="p-tag mx-1">F2</span>
          Frente Caixa (PDV)
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/orders/create')}
        >
          <span className="p-tag mx-1">F3</span>
          Novo pedido
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/os/new')}
        >
          <span className="p-tag mx-1">F4</span>
          Nova O.S
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/products/create')}
        >
          <span className="p-tag mx-1">F8</span>
          Novo produto
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/persons/create')}
        >
          <span className="p-tag mx-1">F9</span>
          Novo cliente
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/products-prices/list')}
        >
          <span className="p-tag mx-1">F10</span>
          Lista de preços
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/cash-register/open')}
        >
          <span className="p-tag mx-1">CTRL</span>+
          <span className="p-tag mx-1">F1</span>
          Abertura caixa
        </li>
        <li
          className="item-list my-2 cursor-pointer"
          onClick={() => navigate.push('/boleto/v1/titulo/list')}
        >
          <span className="p-tag mx-1">CTRL</span>+
          <span className="p-tag mx-1">B</span>
          Boletos
        </li>
      </ul>
    </div>
  );
};

export default AppRightMenu;
